import {useEffect, useState} from 'react';

import {AuthorizationConfig} from 'src/types';
import {getConfigurations} from 'src/api/authConfig';

export const useConfig = () => {
  const [config, setConfig] = useState<AuthorizationConfig | undefined>();

  const loadConfig = async () => {
    const {data} = await getConfigurations();
    setConfig(data);
  };

  useEffect(() => {
    loadConfig();
  }, []);

  const loading = !config;

  return {loading, config, loadConfig} as const;
};
