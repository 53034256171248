import {RepositoryIgnore} from 'src/types';
import {makeResourceFetch, makeResourceListFetch} from 'src/api';

type CreateIgnore = Array<Omit<RepositoryIgnore, 'id'>>;

export const getRepositoryIgnores = makeResourceListFetch<Array<RepositoryIgnore>>({method: 'GET', path: 'settings/repository-ignore'});

export const addRepositoryIgnore = makeResourceFetch<Array<RepositoryIgnore>, CreateIgnore>({method: 'PUT', path: 'settings/repository-ignore'});

export const removeRepositoryIgnores = makeResourceFetch<Array<RepositoryIgnore>, Array<RepositoryIgnore>>({method: 'DELETE', path: 'settings/repository-ignore'});
