import memoize from 'lodash/memoize';

import {GraphData} from 'src/types';

import {NodeWithChildren} from './types';

export const organizeTreeFn = ({nodes, relationships}: GraphData) => {
  const nodeMap = nodes.reduce<{[key: string]:typeof nodes[number]}>((accum, n) => ({
    ...accum,
    [n.id]: n,
  }), {});

  const {startToEndMap, endToStartMap} = relationships.filter(isRelevantRelationship).reduce < {
    startToEndMap: {[key: string]: Array<string>},
    endToStartMap: {[key: string]: Array<string>},
  } >(({startToEndMap, endToStartMap}, {startId, endId}) => {
    if (startToEndMap[startId]) {
      startToEndMap[startId].push(endId);
    } else {
      startToEndMap[startId] = [endId];
    }

    if (endToStartMap[endId]) {
      endToStartMap[endId].push(startId);
    } else {
      endToStartMap[endId] = [startId];
    }

    return {
      startToEndMap,
      endToStartMap,
    };
  }, {
    startToEndMap: {},
    endToStartMap: {},
  });

  // since we are manipulating the objects in place with a forEach here to add the `children` property
  // we force the type to accommodate that new property :(
  (nodes as Array<NodeWithChildren>).forEach((n) => {
    if (!n.children) {
      n.children = [];
    }

    const childIds = startToEndMap[n.id];

    if (childIds) {
      childIds.forEach((id) => {
        n.children?.push(nodeMap[id]);
      });
    }

    return n;
  });

  return {
    nodes,
    startToEndMap,
    endToStartMap,

  };
};

export const organizeTree = memoize(organizeTreeFn, ({nodes}) => nodes.map(({id}) => id).sort().join(','));

const isRelevantRelationship = ({type}: {type: string}) => type.startsWith('CONTAINS_') || type === 'GROUPS';

type Node = {id: string, children?: Array<Node>}
export const recursivelySelectChildren = (nodes: Array<Node>, targetState: boolean, selection: {[key: string]: boolean} = {}) => {
  nodes.forEach(({id, children}) => {
    selection[id] = targetState;
    if (children) {
      selection = Object.assign(selection, recursivelySelectChildren(children, targetState, selection));
    }
  });

  return selection;
};
