export const DEFAULT_SERVER_ADDRESS = `${window.location.protocol}//${window.location.hostname}`;

import {
  EXTERNAL_JAVASCRIPT_DOCKER_AGENT_SUPPORT_LINK,
  EXTERNAL_DOTNET_DOCKER_AGENT_SUPPORT_LINK,
  EXTERNAL_JAVA_DOCKER_AGENT_SUPPORT_LINK,
  EXTERNAL_SQL_DOCKER_AGENT_SUPPORT_LINK,
} from 'src/routes';

export const DOC_LINKS = {
  sql: EXTERNAL_SQL_DOCKER_AGENT_SUPPORT_LINK,
  java: EXTERNAL_JAVA_DOCKER_AGENT_SUPPORT_LINK,
  dotnet: EXTERNAL_DOTNET_DOCKER_AGENT_SUPPORT_LINK,
  javascript: EXTERNAL_JAVASCRIPT_DOCKER_AGENT_SUPPORT_LINK,
};

export const IMAGE_NAMES = {
  sql: 'codelogic_sql',
  java: 'codelogic_java',
  dotnet: 'codelogic_dotnet',
  javascript: 'codelogic_javascript',
};
