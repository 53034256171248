import {
  Card,
  CardBody,
  CardTitle,
  CardText,
} from 'reactstrap';

export const StatCard = ({count, label}: {count: number, label: string}) => (
  <Card className='ChangeRequests__requestStatCard'>
    <CardBody>
      <CardTitle tag="h1" aria-label={label}>
        {count}
      </CardTitle>
      <CardText>
        {label}
      </CardText>
    </CardBody>
  </Card>
);
