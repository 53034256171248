import {useEffect, useRef} from 'react';
import {Spinner} from 'reactstrap';

const STEP_1 = '.';
const STEP_2 = '..';
const STEP_3 = '...';

const nextState = (currentState: string) => {
  switch (currentState) {
    case '':
      return STEP_1;
    case STEP_1:
      return STEP_2;
    case STEP_2:
      return STEP_3;
    case STEP_3:
      return STEP_1;
    default:
      return STEP_1;
  }
};

export const TextPlaceholder = ({title}: {title?: string}) => {
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const {current} = ref;
      if (current) {
        current.innerText = nextState(current.innerText);
      }
    }, 700);

    return () => clearInterval(interval);
  }, []);
  return <span className="LoadingPlaceholder__plaintext" title={title} ref={ref}>.</span>;
};


export const SpinnerPlaceholder = ({title}: {title?: string}) => (
  <div title={title} className="LoadingPlaceholder__spinnerWrapper effect__fade-in">
    <Spinner />
  </div>
);
