import {CSSProperties, MouseEvent, ReactNode} from 'react';
import {UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

type Option = {
  onClick?: (e: MouseEvent) => void,
  disabled?: boolean,
  hidden?: boolean,
  title?: string,
  content: ReactNode,
  header?: boolean
}

type Props = {
  size?: 'sm' | 'md' | 'lg',
  styles?: {
    toggle?: CSSProperties,
    item?: CSSProperties
  },
  title?: string,
  type?: 'link' | 'secondary' | 'primary'
  caret?: boolean,
  label?: ReactNode,
  options: Array<Option>,
  hidden?: boolean,
}

export const Menu = ({
  size = 'sm',
  styles,
  type = 'primary',
  title,
  caret,
  label = 'Menu',
  options,
  hidden,
}: Props) => (
  <UncontrolledDropdown hidden={hidden || false}>
    <DropdownToggle title={title} caret={caret} size={size} color={type} style={styles?.toggle}>
      {label}
    </DropdownToggle>
    <DropdownMenu style={{minWidth: 'unset'}}>
      {options.map((option, i) => (
        <DropdownItem
          disabled={option.disabled}
          hidden={option.hidden}
          header={option.header}
          title={option.title}
          style={styles?.item}
          onClick={option.onClick}
          key={i}
        >
          {option.content}
        </DropdownItem>
      ))}
    </DropdownMenu>
  </UncontrolledDropdown>
);
