import {useState, useContext} from 'react';

import {ThemeContext} from 'src/context';
import {THEMES} from 'src/styles/colors';
import {
  makeColorGetter,
  colorLegend as globalColorLegend,
} from 'src/state/dependency2';

export const useLabelColors = () => {
  const {theme: themeName} = useContext(ThemeContext);
  const theme = THEMES[themeName];

  const [colorLegend, setColorMap] = useState(globalColorLegend);

  const getLegendEntry = makeColorGetter(themeName, theme, (key: string, colorObject) => setColorMap({
    ...colorLegend,
    [themeName]: {
      ...colorLegend[themeName],
      [key]: colorObject,
    },
  }));

  return {colorLegend, getLegendEntry};
};
