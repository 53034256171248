import {PropsWithChildren, ReactNode} from 'react';

import './styles.css';

type Props = PropsWithChildren<{
  agentName: string,
  icon: ReactNode,
}>;

export const AgentDownload = ({children, agentName, icon}: Props) => (
  <div className="AgentDownload">
    <div className="AgentDownload__info">
      <div className="AgentDownload__download-icon">{icon}</div>
      <div className="AgentDownload__agent-name">{agentName}</div>
    </div>
    <div className="AgentDownload__download-button-container">
      {children}
    </div>
  </div>
);
