import ReactPaginate, {ReactPaginateProps} from 'react-paginate';
import './styles.css';

export const PageLinks = (props: ReactPaginateProps) => (
  <ReactPaginate
    containerClassName="PageLinks__pagination pagination"
    activeClassName="active"
    pageClassName="page-item"
    pageLinkClassName="page-link"
    breakClassName="page-item"
    breakLinkClassName="page-link"
    nextClassName="page-item"
    nextLinkClassName="page-link"
    previousClassName="page-item"
    previousLinkClassName="page-link"
    {...props}
  />
);
