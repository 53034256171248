import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import moment from 'moment';
import {useEffect, useState} from 'react';
import Swal from 'sweetalert2';

import {useWorkspaces, useRepositoryUrls} from 'src/hooks';
import {ChangeRequestConfig, ChangeRequestConfigDefault, CreateChangeRequestConfigPayload} from 'src/types';
import {createConfig, updateConfig} from 'src/api/changeRequestConfigs';

import './styles-form.css';

type ValidationErrors = Partial<Record<keyof CreateChangeRequestConfigPayload, string>>;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  config?: ChangeRequestConfig;
  defaults?: ChangeRequestConfigDefault;
}

type UnitOption = {
  label: string,
  unit: 'days' | 'weeks' | 'months',
}

const UNIT_OPTIONS: Array<UnitOption> = [
  {label: 'Day', unit: 'days'},
  {label: 'Week', unit: 'weeks'},
  {label: 'Month', unit: 'months'},
];

const ChangeRequestConfigurationForm = ({isOpen, onClose, config, defaults}: Props) => {
  const {workspaces, loading: loadingWorkspaces} = useWorkspaces({autoInitialize: true});
  const {repositoryUrls, loading: loadingRepositoryUrls} = useRepositoryUrls();
  const [unit, setUnit] = useState(UNIT_OPTIONS[2]);
  const [count, setCount] = useState<number | null>();

  const toggle = () => {
    onClose();
  };

  useEffect(() => {
    if (config && config.retention) {
      const duration = moment.duration(config.retention);
      if (duration.months() > 0) {
        setCount(duration.months());
        setUnit(UNIT_OPTIONS[2]);
      } else if (duration.weeks() > 0) {
        setCount(duration.weeks());
        setUnit(UNIT_OPTIONS[1]);
      } else if (duration.days() > 0) {
        setCount(duration.days());
        setUnit(UNIT_OPTIONS[0]);
      }
    } else {
      setCount(null);
      setUnit(UNIT_OPTIONS[2]);
    }
  }, [config]);

  const validate = (value: CreateChangeRequestConfigPayload) => {
    const errors: ValidationErrors = {};

    if (!value.repositoryUrl) {
      errors.repositoryUrl = '*required';
    }

    if (!value.branchPattern) {
      errors.branchPattern = '*required';
    }

    if (!value.workspaceId) {
      errors.workspaceId = '*required';
    }

    return errors;
  };

  const handleSubmit = async (value: CreateChangeRequestConfigPayload) => {
    const result = config ?
      await updateConfig({...value, id: config.id}) :
      await createConfig(value);
    if (result.status === 'success' && result.data) {
      Swal.fire({
        title: 'Success!',
        html: 'Success!',
        width: '45em',
        icon: 'success',
        showConfirmButton: true,
      });
    } else if (result.status === 'error') {
      const errorMessages = result.error.details?.length && result.error.details.length > 0 ? (
        <>
          {result.error.details?.map(({field, message}) => (
            <p key={`${field}__${message}`}><span>{field}</span>: <span>{message}</span></p>
          ))}
        </>
      ) : result.error.message;

      Swal.fire({
        title: 'Error',
        html: errorMessages,
        width: '45em',
        icon: 'error',
        showConfirmButton: true,
      });
    }
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      fade={false}
      modalClassName="height-auto"
      size="xl"
    >
      <ModalHeader toggle={toggle}>{config ? 'Edit' : 'New'} Config</ModalHeader>
      <ModalBody>
        <Formik<CreateChangeRequestConfigPayload>
          validateOnBlur={true}
          initialValues={{
            repositoryUrl: config?.repositoryUrl || defaults?.repositoryUrl || '',
            branchPattern: config?.branchPattern || defaults?.branchPattern || '',
            workspaceId: config?.workspaceId || workspaces?.find((w) => w.id)?.id || '',
            retention: config?.retention || '',
          }}
          validate={validate}
          onSubmit={handleSubmit}>
          {({isSubmitting, setFieldValue}) => (
            <Form>
              <FormGroup>
                <div className="Form__field">
                  <Label for="repositoryUrl">Repository Url</Label>{' '}
                  <span className='Form__error'><ErrorMessage name="repositoryUrl" /></span>
                  <Input
                    tag={Field}
                    name="repositoryUrl"
                    type="text"
                    component="input"
                    list="urls"
                  />
                  <datalist id="urls">
                    { !loadingRepositoryUrls && repositoryUrls &&
                      repositoryUrls.map((url, index) => (
                        <option key={url} selected={index === 0} disabled={false} value={url}>{url}</option>
                      ),
                      )}
                  </datalist>
                </div>
                <div className="Form__field">
                  <Label for="branchPattern">Branch Pattern</Label>{' '}
                  <span className='Form__error'><ErrorMessage name="branchPattern" /></span>
                  <Input
                    tag={Field}
                    name="branchPattern"
                    type="text"
                    component="input"
                  />
                </div>
                <div className="Form__field">
                  <Label for="workspaceId">Comparison Workspace</Label>{' '}
                  <span className='Form__error'><ErrorMessage name="workspaceId" /></span>
                  <Input
                    tag={Field}
                    name='workspaceId'
                    as="select"
                  >
                    {!loadingWorkspaces && workspaces && workspaces.map((workspace, index) => (
                      <option key={workspace.id} selected={index === 0} disabled={false} value={workspace.id}>{workspace.displayName}</option>
                    ))}
                  </Input>
                </div>
                <div className="Form__field">
                  <span className='Form__error'><ErrorMessage name="retention" /></span>
                  <Label for="count">Retention Period</Label>{' '}
                  <div style={{display: 'flex'}}>
                    <Input
                      tag={Field}
                      name='count'
                      type='number'
                      value={count || ''}
                      style={{marginRight: '1rem'}}
                      onChange={(e) => {
                        if (e.target.value) {
                          const countValue = parseInt(e.target.value);
                          setCount(countValue);
                          if (unit) {
                            const duration = moment.duration(countValue, unit.unit).toISOString();
                            setFieldValue('retention', duration);
                          }
                        } else {
                          setCount(null);
                          setFieldValue('retention', null);
                        }
                      }}
                    >
                    </Input>
                    <Input
                      tag={Field}
                      name='unit'
                      as="select"
                      value={unit.label}
                      style={{width: '25%'}}
                      onChange={(e) => {
                        const unitValue = UNIT_OPTIONS.filter((o) => o.label === e.target.value)[0];
                        setUnit(unitValue);
                        if (count && count > 0) {
                          const duration = moment.duration(count, unitValue.unit).toISOString();
                          setFieldValue('retention', duration);
                        }
                      }}
                    >
                      {UNIT_OPTIONS.map((k, index) => (
                        <option key={k.label} disabled={false} value={k.label}>{k.label}</option>
                      ))}
                    </Input>
                  </div>
                  <span>Empty value retains forever.</span>
                </div>
              </FormGroup>
              <Button type="submit" color="primary" disabled={isSubmitting}>Save</Button>
              <Button color="secondary" style={{float: 'right'}} disabled={isSubmitting} onClick={() => onClose()}>Cancel</Button>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ChangeRequestConfigurationForm;
