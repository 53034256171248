import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {StompSessionProvider} from 'react-stomp-hooks';

import {
  ThemeContextProvider,
  WorkspaceContextProvider,
} from 'src/context';

import App from './App';
import * as serviceWorker from './serviceWorker';


import 'bootstrap/dist/css/bootstrap.css';
import '@fontsource/prompt';
import './index.css';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
      <StrictMode>
        <ThemeContextProvider>
          <StompSessionProvider url={'/codelogic/server/ws'}>
            <WorkspaceContextProvider>
              <App />
            </WorkspaceContextProvider>
          </StompSessionProvider>
        </ThemeContextProvider>
      </StrictMode>,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
