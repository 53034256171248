import React, {useEffect, useState} from 'react';

import './styles.css';
import {AuthorizationType} from 'src/types';

import {useConfig} from './hooks';
import {AzureADForm} from './AzureADForm';
import {AuthTypePicker} from './AuthTypePicker';
import {BasicForm} from './BasicForm';

export const AuthTab = () => {
  const {loading, config, loadConfig} = useConfig();
  const [isBasic, setBasic] = useState<boolean>(config?.authorizationType === AuthorizationType.BASIC);
  useEffect(() => {
    setBasic(config?.authorizationType === AuthorizationType.BASIC);
  }, [config]);

  return (
    <div className="AuthTab">
      {!loading && config ? (
        <AuthTypePicker type={config.authorizationType} typeChanged={(type) => {
          setBasic(type === AuthorizationType.BASIC);
        }
        } />
      ) : null
      }
      {!loading && isBasic && <BasicForm onSuccess={() => loadConfig()}/>}
      {!loading && !isBasic && config && <AzureADForm authorizationConfig={config}/>}
    </div>);
};
