import {
  Binding,
  GraphObject,
  Panel,
  Spot,
  TextBlock,
} from 'gojs';

const {make} = GraphObject;

export const tooltipGenerator = (n: {metadata: {type?: string}}) => {
  return n.metadata?.type ?? 'no type info';
};

export const makeTooltip = () =>
  make('ToolTip',
      make(Panel, 'Vertical',
          make(Panel, 'Horizontal', {alignment: Spot.Left},
              make(TextBlock, {margin: 4, text: 'Item Name:', font: 'bold 13px sans-serif'}),
              make(TextBlock, {margin: 4},
                  new Binding('text', 'text'),
              ),
          ),
          make(Panel, 'Horizontal', {alignment: Spot.Left},
              make(TextBlock, {margin: 4, text: 'Type:', font: 'bold 13px sans-serif'}),
              make(TextBlock, {margin: 4},
                  new Binding('text', '', tooltipGenerator),
              ),
          ),
      ),
  );

