import {FiDownload} from 'react-icons/fi';

import {
  Menu,
} from 'src/components';

import {downloadCSV} from './utils';

export const CSVDownloadMenu = ({
  jobId,
  leftName,
  rightName,
}: {
  jobId: string,
  leftName: string,
  rightName: string
}) => (
  <Menu
    caret
    type="secondary"
    title="Download a CSV containing information about all relevant items in the graph."
    label={<FiDownload strokeWidth={3} size="1.2rem" />}
    size="md"
    styles={{
      item: {
        textAlign: 'right',
      },
    }}
    options={[{
      onClick: () => downloadCSV({
        jobId,
        fileName: `CodeLogic__${leftName}_to_${rightName}_additions.csv`,
        type: 'added',
      }),
      title: 'A CSV of items that were added between the two compared releases.',
      content: <span>Download CSV of <strong>added</strong> items</span>,
    }, {
      onClick: () => downloadCSV({
        jobId,
        fileName: `CodeLogic__${leftName}_to_${rightName}_removals.csv`,
        type: 'removed',
      }),
      title: 'A CSV of items that were removed between the two compared releases.',
      content: <span>Download CSV of <strong>removed</strong> items</span>,
    }, {
      onClick: () => downloadCSV({
        jobId,
        fileName: `CodeLogic__${leftName}_to_${rightName}_impacts.csv`,
        type: 'impacted',
      }),
      title: 'A CSV with all items that were impacted between the two compared releases.',
      content: <span>Download CSV of <strong>impacted</strong> items</span>,
    }]}
  />
);
