import {MouseEvent, useState} from 'react';
import classnames from 'classnames';
import {FaChevronRight} from 'react-icons/fa';

import './styles.css';

// Tied to:
// https://github.com/CodeLogicIncEngineering/capeui/blob/integration/client/src/components/NodeHierarchyList/styles.css#L2
const TOGGLE_ANIMATION_DURATION = 250;

type ToggleTransitionStates = undefined | 'toggling-off' | 'toggling-on'
export const DetailToggle = ({
  className = '',
  toggled,
  onToggle = () => {},
}: {
  className?: string,
  toggled: boolean,
  onToggle: (newState: boolean) => void,
}) => {
  const [transition, setTransition] = useState<ToggleTransitionStates>();

  const toggle = (e: MouseEvent) => {
    e.stopPropagation();
    onToggle(!toggled);
    setTransition(toggled ? 'toggling-off' : 'toggling-on');
    setTimeout(() => {
      setTransition(undefined);
    }, TOGGLE_ANIMATION_DURATION);
  };

  return (
    <button
      className={classnames({
        'DetailToggle': true,
        [className]: !!className,
      })}
      onClick={toggle}
    >
      <FaChevronRight

        className={classnames({
          'DetailToggle__icon': true,
          'DetailToggle__icon--toggled': toggled,
          'DetailToggle__icon--toggling-on': transition === 'toggling-on',
          'DetailToggle__icon--toggling-off': transition === 'toggling-off',
        })}/>
    </button>
  );
};
