import {WORKSPACE_MENU_RECEIVER_ID} from 'src/constants';

import './styles.css';

export const WorkspaceMenuReceiver = () => (
  <div className="WorkspaceMenuReceiver">
    <div
      className="WorkspaceMenuReceiver__content"
      id={WORKSPACE_MENU_RECEIVER_ID}
    />
  </div>
);
