import {useState} from 'react';

import {ComparisonJobById} from 'src/types';
import {getComparisonJob} from 'src/api/comparison-job';

export const useComparisonJob = () => {
  const [comparisonJob, setComparisonJob] = useState<ComparisonJobById | undefined>();
  const [loading, setLoading] = useState(false);

  const fetchComparisonJob = async ({
    id,
  }: {
    id: string,
  }) => {
    setLoading(true);

    const response = await getComparisonJob({comparisonJobId: id});

    if (response.status === 'success') {
      setComparisonJob(response.data);
    }

    setLoading(false);
  };

  return {
    loading,
    comparisonJob,
    fetchComparisonJob,
  } as const;
};
