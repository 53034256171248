import {Modal, ModalBody} from 'reactstrap';
import {ReactEventHandler, useContext} from 'react';

import {ColorLegendContext, ThemeContext} from 'src/context';
import {THEMES} from 'src/styles/colors';
import {Tag} from 'src/components/Tag';
import {colorTag} from 'src/state/dependency2';
import {PropertyList} from 'src/components/PropertyList';
import {LoadingPlaceholder} from 'src/components/LoadingPlaceholder';
import {useRelationship} from 'src/hooks/api';
import {ModalCloseButton} from 'src/components/ModalCloseButton';

export const RelationshipDetailModal = (
    {isOpen, toggle, to, from, type} :
  {isOpen: boolean, toggle: ReactEventHandler, to: string, from: string, type: string}) => {
  const {loading, relationship} = useRelationship(from, type, to);
  const {theme: themeName} = useContext(ThemeContext);
  const {getLegendEntry} = useContext(ColorLegendContext);
  const theme = THEMES[themeName];
  const {color: borderColor} = type ? colorTag(type, {theme, themeName, transparency: false, type: 'node'}) : {color: undefined};

  const {color, fgColor} = type ? getLegendEntry(type) : {fgColor: undefined, color: undefined};
  return (
    <Modal isOpen={isOpen} toggle={toggle} fade={false} modalClassName="NodeDetailModal" fullscreen >
      <ModalBody className='NodeDetailModalBody' style={{borderColor}}>
        {type && (
          <div className="NodeDetailHeader__secondary__item">
            <Tag
              fontSize="1rem"
              label={type}
              backgroundColor={color}
              color={fgColor}
            />
          </div>
        )}
        {loading || !relationship ? <LoadingPlaceholder /> : <PropertyList properties={relationship.properties}/>}
      </ModalBody>
      <ModalCloseButton toggle={toggle} />
    </Modal>
  );
};
