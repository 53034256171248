import './styles.css';

export const SettingHolder = ({name, description, selector}: {name: string, description: string, selector: JSX.Element}) => {
  return (
    <div className='SettingHolder'>
      <div className='SettingHolder__explain-block'>
        <span className='SettingHolder__field-text'>{name}</span>
        <span className='SettingHolder__field-desc'>{description}</span>
      </div>
      <div className='SettingHolder__toggle-holder'>
        {selector}
      </div>
    </div>
  );
};
