import {useEffect, useState} from 'react';
import classnames from 'classnames';
import {IoIosArrowDropleft, IoIosArrowDropright} from 'react-icons/io';
import {FaCubes} from 'react-icons/fa';
import {BsUpcScan} from 'react-icons/bs';
import {NavLink as Link} from 'react-router-dom';
import {FaArchive, FaDatabase} from 'react-icons/fa';
import {IoCompassSharp, IoGitMerge} from 'react-icons/io5';
import {
  Nav,
  Navbar,
  NavItem,
  NavLink,
} from 'reactstrap';
import {debounce} from 'lodash';

import {
  AGENTS_PATH,
  CHANGE_REQUEST_PATH,
  RELEASE_ASSISTANT_PATH,
  SCAN_ARTIFACTS_PATH,
  SCAN_DATABASE_PATH,
  SEARCH_PATH,
  STORE_PATH,
} from 'src/routes';
import {
  SIDEBAR_EXPANDED,
  LOCAL_SETTING_SIDEBAR_AUTO_TOGGLE,
  LOCAL_SETTING_KEY_WELCOME_GUIDE,
} from 'src/constants';
import {useStoredValue, useFeatureFlag, useLocalSetting} from 'src/hooks';
import {getActuatorInfo} from 'src/api/actuator';
import {ReactComponent as RocketIcon} from 'src/images/rocket.svg';
import {ReactComponent as Logo} from 'src/svgs/codelogic-logo.svg';

import {IntroLink} from './components';

import './styles.css';

// relates to ./styles.css#240-264
const ANIMATION_DURATION = 500;
const AUTO_COLLAPSE_THRESHOLD = 1200;

export const SideNav = () => {
  const [serverSideScanningEnabled] = useFeatureFlag('FEATURE_FLAG_SERVER_SIDE_SCANNING');
  const [welcomeWizardEnabled, setWelcomeWizardEnabled] = useLocalSetting<boolean>(LOCAL_SETTING_KEY_WELCOME_GUIDE, true);
  const [autoToggleOn] = useStoredValue(LOCAL_SETTING_SIDEBAR_AUTO_TOGGLE, true);
  const [isExpanded, setIsExpanded] = useStoredValue(SIDEBAR_EXPANDED, autoToggleOn ? window.innerWidth > AUTO_COLLAPSE_THRESHOLD : true);
  const [isCollapsing, setIsCollapsing] = useState(false);
  const [isExpanding, setIsExpanding] = useState(false);
  const [version, setVersion] = useState('');
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const expandEvent = new Event('expand-nav');
  const contractEvent = new Event('contract-nav');

  useEffect(() => {
    const loadVersion = async () => {
      const {data} = await getActuatorInfo();
      if (data && data.app.version) {
        setVersion(data.app.version.replaceAll('\'', ''));
      }
    };
    loadVersion();
  }, []);


  useEffect(() => {
    const handleResize = debounce(() => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 100);

    handleResize();
    window.addEventListener('resize', handleResize, false);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!autoToggleOn) {
      return;
    }
    const doneResizing = () => {
      if (dimensions.width < AUTO_COLLAPSE_THRESHOLD) {
        if (isExpanded) {
          collapse();
        }
      } else if (dimensions.width > AUTO_COLLAPSE_THRESHOLD) {
        if (!isExpanded) {
          expand();
        }
      } else {
        return;
      }
    };
    doneResizing();
  }, [dimensions.width]);


  const expand = () => {
    dispatchEvent(expandEvent);
    setIsExpanding(true);
    setIsExpanded(true);
    setTimeout(() => {
      setIsExpanding(false);
    }, ANIMATION_DURATION);
  };

  const collapse = () => {
    setIsCollapsing(true);
    dispatchEvent(contractEvent);
    setTimeout(() => {
      setIsCollapsing(false);
      setIsExpanded(false);
    }, ANIMATION_DURATION);
  };

  const handleExpandCollapseToggle = isExpanding || isCollapsing ? undefined : isExpanded ? collapse : expand;

  return (
    <Navbar
      className={classnames({
        'SideNav': true,
        'SideNav--collapse-transition': isCollapsing,
        'SideNav--expand-transition': isExpanding,
        'SideNav--collapsing': isCollapsing,
        'SideNav--expanding': isExpanding,
        'SideNav--expanded': isExpanded,
        'SideNav--collapsed': !isExpanded,
      })}
      light
    >
      <Nav navbar>
        <NavItem>
          <button className="link-button nav-link" onClick={handleExpandCollapseToggle}>
            {
              isExpanded && !isExpanding ?
                <IoIosArrowDropleft title="Collapse" className="SideNav__icon" /> :
                <IoIosArrowDropright title="Expand" className="SideNav__icon" />}
          </button>
        </NavItem>
        {welcomeWizardEnabled && (
          <IntroLink
            isExpanded={isExpanded}
            onClose={() => setWelcomeWizardEnabled(false)}
          />
        )}
        <NavItem>
          <NavLink tag={Link} to={SEARCH_PATH}>
            <IoCompassSharp className="SideNav__icon" />
            {isExpanded && <div title="Search" className="SideNav__text-container"><span>Search</span></div>}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={CHANGE_REQUEST_PATH}>
            <IoGitMerge title="Change Requests" className="SideNav__icon" />
            {isExpanded && <div className="SideNav__text-container"><span>Change Requests</span></div>}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={RELEASE_ASSISTANT_PATH}>
            <RocketIcon title="Release Assistant" className="SideNav__icon" />
            {isExpanded && <div className="SideNav__text-container"><span>Release Assistant</span></div>}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={AGENTS_PATH}>
            <BsUpcScan title="Agents" className="SideNav__icon" />
            {isExpanded && <div className="SideNav__text-container"><span>Agents</span></div>}
          </NavLink>
        </NavItem>
        {serverSideScanningEnabled && (
          <>
            <NavItem>
              <NavLink tag={Link} to={SCAN_ARTIFACTS_PATH}>
                <FaArchive title="Scan Artifacts" className="SideNav__icon" />
                {isExpanded && <div className="SideNav__text-container"><span>Scan Artifacts</span></div>}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={SCAN_DATABASE_PATH}>
                <FaDatabase title="Scan Databases" className="SideNav__icon" />
                {isExpanded && <div className="SideNav__text-container"><span>Scan Databases</span></div>}
              </NavLink>
            </NavItem>
          </>
        )}
        <NavItem>
          <NavLink tag={Link} to={STORE_PATH}>
            <FaCubes title="Store" className="SideNav__icon" />
            {isExpanded && <div className="SideNav__text-container"><span>Store</span></div>}
          </NavLink>
        </NavItem>
      </Nav>
      <div className='VersionInfo' >
        <div className='VersionLogo'>
          <Logo />
        </div>
        <p className={classnames({
          'VersionText': true,
          'VersionText--collapse-transition': isCollapsing,
          'VersionText--expand-transition': isExpanding,
          'VersionText--collapsed': !isExpanded,
        })}>v{version}</p>
      </div>
    </Navbar>
  );
};
