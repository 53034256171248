// TODO: refer to names in properties, not on 'text'

const LIMIT = 60;

const findReasonableBreakPoint = (text: string, character: string) => {
  if (text.length > LIMIT) {
    const textMid = Math.floor(text.length / 2);
    let afterLast = text.slice(0, textMid).lastIndexOf(character);

    if (afterLast === -1) {
      afterLast = text.slice(textMid).indexOf(character) + textMid;
      if (afterLast < textMid) {
        afterLast = -1;
      }
    }

    if (afterLast === -1) {
      afterLast = textMid;
    }

    if (afterLast > 0) {
      return afterLast;
    }
  }
};

export const nameGenerator = (n: {data: {properties?: {shortName?:string, }, text: string}}) => {
  const name = (n.data.properties?.shortName ? n.data.properties.shortName : n.data.text);

  const breakpoint = Math.min(
      findReasonableBreakPoint(name, ' ') || Infinity,
      // findReasonableBreakPoint(name, '.') || Infinity,
      // findReasonableBreakPoint(name, '-') || Infinity,
      // findReasonableBreakPoint(name, '_') || Infinity,
  );

  if (breakpoint > -1 && Number.isFinite(breakpoint)) {
    return name.slice(0, breakpoint) + '\n' + name.slice(breakpoint);
  }

  return name;
};
