import {listMaterializedViewDefinitions} from 'src/api/materializedViewDefinitions';

export const loadOptions = async () => {
  const result =
    await listMaterializedViewDefinitions({
      type: 'RELEASE_ASSISTANT',
      pageNumber: 0,
      pageSize: 300,
    });

  if (result.status === 'success') {
    return result.data || [];
  } else {
    return [];
  }
};
