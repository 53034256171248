import {ReactNode, useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {IoWarningOutline} from 'react-icons/io5';
import withReactContent from 'sweetalert2-react-content';
import SwalBase from 'sweetalert2';
import {Progress} from 'reactstrap';

import {
  RELATIVE_COMPARISON_VIEW_PATH,
  RELEASE_ASSISTANT_CREATE_PATH,
} from 'src/routes';
import {ReactComponent as CircleArrowUpIcon} from 'src/images/circle-arrow-up.svg';
import {ReactComponent as GearsIcon} from 'src/images/gears.svg';
import {parseDate} from 'src/util/date';
import {
  Table,
  ActionsMenu,
} from 'src/components';
import {downloadCSV} from 'src/components/Comparison/utils';
import {deleteComparisonJob} from 'src/api/comparison-job';
import {isError, isSuccess} from 'src/components/Administration/utils';
import {MaterializedViewDefinition, UserRole} from 'src/types';
import {AuthContext} from 'src/context';
import {deleteMaterializedViewDefinition} from 'src/api/materializedViewDefinitions';

import {
  RELEASE_ANALYSIS_RESULTS_TITLE_TEXT,
  RELEASE_ANALYSIS_RESULTS_HELP_TEXT,
  RELEASES_TITLE_TEXT,
  RELEASES_HELP_TEXT,
} from './messages';
import {
  useReleaseAssistantJobs,
  useMaterializedViewDefinitions,
} from './hooks';


const Swal = withReactContent(SwalBase);
export const ReleaseAssistantJobTable = ({
  triggerRefetch,
}: {
  triggerRefetch?: number,
}) => {
  const {hasRole} = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const canDelete = hasRole(UserRole.ADMIN);

  const {
    loading,
    releaseAssistantJobs,
    metadata,
    fetchReleaseAssistantJobs,
  } = useReleaseAssistantJobs();

  const fetchJobs = () => {
    fetchReleaseAssistantJobs({
      page: currentPage,
      pageSize,
    });
  };

  const comparisonDeleteConfirmation = async (id: string, name: string) => {
    const response = await Swal.fire({
      title: `Are you sure you want to delete comparison "${name}"?`,
      text: 'This comparison will no longer be viewable.',
      confirmButtonText: 'Delete',
      showCancelButton: true,
      customClass: 'WorkspaceAlert',
      iconHtml: <IoWarningOutline></IoWarningOutline>,
    });

    if (response.isConfirmed) {
      await removeComparisonJob(id, name);
      fetchJobs();
    }
  };

  const removeComparisonJob = async (id: string, name: string) => {
    const result = await deleteComparisonJob(id);
    if (isSuccess(result)) {
      Swal.fire({
        title: 'Success!',
        text: `Successfully deleted comparison "${name}"`,
        width: '45em',
        icon: 'success',
        showConfirmButton: true,
      });
    } else {
      if (isError(result)) {
        Swal.fire({
          title: 'Error',
          html: <span>{result.error.message}</span>,
          width: '45em',
          icon: 'error',
        });
      } else {
        Swal.fire({
          title: 'Error',
          html: <span>{`Error deleting comparison: ${name}`}</span>,
          width: '45em',
          icon: 'error',
        });
      }
    }
  };

  useEffect(() => {
    fetchJobs();
  }, [currentPage, pageSize, triggerRefetch]);

  return (
    <Table
      loading={loading}
      noRecordsMessage={
        <EmptyStateHelpMessage
          image={<CircleArrowUpIcon />}
          title={RELEASE_ANALYSIS_RESULTS_TITLE_TEXT}
          content={RELEASE_ANALYSIS_RESULTS_HELP_TEXT}
        />
      }
      onPageChange={setCurrentPage}
      onSelectPageSize={setPageSize}
      columns={[{
        id: 'comparison',
        cell: ({
          id,
          status,
          diffMaterializedViewDefinition: {displayName: diffDisplayName},
        }) => {
          return status === 'COMPLETE' ? <Link
            state={{jobId: id}}
            to={RELATIVE_COMPARISON_VIEW_PATH.replace(/:entityId/, id)}
          >{`${diffDisplayName}`}</Link> : `${diffDisplayName}`;
        },
        header: () => <span>Comparison</span>,
      }, {
        id: 'status',
        cell: ({status, currentStep, totalSteps}) => {
          if (status === 'RUNNING' && currentStep > 0 && totalSteps > 0) {
            return (<>
              <span>{status}</span>
              <Progress value={(currentStep / totalSteps) * 100} />
            </>);
          } else {
            return (<span>{status}</span>);
          }
        },
        header: () => <span>Status</span>,
      }, {
        id: 'created',
        cell: ({createdOn}) => <span>{parseDate(createdOn)}</span>,
        header: () => <span>Created</span>,
      }, {
        id: 'actions',
        header: () => <span>Actions</span>,
        cell: ({
          id,
          status,
          diffMaterializedViewDefinition: {displayName: diffDisplayName},
        }) => {
          return status !== 'COMPLETE' ? (
          <ActionsMenu options={[
            {
              onClick: async () => await comparisonDeleteConfirmation(id, `${diffDisplayName}`),
              title: 'Delete this comparison result.',
              content: 'Delete Comparison',
              disabled: !canDelete,
            },
          ]} />
          ) : (
          <ActionsMenu options={[
            {
              onClick: async () => await comparisonDeleteConfirmation(id, `${diffDisplayName}`),
              title: 'Delete this comparison result.',
              content: 'Delete Comparison',
              disabled: !canDelete,
            }, {
              header: true,
              content: <span style={{fontWeight: 'bold'}}>Export CSV</span>,
            }, {
              onClick: () => downloadCSV({
                jobId: id,
                fileName: `CodeLogic__${diffDisplayName?.replace(' --> ', '_')}_unresolved-reference-diff.csv`,
              }),
              title: 'Export Unresolved Referenced that changed between the two compared releases.',
              content: 'Unresolved References',
            }, {
              onClick: () => downloadCSV({
                jobId: id,
                fileName: `CodeLogic__${diffDisplayName?.replace(' --> ', '_')}_additions.csv`,
                type: 'added',
              }),
              title: 'Export items that were added between the two compared releases.',
              content: 'Added Items',
            }, {
              onClick: () => downloadCSV({
                jobId: id,
                fileName: `CodeLogic__${diffDisplayName?.replace(' --> ', '_')}_removals.csv`,
                type: 'removed',
              }),
              title: 'Export items that were removed between the two compared releases.',
              content: 'Removed Items',
            }, {
              onClick: () => downloadCSV({
                jobId: id,
                fileName: `CodeLogic__${diffDisplayName?.replace(' --> ', '_')}_impacts.csv`,
                type: 'impacted',
              }),
              title: 'Export items that were impacted between the two compared releases.',
              content: 'Impacted Items',
            },
          ]} />
        );
        },
      }]}
      rows={releaseAssistantJobs}
      metadata={metadata}
    />
  );
};

export const MaterializedViewDefinitionTable = ({
  triggerRefetch,
}: {
  triggerRefetch?: number,
}) => {
  const {
    loading,
    materializedViewDefinitions,
    metadata,
    fetchMaterializedViewDefinitions,
  } = useMaterializedViewDefinitions({type: 'RELEASE_ASSISTANT'});
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const fetchJobs = () => {
    fetchMaterializedViewDefinitions(currentPage, pageSize);
  };

  useEffect(() => {
    fetchJobs();
  }, [triggerRefetch, currentPage, pageSize]);


  const deleteMaterializedViewDefinitionOnClick = async (definition: MaterializedViewDefinition) => {
    const response = await Swal.fire({
      title: `Are you sure you want to delete ${definition.displayName}?`,
      text: 'Deleting this relese will remove all of its data.',
      confirmButtonText: 'Delete',
      showCancelButton: true,
      customClass: 'DefinitionAlert',
      iconHtml: <IoWarningOutline />,
    });

    if (response.isConfirmed) {
      const result = await deleteMaterializedViewDefinition(definition.id);
      if (!result || result.status === 'error') {
        await Swal.fire({
          title: 'Error!',
          text: `Unable to delete ${definition.displayName}`,
          icon: 'error',
          timer: 1200,
          showConfirmButton: false,
        });
      } else {
        await Swal.fire({
          title: 'Success!',
          text: `Successfully deleted ${definition.displayName}`,
          icon: 'success',
          timer: 1200,
          showConfirmButton: false,
        });
        fetchJobs();
      }
    }
  };

  return (
    <Table
      loading={loading}
      noRecordsMessage={
        <EmptyStateHelpMessage
          image={<GearsIcon />}
          title={RELEASES_TITLE_TEXT}
          content={RELEASES_HELP_TEXT}
        />
      }
      onPageChange={setCurrentPage}
      onSelectPageSize={setPageSize}
      columns={[{
        id: 'displayName',
        cell: ({displayName}) => displayName,
        header: () => 'Name',
      }, {
        id: 'actions',
        header: () => <span>Actions</span>,
        cell: (d) => (
          <ActionsMenu options={[
            {
              onClick: async () => {
                await deleteMaterializedViewDefinitionOnClick(d);
              },
              title: 'Delete this Release.',
              content: 'Delete Release',
            },
          ]} />
        )}]}
      rows={materializedViewDefinitions}
      metadata={metadata}
    />
  );
};

export const EmptyStateHelpMessage = ({
  image,
  title,
  content,
}: {
  image: ReactNode,
  title: ReactNode,
  content: ReactNode,
}) => (
  <div className="EmptyStateHelpMessage">
    <div className="EmptyStateHelpMessage__image">
      {image}
    </div>
    <h2>
      {typeof title === 'string' ? <p>{title}</p> : title}
    </h2>
    <div>
      {typeof content === 'string' ? <p>{content}</p> : content}
    </div>
  </div>
);

export const CreateButton = () => {
  const navigate = useNavigate();
  return (
    <button
      className='ReleaseAssistantPage__create-button CreateButton btn btn-primary'
      onClick={ () => navigate(`${RELEASE_ASSISTANT_CREATE_PATH}`)
      }>New Release</button>
  );
};

