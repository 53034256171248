import {PropsWithChildren, useState} from 'react';
import classnames from 'classnames';
import {VscChromeClose} from 'react-icons/vsc';
import {Button} from 'reactstrap';

import './styles.css';

/* `150` is taken from and must match Bootstrap's animation speed: `client/node_modules/bootstrap/scss/_variables.scss` */
const FADE_DELAY_MS = 150;

export const Banner = ({onClose, children}: PropsWithChildren<{onClose: () => void}>) => {
  const [isHiding, setIsHiding] = useState(false);
  const close = () => {
    setTimeout(()=> {
      onClose();
    }, FADE_DELAY_MS);

    setIsHiding(true);
  };

  return (
    <div className={classnames({
      'Banner': true,
      'alert': true,
      'alert-dismissible': true,
      'fade': isHiding,
      'show': !isHiding,
    })} role="alert" >
      {children}
      <Button className='Banner__link' color='link' onClick={close}>
        <VscChromeClose></VscChromeClose>
      </Button>
    </div>
  );
};
