import {useAuthUrl} from './hooks';

export const goToUrl = (url?: string) => {
  if (url) {
    return window.location.assign(url);
  } else {
    return '';
  }
};

export const AzureAdLink = () => {
  const {loading, url} = useAuthUrl();

  return (
    <>
      {!loading && url && (
        <>
          <div className='Login__divider'>
            <div className='Login__line'></div><div className='Login__text'>or</div><div className='Login__line'></div>
          </div>
          <button className='Login__button' onClick={() => {
            goToUrl(url);
          }}>
          Azure AD Log In
          </button>
        </>
      )}
    </>
  );
};
