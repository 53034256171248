import {MultiValueProps, OptionProps} from 'react-select';

import {Tag} from '../Tag';

import {Field} from './types';

export const MultiValue = ({data: {backgroundColor, color, label}, removeProps: {onClick}}: MultiValueProps<Field>) => {
  return (
    <div className="TagSelect__multiValue">
      <Tag onClickSecondary={onClick} backgroundColor={backgroundColor} color={color} label={label} />
    </div>
  );
};

export const Option = ({innerProps, isDisabled, label, data: {color, backgroundColor}}: OptionProps<Field>) => {
  return !isDisabled ? (
    <div {...innerProps} className="TagSelect__option">
      <button className="TagSelect__option-button">
        <Tag backgroundColor={backgroundColor} color={color} label={label} />
      </button>
    </div>
  ) : null;
};
