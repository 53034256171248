import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {LoadingPlaceholder, NoDataPlaceholder} from 'src/components';
import {NodeDetailParams} from 'src/types';
import {CODELOGIC_UI_PATH as UI_PATH} from 'src/config';
import {useNode} from 'src/hooks';

import {NodeDetail} from './NodeDetail';

import './styles.css';

export const NodeDetailPage = ({id: idProp}: {id?: string}) => {
  const navigate = useNavigate();
  const {nodeId} = useParams<NodeDetailParams>();
  const [id, setId] = useState(idProp || nodeId);

  const {loading, node, fetchNodeById} = useNode(id);

  useEffect(() => {
    if (id && node && id !== node.id) {
      fetchNodeById(id);
    }
  }, [id, fetchNodeById, node]);

  const goToNode = idProp ? (newId: string) => {
    if (newId !== id) setId(newId);
  } : (id: string) => {
    if (id !== nodeId) navigate(`${UI_PATH}/node/${id}`);
  };

  return (
    <div className="NodeDetailContainer">
      {loading ?
        <LoadingPlaceholder /> :
        (node) ? (
          <NodeDetail
            node={node}
            goToNode={goToNode}
          />
        ) : (
          <NoDataPlaceholder>
            Unable to fetch data for node
          </NoDataPlaceholder>
        )
      }
    </div>
  );
};
