import type {ThemeName} from 'src/context';
import {DEFAULT_THEME} from 'src/constants';
import {THEMES} from 'src/styles/colors';
import {
  Diagram,
  Margin,
  GraphLinksModel,
  GraphObject,
  Spot,
  TreeLayout,
  InputEvent,
} from 'src/util/gojs/go';
import {GOJS_LICENSE_KEY as LICENSE_KEY} from 'src/gojs-license';
import {nameTypeComparer} from 'src/graph';

type Theme = typeof THEMES[ThemeName];

const {make} = GraphObject;

Diagram.licenseKey = LICENSE_KEY;


export const makeDiagram = ({
  handleContextClick = () => {},
  theme = THEMES[DEFAULT_THEME],
  themeName = DEFAULT_THEME,
} : {
  handleContextClick?: (e: InputEvent) => void,
    theme?: Theme,
    themeName: keyof typeof THEMES
}) =>
  make(Diagram, {
    'undoManager.isEnabled': true,
    'animationManager.isEnabled': false,
    'contentAlignment': Spot.Left,
    'scrollMargin': new Margin(100, 500, 100, 100),
    'layout': make(TreeLayout, {
      nodeSpacing: 10,
      layerSpacing: 20,
      sorting: TreeLayout.SortingAscending,
      comparer: nameTypeComparer,
    }),
    'model': make(GraphLinksModel, {
      modelData: {
        theme,
        themeName,
      },
      linkKeyProperty: 'id',
      nodeKeyProperty: 'id',
    }),
  }, {
    contextClick: handleContextClick,
  });
