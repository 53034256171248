import {CSSProperties, PropsWithChildren, useEffect, useState} from 'react';
import classnames from 'classnames';

import './styles.css';

const DEFAULT_DISPLAY_DELAY_MS = 500;

export const DelayedDisplay = ({
  style,
  className,
  delayMS = DEFAULT_DISPLAY_DELAY_MS,
  children,
}: PropsWithChildren<{
  style?: CSSProperties,
  className?: string,
  delayMS?: number
}>) => {
  const [displayed, setDisplayed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayed(true);
    }, delayMS);

    return () => clearTimeout(timer);
  }, [delayMS]);

  return displayed ? (

        <div
          style={style}
          className={classnames({
            'DelayedDisplay': true,
            'effect__fade-in': true,
            [className || '']: !!className,
          })}>
          {children}
        </div>
  ) : null;
};
