import {
  ApiErrorResponse,
  ApiResponse,
  Namespace,
  ValidationError,
} from 'src/types';
import {CODELOGIC_API_BASE_URL} from 'src/config';

import {axiosInstance} from './baseApi';
import {isAxiosError} from './utils';

import {makeResourceListFetch} from './index';

export const getNamespaces = makeResourceListFetch<Array<Namespace>>({method: 'GET', path: 'settings/namespace'});

export const addNamespace = async (namespaces: Array<Namespace>): Promise<ApiResponse | ApiErrorResponse<ValidationError>> => {
  try {
    const response = await axiosInstance.put(`${CODELOGIC_API_BASE_URL}/settings/namespace`, namespaces, {withCredentials: true});
    return response?.data;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse<ValidationError>>(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
};

export const removeNamespace = async (namespace: Namespace): Promise<ApiResponse | ApiErrorResponse<ValidationError>> => {
  try {
    const response = await axiosInstance.delete(`${CODELOGIC_API_BASE_URL}/settings/namespace`, {
      withCredentials: true,
      data: [
        {
          namespace: namespace.namespace,
          dataSourceId: namespace.dataSourceId,
        },
      ],
    });
    return response?.data;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse<ValidationError>>(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
};
