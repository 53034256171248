import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {useParams} from 'react-router-dom';
import AsyncSelect from 'react-select/async';

import {WhereUsedData} from 'src/types';
import {relationshipTypes, nodeTypes} from 'src/api/reports';
import {
  LoadingPlaceholder,
  NoDataPlaceholder,
  NodeDetailHeader,
  NodeDetailModal,
  NodeToNodeRow,
  GenericList,
  MetaDataPager,
} from 'src/components';
import {WorkspaceContext} from 'src/context';
import {useNode} from 'src/hooks';

import {
  Container,
  Divider,
  FilterContainer,
  FiltersContainer,
  ListContainer,
} from './styled';
import {makeTableDataFetcher, makeOptionLoader} from './utils';


export const NodeUsageTable = ({
  id: nodeIdProp,
}: {
  id?: string,
}) => {
  const [modalNodeId, setModalNodeId] = useState<string>();
  const {currentMaterializedView} = useContext(WorkspaceContext);

  const [nodeFilter, setNodeFilter] = useState<Array<string>>([]);
  const [relationshipFilter, setRelationshipFilter] = useState<Array<string>>([]);

  const closeModal = () => setModalNodeId(undefined);
  const openModal = (id: string) => setModalNodeId(id);

  const {
    id: nodeIdParam,
  } = useParams<{
    id: string | undefined,
  }>() ?? {};

  const nodeId = nodeIdProp || nodeIdParam;

  const {node} = useNode(nodeId);

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [{data, metaData}, setData] = useState<{data?: Array<WhereUsedData>, metaData?: any}>({
    data: undefined,
    metaData: undefined,
  });

  const fetchData = makeTableDataFetcher(setData);

  useEffect(() => {
    (async () => {
      if (nodeId ) {
        setIsLoading(true);
        await fetchData({
          nodeId,
          nodeTypes: nodeFilter,
          relationshipTypes: relationshipFilter,
          page: currentPage,
          pageSize,
        });
        setIsLoading(false);
      }
    })();
  }, [nodeId, nodeFilter, relationshipFilter, pageSize, currentPage]);

  return (
    <Container>
      {node && (
        <>
          <NodeDetailHeader
            id={node.id}
            name={node.name}
            identity={node.identity}
            type={node.type}
          />
          <Divider />
        </>
      )}
      <FiltersContainer>
        {nodeId && (
          <>
            <FilterContainer>
              <AsyncSelect
                defaultOptions
                cacheOptions
                isClearable
                isMulti
                placeholder="Item"
                onChange={(options) => setNodeFilter(options.map(({value}) => value))}
                loadOptions={makeOptionLoader(nodeTypes, nodeId)}
              />
            </FilterContainer>
            <FilterContainer>
              <AsyncSelect
                defaultOptions
                cacheOptions
                isClearable
                isMulti
                placeholder="Relationship"
                onChange={(options) => setRelationshipFilter(options.map(({value}) => value))}
                loadOptions={makeOptionLoader(relationshipTypes, nodeId)}
              />
            </FilterContainer>
          </>
        )}
      </FiltersContainer>
      <ListContainer>
        {!isLoading && data?.length === 0 ? (
        <NoDataPlaceholder>
          This element is not explicitly referred to or activated by any other component within the graph.
        </NoDataPlaceholder>
      ) : (
      <div style={{position: 'relative'}}>
        {isLoading && <LoadingPlaceholder />}
        {!isLoading && !!data && (
          <GenericList
            rows={data}
            renderItem={(item) => (
              <NodeToNodeRow
                key={`${item.fromItem.id}__${item.toItem.id}`}
                fromItemActions={[{
                  content: 'Item Details',
                  onClick: () => openModal(item.fromItem.id),
                }]}
                toItemActions={[{
                  content: 'Item Details',
                  onClick: () => openModal(item.toItem.id),
                }]}
                {...item}
              />
            )}
          />)}
        {metaData?.pageData && (
          <MetaDataPager
            pageData={metaData.pageData}
            onPageChange={setCurrentPage}
            onSelectPageSize={setPageSize}
          />
        )}
      </div>
    )}
      </ListContainer>
      {currentMaterializedView && modalNodeId && (
        <NodeDetailModal
          isOpen={true}
          toggle={closeModal}
          nodeId={modalNodeId}
          goToNode={(id: string) => openModal(id)}
        />
      )}
    </Container>
  );
};

