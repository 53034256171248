import {PropsWithChildren, createContext, useState, useEffect} from 'react';
import pickBy from 'lodash/pickBy';

import {useQuery} from 'src/hooks/router';

const INITIAL_PROPS: ContextType = {
  selectedIds: [],
  selectedIdsMap: {},
  setSelectedIdsMap: () => {},
  setSelectedIds: () => {},
};

const addSelectedIdsToHistory = (selectedIds: Array<string>) => {
  const params = new URLSearchParams(window.location.search);
  const len = selectedIds.length;
  if (len) {
    params.set('selectedIds', JSON.stringify(selectedIds.slice(len - 20, len)));
  } else {
    params.delete('selectedIds');
  }
  const urlBase = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

  // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/size
  const newurl = `${urlBase}${(params as (typeof params & {size: number})).size > 0 ? '?' + params.toString() : ''}`;
  window.history.replaceState({path: newurl}, '', newurl);
};

type ContextType = {
  /**
   * An array containing the ids of selected elements
   */
  selectedIds: Array<string>,
  setSelectedIds: (_: Array<string>) => void,
  /**
   * A map of ids ids to their selected state
   */
  selectedIdsMap: {[key: string]: boolean}
  setSelectedIdsMap: (_: {[key: string]: boolean}) => void,
}

export const SelectedIdsContext = createContext<ContextType>(INITIAL_PROPS);

export const SelectedIdsContextProvider = ({children}: PropsWithChildren) => {
  const {get} = useQuery();

  const [{selectedIds, selectedIdsMap}, setSelectedIdsContext] = useState<{
    selectedIds: ContextType['selectedIds']
    selectedIdsMap: ContextType['selectedIdsMap']
  }>({
    selectedIds: [],
    selectedIdsMap: {},
  });

  const setSelectedIds = (selectedIds: ContextType['selectedIds']) => {
    const selectedIdsMap = selectedIds.reduce((accum, k) => {
      accum[k] = true;
      return accum;
    }, {});

    addSelectedIdsToHistory(selectedIds);
    setSelectedIdsContext({
      selectedIds,
      selectedIdsMap,
    });
  };

  const setSelectedIdsMap = (selectedIdsMap: ContextType['selectedIdsMap']) => {
    const selectedIds = Object.keys(pickBy(selectedIdsMap, (x) => !!x));

    addSelectedIdsToHistory(selectedIds);
    setSelectedIdsContext({
      selectedIds,
      selectedIdsMap,
    });
  };

  useEffect(() => {
    const initialIds = get('selectedIds');
    if (!initialIds) {
      return;
    }

    try {
      const list = JSON.parse(initialIds);
      setSelectedIds(list);
    } catch (e) {
      console.error('Unable to extract selectedIds from search params');
    }
  }, [get]);

  return (
    <SelectedIdsContext.Provider value={{
      selectedIds,
      setSelectedIds,
      selectedIdsMap,
      setSelectedIdsMap,
    }}>
      {children}
    </SelectedIdsContext.Provider>
  );
};
