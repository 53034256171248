import {TypedApiResponse} from 'src/types';

import {CODELOGIC_API_BASE_URL} from '../config';

import {axiosInstance} from './baseApi';
import {isAxiosError} from './utils';

export interface reqParams {
  page?: number;
  pageSize?: number;
  since: string;
  filter: string;
  status: string;
  sort: string;
}

export const searchChangeRequests = async (
    searchTerm: string,
    status: string,
    requestDate?: string,
    page = 0,
    pageSize = 10,
    sort = 'created,desc',
) => {
  try {
    const response = await axiosInstance.get(`${CODELOGIC_API_BASE_URL}/change-request`, {
      params: {
        page,
        sort,
        'size': pageSize,
        'filter': searchTerm,
        status,
        'since': requestDate,
      },
    });
    return response.data;
  } catch (e) {
    if (isAxiosError(e)) {
      return e.response?.data;
    }
  }
};

export const getChangeRequestsCsv = async (
    searchTerm: string,
    status: string,
    requestDate?: string,
) => {
  try {
    const response = await axiosInstance.get(`${CODELOGIC_API_BASE_URL}/change-request/csv`, {
      params: {
        'sort': 'created,desc',
        'filter': searchTerm,
        status,
        'since': requestDate,
      },
    });
    return response.data;
  } catch (e) {
    if (isAxiosError(e)) {
      return e.response?.data;
    }
  }
};

export async function getRepositoryUrls(): Promise<TypedApiResponse<Array<string>>> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/change-request/urls`;

  try {
    const response = await axiosInstance.get<TypedApiResponse<Array<string>>>(endpoint, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
}
