import {Diagram, Node, Link} from 'gojs';

import {colorTag} from 'src/state/dependency2';
import type {Theme, THEMES} from 'src/styles/colors';
import {DEFAULT_THEME} from 'src/styles/colors';
import {
  TEXT_COLOR_FOR_SEMI_TRANSPARENT_BACKGROUND as TEXT_COLOR,
  DE_EMPHASIZED_HEX_OPACITY_VALUE,
} from 'src/graph';
import {hexToRGBComponents, quickContrastingBWColor} from 'src/util/colors';
import {toArray} from 'src/util/gojs';

export const isDeEmphasized = (type: string | undefined) => type && type.startsWith('CONTAINS_') || type === 'GROUPS';

export const makeThemeColorGetter = (feature: keyof Theme) => ({theme}: {theme: Theme}) => theme ? theme[feature] : DEFAULT_THEME[feature];

export const getColor = ({data, diagram}: {data: {type: string, color: string, emphasized: boolean, metadata?: {type: string}}, diagram: Diagram}) => {
  const {type: edgeType, emphasized, metadata} = data;
  const nodeType = metadata?.type;
  const type = nodeType || edgeType;
  const theme = diagram.model.modelData.theme;
  const themeName = diagram.model.modelData.themeName as (keyof typeof THEMES);
  const defaultColor = theme['color-text'] as string || DEFAULT_THEME['color-text'];

  const calculatedColor = colorTag(type, {
    // This is a poor heuristic, but we should stop setting 'type' on color objects anyway
    //       ⤋
    type: nodeType ? 'node' : 'edge',
    transparency: false,
    theme,
    themeName,
  }).color;

  const color = calculatedColor || defaultColor;
  return emphasized ? color + DE_EMPHASIZED_HEX_OPACITY_VALUE : color;
};

export const getTextColor = ({
  data: {
    type: edgeType,
    emphasized,
    metadata,
  },
  diagram,
}: {
  data: {
    metadata?: {
      type: string
    },
    type: string,
    emphasized: string
  },
  diagram: Diagram
}) => {
  const {type: nodeType} = metadata || {};
  const type = nodeType || edgeType;

  const theme = diagram.model.modelData.theme;
  const themeName = diagram.model.modelData.themeName as (keyof typeof THEMES);
  const defaultColor = theme['color-text'] as string || DEFAULT_THEME['color-text'];
  const calculatedColor = colorTag(type, {
    // This is a poor heuristic, but we should stop setting 'type' on color objects anyway
    //       ⤋
    type: nodeType ? 'node' : 'edge',
    transparency: false,
    theme,
    themeName,
  }).color;

  const contrastingColor = emphasized ? TEXT_COLOR : quickContrastingBWColor(...hexToRGBComponents(calculatedColor || defaultColor));

  return contrastingColor;
};

export const hasAtLeastOneDeEmphasizedRelationship = (n: Node) =>
  toArray<Link>(n.findLinksInto()).some((l) => !l.data.emphasized);
