import Swal from 'sweetalert2';
import get from 'lodash/get';

import {ApiErrorResponse} from 'src/types';

import {cookies} from './cookies';
import {AUTHENTICATED_COOKIE_NAME, AUTHENTICATED_COOKIE_PATH, logout, ROLES_COOKIE_NAME} from './auth';
import {UNAVAILABLE_TRY_AGAIN_IN_THIRTY} from './messages';
import {isAxiosError} from './utils';

const noop = () => {};

const logoutWithCleanup = async () => {
  await logout();
  cookies.remove(AUTHENTICATED_COOKIE_NAME, {path: AUTHENTICATED_COOKIE_PATH});
  window.location.reload();
};

const ERROR_RESPONSE_HANDLERS = {
  401: {
    default: () => {
      const authCookie = cookies.get(AUTHENTICATED_COOKIE_NAME);
      if (authCookie) {
        logoutWithCleanup();
      }
    },
  },
  403: {
    default: () => {
      const authCookie = cookies.get(AUTHENTICATED_COOKIE_NAME);
      if (authCookie) {
        logoutWithCleanup();
      }
    },
  },
  502: {
    default: () => {
      Swal.fire(
          'Error',
          UNAVAILABLE_TRY_AGAIN_IN_THIRTY,
          'error',
      );
    },
  },
  default: {default: noop},
};

export const requestInterceptor = function(config) {
  config = Object.assign({}, config);
  if (config.headers) {
    config.headers['Content-Type'] = 'application/json';
  }

  config.data = !!config.data ? config.data : {};
  return config;
};
export const responseSuccessInterceptor =
  (res) => {
    if (res.data && res.data.loggedIn === false) {
      logout().then(() => {
        cookies.remove(AUTHENTICATED_COOKIE_NAME, {path: AUTHENTICATED_COOKIE_PATH});
        cookies.remove(ROLES_COOKIE_NAME, {path: '/'});
        window.location.reload();
      });
    }
    return res;
  };

export const responseErrorInterceptor = <E extends Error>(error: E) => {
  if (isAxiosError<ApiErrorResponse>(error)) {
    if (error.response) {
      const responseGroup = get(ERROR_RESPONSE_HANDLERS, error.response.status, ERROR_RESPONSE_HANDLERS.default);
      const responseHandler = get(responseGroup, error.response.data.error?.code, responseGroup.default);

      responseHandler(error);
    }
  }
  return Promise.reject(error);
};
