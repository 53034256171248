import {PropsWithChildren, createContext, useEffect} from 'react';
import {useSubscription} from 'react-stomp-hooks';
import uniq from 'lodash/uniq';

import {MaterializedViewDefinition, Workspace} from 'src/types';
import {useWorkspace, useMaterializedView, useMaterializedViewDefinition} from 'src/hooks';

type WorkspaceContextType = {
  currentWorkspace: Workspace | undefined,
  currentMaterializedViewDefinition: MaterializedViewDefinition | undefined,
  currentMaterializedView: string | undefined
  materializedViews: string[],
  setCurrentWorkspace: (w: Workspace | undefined) => void,
  loading: boolean,
  initializeWorkspace: (id?: string) => Promise<void>
  initializeMaterializedViewDefinition: (id?: string) => Promise<void>
  setCurrentMaterializedViewDefinition: (_?: MaterializedViewDefinition) => void,
  setCurrentMaterializedView: (_?: string) => void,
  fetchLatestMaterializedView: (definitionId: string) => void,
};

const INITIAL_WORKSPACE_PROPS = {
  currentWorkspace: undefined,
  currentMaterializedViewDefinition: undefined,
  currentMaterializedView: undefined,
  materializedViews: [],
  loading: false,
  setCurrentWorkspace: () => {},
  initializeWorkspace: async () => {},
  initializeMaterializedViewDefinition: async () => {},
  setCurrentMaterializedViewDefinition: () => {},
  setCurrentMaterializedView: () => {},
  fetchLatestMaterializedView: () => {},
};

export const WorkspaceContext = createContext<WorkspaceContextType>(INITIAL_WORKSPACE_PROPS);

export const WorkspaceContextProvider = ({children}: PropsWithChildren) => {
  const {
    loading: loadingWorkspace,
    workspace: currentWorkspace,
    initializeWorkspace,
    setWorkspace:
      setCurrentWorkspace,
  } = useWorkspace();

  const {
    loading: loadingMaterializedViewDefinition,
    materializedViewDefinition: currentMaterializedViewDefinition,
    initializeMaterializedViewDefinition,
    setMaterializedViewDefinition: setCurrentMaterializedViewDefinition,
  } = useMaterializedViewDefinition(currentWorkspace);

  const {
    loading: loadingMaterializedView,
    materializedView: currentMaterializedView,
    setMaterializedView: setCurrentMaterializedView,
    setMaterializedViews,
    materializedViews,
    fetchLatestMaterializedView,
  } = useMaterializedView(currentMaterializedViewDefinition);

  useSubscription('/topic/materializedViews', (message) => {
    try {
      const {workspaceId, materializedView: {id}} = JSON.parse(message.body) as {materializedView: {id: string}, workspaceId: string};

      if (workspaceId) {
        if (currentWorkspace?.id === workspaceId) {
          setMaterializedViews(uniq([id, ...materializedViews]));
        }
      }
    } catch (e) {
      console.error('failed to parse message', message.body);
      return;
    }
  });


  useEffect(() => {
    const defaultDefinition = currentWorkspace?.materializedViewDefinition;
    setCurrentMaterializedViewDefinition(defaultDefinition);
  }, [currentWorkspace]);

  const loading = loadingWorkspace || loadingMaterializedViewDefinition || loadingMaterializedView;

  return (
    <WorkspaceContext.Provider value={{
      currentWorkspace,
      currentMaterializedViewDefinition,
      currentMaterializedView,
      materializedViews,
      setCurrentWorkspace,
      setCurrentMaterializedViewDefinition,
      setCurrentMaterializedView,
      fetchLatestMaterializedView,
      initializeWorkspace,
      initializeMaterializedViewDefinition,
      loading,
    }}>
      {children}
    </WorkspaceContext.Provider>
  );
};
