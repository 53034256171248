import {PropsWithChildren, ReactNode, useState} from 'react';
import {Button} from 'reactstrap';

import './styles.css';

export const FloatingControls = ({
  label,
  children,
}: PropsWithChildren<{
  label: ReactNode,
}>) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <div className="FloatingControls__container">
      <div><Button className="FloatingControls__button" onClick={toggleOpen}>{label}</Button></div>
      {open && (
        <div className="FloatingControls__content-container">
          <div className="FloatingControls__content">
            {children}
          </div>
        </div>
      )}
    </div>
  );
};
