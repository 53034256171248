import {useEffect, useState} from 'react';

import {ToggleSwitch} from 'src/components';
import type {AgentSettings as AgentSettingsType} from 'src/types';
import {getAgentSettings, saveAgentSettings} from 'src/api/agentSettings';

import './styles.css';
export const AgentSettings = () => {
  const [loading, setLoading] = useState(false);
  const [agentSettings, setAgentSettings] = useState<AgentSettingsType>();
  const fetchAgentSettings = async () => {
    setLoading(true);
    const response = await getAgentSettings();
    setLoading(false);
    if (response.status === 'success') {
      setAgentSettings(response.data);
    }
  };

  useEffect(() => {
    fetchAgentSettings();
  }, []);

  const setExpungeScanSessions = async (value: boolean) => {
    if (agentSettings) {
      const updates: AgentSettingsType = {
        ...agentSettings,
        expungeScanSessions: value,
      };
      await storeAgentSettings(updates);
    }
  };

  const setRecursivelyScanArchives = async (value: boolean) => {
    if (agentSettings) {
      const updates: AgentSettingsType = {
        ...agentSettings,
        recursiveScan: value,
      };
      await storeAgentSettings(updates);
    }
  };

  const setRescanArchives = async (value: boolean) => {
    if (agentSettings) {
      const updates: AgentSettingsType = {
        ...agentSettings,
        rescanArchive: value,
      };
      await storeAgentSettings(updates);
    }
  };

  const storeAgentSettings = async (updates: AgentSettingsType) => {
    await saveAgentSettings(updates);
    await fetchAgentSettings();
  };

  return (
    <div className='AgentSettings'>
      <div className='AgentSettings__setting-holder'>
        <div className='AgentSettings__toggle-holder'>
          {loading || (
            <ToggleSwitch name="expungeScanSessions"
              isChecked={!!agentSettings?.expungeScanSessions}
              onChange={setExpungeScanSessions}
            />
          )}
        </div>
        <div className='AgentSettings__explain-block'>
          <span className='AgentSettings__field-text'>Expunge Scan Sessions</span>
          <span className='AgentSettings__field-desc'>
            Allows agents to upload new data and replace old data. It is best to enable this for CI/CD integrations and environments where applications rapidly change
          </span>
        </div>
      </div>
      <div className='AgentSettings__setting-holder'>
        <div className='AgentSettings__toggle-holder'>
          {loading || (
            <ToggleSwitch name="recursivelyScanArchives"
              isChecked={!!agentSettings?.recursiveScan}
              onChange={setRecursivelyScanArchives}
            />
          )}
        </div>
        <div className='AgentSettings__explain-block'>
          <span className='AgentSettings__field-text'>Recursively Scan Artifacts</span>
          <span
            className='AgentSettings__field-desc'>When enabled, agents unpack nested Artifacts, such as über JARs</span>
        </div>
      </div>
      <div className='AgentSettings__setting-holder'>
        <div className='AgentSettings__toggle-holder'>
          {loading || (
            <ToggleSwitch name="rescanArchives"
              isChecked={!!agentSettings?.rescanArchive}
              onChange={setRescanArchives}
            />
          )}
        </div>
        <div className='AgentSettings__explain-block'>
          <span className='AgentSettings__field-text'>Rescan Artifacts</span>
          <span className='AgentSettings__field-desc'>
            Perform a new scan of all Artifacts, even if they have been previously scanned and exist within the CodeLogic database
          </span>
        </div>
      </div>
    </div>
  );
};
