import {useEffect, useState} from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody, Button,
} from 'reactstrap';
import {withTheme} from '@rjsf/core';
import {Theme as Bootstrap4Theme} from '@rjsf/bootstrap-4';
import Swal from 'sweetalert2';

import {getSchema, getUISchema, getValidation} from 'src/util/jsonschema-form';
import {approveAgent, updateProperties} from 'src/api/agents';

import CronWidget from './CronWidget';

const RJSForm = withTheme(Bootstrap4Theme);

const NewApproveAgentForm = ({isOpen, onClose, agentData, editData}) => {
  const [data, setData] = useState<any | null>(null);
  const [schema, setSchema] = useState<any | null>(null);
  const [uiSchema, setUISchema] = useState<any | null>(null);

  const toggle = () => {
    onClose(false);
  };

  const onFormDataChange = (form: any) => {
    const isRemotelyConfigured = form?.formData?.isRemotelyConfigured ?? false;
    if (data && data.properties && data.properties.isRemotelyConfigured !== isRemotelyConfigured) {
      data.properties.isRemotelyConfigured = isRemotelyConfigured;
      data.isRemotelyConfigured = isRemotelyConfigured;
      setData(data);

      setSchema(getSchema(agentData.agentType, isRemotelyConfigured));
      setUISchema(getUISchema(agentData.agentType, isRemotelyConfigured));
    }
  };

  const handleReject = async () => {
    try {
      let reload = false;
      const result = await approveAgent(agentData.agentId, false, agentData.label, agentData.description, '{}');
      if (result.status === 200) {
        Swal.fire({
          title: 'Success!',
          text: 'Revoked agent registration',
          icon: 'success',
          timer: 1200,
          showConfirmButton: false,
        });
        reload = true;
      } else if (result.data && result.data.error && result.data.error.message) {
        Swal.fire({
          title: 'Error!',
          text: result.data.error.message,
          icon: 'error',
          timer: 1200,
          showConfirmButton: false,
        });
      }
      onClose(reload);
    } catch ({errors}) {
      if (errors && Array.isArray(errors)) {
        errors.forEach((error) => {
          const errorMessage =
            error.errors === undefined ?
              error.map((err) => err.message).join('. ') :
              error.errors[Object.keys(error.errors)[0]];
          console.error(errorMessage);
        });
      }
    }
  };

  async function handleSubmit({formData}) {
    try {
      let result; let txt;
      if (editData) {
        result = await updateProperties(formData.agentId, formData.label, formData.description, {
          dataSourceId: formData.agentType,
          properties: formData.properties,
        });
        txt = 'Agent successfully updated';
      } else {
        result = await approveAgent(formData.agentId, true, formData.label, formData.description, agentData.properties);
        txt = 'Agent successfully approved';
      }
      if (result.status === 200) {
        Swal.fire({
          title: 'Success!',
          text: txt,
          icon: 'success',
          timer: 1200,
          showConfirmButton: false,
        });
      } else if (result.data && result.data.error && result.data.error.message) {
        Swal.fire({
          title: 'Error!',
          text: result.data.error.message,
          icon: 'error',
          timer: 1200,
          showConfirmButton: false,
        });
      }
      onClose(result.status === 200);
    } catch ({errors}) {
      if (errors && Array.isArray(errors)) {
        errors.forEach((error) => {
          const errorMessage =
            error.errors === undefined ?
              error.map((err) => err.message).join('. ') :
              error.errors[Object.keys(error.errors)[0]];
          console.error(errorMessage);
        });
      }
    }
  }

  useEffect(() => {
    if (agentData && agentData.properties) {
      const props = JSON.parse(agentData.properties);
      if (props.isRemotelyConfigured === undefined) {
        props.isRemotelyConfigured = false;
      }

      setData({
        agentId: agentData.agentId,
        agentType: agentData.agentType,
        agentTypeName: agentData.agentTypeName,
        label: agentData.label,
        description: agentData.description,
        isRemotelyConfigured: props.isRemotelyConfigured,
        properties: props,
      });

      setSchema(getSchema(agentData.agentType, props.isRemotelyConfigured));
      setUISchema(getUISchema(agentData.agentType, props.isRemotelyConfigured));
    }
  }, [agentData, agentData.properties]);

  const canSubmitOrApprove =
    agentData.requestStatus === 'OPEN' ||
    agentData.requestStatus === 'APPROVED' ||
    agentData.requestStatus === 'REGISTERED';

  const widgets = {
    cronWidget: CronWidget,
  } as any;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fade={false}
      modalClassName="height-auto"
      size="xl"
    >
      <ModalHeader toggle={toggle}>{editData ? 'Edit' : 'Approve'}</ModalHeader>
      <ModalBody>
        {
          data &&
          <>
            <RJSForm
              schema={schema}
              onSubmit={handleSubmit}
              uiSchema={uiSchema}
              validate={getValidation(agentData.agentType, data.isRemotelyConfigured ?? false)}
              formData={data}
              widgets={widgets}
              onChange={onFormDataChange}
            >
              <div>
                <hr />
                {canSubmitOrApprove && (<Button type="submit" color="primary">{editData ? 'Save' : 'Approve'}</Button>)}
                {' '}
                {canSubmitOrApprove && (<Button color="danger" onClick={handleReject}>Reject</Button>)}
                <Button color="secondary" style={{float: 'right'}} onClick={toggle}>Cancel</Button>
              </div>
            </RJSForm>
          </>
        }
      </ModalBody>
    </Modal>
  );
};

export default NewApproveAgentForm;
