import SwalBase from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import {User} from 'src/types';
import {deleteUser} from 'src/api/users';
import {isSuccess, isError} from 'src/components/Administration/utils';

import {DeleteConfirmationPopup} from './components';
import {REQUIRED_CREATE_FIELDS, REQUIRED_EDIT_FIELDS} from './constants';
import {FormFields, ValidationErrors} from './types';

const Swal = withReactContent(SwalBase);

const PASSWORD_VALIDATION_ERROR_MESSAGE = '*Must be at least 8 characters with 1 number and 1 upper-case letter';
const validatePassword = (password: string) =>
  password.length >= 8 &&
    password.match(/[A-Z]/) &&
    password.match(/[0-9]/);

export const makeInitialEditState = ({id, ...userWithoutId}: User) => ({
  ...userWithoutId,
  password: '',
  confirmPassword: '',
});


export const makeFormValidator = (user?: User) => (values: FormFields) => {
  const errors: ValidationErrors = {};

  if (values.roles.length === 0) {
    errors.roles = '*Must select at least one role';
  }

  (user ? REQUIRED_EDIT_FIELDS : REQUIRED_CREATE_FIELDS).forEach((f) => {
    if (!('' + values[f])) {
      errors[f] = '*Required';
    }
  });

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = '*Password confirmation must match';
  }

  if (values.password.length > 0 && !validatePassword(values.password)) {
    errors.password = PASSWORD_VALIDATION_ERROR_MESSAGE;
  }

  if (values.username && !values.username.match(/(.+)@(.+){2,}\.(.+){2,}/)) {
    errors.username = '*Username must be a valid email address';
  }

  return errors;
};

export const removeUser = async ({id, username}: User) => {
  const result = await deleteUser(id);
  if (isSuccess(result)) {
    Swal.fire({
      title: 'Success!',
      html: <DeleteConfirmationPopup username={username} />,
      width: '45em',
      icon: 'success',
      showConfirmButton: true,
    });
  } else {
    if (isError(result)) {
      Swal.fire({
        title: 'Error',
        html: <span>{`${result.error.message} `}</span>,
        width: '45em',
        icon: 'error',
      });
    } else {
      Swal.fire({
        title: 'Error',
        html: <span>{`Error deleting user: ${username} `}</span>,
        width: '45em',
        icon: 'error',
      });
    }
  }
};
