import {useCallback, useEffect, useState} from 'react';
import {Button, TabPane} from 'reactstrap';
import {IoWarningOutline} from 'react-icons/io5';
import withReactContent from 'sweetalert2-react-content';
import SwalBase from 'sweetalert2';


import {ChangeRequestConfig, PageDataType} from 'src/types';
import {Table, Section} from 'src/components';
import {deleteConfig, getChangeRequestConfigs} from 'src/api/changeRequestConfigs';
import {isSuccess, isError} from 'src/components/Administration/utils';
import {ActionMenu} from 'src/components/Administration/UserManagement/components';

import ChangeRequestConfigurationForm from './Details';
import {IgnoreListConfigSection} from './IgnoreList';
import {SecretsConfigSection} from './Secrets';

import 'src/components/Administration/administration.css';

const Swal = withReactContent(SwalBase);

const removeConfig = async (config:ChangeRequestConfig) => {
  const result = await deleteConfig(config.id);
  if (isSuccess(result)) {
    Swal.fire({
      title: 'Success!',
      html: 'Successfully deleted the configuration!',
      width: '45em',
      icon: 'success',
      showConfirmButton: true,
    });
  } else {
    if (isError(result)) {
      Swal.fire({
        title: 'Error',
        html: <span>{`${result.error.message} `}</span>,
        width: '45em',
        icon: 'error',
      });
    } else {
      Swal.fire({
        title: 'Error',
        html: <span>{`Error deleting config`}</span>,
        width: '45em',
        icon: 'error',
      });
    }
  }
};

export const ChangeRequestTab = ({setLoading}: {setLoading: (_: boolean) => void}) => {
  const [data, setData] = useState<ChangeRequestConfig[]>([]);
  const [pageData, setPageData] = useState<PageDataType>();
  const [isOpen, setIsOpen] = useState(false);
  const [editData, setEditData] = useState<ChangeRequestConfig>();

  const configDeleteConfirmation = async (config: ChangeRequestConfig) => {
    const response = await Swal.fire({
      title: `Are you sure you want to delete this configuration?`,
      text: 'The configured repository will no longer generate diffs.',
      confirmButtonText: 'Delete',
      showCancelButton: true,
      customClass: 'WorkspaceAlert',
      iconHtml: <IoWarningOutline></IoWarningOutline>,
    });

    if (response.isConfirmed) {
      await removeConfig(config);
    }
  };

  const fetchConfigs = useCallback(
      async (page?: number, pageSize?: number) => {
        setLoading(true);
        const result = await getChangeRequestConfigs({
          page,
          pageSize,
        });
        setLoading(false);
        if (result && result.data) {
          setData(result.data);
          setPageData(result.metaData?.pageData);
        } else {
          setData([]);
          setPageData(undefined);
        }
      },
      [],
  );

  useEffect(() => {
    fetchConfigs(0, 10);
  }, []);

  return (
    <TabPane>
      <Section
        title="Change Request Configurations"
        controls={(
          <Button type='button' color='primary' onClick={() => {
            setEditData(undefined);
            setIsOpen(true);
          }}>
            Add Change Request
          </Button>
        )}
      >
        <Table<ChangeRequestConfig>
          columns={[{
            header: () => 'Repository Url',
            id: 'repositoryUrl',
            cell: ({repositoryUrl}) => repositoryUrl.startsWith('https://') ? (
              <a rel="noopener noreferrer" target='_blank' href={repositoryUrl}>{repositoryUrl}</a>
            ) : repositoryUrl,
          }, {
            header: () => 'Branch Pattern',
            id: 'branchPattern',
            cell: ({branchPattern}) => branchPattern,
          }, {
            header: () => 'Comparison Workspace',
            id: 'workspaceName',
            cell: ({workspaceName}) => workspaceName,
          }, {
            header: () => 'Retention Period',
            id: 'retentionPeriod',

            // Slice off the 'P' part of the retention period pattern
            cell: ({retention}) => retention?.slice(1),
          }, {
            id: 'actions',
            header: () => 'Actions',
            cell: (entity) => (
              <ActionMenu menuItems={[{
                label: 'Edit',
                action: () => {
                  setIsOpen(true);
                  setEditData(entity);
                },
              }, {
                label: 'Delete',
                action: async () => {
                  await configDeleteConfirmation(entity);
                  await fetchConfigs(pageData?.currentPage || 0, pageData?.pageSize || 10);
                },
              }]}
              />
            ),
          }]}
          rows={data}
          metadata={pageData ? {pageData} : undefined}
          onPageChange={(page) => fetchConfigs(page, pageData?.pageSize || 10)}
          onSelectPageSize={(pageSize) => fetchConfigs(0, pageSize)}
        />
        <ChangeRequestConfigurationForm
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
            setEditData(undefined);
            fetchConfigs(pageData?.currentPage || 0, pageData?.pageSize || 10);
          }
          }
          config={editData}
        />
      </Section>
      <div style={{display: 'flex', flexFlow: 'row wrap'}}>
        <SecretsConfigSection />
        <IgnoreListConfigSection />
      </div>
    </TabPane>
  );
};
