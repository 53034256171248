import {AgentType} from '../types';

import {IMAGE_NAMES} from './constants';
import {DockerCommandOptions} from './types';

export const isValidServerAddress = (value: string) =>
  value.match(/^http(s)?:\/\/[a-zA-Z0-9]([a-zA-Z0-9.-]*[a-zA-Z0-9])?$/);

export const isValidScanPath = (value: string) => value !== '';

export const makeCommandGenerator = (type: AgentType) => ({
  agentUUID,
  agentPassword,
  scanPath,
  serverAddress,
  application,
  username,
  password,
  database,
  jdbcUrl,
}: DockerCommandOptions) => {
  const protocol = window.location.protocol;
  const host = window.location.host;
  const address = serverAddress.startsWith('http') ? serverAddress : `${protocol}//${serverAddress}`;
  let options = '--path /scan';
  let volumeOption = `--volume "${scanPath}:/scan" \\`;
  if (type === 'sql') {
    options = `--username ${username} --password ${password} --jdbc-url ${jdbcUrl} ${database ? '--database ' + database : ''}`;
    volumeOption = '';
  }
  return `docker run --pull always --rm --interactive --tty \\
     --env CODELOGIC_HOST="${address}" \\
     ${agentUUID ? '--env AGENT_UUID="' + agentUUID + '" \\' : ''}
     ${agentPassword ? '--env AGENT_PASSWORD="' + agentPassword + '" \\' : ''}
     ${volumeOption}
     ${host}/${IMAGE_NAMES[type]}:latest analyze \\
     --application "${application}" ${options}`.replace(/     \n/g, '');
};
