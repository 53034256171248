import {useState} from 'react';
import {useSubscription} from 'react-stomp-hooks';

import {
  MaterializedViewDefinition,
  ComparisonJob,
  ScanRoot,
  PageDataType,
} from 'src/types';
import {getDefaultScanSpaceScanRoots} from 'src/api/scanSpace';
import {getReleaseJobs} from 'src/api/comparison-job';
import {listMaterializedViewDefinitions} from 'src/api/materializedViewDefinitions';


export const useScanRoots = (initialScanSpaceId?: string) => {
  const [scanRoots, setScans] = useState<Array<ScanRoot> | undefined>();
  const [metadata, setMetadata] = useState<{pageData: PageDataType} | undefined>();
  const [loading, setLoading] = useState(false);

  const fetchScans = async ({
    scanSpaceId,
    page,
    pageSize,
    filters,
  }: {
      scanSpaceId?: string
      page?: number,
      pageSize?: number,
      filters?: {
        nameFilter?: string,
        applicationFilter?: string,
        gitBranchFilter?: string,
        gitHeadSHAFilter?: string,
      },
    } = {}) => {
    const id = scanSpaceId || initialScanSpaceId;
    setLoading(true);
    const response = await getDefaultScanSpaceScanRoots({
      scanSpaceId: id,
      page,
      size: pageSize,
      filters,
    });

    if (response.status === 'success') {
      setScans(response.data);
      setMetadata(response.metaData);
    }

    setLoading(false);
  };

  return {
    loading,
    scanRoots,
    metadata,
    fetchScans,
  } as const;
};

export const useReleaseAssistantJobs = () => {
  const [releaseAssistantJobs, setReleaseAssistantJobs] = useState<Array<ComparisonJob> | undefined>();
  const [metadata, setMetadata] = useState<{pageData: PageDataType} | undefined>();
  const [loading, setLoading] = useState(false);


  useSubscription('/topic/comparisonJobs', (message) => {
    if (releaseAssistantJobs && releaseAssistantJobs.length > 0) {
      const response: ComparisonJob = JSON.parse(message.body);
      const index = releaseAssistantJobs.findIndex((j) => j.id === response.id);
      if (index >= 0) {
        const newArray = releaseAssistantJobs.slice();
        newArray[index] = response;
        setReleaseAssistantJobs(newArray);
      }
    }
  });

  const fetchReleaseAssistantJobs = async ({
    page,
    pageSize,
  }: {
    page?: number,
    pageSize?: number,
  }) => {
    setLoading(true);

    const response = await getReleaseJobs({
      page,
      size: pageSize,
    });

    if (response.status === 'success') {
      setReleaseAssistantJobs(response.data);
      setMetadata(response.metaData);
    }

    setLoading(false);
  };

  return {
    loading,
    releaseAssistantJobs,
    metadata,
    fetchReleaseAssistantJobs,
  } as const;
};

export const useMaterializedViewDefinitions = ({type}: {type: string}) => {
  const [materializedViewDefinitions, setMaterializedViewDefinitions] = useState<Array<MaterializedViewDefinition> | undefined>();
  const [metadata, setMetadata] = useState<{pageData: PageDataType} | undefined>();
  const [loading, setLoading] = useState(false);

  const fetchMaterializedViewDefinitions = async (pageNumber:number, pageSize:number) => {
    setLoading(true);

    const response = await listMaterializedViewDefinitions({
      type,
      pageNumber,
      pageSize,
    });

    if (response.status === 'success') {
      setMaterializedViewDefinitions(response.data);
      setMetadata(response.metaData);
    }

    setLoading(false);
  };

  return {
    loading,
    materializedViewDefinitions,
    metadata,
    fetchMaterializedViewDefinitions,
  } as const;
};
