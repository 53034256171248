import {useState} from 'react';
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {ErrorMessage, Field, Form, Formik} from 'formik';

interface Errors {
  name?: string;
  description?: string;
}

export const NewScanSpaceModal = ({
  isOpen,
  onClose,
  editData,
  onCreate,
  onEdit,
}) => {
  const [apiError, setApiError] = useState('');
  const errorHandler = (error) => {
    setApiError(error.name + ': ' + error.message);
  };
  const handleSubmit = async (values) => {
    if (editData) {
      onEdit(values).catch(errorHandler);
    } else {
      onCreate(values).catch(errorHandler);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      fade={false}
      modalClassName="height-auto"
      autoFocus={false}
    >
      <ModalHeader toggle={onClose}>
        {editData ? 'Edit' : 'New'} Configuration
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            id: (editData && editData.id) || '',
            displayName: (editData && editData.displayName) || '',
            description: (editData && editData.description) || '',
          }}
          validate={(values) => {
            const errors: Errors = {};
            if (!values.displayName) {
              errors.name = 'Name is required';
            }
            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {({isSubmitting}) => (
            <Form>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input tag={Field} name="displayName" type="text" component="input" autoFocus/>
                <ErrorMessage
                  className="form-error"
                  name="name"
                  component="div"
                />
                { apiError && (<div className='form-error'>{apiError}</div>)}
              </FormGroup>
              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  tag={Field}
                  name="description"
                  type="text"
                  component="input"
                />
                <ErrorMessage
                  className="form-error"
                  name="description"
                  component="div"
                />
              </FormGroup>
              <hr/>
              <Button type="submit" disabled={isSubmitting} color="primary">Save</Button>
              {' '}
              <Button color="secondary" onClick={onClose}>Cancel</Button>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};
