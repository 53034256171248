import {useState} from 'react';

import {ApiErrorResponse, TypedApiSuccessResponse} from 'src/types';

export const useProgress = () => {
  const [submittingFile, setSubmittingFile] = useState<File>();
  const [percent, setPercent] = useState<number>();

  const callFileSubmit = async <T, >(file: File, fileSubmit: (file: File) => Promise<TypedApiSuccessResponse<T> | ApiErrorResponse>) => {
    setSubmittingFile(file);
    const response = await fileSubmit(file);
    setSubmittingFile(undefined);
    setPercent(undefined);
    return response;
  };

  const onProgress = (percentage: number) => {
    setPercent(percentage);
  };

  return {
    submittingFile,
    percent,
    onProgress,
    callFileSubmit,
  } as const;
};
