import {Layout} from 'src/components';

import {AgentSettings} from './components';

import './styles.css';

export {AgentSettings};

export const AgentSettingsPage = () => (
  <Layout title='Agent Configuration' loading={false}>
    <div>
      <p>Configure your default agent settings.</p>
    </div>
    <AgentSettings />
  </Layout>
);
