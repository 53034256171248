import {GraphObject, Node, Map as GoJSMap, InputEvent, Diagram} from 'src/util/gojs/go';
import {makeDiagram} from 'src/graph';
import {isLink, isNode} from 'src/util/gojs';
import {fetchDataForNode, commitNodesToDiagram} from 'src/graph';
import type {Theme} from 'src/styles/colors';
import {THEMES} from 'src/styles/colors';
import {DEFAULT_THEME} from 'src/constants';

import {DecompositionDataLoadedState} from '../utils';

import {makeLinkTemplate, makeNodeTemplate} from './templates';

const RELATIONSHIP_REMOVED_FILTER = 'diffValue:removed';
const RELATIONSHIP_ADDED_FILTER = 'diffValue:added';

export const makeDiagramInitializer = ({
  onContextClick,
  defaultLensValue,
  onInitialized = () => {},
  theme = THEMES[DEFAULT_THEME],
  themeName = DEFAULT_THEME,
}: {
  onContextClick?: (_: {x: number, y: number}) => void,
  defaultLensValue?: string
  onInitialized?: (_:Diagram) => void,
  theme?: Theme,
  themeName: keyof typeof THEMES
}) =>
  () => {
    const diagram = makeDiagram({
      theme,
      themeName,
    });

    if (defaultLensValue) {
      diagram.model.setDataProperty(diagram.model.modelData, 'lens', defaultLensValue);
    }

    diagram.nodeTemplateMap = new GoJSMap<string, Node>()
        .add('', makeNodeTemplate({
          handleContextClick: async (_e: InputEvent, obj: GraphObject) => {
            if (!isNode(obj)) {
              return;
            }
            const diagram = obj.diagram;
            if (!diagram) {
              return;
            }

            const point = diagram.lastInput.viewPoint;
            onContextClick && onContextClick({
              x: point.x,
              y: point.y,
            });
          },
          handleClickExpander: handleDirectionalClickExpansion('OUT'),
          handleOutRelAddedClickExpander: handleDirectionalClickExpansion('OUT', [RELATIONSHIP_ADDED_FILTER]),
          handleOutRelRemovedClickExpander: handleDirectionalClickExpansion('OUT', [RELATIONSHIP_REMOVED_FILTER]),
          handleInRelAddedClickExpander: handleDirectionalClickExpansion('IN', [RELATIONSHIP_ADDED_FILTER]),
          handleInRelRemovedClickExpander: handleDirectionalClickExpansion('IN', [RELATIONSHIP_REMOVED_FILTER]),
        }));

    diagram.linkTemplate = makeLinkTemplate({
      handleContextClick: async (_e: InputEvent, obj: GraphObject) => {
        if (!isLink(obj)) {
          return;
        }
        const diagram = obj.diagram;
        if (!diagram) {
          return;
        }

        const point = diagram.lastInput.viewPoint;
        onContextClick && onContextClick({
          x: point.x,
          y: point.y,
        });
      },
    });

    onInitialized(diagram);
    return diagram;
  };


const getRequiredDecompositionState = (direction?: string, relationshipFilters?: Array<string>) => {
  let requiredDataLoaded = DecompositionDataLoadedState.ALL;
  if (direction === 'IN') {
    if (relationshipFilters?.includes(RELATIONSHIP_ADDED_FILTER)) {
      requiredDataLoaded = DecompositionDataLoadedState.INCOMING_ADDED;
    } else if (relationshipFilters?.includes(RELATIONSHIP_REMOVED_FILTER)) {
      requiredDataLoaded = DecompositionDataLoadedState.INCOMING_REMOVED;
    } else {
      requiredDataLoaded = DecompositionDataLoadedState.INCOMING_BOTH;
    }
  } else if (direction === 'OUT') {
    if (relationshipFilters?.includes(RELATIONSHIP_ADDED_FILTER)) {
      requiredDataLoaded = DecompositionDataLoadedState.OUTGOING_ADDED;
    } else if (relationshipFilters?.includes(RELATIONSHIP_REMOVED_FILTER)) {
      requiredDataLoaded = DecompositionDataLoadedState.OUTGOING_REMOVED;
    } else {
      requiredDataLoaded = DecompositionDataLoadedState.OUTGOING_BOTH;
    }
  }

  return requiredDataLoaded;
};


function focusOnNode(myDiagram: Diagram, node?: Node | null) {
  if (!node) return;
  myDiagram.select(node);
  myDiagram.commandHandler.scrollToPart(node);
}

const handleDirectionalClickExpansion = (
    direction?: 'IN' | 'OUT' | 'BOTH',
    relationshipFilters?: Array<string>,
) =>
  async (_e: InputEvent, obj: GraphObject) => {
    const node = obj.part;

    if (!isNode(node)) {
      return;
    }

    const diagram = node.diagram;
    if (!diagram) {
      return;
    }

    const requiredDataLoaded = getRequiredDecompositionState(direction, relationshipFilters);

    if (!node.isTreeExpanded) {
      const currentlyLoadedData = (node.data.dataLoaded ?? DecompositionDataLoadedState.NONE);
      if ((currentlyLoadedData & requiredDataLoaded) !== requiredDataLoaded) {
        const {nodes, edges} = await fetchDataForNode(node, direction, relationshipFilters);
        diagram.commit(async (diagram) => {
          commitNodesToDiagram(diagram, {
            nodes,
            edges,
          });

          node.data.dataLoaded = (node.data.dataLoaded ?? DecompositionDataLoadedState.NONE) | requiredDataLoaded;
        });


        if (direction === 'IN' && edges?.length > 0) {
          const focusNode: Node | null | undefined = diagram.findNodeForKey(edges[edges.length - 1].from);
          focusOnNode(diagram, focusNode);
        }
      }
      node.isTreeExpanded = true;
    } else {
      node.collapseTree();
    }
  };
