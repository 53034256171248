import {MaterializedView, TypedApiResponse} from 'src/types';

import {CODELOGIC_API_BASE_URL} from '../config';

import {axiosInstance} from './baseApi';

import {makeResourceFetch} from './';

export async function getDefaultMaterializedViewId(): Promise<TypedApiResponse<string | null>> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/materialized-view/default`;

  try {
    const response = await axiosInstance.get(endpoint, {withCredentials: true});
    return response.data;
  } catch (e) {
    return {
      status: 'error',
    };
  }
}

export async function getMaterializedViewId(definitionId: string): Promise<TypedApiResponse<string | null>> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/materialized-view/`;

  const params = {
    definitionId,
  };
  try {
    const response = await axiosInstance.get(endpoint, {withCredentials: true, params});
    return response.data;
  } catch (e) {
    return {
      status: 'error',
    };
  }
}

export async function getLatestDefaultMaterializedView(): Promise<TypedApiResponse<MaterializedView | null>> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/materialized-view/default/latest`;

  try {
    const response = await axiosInstance.get(endpoint, {withCredentials: true});
    return response.data;
  } catch (e) {
    return {
      status: 'error',
    };
  }
}

export const generateMaterializedViewForDefinition =
  makeResourceFetch<boolean, {definitionId: string}>({
    path: 'materialized-view/generate',
    method: 'POST',
  });
