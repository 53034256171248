import {
  ApiErrorResponse,
  AuthorizationConfig,
  AuthorizationType,
  BasicAuthorizationConfig,
  TypedApiResponse,
} from 'src/types';
import {CODELOGIC_API_BASE_URL} from 'src/config';

import {axiosInstance} from './baseApi';
import {isAxiosError} from './utils';

import {makeResourceListFetch} from './index';

export const getConfigurations = makeResourceListFetch<AuthorizationConfig>({method: 'GET', path: 'authorization-config'});

type ValidationError = {field: string, message: string}

export const updateConfig = async (updateConfig: AuthorizationConfig | BasicAuthorizationConfig):
  Promise<TypedApiResponse<AuthorizationConfig | BasicAuthorizationConfig> | ApiErrorResponse<ValidationError>> => {
  try {
    const response = await axiosInstance.put(`${CODELOGIC_API_BASE_URL}/authorization-config/`, updateConfig, {withCredentials: true});
    return response?.data;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse<ValidationError>>(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
};

export const getConfigType = makeResourceListFetch<AuthorizationType>({method: 'GET', path: 'authorization-config/type'});
