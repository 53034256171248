import * as go from 'gojs';

const KAPPA = 4 * ((Math.sqrt(2) - 1) / 3);

go.Shape.defineFigureGenerator('Gear', function(shape, w, h) {
  return new go.Geometry().add(
      new go.PathFigure(0.9375 * w, 0.56246875 * h, true)
          .add(new go.PathSegment(go.PathSegment.Line, 0.9375 * w, 0.4375 * h))
          .add(new go.PathSegment(go.PathSegment.Line, 0.80621875 * w, 0.4375 * h))
          .add(
              new go.PathSegment(
                  go.PathSegment.Bezier,
                  0.763 * w,
                  0.3316875 * h,
                  0.79840625 * w,
                  0.39915625 * h,
                  0.7834375 * w,
                  0.3635 * h,
              ),
          )
          .add(
              new go.PathSegment(go.PathSegment.Line, 0.8566875 * w, 0.23796875 * h),
          )
          .add(new go.PathSegment(go.PathSegment.Line, 0.76825 * w, 0.14959375 * h))
          .add(
              new go.PathSegment(go.PathSegment.Line, 0.67596875 * w, 0.24184375 * h),
          )
          .add(
              new go.PathSegment(
                  go.PathSegment.Bezier,
                  0.5625 * w,
                  0.19378125 * h,
                  0.64228125 * w,
                  0.2188125 * h,
                  0.603875 * w,
                  0.2021875 * h,
              ),
          )
          .add(new go.PathSegment(go.PathSegment.Line, 0.5625 * w, 0.0625 * h))
          .add(new go.PathSegment(go.PathSegment.Line, 0.4375 * w, 0.0625 * h))
          .add(new go.PathSegment(go.PathSegment.Line, 0.4375 * w, 0.19378125 * h))
          .add(
              new go.PathSegment(
                  go.PathSegment.Bezier,
                  0.32775 * w,
                  0.239375 * h,
                  0.39759375 * w,
                  0.20190625 * h,
                  0.36053125 * w,
                  0.2176875 * h,
              ),
          )
          .add(
              new go.PathSegment(go.PathSegment.Line, 0.2379375 * w, 0.14959375 * h),
          )
          .add(
              new go.PathSegment(go.PathSegment.Line, 0.14953125 * w, 0.2379375 * h),
          )
          .add(
              new go.PathSegment(go.PathSegment.Line, 0.23934375 * w, 0.3278125 * h),
          )
          .add(
              new go.PathSegment(
                  go.PathSegment.Bezier,
                  0.19378125 * w,
                  0.4375 * h,
                  0.21765625 * w,
                  0.36059375 * h,
                  0.201875 * w,
                  0.397625 * h,
              ),
          )
          .add(new go.PathSegment(go.PathSegment.Line, 0.0625 * w, 0.4375 * h))
          .add(new go.PathSegment(go.PathSegment.Line, 0.0625 * w, 0.5625 * h))
          .add(new go.PathSegment(go.PathSegment.Line, 0.1938125 * w, 0.5625 * h))
          .add(
              new go.PathSegment(
                  go.PathSegment.Bezier,
                  0.241875 * w,
                  0.67596875 * h,
                  0.20221875 * w,
                  0.603875 * h,
                  0.21884375 * w,
                  0.64228125 * h,
              ),
          )
          .add(new go.PathSegment(go.PathSegment.Line, 0.1495625 * w, 0.76825 * h))
          .add(new go.PathSegment(go.PathSegment.Line, 0.238 * w, 0.8566875 * h))
          .add(
              new go.PathSegment(go.PathSegment.Line, 0.3316875 * w, 0.76296875 * h),
          )
          .add(
              new go.PathSegment(
                  go.PathSegment.Bezier,
                  0.43753125 * w,
                  0.80621875 * h,
                  0.36353125 * w,
                  0.78340625 * h,
                  0.3991875 * w,
                  0.79840625 * h,
              ),
          )
          .add(new go.PathSegment(go.PathSegment.Line, 0.43753125 * w, 0.9375 * h))
          .add(new go.PathSegment(go.PathSegment.Line, 0.5625 * w, 0.9375 * h))
          .add(new go.PathSegment(go.PathSegment.Line, 0.5625 * w, 0.80621875 * h))
          .add(
              new go.PathSegment(
                  go.PathSegment.Bezier,
                  0.67225 * w,
                  0.760625 * h,
                  0.602375 * w,
                  0.79809375 * h,
                  0.63946875 * w,
                  0.78234375 * h,
              ),
          )
          .add(
              new go.PathSegment(go.PathSegment.Line, 0.76828125 * w, 0.8566875 * h),
          )
          .add(new go.PathSegment(go.PathSegment.Line, 0.85671875 * w, 0.76825 * h))
          .add(
              new go.PathSegment(go.PathSegment.Line, 0.76065625 * w, 0.67221875 * h),
          )
          .add(
              new go.PathSegment(
                  go.PathSegment.Bezier,
                  0.80621875 * w,
                  0.56246875 * h,
                  0.78234375 * w,
                  0.63940625 * h,
                  0.798125 * w,
                  0.602375 * h,
              ),
          )
          .add(
              new go.PathSegment(
                  go.PathSegment.Line,
                  0.9375 * w,
                  0.56246875 * h,
              ).close(),
          )

          .add(new go.PathSegment(go.PathSegment.Move, 0.5 * w, 0.6 * h))
          .add(
              new go.PathSegment(
                  go.PathSegment.Arc,
                  90,
                  360,
                  0.5 * w,
                  0.5 * h,
                  0.1 * w,
                  0.1 * h,
              ).close(),
          ),
  );
});

go.Shape.defineFigureGenerator('Database', function(shape, w, h) {
  const geo = new go.Geometry();
  const cpxOffset = KAPPA * 0.5;
  const cpyOffset = KAPPA * 0.1;
  const fig = new go.PathFigure(w, 0.1 * h, true);
  geo.add(fig);

  // Body
  fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.9 * h));
  fig.add(
      new go.PathSegment(
          go.PathSegment.Bezier,
          0.5 * w,
          h,
          w,
          (0.9 + cpyOffset) * h,
          (0.5 + cpxOffset) * w,
          h,
      ),
  );
  fig.add(
      new go.PathSegment(
          go.PathSegment.Bezier,
          0,
          0.9 * h,
          (0.5 - cpxOffset) * w,
          h,
          0,
          (0.9 + cpyOffset) * h,
      ),
  );
  fig.add(new go.PathSegment(go.PathSegment.Line, 0, 0.1 * h));
  fig.add(
      new go.PathSegment(
          go.PathSegment.Bezier,
          0.5 * w,
          0,
          0,
          (0.1 - cpyOffset) * h,
          (0.5 - cpxOffset) * w,
          0,
      ),
  );
  fig.add(
      new go.PathSegment(
          go.PathSegment.Bezier,
          w,
          0.1 * h,
          (0.5 + cpxOffset) * w,
          0,
          w,
          (0.1 - cpyOffset) * h,
      ),
  );
  const fig2 = new go.PathFigure(w, 0.1 * h, false);
  geo.add(fig2);
  // Rings
  fig2.add(
      new go.PathSegment(
          go.PathSegment.Bezier,
          0.5 * w,
          0.2 * h,
          w,
          (0.1 + cpyOffset) * h,
          (0.5 + cpxOffset) * w,
          0.2 * h,
      ),
  );
  fig2.add(
      new go.PathSegment(
          go.PathSegment.Bezier,
          0,
          0.1 * h,
          (0.5 - cpxOffset) * w,
          0.2 * h,
          0,
          (0.1 + cpyOffset) * h,
      ),
  );
  fig2.add(new go.PathSegment(go.PathSegment.Move, w, 0.2 * h));
  fig2.add(
      new go.PathSegment(
          go.PathSegment.Bezier,
          0.5 * w,
          0.3 * h,
          w,
          (0.2 + cpyOffset) * h,
          (0.5 + cpxOffset) * w,
          0.3 * h,
      ),
  );
  fig2.add(
      new go.PathSegment(
          go.PathSegment.Bezier,
          0,
          0.2 * h,
          (0.5 - cpxOffset) * w,
          0.3 * h,
          0,
          (0.2 + cpyOffset) * h,
      ),
  );
  fig2.add(new go.PathSegment(go.PathSegment.Move, w, 0.3 * h));
  fig2.add(
      new go.PathSegment(
          go.PathSegment.Bezier,
          0.5 * w,
          0.4 * h,
          w,
          (0.3 + cpyOffset) * h,
          (0.5 + cpxOffset) * w,
          0.4 * h,
      ),
  );
  fig2.add(
      new go.PathSegment(
          go.PathSegment.Bezier,
          0,
          0.3 * h,
          (0.5 - cpxOffset) * w,
          0.4 * h,
          0,
          (0.3 + cpyOffset) * h,
      ),
  );
  geo.spot1 = new go.Spot(0, 0.4);
  geo.spot2 = new go.Spot(1, 0.9);
  return geo;
});

go.Shape.defineFigureGenerator('Cloud', function(shape, w, h) {
  return new go.Geometry()
      .add(
          new go.PathFigure(0.08034461 * w, 0.1944299 * h, true)
              .add(
                  new go.PathSegment(
                      go.PathSegment.Bezier,
                      0.2008615 * w,
                      0.05349299 * h,
                      -0.09239631 * w,
                      0.07836421 * h,
                      0.1406031 * w,
                      -0.0542823 * h,
                  ),
              )
              .add(
                  new go.PathSegment(
                      go.PathSegment.Bezier,
                      0.4338609 * w,
                      0.074219 * h,
                      0.2450511 * w,
                      -0.00697547 * h,
                      0.3776197 * w,
                      -0.01112067 * h,
                  ),
              )
              .add(
                  new go.PathSegment(
                      go.PathSegment.Bezier,
                      0.6558228 * w,
                      0.07004196 * h,
                      0.4539471 * w,
                      0,
                      0.6066018 * w,
                      -0.02526587 * h,
                  ),
              )
              .add(
                  new go.PathSegment(
                      go.PathSegment.Bezier,
                      0.8921095 * w,
                      0.08370865 * h,
                      0.6914277 * w,
                      -0.01904177 * h,
                      0.8921095 * w,
                      -0.01220843 * h,
                  ),
              )
              .add(
                  new go.PathSegment(
                      go.PathSegment.Bezier,
                      0.9147671 * w,
                      0.3194596 * h,
                      1.036446 * w,
                      0.04105738 * h,
                      1.020377 * w,
                      0.3022052 * h,
                  ),
              )
              .add(
                  new go.PathSegment(
                      go.PathSegment.Bezier,
                      0.9082935 * w,
                      0.562044 * h,
                      1.04448 * w,
                      0.360238 * h,
                      0.992256 * w,
                      0.5219009 * h,
                  ),
              )
              .add(
                  new go.PathSegment(
                      go.PathSegment.Bezier,
                      0.9212406 * w,
                      0.8217117 * h,
                      1.032337 * w,
                      0.5771781 * h,
                      1.018411 * w,
                      0.8120651 * h,
                  ),
              )
              .add(
                  new go.PathSegment(
                      go.PathSegment.Bezier,
                      0.7592566 * w,
                      0.9156953 * h,
                      1.028411 * w,
                      0.9571472 * h,
                      0.8556702 * w,
                      1.052487 * h,
                  ),
              )
              .add(
                  new go.PathSegment(
                      go.PathSegment.Bezier,
                      0.5101666 * w,
                      0.9310455 * h,
                      0.7431877 * w,
                      1.009325 * h,
                      0.5624123 * w,
                      1.021761 * h,
                  ),
              )
              .add(
                  new go.PathSegment(
                      go.PathSegment.Bezier,
                      0.2609328 * w,
                      0.9344623 * h,
                      0.4820677 * w,
                      1.031761 * h,
                      0.3030112 * w,
                      1.002796 * h,
                  ),
              )
              .add(
                  new go.PathSegment(
                      go.PathSegment.Bezier,
                      0.08034461 * w,
                      0.870098 * h,
                      0.2329994 * w,
                      1.01518 * h,
                      0.03213784 * w,
                      1.01518 * h,
                  ),
              )
              .add(
                  new go.PathSegment(
                      go.PathSegment.Bezier,
                      0.06829292 * w,
                      0.6545475 * h,
                      -0.02812061 * w,
                      0.9032597 * h,
                      -0.01205169 * w,
                      0.6835638 * h,
                  ),
              )
              .add(
                  new go.PathSegment(
                      go.PathSegment.Bezier,
                      0.06427569 * w,
                      0.4265613 * h,
                      -0.01812061 * w,
                      0.6089503 * h,
                      -0.00606892 * w,
                      0.4555777 * h,
                  ),
              )
              .add(
                  new go.PathSegment(
                      go.PathSegment.Bezier,
                      0.08034461 * w,
                      0.1944299 * h,
                      -0.01606892 * w,
                      0.3892545 * h,
                      -0.01205169 * w,
                      0.1944299 * h,
                  ),
              ),
      )
      .setSpots(0.1, 0.1, 0.9, 0.9);
});

go.Shape.defineFigureGenerator('Pill', (shape, w, h) => {
  const geo = new go.Geometry();
  const halfHeight = h / 2;
  const widthMinusRadius = w - halfHeight;
  geo.add(
      new go.PathFigure(halfHeight, 0)
          .add(new go.PathSegment(go.PathSegment.Line, widthMinusRadius, 0))
          .add(new go.PathSegment(go.PathSegment.Arc, -90, 180, widthMinusRadius, halfHeight, halfHeight, halfHeight))
          .add(new go.PathSegment(go.PathSegment.Line, halfHeight, h))
          .add(new go.PathSegment(go.PathSegment.Arc, 90, 180, halfHeight, halfHeight, halfHeight, halfHeight).close()),
  );
  return geo;
});

export * from 'gojs';
export default go;
