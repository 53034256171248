import {Input} from 'reactstrap';

import {PageLinks} from 'src/components';

import './styles.css';

type Props = {
  totalItems: number,
  pageCount: number,
  pageIndex: number,
  pageSize?: number,
  itemsOnPage?: number,
  pageSizeOptions?: Array<number>
  onSelectPageSize: (pageSize: number) => void
  onPageChange: (newPage: number) => void
}

export const Pager = ({
  totalItems,
  pageCount,
  pageSize = 0,
  itemsOnPage = 0,
  pageIndex,
  onSelectPageSize,
  onPageChange,
  pageSizeOptions = [10, 20, 30, 40, 50],
}:Props) => {
  const firstItemNumber = totalItems > 0 ? pageIndex * pageSize + 1 : totalItems;
  const lastItemNumber = pageIndex * pageSize + itemsOnPage;
  return (
    <div className="pagination-cont">
      <label>
        <span>Rows per page:</span>
        <Input
          type="select"
          bsSize="sm"
          value={pageSize}
          onChange={({target: {value}}) => {
            onSelectPageSize(Number(value));
          }}
        >
          {pageSizeOptions.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Input>
      </label>
      <span className="data-count">
        {`Showing ${firstItemNumber} - ${lastItemNumber} of ${totalItems}`}
      </span>
      <PageLinks
        pageCount={pageCount}
        forcePage={pageIndex}
        onPageChange={({selected}) => onPageChange(selected)}
      />
    </div>
  );
};
