import type {StorableValue} from 'src/types/primitive';
import {AsyncSelect, ToggleSwitch} from 'src/components';

export const LocalTextSetting = ({
  setValue,
  value,
}: {
  value: string,
  setValue: (value: string) => void
}) => {
  return (
    <div className="LocalSetting">
      <input
        type="text"
        onChange={({target: {value}}) => setValue(value)}
        value={value}
      />
    </div>
  );
};

export const LocalBooleanSetting = ({
  name,
  value,
  setValue,
}: {
  name: string,
  value: boolean,
  setValue: (value: boolean) => void
}) => {
  return (
    <div className="LocalSetting">
      <ToggleSwitch
        name={name}
        onChange={setValue}
        isChecked={value}
      />
    </div>
  );
};

type Option<T> = {
  label: string,
  value: T
}

export const LocalOptionSetting = <T extends StorableValue>({
  options,
  getOptionLabel,
  getOptionValue,
  value,
  setValue,
}: {
  options: Array<Option<T>>,
  getOptionLabel: (_: Option<T>) => string,
  getOptionValue: (_: Option<T>) => string,
  value: T,
  setValue: (_: T) => void,
}) => {
  return (
    <div className="LocalSetting">
      <AsyncSelect<{label: string, value: T}>
        options={options}
        value={options.find((x) => x.value === value)}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={(option) => option && setValue(option.value)}
      />
    </div>
  );
};
