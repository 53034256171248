import {CODELOGIC_API_BASE_URL} from 'src/config';
import {
  TypedApiResponse,
  ApiErrorResponse,
  WhereUsedResponse,
  OverviewRelationship,
} from 'src/types';

import {axiosInstance} from './baseApi';
import {isAxiosError} from './utils';

export async function getRelationshipReport(
    page,
    pageSize,
    sortBy,
    relationshipLabels,
    applicationIds,
    toFilter,
    definitionId?: string,
): Promise<any> {
  try {
    const params :any = {};
    params.page = page;
    params.size = pageSize;
    params.sort = sortBy;
    params.applicationNodeIds = applicationIds;
    if (relationshipLabels) {
      params.relationshipLabels = relationshipLabels;
    }
    if (definitionId) {
      params.definitionId = definitionId;
    }
    params.toFilter = toFilter;

    const response = await axiosInstance.get(
        `${CODELOGIC_API_BASE_URL}/tabular/relationships`,
        {
          withCredentials: true,
          params: params,
        },
    );
    return response.data;
  } catch (e) {
    return e;
  }
}

export async function getRelationshipOverviewReport(
    page: number,
    pageSize: number,
    fromApplicationId: string,
    toApplicationId: string,
    definitionId?: string,
): Promise<TypedApiResponse<Array<OverviewRelationship>>> {
  try {
    const params = {
      page,
      size: pageSize,
      fromApplicationNodeId: fromApplicationId,
      toApplicationNodeId: toApplicationId,
      definitionId,
    };
    const response = await axiosInstance.get(
        `${CODELOGIC_API_BASE_URL}/tabular/relationships/overview`,
        {
          withCredentials: true,
          params: params,
        },
    );
    return response.data;
  } catch (e) {
    return {
      status: 'error',
    };
  }
}

export async function getItemReport(
    page,
    pageSize,
    sortBy,
    types,
    applicationIds,
    definitionId?: string,
): Promise<any> {
  try {
    let endpoint = `${CODELOGIC_API_BASE_URL}/tabular/nodes?page=${page}&size=${pageSize}&sort=${sortBy}`;
    endpoint = endpoint.concat(applicationIds.map((s) => '&applicationFilter=' + s).join(''));
    if (types) {
      endpoint = endpoint.concat(types.map((s) => '&itemTypes=' + s).join(''));
    }
    if (definitionId) {
      endpoint = endpoint.concat('&definitionId=' + definitionId);
    }
    const response = await axiosInstance.get(
        endpoint,
        {
          withCredentials: true,
        },
    );
    return response.data;
  } catch (e) {
    console.error(e);
    return e;
  }
}

export async function getRelationshipReportCsv(
    relationshipLabels,
    applicationIds,
    definitionId?: string,
): Promise<any> {
  try {
    const params :any = {};
    params.applicationNodeIds = applicationIds;
    params.relationshipLabels = relationshipLabels;
    if (definitionId) {
      params.definitionId = definitionId;
    }
    const response = await axiosInstance.get(
        `${CODELOGIC_API_BASE_URL}/tabular/relationships/csv`,
        {
          withCredentials: true,
          params: params,
        },
    );
    return response.data;
  } catch (e) {
    return null;
  }
}

export async function getItemReportCsv(
    types,
    applicationIds,
    definitionId?: string,
): Promise<any> {
  try {
    if (!applicationIds || applicationIds <= 0) {
      throw new Error('One application id is required!');
    }
    let endpoint = `${CODELOGIC_API_BASE_URL}/tabular/nodes/csv`;
    endpoint = endpoint.concat('?applicationFilter=' + applicationIds[0]);
    if (applicationIds.length > 1) {
      endpoint = endpoint.concat(applicationIds.slice(1).map((s) => '&applicationFilter=' + s).join(''));
    }
    if (types) {
      endpoint = endpoint.concat(types.map((s) => '&itemTypes=' + s).join(''));
    }
    if (definitionId) {
      endpoint = endpoint.concat('&definitionId=' + definitionId);
    }
    const response = await axiosInstance.get(
        endpoint,
        {
          withCredentials: true,
        },
    );
    return response.data;
  } catch (e) {
    return null;
  }
}

export async function searchItemTypes(searchTerm: string, definitionId?: string): Promise<any> {
  try {
    const params :any = {};

    params.size = 1000;
    params.searchTerm = searchTerm;

    if (definitionId) {
      params.definitionId = definitionId;
    }

    const response = await axiosInstance.get(
        `${CODELOGIC_API_BASE_URL}/nodes/types`,
        {
          withCredentials: true,
          params: params,
        },
    );
    return response.data;
  } catch (e) {
    return e;
  }
}

export async function searchRelationshipTypes(searchTerm, definitionId?: string): Promise<any> {
  try {
    const params :any = {};

    params.size = 1000;
    params.searchTerm = searchTerm;

    if (definitionId) {
      params.definitionId = definitionId;
    }
    const response = await axiosInstance.get(
        `${CODELOGIC_API_BASE_URL}/relationships/types`,
        {
          withCredentials: true,
          params: params,
        },
    );
    return response.data;
  } catch (e) {
    return e;
  }
}

export async function searchApplications(searchTerm, definitionId?: string): Promise<any> {
  try {
    const params : any = {};
    if (definitionId) {
      params.definitionId = definitionId;
    }
    params.paged = false;
    params.groupingTypes = 'APPLICATION';
    params.searchTerm = searchTerm;
    const response = await axiosInstance.get(
        `${CODELOGIC_API_BASE_URL}/search/groups`,
        {
          withCredentials: true,
          params: params,
        },
    );
    return response.data;
  } catch (e) {
    console.error(e);
    return e;
  }
}

export async function getWhereUsed(
    nodeId: string,
    {
      nodeTypes,
      relationshipTypes,
      page,
      pageSize,
    }: {
    nodeTypes?: Array<string>,
    relationshipTypes?: Array<string>,
    page?: number,
    pageSize?: number,
    } = {},
) {
  try {
    const params = {
      nodeTypes,
      relationshipTypes,
      page,
      size: pageSize,
    };

    const response = await axiosInstance.get<WhereUsedResponse>(
        `${CODELOGIC_API_BASE_URL}/tabular/where-used/${nodeId}`,
        {
          params,
          withCredentials: true,
        },
    );

    return response.data;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse<unknown>>(e)) {
      if (e.response) {
        return e.response.data;
      }
      console.error(e);
    }
    throw e;
  }
}


export const nodeTypes = async (
    nodeId: string,
    {
      nodeTypes,
      relationshipTypes,
    }: {
    nodeTypes?: Array<string>,
    relationshipTypes?: Array<string>,
    } = {},
) => {
  try {
    const params = {
      nodeTypes,
      relationshipTypes,
    };

    const response = await axiosInstance.get<TypedApiResponse<Array<string>>>(
        `${CODELOGIC_API_BASE_URL}/tabular/where-used/${nodeId}/node/types`,
        {
          params,
          withCredentials: true,
        },
    );

    return response.data;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse>(e)) {
      if (e.response) {
        return e.response.data;
      }
      console.error(e);
    }
    throw e;
  }
};

export const relationshipTypes = async (
    nodeId: string,
    {
      nodeTypes,
      relationshipTypes,
    }: {
    nodeTypes?: Array<string>,
    relationshipTypes?: Array<string>,
    } = {},
) => {
  try {
    const params = {
      nodeTypes,
      relationshipTypes,
    };

    const response = await axiosInstance.get<TypedApiResponse<Array<string>>>(
        `${CODELOGIC_API_BASE_URL}/tabular/where-used/${nodeId}/relationship/types`,
        {
          params,
          withCredentials: true,
        },
    );

    return response.data;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse>(e)) {
      if (e.response) {
        return e.response.data;
      }
      console.error(e);
    }
    throw e;
  }
};
