import styled from 'styled-components';

export const HelpIconContainer = styled.button`
  color: var(--color-text-link);
  vertical-align: top;
  border: none;
  background: none;
  margin: 0;
  cursor: help;
  font-size: 0.8em;
  padding: 0.1em 0.2em 0em 0;
`;

