import Select from 'react-select';

import {MultiValue, Option} from './components';
import {Props} from './types';
import {TAG_SELECT_STYLE_OVERRIDES} from './styles';
import './styles.css';

export const TagSelect = (props: Props) => {
  return (
    <Select
      styles={TAG_SELECT_STYLE_OVERRIDES}
      className="TagSelect"
      components={{
        MultiValue,
        Option,
      }}
      isMulti
      placeholder={props.placeholder || 'Filter by'}
      hideSelectedOptions={true}
      closeMenuOnSelect={false}
      {...props}
    />
  );
};
