export type RemoveFirstFromTuple<T extends any[]> =
  T['length'] extends 0 ? undefined :
  (((...b: T) => void) extends (a: any, ...b: infer I) => void ? I : [])

export const isUndefined = <T>(x: T | undefined): x is undefined => typeof x === 'undefined';
export const isDefined = <T>(x: T | undefined): x is T => typeof x !== 'undefined';


// Useful only for unwrapping types to see how they resolve in an editor
// eslint-disable-next-line @typescript-eslint/ban-types
export type Id<T> = {} & { [ P in keyof T]: T[P]}

interface ISODateStringTypeDescriminator extends String {
  [key: string]: unknown;
}

export type ISODateString = ISODateStringTypeDescriminator & string;

export const isIsoDateString = (str: string): str is ISODateString => {
  return str.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,9})?Z$/) !== null;
};

export const assertUnreachable = (_: never): never => {
  throw new Error('Executed code that should be unreachable');
};
