import {Modal, ModalBody, ModalHeader, Progress} from 'reactstrap';

export const FileUploadProgress = ({submittingFile, percent}: {submittingFile: File, percent: number}) => {
  return (
    <Modal isOpen={true}>
      <ModalHeader>
        Uploading {submittingFile.name || ''}
      </ModalHeader>
      <ModalBody>
        <Progress value={percent} />
      </ModalBody>
    </Modal>
  );
};

export {useProgress} from './useProgress';
