import type {Entitlement} from './types';

export const ENTITLEMENT_URLS: Record<Entitlement, string> = {
  'CUSTOMER_ID': 'customer-id',
  'CUSTOMER_NAME': 'customer-name',
  'LICENSE_TYPE': 'license-type',
  'MAX_USERS': 'max-users',
  'MAX_AGENTS': 'max-agents',
  'LDAP_ENABLED': 'ldap-enabled',
  'SSO_ENABLED': 'sso-enabled',
  'RUNTIME_SCANS_ENABLED': 'runtime-scans-enabled',
  'MULTI_SERVER_ENABLED': 'multi-server-enabled',
  'SCAN_STORED_PROCEDURES_ENABLED': 'scan-stored-procedures-enabled',
  'USAGE_METRICS_ENABLED': 'usage-metrics-enabled',
  'EDIT_METRICS_SETTINGS_ENABLED': 'edit-metrics-settings-enabled',
  'VALIDITY_PERIOD_START': 'validity-period-start',
  'VALIDITY_PERIOD_END': 'validity-period-end',
  'PRODUCT_NAME': 'product-name',
  'INVESTIGATE_USAGES_ENABLED': 'investigate-usages-enabled',
};
