import {useContext} from 'react';

import type {ThemeName} from 'src/context';
import {ThemeContext} from 'src/context';
import {useLocalSetting} from 'src/hooks';
import {LocalOptionSetting, LocalBooleanSetting, SettingHolder, Page} from 'src/components';
import {LOCAL_SETTING_SIDEBAR_AUTO_TOGGLE, LOCAL_SETTING_KEY_WELCOME_GUIDE} from 'src/constants';

export const LocalSettings = () => {
  const {theme, setTheme} = useContext(ThemeContext);
  const [sideBarAutoToggle, setSideBarAutoToggle] = useLocalSetting<boolean>(LOCAL_SETTING_SIDEBAR_AUTO_TOGGLE, false);
  const [welcomeWizardEnabled, setWelcomeWizardEnabled] = useLocalSetting<boolean>(LOCAL_SETTING_KEY_WELCOME_GUIDE, false);
  return (
    <Page title='Local Settings'>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <SettingHolder
          name="Theme"
          description="Set the theme for the application."
          selector={
            <LocalOptionSetting<ThemeName>
              value={theme}
              setValue={setTheme}
              options={[{
                value: 'theme-light',
                label: 'Light',
              }, {
                value: 'theme-dark',
                label: 'Dark',
              }, {
                value: 'theme-minimal',
                label: 'Minimal (experimental)',
              }, {
                value: 'theme-minimal-inverse',
                label: 'Minimal Dark (experimental)',
              }]}
              getOptionLabel={({label}) => label}
              getOptionValue={({value}) => value}
            />
          }
        />
        <SettingHolder
          name="Auto Show/Hide Sidebar"
          description="Whether or not to automatically open/close the sidebar based on screen width"
          selector={
            <LocalBooleanSetting
              name={LOCAL_SETTING_SIDEBAR_AUTO_TOGGLE}
              value={sideBarAutoToggle}
              setValue={setSideBarAutoToggle}
            />
          }
        />
        <SettingHolder
          name="Show Welcome Guide"
          description="Show the welcome guide that appears on first login"
          selector={
            <LocalBooleanSetting
              name={LOCAL_SETTING_KEY_WELCOME_GUIDE}
              value={welcomeWizardEnabled}
              setValue={setWelcomeWizardEnabled}
            />
          }
        />
      </div>
    </Page>
  );
};
