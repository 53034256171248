import {StorableValue} from 'src/types/primitive';
import * as featureFlags from 'src/feature-flags';

type KeyOfFeatureFlags = keyof typeof featureFlags

export const getFeatureFlagStorage = (flagName: KeyOfFeatureFlags) => {
  const {defaultValue, key} = featureFlags[flagName];
  const [getFlag, setFlag] = getStorage<boolean>(key, defaultValue);
  return [getFlag, setFlag] as const;
};

export const getDebugStorage = () =>
  getStorage<boolean>('debug', false);


export const getStorage = <T extends StorableValue>(
  key: string,
  defaultValue: T,
) => {
  const getStoredValue = () => {
    const rawPersistedValue = window.localStorage.getItem(key);
    if (rawPersistedValue === null) {
      return defaultValue;
    } else {
      return JSON.parse(rawPersistedValue) as T;
    }
  };

  const setStoredValue = (value: StorableValue) => {
    if (typeof value === 'undefined') {
      window.localStorage.removeItem(key);
      return defaultValue;
    } else {
      window.localStorage.setItem(key, JSON.stringify(value));
      return getStoredValue();
    }
  };

  return [
    getStoredValue,
    setStoredValue,
  ] as const;
};
