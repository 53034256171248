import {useCallback, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {ColumnDef} from '@tanstack/react-table';
import identity from 'lodash/identity';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import {FaEllipsisV} from 'react-icons/fa';
import withReactContent from 'sweetalert2-react-content';
import SwalBase from 'sweetalert2';
import moment from 'moment/moment';

import {
  Layout,
  ListingPage,
  RecordDetailsModal,
} from 'src/components';
import {
  PageDataType,
  QueryFilterType,
  ScanMetadata,
  IncludedScan,
} from 'src/types';
import {
  deleteScanMetadata,
  getScans,
} from 'src/api/scanSpace';
import {DATETIME_FORMAT} from 'src/constants';

const Swal = withReactContent(SwalBase);
export const ScansList = () => {
  const [details, setDetails] = useState<ScanMetadata | undefined>();
  const [isDetailsOpen, setDetailsOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const {entityId: selectedScanSpaceId} = useParams();
  const [scans, setScans] = useState<Array<ScanMetadata>>([]);
  const [scanPageData, setScanPageData] = useState<PageDataType>();
  const scanColumns = useMemo<ColumnDef<IncludedScan>[]>(
      () => [
        {
          header: 'Id',
          accessorKey: 'id',
          enableSorting: false,
        },
        {
          header: 'Scan Context Id',
          accessorKey: 'scanContextId',
          enableSorting: false,
        },
        {
          header: 'Scan Root',
          accessorKey: 'stats.scanRootName',
          enableSorting: false,
        },
        {
          header: 'Started On',
          accessorKey: 'scanStartedOn',
          accessorFn: (s) => {
            if (s.scanStartedOn) {
              return moment(s.scanStartedOn)
                  .local()
                  .format(DATETIME_FORMAT);
            } else {
              return 'unknown';
            }
          },
        },
        {
          header: 'Stats',

          accessorFn: ({stats}) =>
            stats ? Object.entries(stats).map(([key, value]) => (
              `${key}: ${value}`
            )).join('\n') : '',
          enableSorting: false,
        },
        {
          header: 'Actions',
          id: 'actions',
          accessorFn: identity,
          enableSorting: false,
          cell: ({getValue}) => {
            const {scanContextId} = getValue();
            return (
              <div className="row-actions">
                <UncontrolledDropdown>
                  <DropdownToggle color="link">
                    <FaEllipsisV/>
                  </DropdownToggle>
                  {selectedScanSpaceId && (
                    <DropdownMenu>

                      <DropdownItem onClick={() => {
                        setDetails(getValue());
                        setDetailsOpen(true);
                      }}>Details</DropdownItem>
                      <DropdownItem onClick={() => deleteScan(selectedScanSpaceId, scanContextId)}>Delete</DropdownItem>
                    </DropdownMenu>
                  )}
                </UncontrolledDropdown>
              </div>
            );
          },
        },
      ],
      [selectedScanSpaceId],
  );

  const deleteScan = useCallback((scanSpaceId: string, scanContextId: string) => {
    Swal.fire({
      title: 'Do you want to delete this scan?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteScanMetadata(scanSpaceId, scanContextId);
        if (res.status === 'success') {
          Swal.fire({
            title: 'Scan deleted',
            text: 'Scan deleted successfully',
            icon: 'success',
            timer: 1200,
            showConfirmButton: false,
          });
          const queryFilters: QueryFilterType = {};
          if (scanPageData) {
            queryFilters.page = scanPageData.currentPage;
            queryFilters.pageSize = scanPageData.pageSize;
          }
          await fetchScans(queryFilters.page, queryFilters.pageSize);
        }
      }
    });
  }, [scanPageData]);


  const fetchScans = useCallback(async (page = 0, pageSize = 10, sortBy = 'scanStartedOn,desc') => {
    if (!selectedScanSpaceId) {
      return;
    }
    setLoading(true);
    const response = await getScans(selectedScanSpaceId, page, pageSize, sortBy);
    if (response.status === 'success') {
      setScans(response.data || []);
      setScanPageData(response.metaData?.pageData);
    }
    setLoading(false);
  }, [selectedScanSpaceId]);

  return (
    <Layout title={'Scans'} loading={isLoading}>
      <ListingPage
        columns={scanColumns}
        data={scans}
        pageData={scanPageData}
        fetchData={fetchScans}/>
      <RecordDetailsModal isOpen={isDetailsOpen} onClose={() => setDetailsOpen(false)} record={details}/>
    </Layout>
  );
};
