import {ReactNode} from 'react';

import {LicenseType, CustomizableTextKey} from './types';
export const LICENSE_TEXT_MAP: Record<LicenseType, Partial<Record<CustomizableTextKey, ReactNode>>> = {
  CORE: {},
  ENTERPRISE: {},
  COMMUNITY: {},
  TRIAL: {
    'license_type': 'Community Edition Trial',
    'expiration_date_supplimental_note': '(30-day Community Edition Trial)',
  },
} as const;
