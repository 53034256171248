import {createContext} from 'react';

import {THEMES} from 'src/styles/colors';
import {ThemeColorLegendMap} from 'src/types';
import {ColorLegendContext as ColorLegendContextType} from 'src/types';
import {DEFAULT_THEME} from 'src/styles/colors';
import {makeColorGetter} from 'src/state/dependency2';

const themeNames = Object.keys(THEMES);

const colorLegend: ThemeColorLegendMap = themeNames.reduce((acc, themeName) => {
  acc[themeName] = {};
  return acc;
}, {} as ThemeColorLegendMap);
const getLegendEntry = makeColorGetter('theme-dark', DEFAULT_THEME, () => {});

export const ColorLegendContext = createContext<ColorLegendContextType>({
  colorLegend,
  getLegendEntry,
});
