import {CODELOGIC_API_BASE_URL} from 'src/config';
import {
  AgentRequest,
  AgentUpdatePropertiesRequest,
  CreateAgentRequest,
} from 'src/types';

import {axiosInstance} from './baseApi';
import {isAxiosError} from './utils';

import {makeResourceListFetch, makeResourceFetchById} from './index';

export const getAgents = makeResourceListFetch<Array<AgentRequest>>({method: 'GET', path: 'registration/agents', filterName: 'statusFilter'});

export const getProperties = makeResourceFetchById<string>({method: 'GET', path: 'registration/properties'});

export async function createAgent(createAgentRequest: CreateAgentRequest): Promise<any> {
  try {
    const response = await axiosInstance.post(`${CODELOGIC_API_BASE_URL}/registration/manual`, createAgentRequest, {withCredentials: true});
    return response?.data;
  } catch (e: any) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
}

export async function approveAgent(agentId: string, status: boolean, label: string, description: string, body: string): Promise<any> {
  let endpoint = `${CODELOGIC_API_BASE_URL}/registration/approval/${encodeURIComponent(agentId)}?status=${encodeURIComponent(status)}`;
  if (typeof label === 'string') {
    endpoint = endpoint + `&label=${encodeURIComponent(label)}`;
  }
  if (typeof description === 'string') {
    endpoint = endpoint + `&description=${encodeURIComponent(description)}`;
  }

  try {
    const response = await axiosInstance.patch(endpoint, body, {
      withCredentials: true,
    });
    return response;
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response;
      }
    }
    throw e;
  }
}

export async function updateProperties(
    agentId: string,
    label: string,
    description: string,
    request: AgentUpdatePropertiesRequest,
): Promise<any> {
  let endpoint = `${CODELOGIC_API_BASE_URL}/registration/properties/${encodeURIComponent(agentId)}?`;

  if (typeof label === 'string' || typeof description === 'string') {
    if (typeof label === 'string') {
      endpoint += `label=${encodeURIComponent(label)}&`;
    }
    if (typeof description === 'string') {
      endpoint += `description=${encodeURIComponent(description)}&`;
    }
  }

  try {
    const response = await axiosInstance.put(endpoint, request, {
      withCredentials: true,
    });
    return response;
  } catch (e) {
    console.error(e);
    return e;
  }
}

export async function deleteAgent(agentId: string): Promise<any> {
  try {
    return await axiosInstance.delete(`${CODELOGIC_API_BASE_URL}/registration/agent/${encodeURIComponent(agentId)}`, {withCredentials: true});
  } catch (e) {
    console.error(e);
    return e;
  }
}
