import {
  Panel,
  GraphObject,
  PanelLayout,
} from 'gojs';

import {MakeFnInitializers} from '../../types';
import {processRest} from '../../utils';
import {make} from '../..';
import {BACKGROUND_COLOR} from '../styles';

const {Fill} = GraphObject;

export const container = (
    rest?: MakeFnInitializers,
    layout: PanelLayout = Panel.Vertical,
) =>
  make(
      Panel,
      layout,
      {
        background: BACKGROUND_COLOR.container,
        stretch: Fill,
      },
      processRest(rest),
  );


