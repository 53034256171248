
import {Props} from './types';

import './styles.css';

export const FormSection = ({children, title}: Props) => (
  <div className="FormSection">
    <div className="FormSection__header">
      <span className="FormSection__title">
        {title}
      </span>
    </div>
    <div className="FormSection__content">
      {children}
    </div>
  </div>
);

