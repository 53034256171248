import {FormGroup, Input, Label} from 'reactstrap';

import {AuthorizationType} from 'src/types';

import {Props} from './types';
import './styles.css';

export const AuthTypePicker = ({type: authType, typeChanged: typeChanged} : Props) => {
  return (
    <FormGroup>
      <Label for="authType">Authenticate Type</Label>
      <Input id="authType" name="authType" type="select" className="AuthTab__select" onChange={(e) => {
        const basic = e.currentTarget.value === AuthorizationType.BASIC;
        typeChanged(basic ? AuthorizationType.BASIC : AuthorizationType.AZURE_AD);
      }} defaultValue={authType}>
        <option value={AuthorizationType.BASIC} >Basic</option>
        <option value={AuthorizationType.AZURE_AD} >Azure AD</option>
      </Input>
    </FormGroup>
  );
};
