import {IoCheckmarkCircle as SuccessIcon} from 'react-icons/io5';
import {Link} from 'react-router-dom';
import {BsQuestionCircle} from 'react-icons/bs';
import {RiErrorWarningLine} from 'react-icons/ri';
import {Field as FormikField} from 'formik';
import {Input} from 'reactstrap';

import {APP_ROOT} from 'src/routes';
import {Property} from 'src/components';

import {FormData} from './types';
import {Field} from './components';
import {DOC_LINKS} from './constants';

import './styles.css';

const TYPE_TO_LABEL_MAP = {
  javascript: 'JavaScript',
  dotnet: '.NET',
  sql: 'SQL',
  java: 'Java',
} as const;

export const Step1 = ({agentType, agentUUID, agentPassword}: FormData) => (
  <>
    <div className="MultiStepForm__header">
      <h4>{`Let's create your code snippet for a Docker image of the ${(TYPE_TO_LABEL_MAP[agentType] || '') + ' '}Agent`}</h4>
    </div>
    <Field label="CodeLogic server IP address or host" name="serverAddress">
      <Input
        tag={FormikField}
        name="serverAddress"
      />
    </Field>
    { agentType !== 'sql' &&
      <Field label="What directory path will be scanned?" name="scanPath">
        <Input
          tag={FormikField}
          name="scanPath"
        />
      </Field>
    }
    <Field label="What name would you like to give the scan?" name="application">
      <Input
        tag={FormikField}
        name="application"
      />
    </Field>
    {agentType === 'sql' &&
      <>
        <Field label="Username" name="username">
          <Input
            tag={FormikField}
            name="username"
          />
        </Field>
        <Field label="Password" name="password">
          <Input
            tag={FormikField}
            name="password"
          />
        </Field>
        <Field label="JDBC Url" name="jdbcUrl">
          <Input
            tag={FormikField}
            name="jdbcUrl"
          />
        </Field>
        <Field label="Database Name" name="database">
          <Input
            tag={FormikField}
            name="database"
          />
        </Field>
      </>
    }
    <input type="hidden" name="agentUUID" value={agentUUID}/>
    <input type="hidden" name="agentPassword" value={agentPassword}/>
  </>
);

export const Step2 = ({command, agentType, agentLimitReached}: FormData & {agentLimitReached: boolean}) => (
  <>
    <div className="MultiStepForm__status-indicator">
      <div className="MultiStepForm__status-icon-wrapper">
        {agentLimitReached ? (
          <RiErrorWarningLine
            style={{
              color: 'var(--color-danger-1)',
            }}
            size='5em'
          />
        ) : (
        <SuccessIcon
          style={{
            color: 'var(--color-success)',
          }}
          fontSize='6em'
        />
        )}
      </div>
      <h4>{agentLimitReached ? 'Max Agent Alert' : 'Success!'}</h4>
      <p className='MultiStepForm__additional-info'>
        {agentLimitReached ? <>
        You have reached the maximum number of allocated agents for your license. Please reach out to your CodeLogic Admin or
          {' '}<Link to={`${APP_ROOT}/administration/agents`}>remove an existing agent.</Link>
        </> : ''}
      </p>
    </div>
    <div className="MultiStepForm__copy-prompt-container">
      <Property hideValue={true} label='Run code for Docker agent' value={command} />
      <p>
        <a id="docker-agent-help-icon" className="MultiStepForm__help-icon" target="_blank" href={DOC_LINKS[agentType]} rel="noreferrer">
          <BsQuestionCircle className="tooltip-icon" />
        </a>
      </p>
    </div>
    <div className="MultiStepForm__code-container" >
      <code className="MultiStepForm__code">
        {command}
      </code>
    </div>
  </>
);
