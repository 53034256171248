import {ReactNode, useEffect, useRef, useState} from 'react';
import {BsXCircle} from 'react-icons/bs';
import classnames from 'classnames';
import {CSSProperties, PropsWithChildren} from 'react';
import {TabPane} from 'reactstrap';
import Swal from 'sweetalert2';

import {assertUnreachable} from 'src/types/util';
import {uploadLicense} from 'src/api/license';
import {LoadingPlaceholder} from 'src/components';

import {UploaderStates} from './types';

import './styles.css';

export const Container = ({children, style}: PropsWithChildren<{style?: CSSProperties}> = {style: {}}) => (
  <TabPane style={{...style, display: 'flex', height: '100%', width: '100%'}}>
    {children}
  </TabPane>
);

export const LicenseUploader = (props: {
  onChanged: () => void;
}) => {
  const [state, setState] = useState<UploaderStates>('pending-selection');
  const [selectedFile, setSelectedFile] = useState<File>();

  const ref = useRef<HTMLInputElement>(null);

  const handleFiles = () => {
    const {current} = ref;
    if (current && current.files) {
      setSelectedFile(current.files[0]);
      setState('ready');
    }
  };

  const deselectFile = () => setSelectedFile(undefined);

  useEffect(() => {
    document.addEventListener('change', handleFiles, false);
    return () => document.removeEventListener('change', handleFiles);
  }, [ref]);


  const handleSubmit = async () => {
    if (selectedFile) {
      setState('uploading');
      const response = await uploadLicense(selectedFile);
      if (response.status === 'success' && response.data) {
        await Swal.fire({title: 'Success',
          html: `The license was successfully uploaded`,
          icon: 'success',
          width: '60%',
        });
        deselectFile();
        props.onChanged();
      } else if (response.status === 'error' && response.error) {
        if (response.error.code.startsWith('REQ_BAD')) {
          setState('error-validation');
        } else {
          setState('error');
        }
      }
    }
  };

  // Skip the ready/confirm state for the moment
  if (state === 'ready' && selectedFile) {
    handleSubmit();
  }

  return (
    <div className="LicenseUploader">
      <input
        ref={ref}
        type="file"
        className="LicenseUploader__upload-hidden-input"
        id="LicenseUploader__upload-hidden-input"
        name="LicenseUploader__upload-hidden-input"
      />
      <div className="LicenseUploader__action">
        <label
          htmlFor="LicenseUploader__upload-hidden-input"
          className="LicenseUploader__action__upload btn btn-primary">
          <span className={classnames({
            'hidden': state === 'uploading',
          })}>
            Upload
          </span>
          {state === 'uploading' && <LoadingPlaceholder position='absolute' noDelay scale={0.5}/>}
        </label>
      </div>
      <div className="LicenseUploader__instruction">
        <span className={classnames({
          'LicenseUploader__instruction__primary': true,
          'LicenseUploader__instruction__error': ['error', 'error-network', 'error-validation'].includes(state),
        })}>
          {getInstructionText(state)}
        </span>
      </div>
    </div>
  );
};

const getInstructionText = (state: UploaderStates): ReactNode => {
  switch (state) {
    case 'pending-selection':
    case 'ready':
      return <>Upload a license file</>;
    case 'uploading':
      return <>Uploading...</>;
    case 'error':
      return <ErrorMessageUnknown />;
    case 'error-network':
      return <ErrorMessageNetwork />;
    case 'error-validation':
      return <ErrorMessageInvalid />;
    default:
      assertUnreachable(state);
  }
};

const ErrorMessageInvalid = () => (
  <div className="LicenseUploader__instruction__icon-container">
    <BsXCircle className="LicenseUploader__instruction__icon" />
    <span>The license format was invalid.</span>
  </div>
);

const ErrorMessageUnknown = () => (
  <div className="LicenseUploader__instruction__icon-container">
    <BsXCircle className="LicenseUploader__instruction__icon" />
    <span>An unknown problem occurred.</span>
  </div>
);

const ErrorMessageNetwork = () => (
  <div className="LicenseUploader__instruction__icon-container">
    <BsXCircle className="LicenseUploader__instruction__icon" />
    <span>There was a problem uploading the license.</span>
  </div>
);
