import {useState} from 'react';
import throttle from 'lodash/throttle';

export const useTermFilter = (initialTerms = []) => {
  const [searchTerms, setSearchTerms] = useState<Array<string>>(initialTerms);

  const updateSearchTerm = throttle(
      setSearchTerms,
      100,
      {leading: true},
  );

  return [searchTerms, updateSearchTerm] as const;
};

export const useTextFilter = (initialText = '') => {
  const [searchString, setSearchString] = useState(initialText);

  const splitSearchTerms =
    searchString
        .trim()
        .replace(/\s\s+/g, ' ')
        .split(' ')
        .filter((t) => t.length > 0);

  const updateSearchTerm = throttle(
      setSearchString,
      100,
      {leading: true},
  );

  return [splitSearchTerms, updateSearchTerm] as const;
};
