import {useState, useMemo, useContext, useCallback} from 'react';
import identity from 'lodash/identity';
import {ColumnDef} from '@tanstack/react-table';
import Swal from 'sweetalert2';
import {FaEllipsisV} from 'react-icons/fa';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  TabPane,
  UncontrolledDropdown,
} from 'reactstrap';

import {AuthContext} from 'src/context';
import {getConfigurations, deleteConfiguration} from 'src/api/servicenowConfig';
import {UserRole} from 'src/types';
import {ListingPage} from 'src/components';

import NewServicenowConfig from './NewServicenowConfig';

import './administration.css';

interface ConfigDataType {
  url?: string;
  username?: string;
}

const ServiceNowConfig = ({setLoading}: {setLoading: (_: boolean) => void}) => {
  const {hasRole} = useContext(AuthContext);
  const [configData, setConfigData] = useState<Array<ConfigDataType>>([]);
  const [isConfigFormOpen, setIsConfigFormOpen] = useState(false);
  const [editData, setEditData] =
    useState<ConfigDataType | undefined>(undefined);

  const columns = useMemo<ColumnDef<ConfigDataType>[]>(
      () => [
        {
          header: 'URL',
          accessorKey: 'url',
          enableSorting: false,
        },
        {
          header: 'Username',
          accessorKey: 'username',
          enableSorting: false,
        },
        {
          header: 'Actions',
          accessorFn: identity,
          enableSorting: false,
          cell: ({getValue}: {getValue: () => ConfigDataType}) => {
            const {url, username} = getValue();
            return (
              <div className="row-actions">
                <UncontrolledDropdown>
                  <DropdownToggle color="link">
                    <FaEllipsisV />
                  </DropdownToggle>
                  <DropdownMenu>
                    {url && username && (
                      <DropdownItem onClick={() => editConfig(url, username) }>
                        Edit
                      </DropdownItem>
                    )}
                    {url && (
                      <DropdownItem onClick={() => deleteConfig(url)}>
                        Delete
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            );
          },
        },
      ],
      [],
  );

  const openConfigForm = () => {
    setIsConfigFormOpen(true);
  };

  const closeConfigForm = () => {
    setIsConfigFormOpen(false);
    setEditData(undefined);
    fetchConfig();
  };

  const fetchConfig = useCallback(
      async (page = 0, pageSize = 10) => {
        setLoading(true);
        const response = await getConfigurations({page, pageSize});
        if (response.data && Array.isArray(response.data)) {
          setConfigData(response.data);
        }
        setLoading(false);
      },
      [isConfigFormOpen],
  );

  const editConfig = (url: string, username: string) => {
    setEditData({url, username});
    setIsConfigFormOpen(true);
  };

  const deleteConfig = (url: string) => {
    Swal.fire({
      title: 'Do you want to delete this configuration?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Delete`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteConfiguration(url);
        if (res.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: 'Configuration deleted successfully',
            icon: 'success',
            timer: 1200,
            showConfirmButton: false,
          });
          fetchConfig();
        }
      }
    });
  };

  const canCreate = hasRole(UserRole.ADMIN);

  return (
    <TabPane>
      <div className="admin-page-options">
        {canCreate && (
          <Button color="primary" onClick={openConfigForm}>
          Add Configuration
          </Button>
        )}
      </div>
      <ListingPage
        columns={columns}
        data={configData}
        pageData={undefined}
        fetchData={fetchConfig}
      />
      {isConfigFormOpen && (
        <NewServicenowConfig
          isOpen={isConfigFormOpen}
          onClose={closeConfigForm}
          editData={editData}
        />
      )}
    </TabPane>
  );
};

export default ServiceNowConfig;
