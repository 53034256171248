import {AuthResponseData, ApiErrorResponse} from 'src/types';
import {CODELOGIC_API_BASE_URL} from 'src/config';

import {axiosInstance} from './baseApi';
import {isAxiosError} from './utils';

import {makeResourceListFetch} from './index';

export const getAuthorizationUrl = makeResourceListFetch<string | null>({method: 'GET', path: 'auth/azure/authorization-url'});

export async function authenticate(code: string) {
  const params = new URLSearchParams();
  params.append('code', code);

  try {
    const response = await axiosInstance.post<AuthResponseData>(
        `${CODELOGIC_API_BASE_URL}/auth/azure/authenticate`,
        params,
        {
          withCredentials: false,
          headers: {'content-type': 'application/x-www-form-urlencoded'},
        },
    );

    return response;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse>(e)) {
      if (e.response) {
        return e.response;
      }
    }

    throw e;
  }
}
