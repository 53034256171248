import {ToggleSwitch} from 'src/components/ToggleSwitch';
import {useFeatureFlagByValue} from 'src/hooks/storage';

export const FeatureDisplay = ({feature}) => {
  const [flagValue, setFlagValue] = useFeatureFlagByValue(feature);
  return (
    <ToggleSwitch
      key={feature.key}
      name={feature.key}
      onChange={setFlagValue}
      isChecked={flagValue || false}/>
  );
};

