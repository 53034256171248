import {useEffect, useState} from 'react';

import {getLicense} from 'src/api/license';
import {useResource} from 'src/hooks';
import {NoDataPlaceholder, LoadingPlaceholder, PropertyList} from 'src/components';
import {isIsoDateString} from 'src/types/util';
import {LicenseUploader} from 'src/components';
import {parseDate} from 'src/util/date';

import {makeLicenseTextGetter} from './utils';
import {Container, Section} from './components';
import {LicenseType} from './types';


import '../administration.css';

export const LicenseTab = () => {
  const {loading, entity: license, loadEntity: loadLicense} = useResource(getLicense);
  const [reload, setReload] = useState(0);


  useEffect(() => {
    loadLicense();
  }, [reload]);

  if (loading) {
    return (
      <Container>
        <LoadingPlaceholder scale={2}/>
      </Container>
    );
  }

  if (!license) {
    return (
      <Container style={{fontSize: '1.2em'}}>
        <NoDataPlaceholder>
          No license data available
        </NoDataPlaceholder>
      </Container>
    );
  }

  const {entitlements: {'max-users': maxUsers}, licenseType, expirationDate} = license;

  // TODO: Move LicenseType up to src/types in with the license entitlements
  const text = makeLicenseTextGetter(licenseType as LicenseType);

  return (
    <Container style={{alignItems: 'center'}}>
      <div className="license-tab-container">
        <Section>
          <PropertyList
            disableCopy
            properties={{
              'License Type': text('license_type', licenseType),
              'Valid Through': `${isIsoDateString(expirationDate) ? parseDate(expirationDate) : expirationDate} ${text('expiration_date_supplimental_note')}`,
              'Allocated Users': maxUsers,
            }} />
        </Section>
        <Section>
          <LicenseUploader onChanged={() => setReload(reload + 1)}/>
        </Section>
      </div>
    </Container>
  );
};

