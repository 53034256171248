import get from 'lodash/get';

type PathArg = Parameters<typeof get>[1]

import {useTextFilter, useTermFilter} from 'src/hooks';

import {filterListOnRelationTerms} from './utils';

export const useTextFilteredList = <T>(list: Array<T>, path?: PathArg) => {
  const [terms, setFilterText] = useTextFilter();

  const filteredList = filterListOnRelationTerms(list, terms, path);

  return {
    filteredList,
    terms,
    setFilterText,
  } as const;
};

export const useTermFilteredList = <T>(list: Array<T>, path?: PathArg) => {
  const [terms, setFilterTerms] = useTermFilter();

  const filteredList = filterListOnRelationTerms(list, terms, path);

  return {
    filteredList,
    terms,
    setFilterTerms,
  } as const;
};
