import {useEffect, useCallback, useState} from 'react';
import {
  Button,
  TabPane,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import {
  Formik,
  Form as FForm,
  Field,
  ErrorMessage,
} from 'formik';

import {useResource} from 'src/hooks';
import {ConfirmCancelButton, Section, Table} from 'src/components';
import {SlackConfig} from 'src/types';
import {
  listSlackConfig,
  createSlackConfig,
  updateSlackConfig,
} from 'src/api/notifications';
import {
  ROOT_PATH as SLACK_ROOT_PATH,
} from 'src/api/notifications/slack';
import {
  makeResourceFetch,
} from 'src/api';

import {Modal} from './components';

const isCompleteConfig = (cfg: Partial<SlackConfig>): cfg is SlackConfig => !!cfg.title && !!cfg.accessToken;

export const NotificationConfigManagement = () => {
  const [subject, setSubject] = useState<Partial<SlackConfig> | undefined>(undefined);
  const [{page, pageSize}, setQueryPagination] = useState<{page: number, pageSize: number}>({
    page: 0,
    pageSize: 10,
  });

  const list = useCallback(listSlackConfig, []);
  const {
    entity: configs,
    metadata: configMetadata,
    loadEntity: loadConfigs,
  } = useResource(list);

  const onSubmit = useCallback(
      async (values: SlackConfig) => {
        if (values.id) {
          await updateSlackConfig(values);
        } else {
          await createSlackConfig(values);
        }
        loadConfigs({
          page,
          pageSize,
        });
      }, [updateSlackConfig, createSlackConfig, loadConfigs],
  );

  useEffect(() => {
    loadConfigs({
      page,
      pageSize,
    });
  }, []);

  return (
    <TabPane>
      <Section title={(
        <h1 style={{margin: 0}}>Notifications</h1>
      )}
      />
      <Section title={(
        <>
          <h2 style={{margin: 0}}>Slack Configuration</h2>
          <Button
            style={{
              minWidth: '10em',
              marginLeft: '1em',
            }}
            onClick={() => setSubject({})}
          >
            Create New
          </Button>
        </>
      )}>
        <Table
          rows={configs}
          columns={[
            {id: 'accessToken', header: () => 'Access Token', cell: ({accessToken}) => accessToken},
            {id: 'title', header: () => 'Title', cell: ({title}) => title},
            {id: 'action', width: 400, cell: (config) => (
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                  style={{marginRight: '1em'}}
                  onClick={() => setSubject(config)}
                >
                  Edit
                </Button>
                <ConfirmCancelButton
                  promptText={'Delete'}
                  confirmText="Confirm"
                  onConfirm={async () => {
                    const deleteReport = makeResourceFetch<SlackConfig>({
                      path: `${SLACK_ROOT_PATH}/${config.id}`,
                      method: 'DELETE',
                    });

                    const response = await deleteReport({});
                    if (response.status === 'success') {
                      loadConfigs({
                        page,
                        pageSize,
                      });
                    }

                    return false;
                  }}
                />
              </div>
            )},
          ]}
          onSelectPageSize={(pageSize) => setQueryPagination({
            pageSize,
            page,
          })}
          onPageChange={(page) => setQueryPagination({
            pageSize,
            page,
          })}
          metadata={configMetadata}
        />
      </Section>
      {subject && (
        <Modal title={`${subject.id ? 'Edit' : 'Create'} Slack Configuration`}>
          <Formik<Partial<SlackConfig>>
            initialValues={subject || {
              accessToken: '',
              name: '',
            }}
            validate={(values: Partial<SlackConfig>) => {
              const errors: Partial<Record<keyof SlackConfig, string>> = {};

              ['accessToken', 'title'].forEach((f) => {
                if (values[f] === undefined || values[f] === '') {
                  errors[f] = '*Required';
                }
              });
              return errors;
            }}
            onSubmit={(values) => {
              if (isCompleteConfig(values)) {
                onSubmit(values);
              }
              setSubject(undefined);
            }}
            validateOnBlur={true}
          >
            {({isSubmitting}) => (
              <FForm autoComplete="off">
                <FormGroup>
                  <div className="Form__field">
                    <Label for="accessToken">Access Token</Label>{' '}
                    <span className='Form__error'><ErrorMessage name="accessToken" /></span>
                    <Input
                      tag={Field}
                      name="accessToken"
                      type="text"
                    />
                  </div>
                  <div className="Form__field">
                    <Label for="title">Title</Label>{' '}
                    <span className='Form__error'><ErrorMessage name="title" /></span>
                    <Input
                      tag={Field}
                      name="title"
                      type="text"
                    />
                  </div>
                </FormGroup>
                <Button type="submit" color="primary" disabled={isSubmitting}>Save</Button>
                <Button color="secondary" style={{float: 'right'}} disabled={isSubmitting} onClick={() => setSubject(undefined)}>Cancel</Button>
              </FForm>
            )}
          </Formik>
        </Modal>
      )}
    </TabPane>
  );
};
