import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik';
import {Button, FormGroup, FormText, Input, Label} from 'reactstrap';
import React from 'react';

import {Errors, FormFields} from 'src/components/Administration/Auth/types';
import {AuthorizationConfig, AuthorizationType} from 'src/types';
import {updateAuthConfig} from 'src/components/Administration/Auth/utils';
import {OPTIONS} from 'src/components/Administration/UserManagement/constants';

import {Props} from './types';


export const AzureADForm = ({authorizationConfig: authorizationConfig} : Props) => {
  return (
    <>
      <Formik<FormFields>
        initialValues={{
          tenantId: authorizationConfig.tenantId || '',
          clientId: authorizationConfig.clientId || '',
          redirectUrl: authorizationConfig.redirectUrl || '',
          secretKey: '',
          roleGroup: Object.entries(authorizationConfig.externalGroupToInternalRoleMap).map(([key, value]) => {
            return {group: key, role: value};
          }) || [],
        }}
        onSubmit={async (values) => {
          const groupData: {[name: string]: string} = {};
          await values.roleGroup.forEach((entry) => {
            groupData[entry.group] = entry.role;
          });
          const config: AuthorizationConfig = {
            authorizationType: AuthorizationType.AZURE_AD,
            tenantId: values.tenantId,
            redirectUrl: values.redirectUrl,
            clientId: values.clientId,
            clientSecret: values.secretKey || undefined,
            externalGroupToInternalRoleMap: groupData,
          };
          await updateAuthConfig(config);
        }}
        validate={(values) => {
          const errors: Errors = {};
          const requiredString = '*Required';
          if (!values.tenantId) {
            errors.tenantId = requiredString;
          }
          if (!values.clientId) {
            errors.clientId = requiredString;
          }
          if (!values.secretKey) {
            errors.secretKey = requiredString;
          }
          if (!values.roleGroup || values.roleGroup.length <= 0) {
            errors.roleGroup = '*at least one entry is required';
          } else if (values.roleGroup.some((entry) => !entry.role || !entry.group)) {
            errors.roleGroup = values.roleGroup.map((entry, index) => {
              const row: {group?:string, role?:string} = {};
              if (!entry.role) {
                row.role = requiredString;
              }
              if (!entry.group) {
                row.group = requiredString;
              }
              return row;
            });
          }
          return errors;
        }}
      >
        {({isSubmitting, errors, values}) => (
          <Form>
            <FormGroup>
              <Label for="tenantId">Azure AD tenant Id</Label>
              <ErrorMessage
                className="form-error"
                name="tenantId"
                component="div"
              />
              <Input tag={Field} id="tenantId" name="tenantId" className='AuthTab__text'/>
            </FormGroup>
            <FormGroup>
              <Label for="redirectUrl">Redirect URL</Label>
              <ErrorMessage
                className="form-error"
                name="redirectUrl"
                component="div"
              />
              <Input tag={Field} id="redirectUrl" name="redirectUrl" className='AuthTab__text'/>
            </FormGroup>
            <FormGroup>
              <Label for="clientId">Client ID</Label>
              <ErrorMessage
                className="form-error"
                name="clientId"
                component="div"
              />
              <Input
                tag={Field}
                id="clientId"
                name="clientId"
                className='AuthTab__text'
                placeholder='Enter your Client ID'/>
            </FormGroup>
            <FormGroup>
              <Label for="secretKey">Secret Key</Label>
              <ErrorMessage
                className="form-error"
                name="secretKey"
                component="div"
              />
              <Input
                tag={Field}
                id="secretKey"
                name="secretKey"
                className='AuthTab__text'
                placeholder='Enter secret key'
              />
            </FormGroup>
            <Label for="roles">Role Groups</Label>{' '}
            {errors.roleGroup && typeof errors.roleGroup === 'string' && <ErrorMessage
              className="form-error"
              name={`roleGroup`}
              component="div"
            />}
            <FieldArray name="roleGroup">
              {({insert, remove, push}) => (
                <>
                  <div style={{display: 'flex', flexFlow: 'column'}}>
                    {values.roleGroup.length > 0 &&
                    values.roleGroup.map((roleGroupEntry, index) =>(
                      <div style={{display: 'flex', flexFlow: 'row'}} key={index}>
                        <FormGroup>
                          <Label for={`roleGroup.${index}.group`}>Group</Label>
                          <ErrorMessage
                            className="form-error"
                            name={`roleGroup.${index}.group`}
                            component="div"
                          />
                          <Input
                            tag={Field}
                            name={`roleGroup[${index}].group`}
                            className='AuthTab__multi__text'
                            placeholder='userGroup'
                          />
                          <FormText>The security groups for the selected role.</FormText>
                        </FormGroup>
                        <FormGroup key={index}>
                          <Label for={`roleGroup.${index}.role`}>Role</Label>
                          <ErrorMessage
                            className="form-error"
                            name={`roleGroup[${index}].role`}
                            component="div"
                          />
                          <Input
                            tag={Field}
                            name={`roleGroup.${index}.role`}
                            className='AuthTab__multi__text'
                            placeholder='userRole'
                            as="select"
                          >
                            {OPTIONS.map(({label, id, disabled}, index) => (
                              <option key={id} disabled={disabled} value={id}>{label}</option>
                            ))}
                          </Input>
                          <FormText>The role to assign this security group.</FormText>
                        </FormGroup>
                        <div>
                          <button
                            type="button"
                            className="secondary"
                            onClick={() => remove(index)}
                          >
                            -
                          </button>
                        </div>
                      </div>
                    ))
                    }
                  </div>
                  <div>
                    <button
                      type="button"
                      className="secondary"
                      onClick={() => push({group: '', role: ''})}
                      style={{marginBottom: '1rem'}}
                    >
                      +
                    </button>
                  </div>
                </>
              )}
            </FieldArray>
            <Button color="secondary" style={{marginRight: '1rem'}}>Cancel</Button>
            <Button type="submit" color="primary">Save</Button>
          </Form>
        )}
      </Formik>
    </>
  );
};
