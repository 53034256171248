import {Node} from 'gojs';

import {getDecomposition} from 'src/api/dependency';
import {getNodesInGroup} from 'src/api/groups';
import {
  mapContainedNodesToNodes,
  mapContainedNodesToEdges,
  mapReferencedNodesToNodes,
  mapReferencedNodesToEdges,
} from 'src/state/dependency2';

// 1. HasNodeParent: Checks if the current node already has been *clicked* / children have been rendered
// If not, load the node's children from API
// Insert into tree
// Re-derive tree structure
export const fetchDataForNode = async (
    node: Node,
    direction?: 'IN' | 'OUT' | 'BOTH',
    relationshipFilters?: Array<string>,
    nodeFilters?: Array<string>,
) => {
  const id = node.data.id.toString();
  if (!id) {
    return {
      nodes: [],
      edges: [],
    };
  }
  const {nodes, edges} = node.data.isGroupNode ?
    await fetchNodesInGroup(id) :
    await fetchNodeDecomposition(id, direction, relationshipFilters, nodeFilters);

  return {
    nodes,
    edges,
  };
};

export const fetchNodesInGroup = async (
    groupNodeId: string,
) => {
  const nodesData = await getNodesInGroup(groupNodeId, {page: 0, pageSize: 1000});
  if (nodesData.error) {
    throw nodesData.error;
  }

  const nodes = nodesData.data.map(({
    id,
    type,
    identity,
    name,
    properties,
  }) => ({
    metadata: {
      type,
      identity,
    },
    text: name || identity,
    name,
    id,
    isGroupNode: false,
    isScanRoot: properties.isScanRoot,
    category: 'parent',
    properties,
  }));

  const edges = nodesData.data.map(({id}: {id: string}) => ({
    id: `${groupNodeId}-${id}`,
    from: groupNodeId,
    to: id,
    groups: true,
  }));

  return {nodes, edges};
};

export const fetchNodeDecomposition = async (
    nodeId: string,
    direction?: 'IN' | 'OUT' | 'BOTH',
    relationshipFilters?: Array<string>,
    nodeFilters?: Array<string>,
) => {
  const res = await getDecomposition({
    id: nodeId, direction,
    relationshipFilters,
    nodeFilters,
  });
  if (res.error) {
    throw res.error;
  }
  const baseNodeId = res.data.id;
  const baseNodeType = res.data.type;

  const containedNodes = mapContainedNodesToNodes(res.data.containedNodes);
  const referencedNodes = mapReferencedNodesToNodes(res.data.referencedNodes);
  const nodes = [...containedNodes, ...referencedNodes];

  const containedEdges = mapContainedNodesToEdges(res.data.containedNodes, baseNodeId, baseNodeType);
  const referencedEdges = mapReferencedNodesToEdges(res.data.referencedNodes, baseNodeId, baseNodeType);
  const edges = [...containedEdges, ...referencedEdges];

  if (direction === 'IN') {
    edges.filter((e) => e.expanded).forEach((e) => {
      const inboundNode = nodes.find((n) => n.id === e.from);
      if (inboundNode) {
        inboundNode.isTreeExpanded = true;
      }
    });
  }

  return {nodes, edges};
};

