import {
  TreeVertex,
} from 'src/util/gojs/go';

export const nameTypeComparer = (va: TreeVertex, vb: TreeVertex) => {
  // Before this code was typed, we implicitly assumed that
  // va and vb always had a node.  In theory, we should never hit
  // this condition.  To handle the possible case of null nodes
  // we assume the missing (null) node sorts after the present (non-null) node.

  if (!va.node || !vb.node) {
    return 0;
  }

  if (!va.node) {
    return -1;
  }

  if (!vb.node) {
    return 1;
  }

  const da = va.node.data;
  const db = vb.node.data;
  if (!da.metadata?.type || !db.metadata?.type) {
    return 0;
  }

  const typeA = da.metadata?.type.toUpperCase(); // ignore upper and lowercase
  const typeB = db.metadata?.type.toUpperCase(); // ignore upper and lowercase
  if (typeA < typeB) {
    return -1;
  }
  if (typeA > typeB) {
    return 1;
  }

  const nameA = da.text?.toUpperCase(); // ignore upper and lowercase
  const nameB = db.text?.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // types must be equal
  return 0;
};
