import {CSSProperties, PropsWithChildren, ReactNode} from 'react';
import classnames from 'classnames';

type SectionStyle = 'default' | 'help'

import './styles.css';
export const Section = ({
  title,
  type,
  controls,
  children,
  className = '',
  style,
}: PropsWithChildren<{
  title?: ReactNode,
  controls?: ReactNode,
  className?: string,
  type?: SectionStyle
  style?: CSSProperties
}>) => (
  <div className={classnames({
    'Section': true,
    'Section--help': type === 'help',
    [className]: !!className,

  })}>
    {(title || controls ) && (
      <div className="Section__header" style={style}>
        <div className="Section__title-container">
          {typeof title === 'string' ? (
            <h2>{title}</h2>
          ) : (
            title
          )}
        </div>
        <div className="Section__controls">{controls}</div>
      </div>
    )}
    <div className="Section__content">{children}</div>
  </div>
);
