import {CODELOGIC_API_BASE_URL} from 'src/config';
import {AwsConfig, AwsData, AwsResponse} from 'src/types';

import {axiosInstance} from './baseApi';
import {isAxiosError} from './utils';

import {makeResourceListFetch} from './index';

const API_ENDPOINT = `${CODELOGIC_API_BASE_URL}/aws/account-configuration`;

export const getConfigurations = makeResourceListFetch<Array<AwsConfig>>({method: 'GET', path: 'aws/account-configuration'});

export async function postConfiguration(data: AwsData) {
  try {
    const reqBody = {...data, scanAfterSave: true};

    const response = await axiosInstance.post<AwsResponse>(API_ENDPOINT, reqBody, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    if (isAxiosError<AwsResponse>(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
}

export async function deleteConfiguration(
    accountId: string,
): Promise<AwsResponse > {
  try {
    const reqBody = {
      accountId,
    };
    const response: {
      data: AwsResponse;
    } = await axiosInstance.request<AwsResponse>({
      method: 'delete',
      url: API_ENDPOINT,
      data: reqBody,
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    if (isAxiosError<AwsResponse>(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
}
