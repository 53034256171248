import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import {useNavigate} from 'react-router-dom';
import {IoEllipsisHorizontal} from 'react-icons/io5';

import {WORKSPACE_COPY_PATH, WORKSPACE_EDIT_PATH} from 'src/routes';


import {WorkspaceMenuProps} from './types';

export const WorkspaceMenu = ({id, onMarkDefault, showDefault}: WorkspaceMenuProps) => {
  const navigate = useNavigate();
  return (
    <UncontrolledDropdown style={{
      marginLeft: 'auto',
      marginRight: '0',
    }}>
      <DropdownToggle
        onClick={(e) => e.stopPropagation()}
        tag='a'
      >
        <IoEllipsisHorizontal className='KebobIcon'></IoEllipsisHorizontal>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={(e) => {
          e.stopPropagation();
          navigate(WORKSPACE_EDIT_PATH.replace(':entityId', id));
        }}>Edit</DropdownItem>
        <DropdownItem onClick={(e) => {
          e.stopPropagation();
          navigate(WORKSPACE_COPY_PATH.replace(':entityId', id));
        }}>Duplicate</DropdownItem>
        {showDefault && <DropdownItem onClick={onMarkDefault}>Set as Default</DropdownItem>}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
