import {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router';

import {AuthContext} from 'src/context';
import {authenticate} from 'src/api/azure';
import {processAuthResponse} from 'src/components/Login/utils';
import {useQuery} from 'src/hooks/router';

export const AzureAdCallback = () => {
  const {get} = useQuery();
  const authCode = get('code');
  const {setIsLoggedIn, setRoles} = useContext(AuthContext);
  const navigate = useNavigate();

  const authenticateWithServer = async () => {
    if (authCode) {
      const response = await authenticate(authCode);
      if (response.status === 200) {
        processAuthResponse(response, (authResponse, roles) => {
          navigate(-1);
          setIsLoggedIn(true);
          setRoles(roles);
        });
      }
    }
  };

  useEffect(() => {
    authenticateWithServer();
  }, []);

  return (
    <p>Logging in.</p>
  );
};
