import {createContext} from 'react';

import {AuthContextType, UserRole} from 'src/types';

export const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  roles: [],
  setRoles: () => {},
  hasRole: (role: UserRole) => false,
});
