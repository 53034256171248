import {useState} from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';
import Swal from 'sweetalert2';

import {
  postConfiguration,
  validateConfiguration,
} from '../../api/servicenowConfig';
import './administration.css';

interface Errors {
  url?: string;
  username?: string;
  password?: string;
}

const NewConfigForm = ({isOpen, onClose, editData}) => {
  const [validationError, setValidationError] = useState('');

  const toggle = () => {
    onClose();
  };

  async function handleSubmit(values) {
    try {
      setValidationError('');
      const validate = await validateConfiguration(values);

      if (validate.status !== 'success') {
        return setValidationError('Configuration validation failed');
      }

      const res = await postConfiguration(values);

      onClose();
      if (res.status === 'success') {
        Swal.fire({
          title: 'Success!',
          text: 'Configuration added successfully',
          icon: 'success',
          timer: 1200,
          showConfirmButton: false,
        });
      }
    } catch ({errors}) {
      if (errors && Array.isArray(errors)) {
        errors.forEach((error) => {
          const errorMessage =
          error.errors === undefined ?
            error.map((err) => err.message).join('. ') :
            error.errors[Object.keys(error.errors)[0]];
          console.error(errorMessage);
        });
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fade={false}
      modalClassName="height-auto"
    >
      <ModalHeader toggle={toggle}>
        {editData ? 'Edit' : 'New'} Configuration
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            url: (editData && editData.url) || '',
            username: (editData && editData.username) || '',
            password: '',
          }}
          validate={(values) => {
            const errors: Errors = {};
            if (!values.url) {
              errors.url = 'URL is required';
            }
            if (!values.username) {
              errors.username = 'Username is required';
            }
            if (!values.password) {
              errors.password = 'Password is required';
            }
            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {({isSubmitting}) => (
            <Form>
              <FormGroup>
                <Label for="url">URL</Label>
                <Input
                  tag={Field}
                  name="url"
                  type="text"
                  component="input"
                  disabled={editData ? true : false}
                />
                <ErrorMessage
                  className="form-error"
                  name="url"
                  component="div"
                />
              </FormGroup>
              <FormGroup>
                <Label for="username">Username</Label>
                <Input
                  tag={Field}
                  name="username"
                  type="text"
                  component="input"
                />
                <ErrorMessage
                  className="form-error"
                  name="username"
                  component="div"
                />
              </FormGroup>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input
                  tag={Field}
                  name="password"
                  type="password"
                  component="input"
                />
                <ErrorMessage
                  className="form-error"
                  name="password"
                  component="div"
                />
              </FormGroup>
              {validationError && (
                <span style={{color: 'red'}}>{validationError}</span>
              )}
              <hr />
              <Button type="submit" disabled={isSubmitting} color="primary">
                Save
              </Button>{' '}
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default NewConfigForm;
