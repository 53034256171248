import {useEffect, useState} from 'react';

import {ApiErrorResponse, AuthorizationType, TypedApiResponse} from 'src/types';
import {getConfigType} from 'src/api/authConfig';

const isSuccess = (response: TypedApiResponse<AuthorizationType | null> | ApiErrorResponse)
  : response is TypedApiResponse<AuthorizationType> => {
  return 'status' in response && response.status === 'success';
};

export const useAuthConfigType = () => {
  const [authorizationType, setAuthorizationType] = useState<AuthorizationType | null>(null);
  const [loading, setLoading] = useState(true);

  const loadAuthorizationType = async () => {
    const result = await getConfigType();
    if (isSuccess(result)) {
      setAuthorizationType(result.data || null);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadAuthorizationType();
  }, []);

  return {loading, authorizationType, loadAuthorizationType} as const;
};
