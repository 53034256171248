import {Button} from 'reactstrap';
import {NavLink as Link} from 'react-router-dom';
import {FaTimes, FaMapSigns} from 'react-icons/fa';
import {
  NavItem,
  NavLink,
} from 'reactstrap';

import {
  GETTING_STARTED_PATH,
  WELCOME_WIZARD_PATH,
} from 'src/routes';
import {useFeatureFlag} from 'src/hooks';
import 'src/components/SideNav/styles.css';
import './styles.css';
export const IntroLink = ({isExpanded = true, onClose = () => {}}: {isExpanded?: boolean, onClose?: () => void}) => {
  const [serverSideScanningEnabled] = useFeatureFlag('FEATURE_FLAG_SERVER_SIDE_SCANNING');
  const gettingStartedLinkPath = serverSideScanningEnabled ? WELCOME_WIZARD_PATH : GETTING_STARTED_PATH;
  return (
    <NavItem>
      <NavLink tag={Link} to={gettingStartedLinkPath}>
        <FaMapSigns className="SideNav__icon" />
        {isExpanded && (
          <div title="Search" className="SideNav__text-container">
            <span>Getting Started</span>
            <Button style={{
              paddingTop: 0,
              paddingBottom: 0,
              marginLeft: '0.25em',
            }} color="link" onClick={onClose}>
              <FaTimes style={{fontSize: '0.8em'}} title="Hide Getting Started Guide" />
            </Button>
          </div>
        )}
      </NavLink>
    </NavItem>
  );
};
