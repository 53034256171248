import {
  Node,
  Binding,
  GraphObject,
  Margin,
  Panel,
  Point,
  Size,
  Shape,
  Spot,
  TextBlock,
} from 'src/util/gojs/go';
import {ClickHandler} from 'src/util/gojs/types';
import {makeNameAndTypeTooltip} from 'src/util/gojs/components';
import {highlight, semiTransparentHighlight, promptFont} from 'src/util/gojs/styles';
import {
  COLOR_EXTRACTED_7,
  COLOR_EXTRACTED_83,
  colorPrimaryGreen,
  colorPrimaryRed,
} from 'src/styles/colors';
import {
  getColor,
  makeThemeColorGetter,
} from 'src/graph';
import {nameGenerator} from 'src/util';
import {makeCustomToolTip} from 'src/util/gojs/components/tooltip/Generic';

const {make} = GraphObject;

export const makeNodeTemplate = ({
  handleClickExpander,
  handleOutRelAddedClickExpander,
  handleOutRelRemovedClickExpander,
  handleInRelAddedClickExpander,
  handleInRelRemovedClickExpander,
  handleDoubleClick,
  handleContextClick,
}: {
  handleClickExpander?: ClickHandler,
  handleOutRelAddedClickExpander?: ClickHandler,
  handleOutRelRemovedClickExpander?: ClickHandler,
  handleInRelAddedClickExpander?: ClickHandler,
  handleInRelRemovedClickExpander?: ClickHandler,
  handleDoubleClick?: ClickHandler
  handleContextClick?: ClickHandler,
} = {
  handleClickExpander: () => {},
  handleOutRelAddedClickExpander: () => {},
  handleOutRelRemovedClickExpander: () => {},
  handleInRelAddedClickExpander: () => {},
  handleInRelRemovedClickExpander: () => {},
  handleDoubleClick: () => {},
  handleContextClick: () => {},
}) => {
  return make(
      Node,
      'Spot',
      {
        doubleClick: handleDoubleClick,
        isTreeExpanded: false,
      },
      new Binding('isTreeExpanded', '', (model, node) => {
        if (node.data.isTreeExpanded || node.isTreeExpanded) {
          return true;
        } else {
          return false;
        }
      }).ofModel(),
      new Binding('opacity', '', (model, node) => {
        if (model.lens === 'mixed') {
          return 1;
        } else if (model.lens === 'impacted' &&
          !node.data.properties?.diffImpacted &&
          node.data.properties?.diffValue !== 'added' &&
          node.data.properties?.diffValue !== 'removed' &&
          !node.data.isGroupNode
        ) {
          return 0.2;
        } else if (model.lens === 'added' && node.data.properties?.diffValue !== 'added') {
          return 0.2;
        } else if (model.lens === 'removed' && node.data.properties?.diffValue !== 'removed') {
          return 0.2;
        }
        return 1;
      }).ofModel(),
      new Binding('visible', '', (model, node) => {
        if (model.lens === 'impacted-only' &&
          (
            node.data.properties?.diffImpacted ||
            node.data.properties?.diffValue === 'added' ||
            node.data.properties?.diffValue === 'removed'
          )
        ) {
          return true;
        } else if (model.lens === 'impacted-only' ) {
          return false;
        } else {
          return true;
        }
      }).ofModel(),
      make(
          Panel,
          'Auto',
          makeAddedOrRemovedHighlight(),
          make(
              Panel,
              'Auto',
              make(
                  Shape,
                  {
                    fill: 'transparent',
                    stroke: null,
                  },
                  new Binding('fill', '', semiTransparentHighlight),
                  new Binding( 'stroke', '', highlight),
              ),
              make(
                  Panel,
                  'Vertical',
                  new Binding('location', 'loc', Point.parse).makeTwoWay(
                      Point.stringify,
                  ),
                  make(
                      Panel, 'Auto',
                      make(Panel, 'Vertical',
                          makeAddedOrRemovedText(),
                          make(
                              Panel,
                              'Horizontal',
                              makeIncomingRelationshipsAddedExpander(handleInRelAddedClickExpander),
                              makeIncomingRelationshipsRemovedExpander(handleInRelRemovedClickExpander),
                              makeOutgoingRelationshipsRemovedExpander(handleOutRelRemovedClickExpander),
                              makeOutgoingRelationshipsAddedExpander(handleOutRelAddedClickExpander),
                          ),
                          make(
                              Panel, 'Horizontal',
                              make(
                                  Panel, 'Horizontal',
                                  makeNodePill(),
                              ),
                              makeExpanderButton(handleClickExpander),
                              {
                                toolTip: makeNameAndTypeTooltip(),
                              },
                          ),
                      ),
                      {
                        alignment: Spot.TopLeft,
                        margin: new Margin(0),
                      },
                  ),
                  make(
                      TextBlock,
                      {margin: new Margin(2, 0, 0, 4), alignment: Spot.Left},
                      new Binding('stroke', '', makeThemeColorGetter('color-text')).ofModel(),
                      new Binding('text', '', nameGenerator).ofObject(),
                  ),
              ),
          ),
      ),
      {
        contextClick: handleContextClick,
      },
  );
};

const makeNodePill = () => make(
    Shape,
    'Pill',
    {
      portId: '',
      fromSpot: new Spot(0.5, 0.5),
      toSpot: new Spot(0.5, 0.5),
      fromEndSegmentLength: 10,
      toEndSegmentLength: 10,
      width: 60,
      height: 20,
      cursor: 'pointer',
      stroke: '#b4bfcc', strokeWidth: 1,
      alignment: Spot.TopLeft,
    },
    new Binding('fill', '', getColor).ofObject(),
);

const makeAddedOrRemovedHighlight = () => make(
    Shape,
    {
      opacity: 0.5,
      stroke: 'transparent',
      fill: 'transparent',
    },
    new Binding('fill', '', ({data}: {data: any}) => {
      const diffValue = data.properties.diffValue;
      if (diffValue === 'removed') {
        return 'rgba(255, 129, 130, 0.4)';
      } else if (diffValue === 'added') {
        return 'rgb(171, 242, 188)';
      } else if (diffValue === 'both') {
        return 'transparent';
      } else {
        return 'transparent';
      }
    }).ofObject(),
);

const makeAddedOrRemovedText = () => make(
    TextBlock,
    {

      alignment: Spot.Left,
      font: promptFont(1),
      textAlign: 'center',
      text: '',
    },
    new Binding('stroke', '', makeThemeColorGetter('color-text')).ofModel(),
    new Binding('text', '', ({data}: {data: any}) => {
      const diffValue = data.properties.diffValue;
      if (diffValue === 'removed') {
        return 'removed';
      } else if (diffValue === 'added') {
        return 'added';
      } else if (diffValue === 'both') {
        return '';
      } else {
        return '';
      }
    }).ofObject(),
);

const makeExpanderButton = (handleClickExpander: ClickHandler | undefined) => make('Button',
    {
      'visible': false,
      'name': 'ButtonButton',
      'alignment': Spot.Right, 'alignmentFocus': Spot.Left,
      // Place button within the 'pill'
      'margin': -20,
      '_treeExpandedFigure': 'MinusLine',
      '_treeCollapsedFigure': 'PlusLine',
      // set properties on the border Shape of the "Button"
      'ButtonBorder.figure': 'Circle',
      'ButtonBorder.fill': COLOR_EXTRACTED_83,
      'ButtonBorder.stroke': COLOR_EXTRACTED_83,
      '_buttonFillOver': COLOR_EXTRACTED_7,
      '_buttonStrokeOver': COLOR_EXTRACTED_7,
      'click': handleClickExpander,
    },
    make(
        Shape,
        {
          name: 'ButtonIcon',
          figure: 'PlusLine', // default value for isTreeExpanded is true
          stroke: 'white',
          strokeWidth: 2,
          desiredSize: new Size(8, 8),
        },
        new Binding('figure', 'isTreeExpanded',
            (exp: boolean, shape: Shape): string => {
              const but = shape.panel;
              return exp ? (but as any)['_treeExpandedFigure'] : (but as any)['_treeCollapsedFigure'];
            },
        ).ofObject(),
    ),
    new Binding( 'visible', '', ({data}: {data: {type: string, category: string}}) =>
      data.type === 'Application' || data.category === 'parent').ofObject(),
);

const makeOutgoingRelationshipsAddedExpander = (handleClickExpander: ClickHandler | undefined) => make('Button',
    {
      'visible': false,
      'name': 'OutRelAddedButton',
      'alignment': Spot.Left, 'alignmentFocus': Spot.Left,
      'margin': 2,
      '_treeExpandedFigure': 'MinusLine',
      '_treeCollapsedFigure': 'LineRight',
      // set properties on the border Shape of the "Button"
      'ButtonBorder.figure': 'Circle',
      'ButtonBorder.fill': colorPrimaryGreen,
      'ButtonBorder.stroke': COLOR_EXTRACTED_83,
      '_buttonFillOver': COLOR_EXTRACTED_7,
      '_buttonStrokeOver': COLOR_EXTRACTED_7,
      'click': handleClickExpander,
      'toolTip': makeCustomToolTip('One or more outgoing relationships were added.'),
    },
    make(
        Shape,
        {
          name: 'OutRelAddedIcon',
          figure: 'LineRight', // default value for isTreeExpanded is true
          stroke: 'white',
          strokeWidth: 3,
          desiredSize: new Size(8, 8),
        },
        new Binding('figure', 'isTreeExpanded',
            (exp: boolean, shape: Shape): string => {
              const but = shape.panel;
              return exp ? (but as any)['_treeExpandedFigure'] : (but as any)['_treeCollapsedFigure'];
            },
        ).ofObject(),
    ),
    new Binding( 'visible', '', ({data}: {data: {type: string, category: string, properties: any}}) => {
      return !!(data?.properties?.outgoingRelationshipsAdded);
    }).ofObject(),
);

const makeOutgoingRelationshipsRemovedExpander = (handleClickExpander: ClickHandler | undefined) => make('Button',
    {
      'visible': false,
      'name': 'OutRelRemovedButton',
      'alignment': Spot.Left, 'alignmentFocus': Spot.Left,
      'margin': 2,
      '_treeExpandedFigure': 'MinusLine',
      '_treeCollapsedFigure': 'LineRight',
      // set properties on the border Shape of the "Button"
      'ButtonBorder.figure': 'Circle',
      'ButtonBorder.fill': colorPrimaryRed,
      'ButtonBorder.stroke': COLOR_EXTRACTED_83,
      '_buttonFillOver': COLOR_EXTRACTED_7,
      '_buttonStrokeOver': COLOR_EXTRACTED_7,
      'click': handleClickExpander,
      'toolTip': makeCustomToolTip('One or more outgoing relationships were removed.'),
    },
    make(
        Shape,
        {
          name: 'OutRelAddedIcon',
          figure: 'LineRight', // default value for isTreeExpanded is true
          stroke: 'white',
          strokeWidth: 3,
          desiredSize: new Size(8, 8),
        },
        new Binding('figure', 'isTreeExpanded',
            (exp: boolean, shape: Shape): string => {
              const but = shape.panel;
              return exp ? (but as any)['_treeExpandedFigure'] : (but as any)['_treeCollapsedFigure'];
            },
        ).ofObject(),
    ),
    new Binding( 'visible', '', ({data}: {data: {type: string, category: string, properties: any}}) => {
      return !!(data?.properties?.outgoingRelationshipsRemoved);
    }).ofObject(),
);

const makeIncomingRelationshipsAddedExpander = (handleClickExpander: ClickHandler | undefined) => make('Button',
    {
      'visible': false,
      'name': 'InRelAddedButton',
      'alignment': Spot.Left, 'alignmentFocus': Spot.Left,
      'margin': 2,
      '_treeExpandedFigure': 'MinusLine',
      '_treeCollapsedFigure': 'LineLeft',
      // set properties on the border Shape of the "Button"
      'ButtonBorder.figure': 'Circle',
      'ButtonBorder.fill': colorPrimaryGreen,
      'ButtonBorder.stroke': COLOR_EXTRACTED_83,
      '_buttonFillOver': COLOR_EXTRACTED_7,
      '_buttonStrokeOver': COLOR_EXTRACTED_7,
      'click': handleClickExpander,
      'toolTip': makeCustomToolTip('One or more incoming relationships were added.'),
    },
    make(
        Shape,
        {
          name: 'OutRelAddedIcon',
          figure: 'LineLeft', // default value for isTreeExpanded is true
          stroke: 'white',
          strokeWidth: 3,
          desiredSize: new Size(8, 8),
        },
        new Binding('figure', 'isTreeExpanded',
            (exp: boolean, shape: Shape): string => {
              const but = shape.panel;
              return exp ? (but as any)['_treeExpandedFigure'] : (but as any)['_treeCollapsedFigure'];
            },
        ).ofObject(),
    ),
    new Binding( 'visible', '', ({data}: {data: {type: string, category: string, properties: any}}) => {
      return !!(data?.properties?.incomingRelationshipsAdded);
    }).ofObject(),
);

const makeIncomingRelationshipsRemovedExpander = (handleClickExpander: ClickHandler | undefined) => make('Button',
    {
      'visible': false,
      'name': 'InRelRemovedButton',
      'alignment': Spot.Left, 'alignmentFocus': Spot.Left,
      'margin': 2,
      '_treeExpandedFigure': 'MinusLine',
      '_treeCollapsedFigure': 'LineLeft',
      // set properties on the border Shape of the "Button"
      'ButtonBorder.figure': 'Circle',
      'ButtonBorder.fill': colorPrimaryRed,
      'ButtonBorder.stroke': COLOR_EXTRACTED_83,
      '_buttonFillOver': COLOR_EXTRACTED_7,
      '_buttonStrokeOver': COLOR_EXTRACTED_7,
      'click': handleClickExpander,
      'toolTip': makeCustomToolTip('One or more incoming relationships were removed.'),
    },
    make(
        Shape,
        {
          name: 'InRelRemovedIcon',
          figure: 'LineLeft', // default value for isTreeExpanded is true
          stroke: 'white',
          strokeWidth: 3,
          desiredSize: new Size(8, 8),
        },
        new Binding('figure', 'isTreeExpanded',
            (exp: boolean, shape: Shape): string => {
              const but = shape.panel;
              return exp ? (but as any)['_treeExpandedFigure'] : (but as any)['_treeCollapsedFigure'];
            },
        ).ofObject(),
    ),
    new Binding( 'visible', '', ({data}: {data: {type: string, category: string, properties: any}}) => {
      return !!(data?.properties?.incomingRelationshipsRemoved);
    }).ofObject(),
);
