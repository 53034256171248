import {CODELOGIC_API_BASE_URL} from 'src/config';

import {axiosInstance} from './baseApi';


const API_ENDPOINT = `${CODELOGIC_API_BASE_URL}/actuator`;

export async function getActuatorInfo(): Promise<any> {
  try {
    const response = await axiosInstance.get(`${API_ENDPOINT}/info`);
    return response;
  } catch (e) {
    return e;
  }
}
