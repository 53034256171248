import {Pager} from 'src/components';

type PagerProps = Parameters<typeof Pager>[0]

export const MetaDataPager = ({
  pageData: {
    currentPage: pageIndex,
    pageSize,
    resultsOnPage: itemsOnPage,
    startingResult,
    totalPages: pageCount,
    totalResults: totalItems,
  },
  onSelectPageSize,
  onPageChange,
}: {
  pageData: {
    currentPage: number,
    pageSize: number,
    resultsOnPage: number,
    startingResult: number,
    totalPages: number,
    totalResults: number,
  },
  onSelectPageSize: PagerProps['onSelectPageSize'],
  onPageChange: PagerProps['onPageChange'],
}) => (
  <Pager
    totalItems={totalItems}
    itemsOnPage={itemsOnPage}
    pageCount={pageCount}
    pageIndex={pageIndex}
    pageSize={pageSize}
    onSelectPageSize={onSelectPageSize}
    onPageChange={onPageChange}
  />
);
