import {useContext, useEffect} from 'react';
import {Button, Form, FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import {Formik} from 'formik';

import {AuthContext} from 'src/context';
import {AuthData, AuthorizationType} from 'src/types';
import {
  authenticate,
  DEMO_AUTH_PASSWORD,
  DEMO_AUTH_USERNAME,
} from 'src/api/auth';
import {useQuery} from 'src/hooks/router';

import LoginContainer from './LoginContainer';
import {useAuthConfigType} from './hooks';
import {AzureAdLink} from './AzureAd/Link';
import {processAuthResponse} from './utils';

export const Login = () => {
  const {setIsLoggedIn, setRoles} = useContext(AuthContext);
  const {get} = useQuery();
  const demo = get('demo');
  const isDemo = demo || false;
  const {loading, authorizationType} = useAuthConfigType();

  const demoLogin = async () => {
    await authenticateWithServer({
      email: DEMO_AUTH_USERNAME,
      password: DEMO_AUTH_PASSWORD,
    });
  };

  const authenticateWithServer = async (authParams: AuthData) => {
    const response = await authenticate(authParams);
    if (response) {
      processAuthResponse(response, (_authResponse: unknown, roles) => {
        setIsLoggedIn(true);
        setRoles(roles);
      });
    }
  };

  useEffect(() => {
    if (isDemo) {
      demoLogin();
    }
  }, []);

  return (
    <>
      {!isDemo && !loading && <LoginContainer heading="">
        <div className="form">
          <div className="form-link">
            <Formik
              initialValues={{email: '', password: ''}}
              validate={(values) => {
                const errors: AuthData = {};

                if (!values.email) {
                  errors.email = 'This field is required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }

                if (!values.password) {
                  errors.password = 'This field is required';
                }
                return errors;
              }}
              onSubmit={async (
                  values,
                  {setSubmitting, setFieldValue, setFieldTouched},
              ) => {
                await authenticateWithServer(values);
                setSubmitting(false);
                setFieldValue('password', '');
                setFieldTouched('password', false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label for="userEmail">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="userEmail"
                      placeholder="Enter email address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      invalid={!!errors.email && touched.email}
                    />
                    <FormFeedback>{errors.email}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="userPassword">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      id="userPassword"
                      placeholder="Enter password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      invalid={!!errors.password && touched.password}
                    />
                    <FormFeedback>{errors.password}</FormFeedback>
                  </FormGroup>

                  <Button
                    color="primary"
                    className="form-action"
                    type="submit"
                    disabled={isSubmitting}
                  >
                  Log In
                  </Button>
                  { authorizationType === AuthorizationType.AZURE_AD &&
                  <AzureAdLink />
                  }
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </LoginContainer>}
    </>
  );
};
