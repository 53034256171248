import {useEffect, useState} from 'react';
import uniq from 'lodash/uniq';

import {
  LAST_WORKSPACE_ID,
  LAST_MATERIALIZED_VIEW_DEFINITION_ID,
  LAST_WORKSPACE_IDS,
  LAST_MATERIALIZED_VIEW_DEFINITION_IDS,
} from 'src/constants';
import {getDefaultWorkspace, getWorkspace} from 'src/api/workspace';
import {getMaterializedViewDefinition} from 'src/api/materializedViewDefinitions';
import {getMaterializedViewId} from 'src/api/materializedViews';
import {MaterializedViewDefinition, Workspace} from 'src/types';

export const useWorkspace = () => {
  const [loading, setLoading] = useState(false);
  const [workspace, internalSetWorkspace] = useState<Workspace>();
  const [workspaceHistory, setWorkspaceHistory] = useState<Set<string>>(() => {
    const historyStr = localStorage.getItem(LAST_WORKSPACE_IDS) || '[]';
    return new Set<string>(Array.from(JSON.parse(historyStr)));
  });

  const setWorkspace = (entity?: Workspace) => {
    if (entity) {
      const history = workspaceHistory;
      if (history.has(entity.id)) {
        history.delete(entity.id);
      }
      if (history.size > 3) {
        const [last] = Array.from(history);
        if (last) {
          history.delete(last);
        }
      }
      history.add(entity.id);
      setWorkspaceHistory(history);
      localStorage.setItem(LAST_WORKSPACE_IDS, JSON.stringify(Array.from(history)));
    } else {
      localStorage.removeItem(LAST_WORKSPACE_ID);
    }

    internalSetWorkspace(entity);
  };

  const getWorkspaceByIdOrDefault = async (id: string | undefined): Promise<Workspace | undefined> => {
    const {data, status} = id ? await getWorkspace({id}) : await getDefaultWorkspace();
    if (status === 'success' && data) {
      return data;
    } else {
      return undefined;
    }
  };

  const getWorkspaceFromHistory = async (): Promise<Workspace | undefined> => {
    for (const value of Array.from(workspaceHistory).reverse()) {
      if (!value) continue;
      const {data, status} = await getWorkspace({id: value});
      if (status === 'success' && data) {
        return data;
      }
    }

    return undefined;
  };

  const initializeWorkspace = async (paramId?: string) => {
    let entity: Workspace | undefined;
    setLoading(true);
    if (paramId) {
      entity = await getWorkspaceByIdOrDefault(paramId);
    } else {
      const lastId = localStorage.getItem(LAST_WORKSPACE_ID) || undefined;
      entity = await getWorkspaceFromHistory();
      if (!entity) {
        entity = await getWorkspaceByIdOrDefault(lastId);
      }
    }
    setLoading(false);
    setWorkspace(entity);
  };

  return {loading, workspace, initializeWorkspace, setWorkspace} as const;
};

export const useMaterializedViewDefinition = (workspace?: Workspace) => {
  const [loading, setLoading] = useState(false);
  const [materializedViewDefinition, internalSetMaterializedViewDefinition] = useState<MaterializedViewDefinition | undefined>(workspace?.materializedViewDefinition);
  const [materializedViewDefinitionHistory, setMaterializedViewDefinitionHistory] = useState<Set<string>>(() => {
    const historyStr = localStorage.getItem(LAST_MATERIALIZED_VIEW_DEFINITION_IDS) || '[]';
    return new Set<string>(Array.from(JSON.parse(historyStr)));
  });

  const setMaterializedViewDefinition = (entity?: MaterializedViewDefinition) => {
    if (entity) {
      const history = materializedViewDefinitionHistory;
      if (history.has(entity.id)) {
        history.delete(entity.id);
      }
      if (history.size > 3) {
        const [last] = Array.from(history);
        if (last) {
          history.delete(last);
        }
      }
      history.add(entity.id);
      setMaterializedViewDefinitionHistory(history);
      localStorage.setItem(LAST_MATERIALIZED_VIEW_DEFINITION_IDS, JSON.stringify(Array.from(history)));
    } else {
      localStorage.removeItem(LAST_MATERIALIZED_VIEW_DEFINITION_ID);
    }

    internalSetMaterializedViewDefinition(entity);
  };

  const getMaterializedViewDefinitionByIdOrDefault = async ( defaultId: string, id: string | undefined): Promise<MaterializedViewDefinition | undefined> => {
    const {data, status} = await getMaterializedViewDefinition(id || defaultId);
    if (status === 'success' && data) {
      return data;
    } else {
      return undefined;
    }
  };

  const getMaterializedViewDefinitionFromHistory = async (): Promise<MaterializedViewDefinition | undefined> => {
    for (const value of Array.from(materializedViewDefinitionHistory).reverse()) {
      if (!value) continue;
      const {data, status} = await getMaterializedViewDefinition(value);
      if (status === 'success' && data) {
        return data;
      }
    }

    return undefined;
  };

  const initializeMaterializedViewDefinition = async (paramId?: string) => {
    let entity: MaterializedViewDefinition | undefined;
    setLoading(true);
    if (paramId) {
      const {data} = await getMaterializedViewDefinition(paramId);
      entity = data;
    } else {
      const lastId = localStorage.getItem(LAST_MATERIALIZED_VIEW_DEFINITION_ID) || undefined;
      entity = await getMaterializedViewDefinitionFromHistory();
      if (!entity && workspace) {
        entity = await getMaterializedViewDefinitionByIdOrDefault(workspace.materializedViewDefinition.id, lastId);
      }
    }

    setLoading(false);
    setMaterializedViewDefinition(entity);
  };

  return {loading, materializedViewDefinition, initializeMaterializedViewDefinition, setMaterializedViewDefinition} as const;
};

export const useMaterializedView = (materializedViewDefinition?: MaterializedViewDefinition | string) => {
  const [loading, setLoading] = useState(false);
  const [materializedViews, setMaterializedViews] = useState<Array<string>>([]);
  const [materializedView, setMaterializedView] = useState<string>();

  const fetchLatestMaterializedView = async (definitionId: string) => {
    setLoading(true);
    const {data} = await getMaterializedViewId(definitionId);
    setMaterializedView(data || undefined);
    if (data) {
      setMaterializedViews(uniq([data, ...materializedViews]));
    }
    setLoading(false);
  };

  useEffect(() => {
    const definitionId = typeof materializedViewDefinition === 'string' ? materializedViewDefinition : materializedViewDefinition?.id;
    setMaterializedViews([]);
    if (definitionId) {
      fetchLatestMaterializedView(definitionId);
    }
  }, [materializedViewDefinition]);

  return {
    loading,
    materializedView,
    setMaterializedView,
    materializedViews,
    setMaterializedViews,
    fetchLatestMaterializedView,
  } as const;
};

