import {PropsWithChildren, useContext, useEffect, useState} from 'react';
import uniq from 'lodash/uniq';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Button} from 'reactstrap';

import {Diagram as GOJSDiagram} from 'src/util/gojs/go';
import {THEMES} from 'src/styles/colors';
import {
  mapGroupData,
  mapReferencedNodesToNodes,
  mapContainedNodesToNodes,
} from 'src/state/dependency2';
import {getRootDecomposition} from 'src/api/dependency';
import {getGroupingNodes} from 'src/api/groups';
import {
  useContextMenuPosition,
  useNode,
  useStoredValue,
} from 'src/hooks';
import {
  AsyncSelect,
  LabeledControl,
  LoadingPlaceholder,
  NodeDetailModal,
  ContextMenu,
  MenuItem,
  Page,
  Diagram,
  TypeLegend,
  RelationshipDetailModal,
} from 'src/components';
import {
  LOCAL_SETTING_LEGEND_EXPANDED,
} from 'src/constants';
import {
  DiagramChangeLens,
  DiagramLink,
  DiagramNode,
} from 'src/types';
import {
  ThemeName,
  ThemeContext,
} from 'src/context';
import {fetchNodeAndPath} from 'src/components/Dependency/GraphView/utils';
import {useQuery} from 'src/hooks/router';

import {makeDiagramInitializer} from './diagram';
import {useComparisonJob} from './hooks';
import {CSVDownloadMenu} from './components';

import './styles.css';


const LENSES = [{
  value: 'all',
  label: 'none',
}, {
  value: 'added',
  label: 'added nodes',
}, {
  value: 'removed',
  label: 'removed nodes',
}, {
  value: 'impacted',
  label: 'impacted nodes',
}, {
  value: 'impacted-only',
  label: 'impacted only',
}] as const;


type GraphState = {
  nodes: Array<DiagramNode>,
  edges: Array<DiagramLink>
};

export const ComparisonPage = () => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const [diagram, setDiagram] = useState<GOJSDiagram | undefined>();
  const [showLegend, setShowLegend] = useStoredValue<boolean>(LOCAL_SETTING_LEGEND_EXPANDED, true);
  const {theme: themeName} = useContext(ThemeContext);

  const {
    contextMenuState,
    showContextMenu,
  } = useContextMenuPosition();

  const [{nodes, edges}, setData] = useState<{
    nodes: Array<DiagramNode>,
    edges: Array<DiagramLink>
  }>({
    nodes: [],
    edges: [],
  });

  const {fetchNodeById: setInspectedNode, node: inspectedNode, clearData: clearInspectedNode} = useNode();
  const [inspectedEdge, setInspectedEdge] = useState<DiagramLink | undefined>();
  const {entityId} = useParams<{entityId: string | undefined}>() ?? {};
  const {get} = useQuery();
  const nodeId = get('nodeId');

  const [lens, setLens] = useState<DiagramChangeLens>(LENSES[4]);

  useEffect(() => {
    if (diagram) {
      diagram.model.setDataProperty(diagram.model.modelData, 'lens', lens.value);
    }
  }, [lens, diagram]);


  const {fetchComparisonJob, comparisonJob, loading} = useComparisonJob();

  const [{selectedNodeIds, selectedEdgeIds}, setSelection] = useState<{
    selectedNodeIds: Array<string>,
    selectedEdgeIds: Array<string>
  }>({
    selectedNodeIds: [],
    selectedEdgeIds: [],
  });

  const selectedNodes = nodes.filter(({id}) => selectedNodeIds.includes(id));
  const selectedEdges = edges.filter(({id}) => selectedEdgeIds.includes(id));

  const focusedNode = selectedNodes.length ? selectedNodes[0] : undefined;
  const focusedEdge = selectedEdges.length ? selectedEdges[0] : undefined;

  useEffect(() => {
    (async () => {
      if (comparisonJob) {
        const diffId = comparisonJob.diffMaterializedViewDefinitionId;
        const [decomposition, groupings] = await Promise.all([
          getRootDecomposition({definitionId: diffId}),
          getGroupingNodes(null, diffId),
        ]);

        const nodes = decomposition.status === 'success' ? [
          ...mapContainedNodesToNodes(decomposition.data.containedNodes),
          ...mapReferencedNodesToNodes(decomposition.data.referencedNodes),
        ] : [];
        const groupNodes = groupings.status === 'success' ?
          mapGroupData(groupings.data || []) :
            [];
        const loadedNodes: DiagramNode[] = [];
        const loadedEdges: DiagramLink[] = [];

        if (nodeId && groupNodes) {
          const {nodes: ns, edges: es} = await fetchNodeAndPath(nodeId);
          loadedNodes.push(...ns.filter((n) => !nodes.find((it) => it.id === n.id)));
          loadedEdges.push(...es);

          navigate(pathname, {replace: true});

          setLens(LENSES[0]);
        }

        setData({
          nodes: [...groupNodes, ...nodes, ...loadedNodes],
          edges: [...loadedEdges],
        });
      }
    })();
  }, [comparisonJob]);

  useEffect(() => {
    if (entityId) {
      fetchComparisonJob({id: entityId});
    }
  }, [entityId]);

  const hasDisplayName = !!comparisonJob?.displayName;
  const hasPr = !!comparisonJob?.pullRequestTitle;

  const comparisonContent = hasDisplayName ? (
    <span style={{display: 'block'}}>
      Comparison: {comparisonJob?.displayName}
    </span>
  ) : (
    <span>Comparison</span>
  );

  const pullRequestContent = hasPr ? (
    <span>Pull Request: {comparisonJob?.pullRequestTitle}</span>
  ) : <></>;

  const pageTitle = (
    <h1 style={{fontSize: '1.375em'}}>
      {comparisonContent}
      {pullRequestContent}
    </h1>
  );

  return (
    <Page variant='fullscreen' title={pageTitle} controls={
      <div style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column', width: '100%'}}>
        {comparisonJob && (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            paddingBottom: '1rem',
          }}>
            <LabeledControl help="Emphasize a particular aspects of the graph" layout="horizontal" label="Focus:">
              <AsyncSelect<DiagramChangeLens>
                styles={{
                  container: (styles) => ({
                    ...styles,
                    width: '12rem',
                  }),
                }}
                options={[...LENSES]}
                value={lens}
                getOptionLabel={({label}) => label}
                getOptionValue={({value}) => value}
                onChange={(lens) => !!lens && setLens(lens)}
              />
            </LabeledControl>
            <div style={{marginLeft: '0.5rem'}}>
              <CSVDownloadMenu
                leftName='left'
                rightName='right'
                jobId={comparisonJob.id}
              />
            </div>
          </div>
        )}
        <OverflowToolContainer>
          {showLegend ? (
            <TypeLegend
              onHide={setShowLegend.bind(null, false)}
              nodeTypes={uniq(nodes.map(({metadata: {type}}) => type).filter((type) => !!type) as Array<string>)}
              linkTypes={uniq(edges.map(({type}) => type).filter((type) => !!type ))}
            />
          ) : (
          <Button
            style={{
              // match the margin of the TypeLegend header to prevent jitter (0.5rem margin is 0.125rem here plus 0.375rem from the Button)
              marginBottom: '0.125rem',
            }}
            color="link"
            className="search-btn-drop"
            onClick={setShowLegend.bind(null, !showLegend)}
          >
            Show Legend
          </Button>
        )}
        </OverflowToolContainer>
      </div>
    }>
      {loading ? <LoadingPlaceholder position="absolute" scale={1.2} /> : (
        <>
          <DiagramRender
            themeName={themeName}
            lens={lens}
            showContextMenu={showContextMenu}
            setDiagram={setDiagram}
            loading={loading}
            setData={setData}
            nodes={nodes}
            edges={edges}
            setSelection={setSelection}
          />
          <ContextMenu
            {...contextMenuState}
          >
            {focusedNode && <MenuItem context={focusedNode} onClick={(node) => {
              if (node) {
                setInspectedNode(node.id);
              }
            }} >Item Details</MenuItem>}
            {focusedEdge && focusedEdge.from && focusedEdge.to && focusedEdge.type &&
              <MenuItem context={focusedEdge} onClick={(edge) => {
                if (edge) {
                  setInspectedEdge(edge);
                }
              }} >Relationship Details</MenuItem>}
          </ContextMenu>
          <NodeDetailModal
            isOpen={!!inspectedNode}
            toggle={clearInspectedNode}
            nodeId={inspectedNode?.id}
            goToNode={setInspectedNode}
          />

          {inspectedEdge &&
            <RelationshipDetailModal
              isOpen={!!inspectedEdge}
              toggle={() => setInspectedEdge(undefined)}
              to={inspectedEdge.to}
              from={inspectedEdge.from}
              type={inspectedEdge.type}
            />
          }
        </>
    )}
    </Page>
  );
};

const DiagramRender = ({
  nodes,
  edges,
  themeName,
  lens,
  setData,
  setSelection,
  showContextMenu,
  setDiagram,
  loading,
}: {
  nodes: Array<DiagramNode>,
  edges:Array<DiagramLink>,
  themeName: ThemeName,
  lens: DiagramChangeLens,
  setData: (d: GraphState) => void,
  setDiagram: (d: GOJSDiagram) => void,
  setSelection: (_: {selectedNodeIds: Array<string>, selectedEdgeIds: Array<string>}) => void,
  showContextMenu: (x: number, y: number) => void,
  loading: boolean,
}) => {
  return (
    <Diagram
      loading={loading}
      initDiagram={makeDiagramInitializer({
        onInitialized: setDiagram,
        theme: THEMES[themeName],
        themeName,
        onContextClick: ({x, y}) => {
          showContextMenu(x, y);
        },
        defaultLensValue: lens.value,
      })}
      onUpdateElementSelections={setSelection}
      setData={setData}
      data={{
        nodes,
        edges,
      }}
    />

  );
};

const OverflowToolContainer = ({children}: PropsWithChildren) => (
  <div
    style={{
      maxHeight: '1.875rem',
      overflowY: 'visible',
    }}
  >
    <div
      style={{
        backgroundColor: 'var(--color-background--standard-90)',
      }}>
      {children}
    </div>
  </div>
);
