import {
  Binding,
  Margin,
  Node,
  Panel,
  Point,
  Shape,
  Size,
  Spot,
  TextBlock,
} from 'src/util/gojs/go';
import {ClickHandler} from 'src/util/gojs/types';
import {semiTransparentHighlight} from 'src/util/gojs/styles';
import {make, isSearchNode} from 'src/util/gojs';
import {makeNameAndTypeTooltip} from 'src/util/gojs/components';
import {nameGenerator} from 'src/util';
import {
  COLOR_EXTRACTED_7,
  COLOR_EXTRACTED_83,
} from 'src/styles/colors';
import {
  getColor,
  getTextColor,
  DE_EMPHASIZED_OPACITY_VALUE,
  hasAtLeastOneDeEmphasizedRelationship,
  makeThemeColorGetter,
} from 'src/graph';


export const makeNodeTemplate = ({
  expandable = true,
  hasEmphasis = false,
  handleClickExpander,
  handleDoubleClick = () => {},
  handleContextClick = () => {},
} : {
  expandable?: boolean,
  hasEmphasis?: boolean,
  handleClickExpander?: ClickHandler,
  handleDoubleClick?: ClickHandler,
  handleContextClick?: ClickHandler,
} = {}) =>
  make(
      Node,
      'Spot',
      {
        doubleClick: handleDoubleClick,
        isTreeExpanded: expandable ? false : true,
      },
      new Binding('isTreeExpanded', '', (node) => {
        if (!expandable) {
          return true;
        }
        return node.findLinksOutOf().count > 0;
      }).ofObject(),
      make(
          Panel,
          'Auto',
          make(
              Shape,
              {
                fill: 'transparent',
                stroke: null,
              },
              new Binding('fill', '', semiTransparentHighlight),
          ),
          make(
              Panel,
              'Vertical',
              new Binding('location', 'loc', Point.parse).makeTwoWay(
                  Point.stringify,
              ),
              make(
                  Panel,
                  'Auto',
                  make(Panel, 'Horizontal',
                      make(
                          Shape,
                          'Pill',
                          {

                            portId: '',
                            fromSpot: new Spot(0.5, 0.5),
                            toSpot: new Spot(0.5, 0.5),
                            fromEndSegmentLength: 10,
                            toEndSegmentLength: 10,
                            width: 60,
                            height: 20,
                            cursor: 'pointer',
                            strokeWidth: 0,
                            alignment: Spot.TopLeft,
                          },
                          new Binding('fill', '', getColor).ofObject(),
                          new Binding('opacity', '', (n) => {
                            if (!hasEmphasis) {
                              return 1;
                            }

                            if (n.data.emphasized) {
                              const shouldDeEmphasize = (n.findLinksInto().count === 0 || hasAtLeastOneDeEmphasizedRelationship(n));
                              return shouldDeEmphasize ? DE_EMPHASIZED_OPACITY_VALUE : '1';
                            }

                            return DE_EMPHASIZED_OPACITY_VALUE;
                          }).ofObject(),

                          new Binding('stroke', '', getTextColor).ofObject(),
                          new Binding('strokeDashArray', '', (n) => isSearchNode(n) ? [10, 5] : null),
                      ),
                      expandable && handleClickExpander && make('Button',
                          {
                            'name': 'ButtonButton',
                            'alignment': Spot.Right,
                            'alignmentFocus': Spot.Left,

                            // Place button within the 'pill'
                            'margin': -20,
                            '_treeExpandedFigure': 'MinusLine',
                            '_treeCollapsedFigure': 'PlusLine',
                            // set properties on the border Shape of the "Button"
                            'ButtonBorder.figure': 'Circle',
                            'ButtonBorder.fill': COLOR_EXTRACTED_83,
                            'ButtonBorder.stroke': COLOR_EXTRACTED_83,
                            '_buttonFillOver': COLOR_EXTRACTED_7,
                            '_buttonStrokeOver': COLOR_EXTRACTED_7,
                            'click': handleClickExpander,
                          },
                          make(Shape,
                              {
                                name: 'ButtonIcon',
                                figure: 'PlusLine', // default value for isTreeExpanded is true
                                stroke: 'white',
                                strokeWidth: 2,
                                desiredSize: new Size(8, 8),
                              },
                              new Binding('figure', 'isTreeExpanded',
                                  (exp: boolean, shape: Shape): string => {
                                    const but = shape.panel;
                                    return exp ? (but as any)['_treeExpandedFigure'] : (but as any)['_treeCollapsedFigure'];
                                  },
                              ).ofObject(),
                          ),
                          new Binding('visible', '', (node: Node) => node.data.relationshipCount !== 0).ofObject(),
                      ),
                  ),
                  {
                    alignment: Spot.TopLeft,
                    margin: new Margin(0),
                  },
              ),
              make(
                  Panel,
                  'Auto',
                  make(
                      Shape,
                      {
                        opacity: 0.8,
                        stroke: 'transparent',
                        fill: 'transparent',
                      },
                      new Binding('fill', '', makeThemeColorGetter('color-background--standard')).ofModel(),
                  ),
                  make(
                      TextBlock,
                      {margin: new Margin(2, 0, 0, 4), alignment: Spot.Left},
                      new Binding('stroke', '', makeThemeColorGetter('color-text')).ofModel(),
                      new Binding('text', '', nameGenerator).ofObject(),
                  ),
              ),
          ),
      ),
      {
        contextClick: handleContextClick,
        toolTip: makeNameAndTypeTooltip(),
      },
  );
