import {useContext} from 'react';

import {Tag, ActionsMenu} from 'src/components';
import {ThemeContext} from 'src/context';
import {THEMES} from 'src/styles/colors';
import {colorTag} from 'src/state/dependency2';

import {Props} from './types';
import {
  ArrowDividerContainer,
  ArrowCorridor,
  ArrowBody,
  ArrowHead,
  ArrowLabel,
  ArrowLabelText,
  DataContainer,
  ActionContainer,
  Container,
  MetaDataRow,
  MetaDataRowSection,
  Title,
  TitleRow,
} from './styled';

export const Card = ({name, type, scanRootName, isInvestigatedNode, actions}: Props) => {
  const {theme: themeName} = useContext(ThemeContext);
  const {color, fgColor} = colorTag(type, {
    type: 'node',
    themeName,
    theme: THEMES[themeName],
    transparency: false,

  });
  return (
    <Container highlighted={false}>
      <DataContainer>
        <TitleRow>
          <Title>{name}</Title>
        </TitleRow>
        <MetaDataRow>
          <MetaDataRowSection>
            <Tag
              label={type}
              color={fgColor}
              backgroundColor={color}
            />
          </MetaDataRowSection>
          <MetaDataRowSection>
            {scanRootName ? (
            <Tag
              label={scanRootName}
              color='white'
              backgroundColor='black'
              borderColor='transparent'
            />
          ) : null}
          </MetaDataRowSection>
        </MetaDataRow>
      </DataContainer>
      <ActionContainer>
        {actions ? (<ActionsMenu options={actions} />) : null}
      </ActionContainer>
    </Container>
  );
};

export const ArrowDivider = ({label}: {label: string}) => (
  <ArrowDividerContainer>
    <ArrowCorridor>
      <ArrowBody />
      <ArrowLabel>
        <ArrowLabelText>
          {label}
        </ArrowLabelText>
      </ArrowLabel>
      <ArrowHead />
    </ArrowCorridor>
  </ArrowDividerContainer>
);
