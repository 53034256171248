import get from 'lodash/get';

type PathArg = Parameters<typeof get>[1]

/**
 * given a list of items, a list of terms and a `lodash/get`-style path spec
 * filter the list by fetching the property at `path` and comparing it to terms.
 * items will be included in the final list if any terms match.
 * if no `path` is specified, the function assumes the list is a flat list of strings.
 */
export const filterListOnRelationTerms =
  <T, >(list: Array<T>, terms: Array<string>, path?: PathArg) =>
  terms.length ?
    list.filter(
        (item) => terms.some((t) => (path ? get(item, path, '') : item).includes(t)),
    ) : list;
