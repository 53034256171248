import {useState, useMemo, useContext, useCallback} from 'react';
import identity from 'lodash/identity';
import {ColumnDef} from '@tanstack/react-table';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  TabPane,
  UncontrolledDropdown,
} from 'reactstrap';
import Swal from 'sweetalert2';
import {FaEllipsisV} from 'react-icons/fa';

import {getConfigurations, deleteConfiguration} from 'src/api/awsConfig';
import {ListingPage} from 'src/components';
import {UserRole, AwsConfig as AwsConfigType} from 'src/types';
import {AuthContext} from 'src/context';

import NewAwsConfig from './NewAwsConfig';

import './administration.css';

const AwsConfig = ({setLoading}: {setLoading: (_:boolean) => void}) => {
  const {hasRole} = useContext(AuthContext);
  const [configData, setConfigData] = useState<Array<AwsConfigType>>([]);
  const [isConfigFormOpen, setIsConfigFormOpen] = useState(false);
  const [editData, setEditData] =
    useState<AwsConfigType | undefined>(undefined);


  const columns = useMemo<ColumnDef<AwsConfigType>[]>(
      () => [
        {
          header: 'Account ID',
          accessorKey: 'accountId',
          enableSorting: false,
        },
        {
          header: 'Access Key',
          accessorKey: 'accessKey',
          enableSorting: false,
        },
        {
          header: 'Actions',
          accessorFn: identity,
          enableSorting: false,
          cell: ({getValue}: {getValue: () => AwsConfigType}) => {
            const {accountId} = getValue();
            return (
              <div className="row-actions">
                <UncontrolledDropdown>
                  <DropdownToggle color="link">
                    <FaEllipsisV />
                  </DropdownToggle>
                  <DropdownMenu>
                    {accountId && (
                      <DropdownItem onClick={() => editConfig(accountId)}>
                        Edit
                      </DropdownItem>
                    )}
                    {accountId && (
                      <DropdownItem onClick={() => deleteConfig(accountId)}>
                        Delete
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            );
          },
        },
      ],
      [],
  );

  const openConfigForm = () => {
    setIsConfigFormOpen(true);
  };

  const closeConfigForm = () => {
    setIsConfigFormOpen(false);
    setEditData(undefined);
    fetchConfig();
  };

  const fetchConfig = useCallback(
      async (page = 0, pageSize = 10) => {
        setLoading(true);
        const {data} = await getConfigurations({page, pageSize});
        setLoading(false);
        if (data) {
          setConfigData(data);
        }
      },
      [isConfigFormOpen],
  );

  const editConfig = (accountId: string) => {
    setEditData({accountId});
    setIsConfigFormOpen(true);
  };

  const deleteConfig = (accountId: string) => {
    Swal.fire({
      title: 'Do you want to delete this configuration?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Delete`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteConfiguration(accountId);
        if (res.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: 'Configuration deleted successfully',
            icon: 'success',
            timer: 1200,
            showConfirmButton: false,
          });
          fetchConfig();
        }
      }
    });
  };

  const canCreate = hasRole(UserRole.ADMIN);

  return (
    <TabPane>
      <div className="admin-page-options">
        {canCreate && (
          <Button color="primary" onClick={openConfigForm}>
          Add Configuration
          </Button>
        )}
      </div>
      <ListingPage
        columns={columns}
        data={configData}
        pageData={undefined}
        fetchData={fetchConfig}
      />
      {isConfigFormOpen && (
        <NewAwsConfig
          isOpen={isConfigFormOpen}
          onClose={closeConfigForm}
          editData={editData}
        />
      )}
    </TabPane>
  );
};

export default AwsConfig;
