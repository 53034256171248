import {useState, useEffect} from 'react';

import {useFeatureFlag} from 'src/hooks';
import {getDecomposition, getRelationships} from 'src/api/dependency';
import {NodeData, Workspace, TypedApiResponse, RelationshipData, MetaDataType} from 'src/types';
import {getDefaultScanSpaceApplications, getScanSpaceApplications} from 'src/api/scanSpace';
import {getWorkspaces} from 'src/api/workspace';
import {getRepositoryUrls} from 'src/api/changeRequests';

export const useRepositoryUrls = () => {
  const [repositoryUrls, setRepositoryUrls] = useState<string[] | undefined>();

  const loadRepositoryUrls = async () => {
    const {data} = await getRepositoryUrls();
    setRepositoryUrls(data);
  };

  useEffect(() => {
    loadRepositoryUrls();
  }, []);


  const loading = !repositoryUrls;

  return {loading, repositoryUrls, loadRepositoryUrls} as const;
};

export const useWorkspaces = ({autoInitialize}: {autoInitialize: boolean} = {autoInitialize: false}) => {
  const [enabled] = useFeatureFlag('FEATURE_FLAG_SELECT_MATERIALIZED_VIEWS');
  const [workspaces, setWorkspaces] = useState<Workspace[] | undefined>();

  const loadWorkspaces = async () => {
    const {data} = await getWorkspaces({
      showHidden: enabled,
      page: 0,
      pageSize: 100000,
    });
    setWorkspaces(data);
  };

  useEffect(() => {
    if (autoInitialize) {
      loadWorkspaces();
    }
  }, []);


  const loading = !workspaces;

  return {loading, workspaces, loadWorkspaces} as const;
};

export const useScanSpaceApplicationNames = (id?: string) => {
  const [applications, setApplications] = useState<Array<string> | undefined>();

  const load = async (id?: string) => {
    if (id) {
      const {data} = await getScanSpaceApplications(id);
      setApplications(data);
    } else {
      const {data} = await getDefaultScanSpaceApplications();
      setApplications(data);
    }
  };

  useEffect(() => {
    load(id);
  }, []);

  const loading = !applications;

  return {loading, applications, load} as const;
};

export const useResource = <
  T,
  U extends (...args: Array<any>) => Promise<TypedApiResponse<T>>
>(apiCall: U) => {
  type AwaitedApiReturnType = Awaited<ReturnType<U>>['data']
  const [entity, setEntity] = useState<AwaitedApiReturnType | undefined>();
  const [metadata, setMetadata] = useState<MetaDataType | undefined>();
  const [loading, setLoading] = useState(false);

  const loadEntity = async (...args: Parameters<typeof apiCall>) => {
    setLoading(true);
    const response = await apiCall(...args);
    setEntity(response.data);
    setMetadata(response.metaData);
    setLoading(false);
  };

  return {loading, entity, metadata, loadEntity} as const;
};

export const useNode = (nodeId?: string) => {
  const [node, setNode] = useState<NodeData | undefined>();
  const [loading, setLoading] = useState(false);

  const fetchNodeById = async (nodeId: string) => {
    setLoading(true);
    setNode((await getDecomposition({id: nodeId})).data);
    setLoading(false);
  };

  const clearData = () => {
    setNode(undefined);
  };

  useEffect(() => {
    if (nodeId) {
      fetchNodeById(nodeId);
    }
  }, [nodeId]);

  return {loading, node, fetchNodeById, clearData};
};

export const useRelationship = (fromId: string, type: string, toId: string) => {
  const [relationship, setRelationship] = useState<RelationshipData | undefined>();
  const [loading, setLoading] = useState(false);

  const fetchRelationship = async (fromId: string, type: string, toId: string) => {
    setLoading(true);
    const relationships = (await getRelationships(fromId, type, toId)).data.data;
    setRelationship(relationships[0]);
    setLoading(false);
  };

  const clearData = () => {
    setRelationship(undefined);
  };

  useEffect(() => {
    if (fromId && type && toId) {
      fetchRelationship(fromId, type, toId);
    }
  }, [fromId, type, toId]);

  return {loading, relationship, fetchRelationship, clearData};
};
