import {LoadingPlaceholder} from 'src/components';

import './styles.css';

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/td#attr-colspan
const MAX_COLSPAN = 1000;

export const LoadingRecords = ({}) => (
  <tr className="LoadingRecordsRow">
    <td colSpan={MAX_COLSPAN}>
      <div className="LoadingRecords">
        <LoadingPlaceholder />
      </div>
    </td>
  </tr>
);

