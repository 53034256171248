import RSAsyncSelect from 'react-select/async';
import RSSelect, {FormatOptionLabelMeta} from 'react-select';
import identity from 'lodash/identity';

import type {StylesConfig} from 'react-select';

export const Select = <T, >({
  placeholder,
  onChange,
  options,
  value,
  getOptionLabel = identity,
  getOptionValue = identity,
  formatOptionLabel,
  styles = {},
}: {
  placeholder?: string,
  onChange: (_:T | undefined) => void,
  options: Array<T>,
  value?: T,
  getOptionLabel?: (_: T) => string,
  getOptionValue: (_: T) => string,
  formatOptionLabel?: ((data: T, formatOptionLabelMeta: FormatOptionLabelMeta<T>) => React.ReactNode)
  styles?: StylesConfig<T>,
}) => (
    <RSSelect<T>
      styles={{
        container: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
          minWidth: '20rem',
        }),
        control: (styles) => ({
          ...styles,
          'backgroundColor': 'var(--color-background--standard)',
          'border': '1px solid var(--color-border--standard)',
          '&:hover': {
            border: '1px solid var(--color-text-link--hover)',
            color: 'var(--color-text-link--hover)',
          },
        }),
        indicatorsContainer: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
          color: 'var(--color-text-link)',
        }),
        valueContainer: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
        }),
        singleValue: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
          color: 'var(--color-text-link)',
        }),
        menu: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
        }),
        menuList: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
          border: '1px solid var(--color-border--standard)',
        }),
        option: (styles) => ({
          ...styles,
          'backgroundColor': 'var(--color-background--standard)',
          'color': 'var(--color-text-link)',
          '&:hover': {
            backgroundColor: 'var(--color-background--inverse)',
            color: 'var(--color-text--inverse)',
          },
        }),
        ...styles,
      }}
      isMulti={false}
      placeholder={placeholder}
      onChange={(value) => onChange(value || undefined)}
      value={value}
      formatOptionLabel={formatOptionLabel}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      options={options}
    />
  );

export const AsyncSelect = <T, >({
  placeholder,
  onChange,
  loadOptions,
  options = [],
  value,
  getOptionLabel = identity,
  getOptionValue = identity,
  formatOptionLabel,
  styles = {},
}: {
  placeholder?: string,
  onChange: (_:T | undefined) => void,
  options?: Array<T>,
  value?: T,
  getOptionLabel?: (_: T) => string,
  getOptionValue: (_: T) => string,
  formatOptionLabel?: ((data: T, formatOptionLabelMeta: FormatOptionLabelMeta<T>) => React.ReactNode)
  loadOptions?: (input: string) => Promise<Array<T>>,
  styles?: StylesConfig<T>,
}) => (
    <RSAsyncSelect<T>
      styles={{
        container: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
          minWidth: '20rem',
        }),
        control: (styles) => ({
          ...styles,
          'backgroundColor': 'var(--color-background--standard)',
          'border': '1px solid var(--color-border--standard)',
          '&:hover': {
            border: '1px solid var(--color-text-link--hover)',
            color: 'var(--color-text-link--hover)',
          },
        }),
        indicatorsContainer: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
          color: 'var(--color-text-link)',
        }),
        valueContainer: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
        }),
        singleValue: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
          color: 'var(--color-text-link)',
        }),
        menu: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
        }),
        menuList: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
          border: '1px solid var(--color-border--standard)',
        }),
        option: (styles) => ({
          ...styles,
          'backgroundColor': 'var(--color-background--standard)',
          'color': 'var(--color-text-link)',
          '&:hover': {
            backgroundColor: 'var(--color-background--inverse)',
            color: 'var(--color-text--inverse)',
          },
        }),
        ...styles,
      }}
      defaultOptions
      cacheOptions
      isMulti={false}
      placeholder={placeholder}
      onChange={(value) => onChange(value || undefined)}
      value={value}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      formatOptionLabel={formatOptionLabel}
      loadOptions={(input) => loadOptions ? loadOptions(input) : Promise.resolve(options)}
    />
  );

export const AsyncMultiSelect = <T, >({
  placeholder,
  onChange,
  loadOptions,
  options = [],
  value = [],
  getOptionLabel = identity,
  getOptionValue = identity,
  formatOptionLabel,
  styles = {},
}: {
  placeholder?: string,
  onChange: (_: Array<T> | undefined) => void,
  options?: Array<T>,
  value?: Array<T>,
  getOptionLabel?: (_: T) => string,
  getOptionValue: (_: T) => string,
  formatOptionLabel?: ((data: T, formatOptionLabelMeta: FormatOptionLabelMeta<T>) => React.ReactNode)
  loadOptions?: (input: string) => Promise<Array<T>>,
  styles?: StylesConfig<T>,
}) => (
    <RSAsyncSelect<T, true>
      styles={{
        container: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
          minWidth: '20rem',
        }),
        control: (styles) => ({
          ...styles,
          'backgroundColor': 'var(--color-background--standard)',
          'border': '1px solid var(--color-border--standard)',
          '&:hover': {
            border: '1px solid var(--color-text-link--hover)',
            color: 'var(--color-text-link--hover)',
          },
        }),
        indicatorsContainer: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
          color: 'var(--color-text-link)',
        }),
        valueContainer: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
        }),
        singleValue: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
          color: 'var(--color-text-link)',
        }),
        menu: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
        }),
        menuList: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
          border: '1px solid var(--color-border--standard)',
        }),
        option: (styles) => ({
          ...styles,
          'backgroundColor': 'var(--color-background--standard)',
          'color': 'var(--color-text-link)',
          '&:hover': {
            backgroundColor: 'var(--color-background--inverse)',
            color: 'var(--color-text--inverse)',
          },
        }),
        ...styles,
      }}
      defaultOptions
      cacheOptions
      isMulti={true}
      placeholder={placeholder}
      onChange={(value) => onChange(value as Array<T> /* MultiValue<T> is just readonly T[] */)}
      options={options}
      value={value}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      formatOptionLabel={formatOptionLabel}
      loadOptions={(input) => loadOptions ? loadOptions(input) : Promise.resolve(options)}
    />
  );

export const MultiSelect = <T, >({
  placeholder,
  onChange,
  options,
  value = [],
  getOptionLabel = identity,
  getOptionValue = identity,
  formatOptionLabel,
  styles = {},
}: {
  placeholder?: string,
  onChange: (_:Array<T> | undefined) => void,
  options?: Array<T>,
  value?: Array<T>,
  getOptionLabel?: (_: T) => string,
  getOptionValue: (_: T) => string,
  formatOptionLabel?: ((data: T, formatOptionLabelMeta: FormatOptionLabelMeta<T>) => React.ReactNode)
  styles?: StylesConfig<T>,
}) => (
    <RSSelect<T, true>
      styles={{
        container: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
          minWidth: '20rem',
        }),
        control: (styles) => ({
          ...styles,
          'backgroundColor': 'var(--color-background--standard)',
          'border': '1px solid var(--color-border--standard)',
          '&:hover': {
            border: '1px solid var(--color-text-link--hover)',
            color: 'var(--color-text-link--hover)',
          },
        }),
        indicatorsContainer: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
          color: 'var(--color-text-link)',
        }),
        valueContainer: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
        }),
        singleValue: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
          color: 'var(--color-text-link)',
        }),
        menu: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
        }),
        menuList: (styles) => ({
          ...styles,
          backgroundColor: 'var(--color-background--standard)',
          border: '1px solid var(--color-border--standard)',
        }),
        option: (styles) => ({
          ...styles,
          'backgroundColor': 'var(--color-background--standard)',
          'color': 'var(--color-text-link)',
          '&:hover': {
            backgroundColor: 'var(--color-background--inverse)',
            color: 'var(--color-text--inverse)',
          },
        }),
        ...styles,
      }}
      isMulti={true}

      placeholder={placeholder}
      onChange={(value) => onChange(value as Array<T>) /* MultiValue<T> is just readonly T[] */}
      options={options}
      value={value}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      formatOptionLabel={formatOptionLabel}
    />
  );
