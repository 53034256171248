import {useState} from 'react';
import {FaSortAlphaDown, FaSortAlphaDownAlt} from 'react-icons/fa';

import {Tag} from 'src/components';

export const CountTag = ({count}: {count: number}) => (
  <div className="RelationshipList__countContainer">
    <Tag fontSize="1em"
      borderColor='var(--color-border--standard)'
      color='var(--color-text)'
      label={'' + count} />
  </div>
);

export const NoRecords = () => <div className="RelationshipList-list__no-records"><span>no records</span></div>;

type SortDirection = 'asc' | 'desc'

export const SortToggle = ({onClick = () => {}}: {onClick: (direction: SortDirection) => void}) => {
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');

  const handleClick = (dir: SortDirection) => {
    setDirection(dir);
    onClick(dir);
  };

  return (
    <button style={{
      fontSize: '1.2em',
      minHeight: '38px',
      padding: '0.1em 0.5em',
    }} className="btn btn-link" onClick={() => handleClick(direction === 'desc' ? 'asc' : 'desc')}>
      {direction === 'asc' ? <FaSortAlphaDown /> : <FaSortAlphaDownAlt />}
    </button>
  );
};
