import {MouseEvent, ReactNode} from 'react';
import {FaChevronDown} from 'react-icons/fa';

import {Menu} from 'src/components';

type Option = {
  onClick: (e: MouseEvent) => void
  content: ReactNode
}

type Props = {
  label?: ReactNode,
  options: Array<Option>
}

export const OverflowMenu = ({label = 'Menu', options}: Props) => (
  <Menu
    label={<span style={{
      color: 'var(--color-text--inverse)',
    }}>{label} <FaChevronDown /></span>}
    options={options}
  />
);
