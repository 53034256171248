import {
  RefObject,
  useEffect,
  useState,
} from 'react';
import {
  Diagram as GOJSDiagram,
  GraphLinksModel,
} from 'gojs';
import {ReactDiagram} from 'gojs-react';

type Diagram = GOJSDiagram & {model: GraphLinksModel}

export const useDiagram = (ref: RefObject<ReactDiagram>) => {
  const [diagram, setDiagram] = useState<Diagram | undefined>();

  useEffect(() => {
    const {current} = ref;
    if (current) {
      setDiagram(current.getDiagram() as Diagram || undefined);
    }
  }, [ref.current]);

  return diagram;
};
