import {PropsWithChildren, ReactNode} from 'react';
import {ErrorMessage} from 'formik';
import {FormGroup, Label} from 'reactstrap';

import './styles.css';

type Props = PropsWithChildren<{
  readOnly?: boolean,
  label: ReactNode,
  name: string
}>

export const Field = ({readOnly, label, name, children}: Props) => (
  <FormGroup>
    <Label className="CreateForm__field-label">
      <p className="CreateForm__field-title">{label}</p>
      {children}
    </Label>
    <div className="CreateForm__error-message-container">
      <ErrorMessage
        name={name}
        component="span"
      />
    </div>
  </FormGroup>

);
