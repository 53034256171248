import {JSONSchema7} from 'json-schema';


const commonSchemaProps = {
  'isRemotelyConfigured': {
    title: 'Advanced Remote Configuration',
    type: 'boolean',
    default: false,
  },
  'agentId': {
    title: 'Agent Id',
    type: 'string',
  },
  'agentTypeName': {
    title: 'Agent Type',
    type: 'string',
  },
  'label': {
    title: 'Agent Name',
    type: 'string',
  },
  'description': {
    title: 'Agent Description',
    type: 'string',
  },
} as const;

const defaultSchema = {
  'type': 'object',
  'properties': {
    ...commonSchemaProps,
  },
} as const;

const commonUISchemaProps = {
  'agentId': {
    'ui:readonly': true,
  },
  'agentTypeName': {
    'ui:readonly': true,
  },
} as any;

const notOrdered = {
  'ui:options': {
    'orderable': false,
  },
};


const sqlCapeUISchema = {
  ...commonUISchemaProps,
  'properties': {
    'encrypted': {
      'databases': {
        ...notOrdered,
        'items': {
          'password': {
            'ui:widget': 'password',
          },
        },
      },
    },
    'agent': {
      'schedule': {
        'ui:widget': 'cronWidget',
      },
      'application': {
        ...notOrdered,
      },
    },
  },
};

const sqlCapeSchema : JSONSchema7 = {
  'definitions': {
    'Props': {
      'type': 'object',
      'properties': {
        'encrypted': {
          'type': 'object',
          '$ref': '#/definitions/Encrypted',
          'title': '',
        },
        'agent': {
          'type': 'object',
          '$ref': '#/definitions/Agent',
          'title': '',
        },
      },
    },
    'Agent': {
      'type': 'object',
      'properties': {
        'schedule': {
          title: 'Schedule',
          type: 'string',
        },
        'batchSize': {
          title: 'Batch Size',
          type: 'number',
        },
        'application': {
          'type': 'array',
          'title': 'A list of applications',
          'items': {
            'type': 'string',
          },
        },
      },
    },
    'Encrypted': {
      'type': 'object',
      'properties': {
        'databases': {
          'type': 'array',
          'title': 'Databases',
          'minItems': 1,
          'items': {
            '$ref': '#/definitions/Database',
          },
        },
      },
    },
    'Database': {
      'type': 'object',
      'properties': {
        'jdbcUrl': {
          title: 'JDBC Url',
          type: 'string',
        },
        'username': {
          title: 'Username',
          type: 'string',
        },
        'password': {
          title: 'Password',
          type: 'string',
        },
        'databaseNameOverride': {
          title: 'Database Name Override',
          type: 'string',
        },
      },
    },
  },
  'type': 'object',
  'properties': {
    ...commonSchemaProps,
    'properties': {
      'type': 'object',
      '$ref': '#/definitions/Props',
      'title': '',
    },
  },
};

const jCapeInstrumentationUISchema = {
  ...commonUISchemaProps,
  'properties': {
    'packageLinkingFilters': {
      ...notOrdered,
    },
  },
};

const jCapeInstrumentationSchema : JSONSchema7 = {
  'definitions': {
    'Props': {
      'type': 'object',
      'properties': {
        'packageLinkingFilters': {
          'type': 'array',
          'title': 'A list of group names.',
          'items': {
            'type': 'string',
          },
        },
        'dataReportingIntervalMinutes': {
          title: 'Data Reporting Interval Minutes',
          type: 'number',
        },
        'debugEnabled': {
          'type': 'boolean',
          'title': 'Debug Enabled',
        },
      },
    },
  },
  'type': 'object',
  'properties': {
    ...commonSchemaProps,
    'properties': {
      'type': 'object',
      '$ref': '#/definitions/Props',
      'title': '',
    },
  },
};

const netCapeInstrumentationSchema : JSONSchema7 = {
  'definitions': {
    'Props': {
      'type': 'object',
      'properties': {
        'agent': {
          'type': 'object',
          '$ref': '#/definitions/Agent',
          'title': '',
        },
      },
    },
    'Agent': {
      'type': 'object',
      'properties': {
        'capeClient': {
          'type': 'object',
          'title': '',
          'properties': {
            'logLevel': {
              'title': 'Logging Level',
              'type': 'string',
              'default': 'Information',
              'enum': [
                'Trace',
                'Debug',
                'Information',
                'Warning',
                'Error',
                'Critical',
                'None',
              ],
            },
          },
        },
      },
    },
  },
  'type': 'object',
  'properties': {
    ...commonSchemaProps,
    'properties': {
      'type': 'object',
      '$ref': '#/definitions/Props',
      'title': '',
    },
  },
};

const jCapeSchema : JSONSchema7 = {
  'definitions': {
    'Props': {
      'type': 'object',
      'properties': {
        'agent': {
          'type': 'object',
          '$ref': '#/definitions/Agent',
          'title': '',
        },
      },
    },
    'Agent': {
      'type': 'object',
      'properties': {
        'schedule': {
          title: 'Schedule',
          type: 'string',
        },
        'batchSize': {
          title: 'Batch Size',
          type: 'number',
        },
        'analysisConfigurations': {
          'type': 'array',
          'title': 'Agent Configurations',
          'minItems': 1,
          'items': {
            '$ref': '#/definitions/AgentConfiguration',
          },
        },
      },
    },
    'AgentConfiguration': {
      'type': 'object',
      'properties': {
        'application': {
          'type': 'array',
          'title': 'A list of applications',
          'items': {
            'type': 'string',
          },
        },
        'databases': {
          'type': 'array',
          'title': 'A list of databases',
          'items': {
            'type': 'string',
          },
        },
        'recursion': {
          'type': 'array',
          'title': 'A list of recursions',
          'items': {
            'type': 'string',
          },
        },
        'methodInvokesScanningEnabled': {
          'type': 'boolean',
          'title': 'Enable method invocation Scanning',
        },
        'methodInvokesScanningPackageIncludeList': {
          'type': 'array',
          'title': 'A list of methods to invoke scanning on',
          'items': {
            'type': 'string',
          },
        },
        'scanner': {
          'type': 'object',
          'title': '',
          'properties': {
            'depth': {
              'title': 'Depth to scan to',
              'type': 'number',
            },
            'paths': {
              'type': 'array',
              'title': 'List of paths to scan.',
              'items': {
                'type': 'string',
              },
            },
            'filters': {
              'type': 'array',
              'title': 'List of filter patterns',
              'items': {
                'type': 'string',
              },
            },
          },
        },
      },
    },
  },
  'type': 'object',
  'properties': {
    ...commonSchemaProps,
    'properties': {
      'type': 'object',
      '$ref': '#/definitions/Props',
      'title': '',
    },
  },
};

const netCapeSchema : JSONSchema7 = {
  'definitions': {
    'Props': {
      'type': 'object',
      'properties': {
        'agent': {
          'type': 'object',
          '$ref': '#/definitions/Agent',
          'title': '',
        },
      },
    },
    'Agent': {
      'type': 'object',
      'properties': {
        'schedule': {
          title: 'Schedule',
          type: 'string',
        },
        'capeClient': {
          'type': 'object',
          'title': '',
          'properties': {
            'batchSize': {
              'title': 'Batch Size',
              'type': 'number',
            },
            'logLevel': {
              'title': 'Logging Level',
              'type': 'string',
              'default': 'Information',
              'enum': [
                'Trace',
                'Debug',
                'Information',
                'Warning',
                'Error',
                'Critical',
                'None',
              ],
            },
          },
        },
        'scanConfigurations': {
          'type': 'array',
          'title': 'Scan Configurations',
          'minItems': 1,
          'items': {
            '$ref': '#/definitions/ScanConfiguration',
          },
        },
      },
    },
    'ScanConfiguration': {
      'type': 'object',
      'properties': {
        'application': {
          'type': 'array',
          'title': 'A list of applications',
          'items': {
            'type': 'string',
          },
        },
        'isSkipSubdirectories': {
          'type': 'boolean',
          'title': 'Skip Subdirectories',
          'default': false,
        },
        'isIISAnalysis': {
          'type': 'boolean',
          'title': 'IIS Analysis',
          'default': false,
        },
        'isShowCompilerGenerated': {
          'type': 'boolean',
          'title': 'Show Compiler Generated',
          'default': false,
        },
        'filePathsToAnalyze': {
          'type': 'array',
          'title': 'Paths',
          'items': {
            'type': 'string',
          },
        },
        'webSites': {
          'type': 'array',
          'title': 'List of websites',
          'items': {
            'type': 'string',
          },
        },
        'filters': {
          'type': 'array',
          'title': 'List of filter patterns',
          'items': {
            'type': 'string',
          },
        },
        'databases': {
          'type': 'array',
          'title': 'A list of databases',
          'items': {
            'type': 'string',
          },
        },
      },
    },
  },
  'type': 'object',
  'properties': {
    ...commonSchemaProps,
    'properties': {
      'type': 'object',
      '$ref': '#/definitions/Props',
      'title': '',
    },
  },
};

const jCapeUISchema = {
  ...commonUISchemaProps,
  'properties': {
    'agent': {
      'schedule': {
        'ui:widget': 'cronWidget',
      },
      'analysisConfigurations': {
        ...notOrdered,
        'items': {
          'application': {
            ...notOrdered,
          },
          'recursion': {
            ...notOrdered,
          },
          'methodInvokesScanningPackageWhitelist': {
            ...notOrdered,
          },
          'databases': {
            ...notOrdered,
          },
          'scanner': {
            'paths': {
              ...notOrdered,
            },
            'filters': {
              ...notOrdered,
            },
          },
        },
      },
    },
  },
};

const netCapeUISchema = {
  ...commonUISchemaProps,
  'properties': {
    'agent': {
      'serviceCronSchedule': {
        'ui:widget': 'cronWidget',
      },
      'scanConfigurations': {
        ...notOrdered,
        'items': {
          'filePathsToAnalyze': {
            ...notOrdered,
          },
          'webSites': {
            ...notOrdered,
          },
          'filters': {
            ...notOrdered,
          },
          'databases': {
            ...notOrdered,
          },
        },
      },
    },
  },
};

const nodeJSCapeSchema : JSONSchema7 = {
  'definitions': {
    'Props': {
      'type': 'object',
      'properties': {
        'agent': {
          'type': 'object',
          '$ref': '#/definitions/Agent',
          'title': '',
        },
      },
    },
    'Agent': {
      'type': 'object',
      'properties': {
        'schedule': {
          title: 'Schedule',
          type: 'string',
        },
        'batchSize': {
          title: 'Batch Size',
          type: 'number',
        },
        'paths': {
          'type': 'array',
          'title': 'Paths',
          'minItems': 1,
          'items': {
            '$ref': '#/definitions/Path',
          },
        },
      },
    },
    'Path': {
      'type': 'object',
      'properties': {
        'parseNodeModules': {
          'type': 'boolean',
          'title': 'Parse Node Modules',
        },
        'jsPlugins': {
          'type': 'array',
          'title': 'A list of JavaScript plugins',
          'items': {
            'type': 'string',
          },
        },
        'tsPlugins': {
          'type': 'array',
          'title': 'A list of TypeScript plugins',
          'items': {
            'type': 'string',
          },
        },
        'path': {
          'type': 'string',
          'title': 'The path to scan',
        },
        'moduleName': {
          'type': 'string',
          'title': 'The moduleName to parse',
        },
      },
    },
  },
  'type': 'object',
  'properties': {
    ...commonSchemaProps,
    'properties': {
      'type': 'object',
      '$ref': '#/definitions/Props',
      'title': '',
    },
  },
};

const nodeJSCapeUISchema = {
  ...commonUISchemaProps,
  'properties': {
    'agent': {
      'schedule': {
        'ui:widget': 'cronWidget',
      },
      'paths': {
        ...notOrdered,
        'items': {
          'jsPlugins': {
            ...notOrdered,
          },
          'tsPlugins': {
            ...notOrdered,
          },
        },
      },
    },
  },
};

const jCape = 'jCape';
const jCapeInstrumentation = 'jCapeInstrumentation';
const netCapeInstrumentation = 'netCapeDynamic';
const sqlCape = 'sqlCape';
const nodeJsCape = 'NodeJsCape';
const netCape = 'netCape';

/**
 * Returns the schema for the given agentType.
 * A 'minimalSchema' are the schema validations common among all agents and
 * used even if the agent is going to be configured with command line parameters.
 *
 * @param dataSource - the key for the agentType.
 * @param isRemotelyConfigured - false if the agent is being configured via command line params. Will return a minimal schema
 */
export const getSchema = (dataSource: string, isRemotelyConfigured = false) : JSONSchema7 => {
  if (!isRemotelyConfigured) return defaultSchema;

  if (dataSource === jCape) {
    return jCapeSchema;
  } else if (dataSource === netCapeInstrumentation) {
    return netCapeInstrumentationSchema;
  } else if (dataSource === jCapeInstrumentation) {
    return jCapeInstrumentationSchema;
  } else if (dataSource === sqlCape) {
    return sqlCapeSchema;
  } else if (dataSource === nodeJsCape) {
    return nodeJSCapeSchema;
  } else if (dataSource === netCape) {
    return netCapeSchema;
  } else {
    return defaultSchema;
  }
};

export const getUISchema = (dataSource: string, isRemotelyConfigured = false) => {
  if (!isRemotelyConfigured) return commonUISchemaProps;

  if (dataSource === jCape) {
    return jCapeUISchema;
  } else if (dataSource === jCapeInstrumentation) {
    return jCapeInstrumentationUISchema;
  } if (dataSource === nodeJsCape) {
    return nodeJSCapeUISchema;
  } else if (dataSource === netCape) {
    return netCapeUISchema;
  } else if (dataSource === sqlCape) {
    return sqlCapeUISchema;
  } else {
    return commonUISchemaProps;
  }
};

export const getValidation = (dataSource: string, isRemotelyConfigured = false) => {
  if (dataSource === netCape && isRemotelyConfigured) {
    return function validate(formData, errors) {
      formData.properties.agent.scanConfigurations.forEach((scanConfiguration, index) => {
        if (!scanConfiguration.isIISAnalysis && scanConfiguration.filePathsToAnalyze.length <= 0) {
          errors.properties.agent.scanConfigurations[index].filePathsToAnalyze.addError('should NOT have fewer than 1 items');
        }
      });
      return errors;
    };
  } else {
    return (formData, errors) => {
      return errors;
    };
  }
};


