import {ReactNode} from 'react';
import styled from 'styled-components';

import {ReactComponent as Logo} from 'src/svgs/codelogic-header-logo.svg';

const Wrapper = styled.div`
  display: flex;
  // height: 100%;
  width: 100%;
  min-height: calc(100% - 57px);
  align-items: center;
  justify-content: center;
`;

const InnerWrapper = styled.div`
  flex: 1;
`;

const Header = styled.div`
  width: 350px;
  background: var(--color-nav-gradient-1);
  margin: 0 auto;
  text-align: center;
  padding: 20px 0px 0px;

  > svg {
    color: var(--color-sidenav-link-1);
    max-height: 30px;
    margin-bottom: 20px;
  }
`;

const Content = styled.div`
  .form {
    width: 350px;
    background: var(--color-background--standard);
    border: 1px solid var(--color-border--standard);
    padding: 25px;
    margin: 0 auto;
    text-align: center;
  }

  .form-action {
    width: 100%;
  }

  .form-link {
    // margin-top: 15px;
    font-size: 0.85rem;
    text-align: left;

    a {
      display: inline-block;
      margin-bottom: 5px;
    }

    label {
      margin-left: 0 !important;
    }

    button {
      margin-top: 15px;
    }
  }
`;

const LoginContainer = (props: {
  children: ReactNode;
  heading: string;
}) => {
  const {children} = props;
  return (
    <Wrapper>
      <InnerWrapper>
        <Header>
          <Logo
            style={{width: '138px'}}
            alt="CodeLogic logo"
          />
        </Header>
        <Content>{children}</Content>
      </InnerWrapper>
    </Wrapper>
  );
};

export default LoginContainer;
