import uniqBy from 'lodash/uniqBy';

import {Node, Map as GoJSMap} from 'src/util/gojs/go';
import {getWhereUsed as getDiagramWhereUsed, getContainsPath} from 'src/api/dependency';
import {
  makeDiagram,
  isDeEmphasized,
  makeLinkTemplate,
  makeNodeTemplate,
} from 'src/graph';
import type {Theme} from 'src/styles/colors';
import {THEMES} from 'src/styles/colors';
import {DEFAULT_THEME} from 'src/constants';

export const fetchDiagramData = async (nodeId: string) => {
  if (!nodeId) {
    return;
  }

  const [result, {data: containsPathData}] = await Promise.all([
    getDiagramWhereUsed(nodeId),
    getContainsPath(nodeId),
  ]);


  if (result.status === 'success' && result.data && result.data !== undefined) {
    const data = {
      nodes: result.data.nodes,
      relationships: result.data.relationships,
    };

    if (containsPathData.status === 'success' && containsPathData.data && containsPathData.data !== undefined) {
      data.nodes = uniqBy(data.nodes.concat(containsPathData.data.nodes), ({id}) => id);
      data.relationships = uniqBy(data.relationships.concat(containsPathData.data.relationships), ({startId, endId}) => `${startId},${endId}`);
    }
    return {
      nodes: data.nodes.map((n) => ({
        ...n,
        emphasized: !isDeEmphasized(n.primaryLabel),
      })),
      relationships: data.relationships.map((r) => ({...r, emphasized: !isDeEmphasized(r.type)})),
    };
  } else {
    return {
      nodes: [],
      relationships: [],
    };
  }
};

export const makeInitDiagram = ({
  initialFocusNodeId,
  themeName = DEFAULT_THEME,
  theme = THEMES[DEFAULT_THEME],
}: {
  initialFocusNodeId?: string
  themeName: keyof typeof THEMES,
  theme?: Theme,
}) => () => {
  const diagram = makeDiagram({
    themeName,
    theme,
  });
  diagram.nodeTemplateMap = new GoJSMap<string, Node>()
      .add('', makeNodeTemplate({
        expandable: false,
        hasEmphasis: false,
      }));

  diagram.linkTemplate = makeLinkTemplate({labeled: true});

  if (initialFocusNodeId) {
    diagram.addDiagramListener('InitialLayoutCompleted', (e) => {
      const node = diagram.findNodeForKey(initialFocusNodeId);
      if (node) {
        diagram.centerRect(node.actualBounds);
        diagram.select(node);
      }
    });
  }

  return diagram;
};
