import {CODELOGIC_API_BASE_URL} from 'src/config';
import {
  TypedApiResponse,
  Workspace,
  CreateWorkspaceWithMaterializedViewDefinitionPayload,
  UpdateWorkspaceWithMaterializedViewDefinitionPayload,
  ApiErrorResponse,
  ApiError,
} from 'src/types';

import {axiosInstance} from './baseApi';
import {isAxiosError} from './utils';

import {makeResourceListFetch, makeResourceFetchById} from './index';

export async function getDefaultWorkspace(): Promise<TypedApiResponse<Workspace>> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/workspace/default`;

  try {
    const response = await axiosInstance.get(endpoint, {withCredentials: true});
    return response.data;
  } catch (e) {
    return {
      status: 'error',
    } as const;
  }
}

export const getWorkspace = makeResourceFetchById<Workspace>({method: 'GET', path: 'workspace'});

export const getWorkspaces = makeResourceListFetch<Array<Workspace>, {showHidden: boolean}>({method: 'GET', path: 'workspace', defaultSortBy: 'displayName,asc,ignoreCase'});

export async function deleteWorkspace(workspaceId: string): Promise<TypedApiResponse<boolean | ApiErrorResponse<ApiError>>> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/workspace/${workspaceId}`;
  try {
    const response = await axiosInstance.delete(endpoint, {withCredentials: true});
    return response.data;
  } catch (e) {
    if (isAxiosError(e) && e.response?.data) {
      return e.response.data;
    } else {
      return {
        status: 'error',
      } as const;
    }
  }
}

export async function createWorkspace(data: CreateWorkspaceWithMaterializedViewDefinitionPayload) {
  try {
    const result = await axiosInstance.post<TypedApiResponse<Workspace>>(`${CODELOGIC_API_BASE_URL}/workspace`, data, {withCredentials: true});
    return result.data;
  } catch (e) {
    return {
      status: 'error',
    } as const;
  }
}

export async function updateWorkspace(data: UpdateWorkspaceWithMaterializedViewDefinitionPayload) {
  try {
    const result = await axiosInstance.put<TypedApiResponse<Workspace>>(`${CODELOGIC_API_BASE_URL}/workspace/${data.id}`, data, {withCredentials: true});
    return result.data;
  } catch (e) {
    return {
      status: 'error',
    } as const;
  }
}

export async function setDefault(workspaceId: string) {
  try {
    const result = await axiosInstance.put<TypedApiResponse<Workspace>>(`${CODELOGIC_API_BASE_URL}/workspace/primary/${workspaceId}`, null, {withCredentials: true});
    return result.data;
  } catch (e) {
    return {
      status: 'error',
    } as const;
  }
}
