import {useEffect, useState} from 'react';
import {IoClose} from 'react-icons/io5';
import {Button, Label} from 'reactstrap';
import SwalBase from 'sweetalert2';
import {useSubscription} from 'react-stomp-hooks';
import withReactContent from 'sweetalert2-react-content';

import {useFeatureFlag} from 'src/hooks';
import {addNamespace, getNamespaces, removeNamespace} from 'src/api/namespace';
import {AsyncSelect} from 'src/components';
import {DragDropFile} from 'src/components/';
import {Namespace, NamespacesMessage} from 'src/types';
import {postNamespaceScan} from 'src/api/serverSideScanning';
import {isError, isSuccess} from 'src/api';
import {FileUploadProgress, useProgress} from 'src/components/FileUploadProgress';

import {ScanModal} from './ScanModal';

const Swal = withReactContent(SwalBase);

const OPTIONS = [{
  label: 'Java',
  value: 'jCape',
}, {
  label: 'DotNet',
  value: 'netCape',
}, {
  label: 'SQL',
  value: 'sqlCape',
}, {
  label: 'Node.js',
  value: 'NodeJsCape',
}];

export const Namespaces = () => {
  const [serverSideScanningEnabled] = useFeatureFlag('FEATURE_FLAG_SERVER_SIDE_SCANNING');
  const [loading, setLoading] = useState(true);
  const [selectedType, setSelectedType] = useState('jCape');
  const [namespaces, setNamespaces] = useState<Array<Namespace>>([]);
  const [namespace, setNamespace] = useState('');
  const {submittingFile, percent, onProgress, callFileSubmit} = useProgress();
  const [submitting, setSubmitting] = useState(false);
  const [namespaceScan, setNamespaceScan] = useState<NamespacesMessage | null>(null);

  const getData = async () => {
    setLoading(true);
    const response = await getNamespaces({});
    if (response.status === 'success' && response.data) {
      setNamespaces(response.data);
    }
    setLoading(false);
  };

  const addNamespaceCall = async (e) => {
    setSubmitting(true);
    const response = await addNamespace([{
      namespace: namespace,
      dataSourceId: selectedType,
    }]);
    setSubmitting(false);
    if (response.status === 'success') {
      setNamespace('');
      await getData();
    }
  };

  const addNamespacesCall = async (namespacesToAdd: Array<Namespace>) => {
    setSubmitting(true);
    const response = await addNamespace(namespacesToAdd);
    setSubmitting(false);
    if (response.status === 'success') {
      await getData();
    }
    setNamespaceScan(null);
  };
  const removeNamespaceCall = async (namespace) => {
    setSubmitting(true);
    const response = await removeNamespace(namespace);
    setSubmitting(false);
    if (response.status === 'success') {
      setNamespace('');
      await getData();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useSubscription('/topic/namespaces', (message) => {
    const response: NamespacesMessage = JSON.parse(message.body);
    if (response.namespaces && response.namespaces[0]) {
      setNamespaceScan(response);
    }
  });

  return (
    <div style={{marginBottom: '1rem', marginLeft: '1rem'}}>
      <div style={{margin: '1rem 0 1rem', display: 'flex', flexFlow: 'column'}}>
        <Label for='addButton' className='form-label'>Add a Namespace</Label>
        <div>
          <input name='addButton' type='text' value={namespace} onChange={(e) => setNamespace(e.target.value)}/>
          <div
            style={{
              lineHeight: 1,
              display: 'inline-block',
              margin: '0 0.5rem 0',
            }}
          >
            <AsyncSelect<{label: string, value: string}>
              options={OPTIONS}
              value={OPTIONS.find((o) => o.value === selectedType)}
              getOptionLabel={({label}) => label}
              getOptionValue={({value}) => value}
              onChange={(data) => {
                const value = data?.value;
                if (value) {
                  setSelectedType(value);
                }
              }}
            />
          </div>
          <Button style={{marginLeft: '1rem'}}
            color='primary'
            disabled={submitting}
            onClick={addNamespaceCall}
          >Add</Button>
        </div>
      </div>
      <div style={{display: 'flex'}}>
        <div className='Table' style={{width: '50%'}}>
          <table className='table' cellSpacing={0} cellPadding={0}>
            <thead>
              <tr>
                <th>
                  Included Namespaces
                </th>
                <th>
                  Data Source
                </th>
                <th>
                </th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
            namespaces.map((n) => (
              <tr key={n.namespace + n.dataSourceId}>
                <td style={{width: '45%'}}>{n.namespace}</td>
                <td style={{width: '45%'}}>{OPTIONS.find((o) => o.value === n.dataSourceId)?.label}</td>
                <td>
                  <button
                    className="btn btn-link"
                    style={{padding: '0rem 0.5rem', fontSize: '1.25rem'}}
                    disabled={submitting}
                    onClick={() => removeNamespaceCall(n)}
                  >
                    <IoClose />
                  </button>
                </td>
              </tr>
            ))
              }
            </tbody>
          </table>
        </div>
        {serverSideScanningEnabled && (
          <div style={{width: '50%', marginLeft: '1rem'}}>
            <DragDropFile
              caption={<>We&apos;ll autodetect the included namespaces and add them to your list</>}
              onUpload={async (files) => {
                const file = files[0];
                const name = file.name;
                const response = await callFileSubmit(file, async (f: File) => {
                  return await postNamespaceScan(file, onProgress);
                });
                if (isSuccess(response)) {
                  await Swal.fire({
                    title: 'Success!',
                    text: `Successfully submitted ${name} for scanning!`,
                    icon: 'success',
                    timer: 1200,
                    showConfirmButton: false,
                  });
                  return response.data;
                } else if (isError(response)) {
                  await Swal.fire({
                    title: 'Error!',
                    text: `Error Scanning ${name} for Namespaces!`,
                    icon: 'error',
                    timer: 1200,
                  });
                }
              }}
            />
          </div>
        )}
      </div>
      {submittingFile && percent && <FileUploadProgress submittingFile={submittingFile} percent={percent}/>}
      {namespaceScan && <ScanModal msg={namespaceScan} onAdd={addNamespacesCall}/>}
    </div>
  );
};
