import React from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import SwalBase from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import {Page} from 'src/components/Page';
import {DragDropFile} from 'src/components/DragDropFile';
import {postAnalyze} from 'src/api/serverSideScanning';
import {isError, isSuccess} from 'src/api';
import {FileUploadProgress, useProgress} from 'src/components/FileUploadProgress';

import {Errors, FormFields} from './types';

const Swal = withReactContent(SwalBase);
export const ScanArtifactsPage = () => {
  const {submittingFile, percent, onProgress, callFileSubmit} = useProgress();
  return (
    <Page title='Upload and Scan Artifacts'>
      <div style={{margin: '1rem', width: '50%'}}>
        <div style={{marginBottom: '1rem'}}>Here you can scan your Java and .Net application artifacts</div>
        <Formik<FormFields> initialValues={{
          application: '',
          database: '',
          files: [],
        }} onSubmit={async (values) => {
          const file = values.files[0];
          const name = file.name;
          const application = values.application;
          const database = values.database;
          const response = await callFileSubmit(file, async (f: File) => {
            return await postAnalyze(file, application, database, onProgress);
          });
          if (isSuccess(response)) {
            await Swal.fire({
              title: 'Success!',
              text: `Successfully submitted ${name} for scanning!`,
              icon: 'success',
              timer: 1200,
              showConfirmButton: false,
            });
            return response.data;
          } else if (isError(response)) {
            await Swal.fire({
              title: 'Error!',
              text: `Error Scanning ${name}!`,
              icon: 'error',
              timer: 1200,
            });
          }
        }} validate={(values: FormFields) => {
          const errors: Errors = {};
          if (!values.application) {
            errors.application = '* required';
          }
          return errors;
        }} validateOnBlur={true}>
          {({
            setFieldValue,
            submitForm,
          }) => (
            <Form>
              <FormGroup>
                <Label for='application'>Application</Label>
                <ErrorMessage
                  className='form-error'
                  name='application'
                  component='div'
                />
                <Input name='application' type='text' tag={Field} />
                <Label>Database</Label>
                <ErrorMessage
                  className="form-error"
                  name="database"
                  component="div"
                />
                <Input name='database' type="text" style={{marginBottom: '1rem'}} tag={Field} />
                <DragDropFile
                  caption={<>We&apos;ll ingest the artifact and add it to a background scan queue.</>}
                  onUpload={async (files) => {
                    setFieldValue('files', files);
                    await submitForm();
                  }} />
              </FormGroup>
            </Form>
          )}
        </Formik>
      </div>
      {submittingFile && percent && <FileUploadProgress submittingFile={submittingFile} percent={percent}/>}
    </Page>
  );
};
