import {Tag} from 'src/components';

import {Props} from './types';

import './styles.css';

export const RelationshipListItem = ({id, name, relation, color: {color, fgColor}, goToNode, parentName}: Props) => (
  <a className="RelationshipListItem-link" href="#" tabIndex={0} onClick={(e) => {
    e.preventDefault();
    e.stopPropagation();
    goToNode(id, name);
  }}>
    <li className="RelationshipListItem">
      <div className="RelationshipListItem-marker"/>
      <div className="RelationshipListItem-content">
        <p className="RelationshipListItem-name">{name}</p>
        <div className="RelationshipListItem-relationship">
          {relation && (
            <Tag
              backgroundColor={color}
              color={fgColor}
              label={relation}
            />
          )}
          {parentName &&
            <Tag
              backgroundColor={'black'}
              color={'white'}
              label={'parent:' + parentName}
              round={false}
            />
          }
        </div>
      </div>
    </li>
  </a>
);
