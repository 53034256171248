import {ReactNode} from 'react';

import {UnorderedList} from './styled';

export const GenericList = <T, >({
  rows,
  renderItem,
  className,
}: {
  rows: Array<T>,
  renderItem: (item: T) => ReactNode,
  className?: string,
}) => (
    <UnorderedList>
      {rows.map((item) => renderItem(item))}
    </UnorderedList>
  );


