import uniqBy from 'lodash/uniqBy';

import {RelationshipRowData} from './types';

const hasRelation = <T extends RelationshipRowData>(x: T): x is T & {relation: string} => typeof x.relation === 'string';

export const buildSelectOptions = (list: Array<RelationshipRowData>) =>
  uniqBy(list, 'relation')
      .filter(hasRelation)
      .map(({relation, color: {color, fgColor}}) => ({
        label: relation,
        value: relation,
        color: fgColor,
        backgroundColor: color || 'none',
      }
      ));
