import {useCallback} from 'react';

import {GenericList} from 'src/components';

import {organizeTree} from './utils';
import {makeRowComponentFn} from './components';
import {Props} from './types';
import './styles.css';

export const NodeHierarchyList = ({
  data,
  rootNodeId,
  onSelectItem = () => {},
  onToggleDetail = () => {},
  disclosedNodes = {},
  selectedNodes = {},
}: Props) => {
  const {nodes: nodeList, endToStartMap} = organizeTree(data);
  const parentNodes = nodeList.filter(({id}) => !endToStartMap[id]);

  const Row = useCallback(makeRowComponentFn(rootNodeId), [rootNodeId]);

  return (
    <div className="NodeHierarchyList">
      <GenericList
        className="NodeHierarchyList_list"
        rows={parentNodes}
        renderItem={(d) => (
          <Row
            key={d.id}
            onSelect={onSelectItem}
            onToggleDetail={onToggleDetail}
            depth={0}
            node={d}
            selectedNodes={selectedNodes}
            disclosedNodes={disclosedNodes}
          />
        )}
      />
    </div>
  );
};
