import {
  Diagram,
  GraphLinksModel,
} from 'src/util/gojs/go';
import {toArray} from 'src/util/gojs';
import {isDefined} from 'src/types/util';

export const commitNodesToDiagram = (diagram: Diagram, {nodes, edges}: {nodes?: Array<any>, edges?: Array<any>}) => {
  if (nodes) {
    const keys = nodes.map(({id}) => id);
    const existingNodeIds = diagram.model.nodeDataArray.map((x) => x.id);
    const filtered = nodes.filter((n) => !existingNodeIds.includes(n.id));
    diagram.model.addNodeDataCollection(filtered);

    const hiddenLinkedEdges = toArray(diagram.links.filter((l) => {
      const k = l.toNode?.key;
      if (k) {
        return keys.includes(k.toString());
      }
      return false;
    }));
    hiddenLinkedEdges.forEach((e) => e.visible = true);
  }


  if (edges) {
    const model = (diagram.model as GraphLinksModel);
    const existingEdgeIds = model.linkDataArray.map((x) => x.id);
    const filtered = edges.filter((n) => !existingEdgeIds.includes(n.id));

    (diagram.model as GraphLinksModel).addLinkDataCollection(filtered);
  }
};

export const removeElementsFromDiagram = (diagram: Diagram, {nodes, edges}: {nodes?: Array<string>, edges?: Array<string>}) => {
  const model = diagram.model as GraphLinksModel;

  if (nodes) {
    const data = nodes.map((k) => model.findNodeDataForKey(k) || undefined).filter(isDefined);

    const unlinkedEdges = toArray(diagram.links.filter((l) => {
      const k = l.toNode?.key;
      if (k) {
        return nodes.includes(k.toString());
      }
      return false;
    }));
    unlinkedEdges.forEach((e) => e.visible = false);

    model.removeNodeDataCollection(data);
  }


  if (edges) {
    const edgeData = edges.map((k) => model.findLinkDataForKey(k) || undefined).filter(isDefined);
    model.removeLinkDataCollection(edgeData);
  }
};
