import {useCallback, useMemo, useState} from 'react';
import identity from 'lodash/identity';
import {ColumnDef} from '@tanstack/react-table';
import {useParams} from 'react-router-dom';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import {FaEllipsisV} from 'react-icons/fa';
import Swal from 'sweetalert2';

import {Layout, ListingPage, RecordDetailsModal} from 'src/components';
import {deleteScanSession, getScanSessions} from 'src/api/scanSpace';
import {PageDataType, ScanSession} from 'src/types';

export const ScanSessionsList = () => {
  const {entityId} = useParams<{entityId: string}>();
  const [sessions, setSessions] = useState<ScanSession[]>([]);
  const [pageData, setPageData] = useState<PageDataType>();
  const [isReloadRequired, setIsReloadRequired] = useState(true);
  const [detailsScanSession, setDetailsScanSession] = useState<ScanSession | undefined>();
  const [isDetailsOpen, setDetailsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const fetchSessions = useCallback(
      async (page: number, pageSize: number, sort: string) => {
        if (!entityId) {
          return;
        }
        setLoading(true);
        const result = await getScanSessions(entityId, page, pageSize, sort);
        setLoading(false);
        if (result && result.data) {
          setSessions(result.data);
          setPageData(result.metaData?.pageData);
        } else {
          setSessions([]);
          setPageData(undefined);
        }
      },
      [isReloadRequired],
  );
  const confirmDeleteScanSession = (scanSessionId: string) => {
    Swal.fire({
      title: 'Do you want to delete this scan session?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteScanSession(scanSessionId);
        if (res.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: 'Scan Session Deleted Successfully',
            icon: 'success',
            timer: 1200,
            showConfirmButton: false,
          });
        }
        setIsReloadRequired(!isReloadRequired);
      }
    });
  };
  const columns = useMemo<ColumnDef<ScanSession>[]>(
      () => [
        {
          header: 'Id',
          accessorKey: 'id',
        },
        {
          header: 'Agent',
          accessorFn: (session) => session.agent.name ?? session.agent.id,
          enableSorting: false,
        },
        {
          header: 'Data Source',
          accessorKey: 'dataSourceId',
        },
        {
          header: 'Started On',
          accessorKey: 'startedOn',
        },
        {
          header: 'Finished On',
          accessorKey: 'finishedOn',
        },
        {
          header: 'Scan Roots',
          accessorFn: identity,
          cell: ({getValue}) => {
            const {includedScans} = getValue();
            return (
              <ul>
                {includedScans.map(({scanRoot}) => (
                  scanRoot ? (<li key={scanRoot.id}>{scanRoot.name}</li>) : undefined
                ))}
              </ul>
            );
          },
          enableSorting: false,
        },
        {
          header: 'Actions',
          accessorFn: identity,
          cell: ({getValue}) => {
            const value = getValue();
            return (<div className="row-actions">
              <UncontrolledDropdown>
                <DropdownToggle color="link">
                  <FaEllipsisV />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => {
                    setDetailsScanSession(value);
                    setDetailsOpen(true);
                  }}>Details</DropdownItem>
                  <DropdownItem onClick={() => confirmDeleteScanSession(value.id)}>
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>);
          },
        },
      ], [],
  );
  return (
    <Layout title={'Scan Sessions'} loading={loading}>
      <RecordDetailsModal isOpen={isDetailsOpen} onClose={() => setDetailsOpen(false)} record={detailsScanSession}/>
      <ListingPage columns={columns} data={sessions} pageData={pageData} fetchData={fetchSessions}/>
    </Layout>);
};
