import {CSSProperties, PropsWithChildren, ReactNode, useRef} from 'react';
import {v4 as uuid} from 'uuid';
import {
  UncontrolledTooltip,
} from 'reactstrap';
import classnames from 'classnames';

import './styles.css';

export const LabeledControl = ({
  id: manualId,
  help,
  layout = 'vertical',
  label,
  children,
  className,
  style,
}: PropsWithChildren<{
  id?: string,
  help?: ReactNode,
  layout?: 'vertical' | 'horizontal',
  label?: string,
  className?: string,
  style?: CSSProperties
}>) => {
  const {current: id} = useRef(help ? (manualId || `id-${uuid().replace(/-/g, '')}`) : undefined);
  return (
    <div
      style={style}
      className={classnames({
        'LabeledControl': true,
        'LabeledControl--horizontal': layout === 'horizontal',
        [className || '']: !!className,
      })}
      id={!label ? id : undefined}
    >
      <div
        className='LabeledControl__label-container'
        id={label ? id : undefined}
      >
        {label ? <label className="LabeledControl__label">{label}</label> : '\u00A0'}
      </div>
      <div className="LabeledControl__control-container">
        {children}
      </div>
      {help && (
        <UncontrolledTooltip delay={{
          show: 4000,
          hide: 800,
        }} target={`#${id}`}>
          {help}
        </UncontrolledTooltip>
      )}
    </div>
  );
};
