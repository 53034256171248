import {AgentSettings, ApiErrorResponse, ApiResponse, ValidationError} from 'src/types';

import {CODELOGIC_API_BASE_URL} from '../config';

import {axiosInstance} from './baseApi';
import {isAxiosError} from './utils';

import {makeResourceListFetch} from './index';

export const getAgentSettings = makeResourceListFetch<AgentSettings>({method: 'GET', path: 'settings/agent-settings'});

export const saveAgentSettings = async (agentSettings: AgentSettings): Promise<ApiResponse | ApiErrorResponse<ValidationError>> => {
  try {
    const response = await axiosInstance.put(`${CODELOGIC_API_BASE_URL}/settings/agent-settings`, agentSettings, {withCredentials: true});
    return response?.data;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse<ValidationError>>(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
};
