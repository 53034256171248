import {COLOR_EXTRACTED_0, COLOR_EXTRACTED_58} from 'src/styles/colors';

export const hexToRGBComponents = (hexColor: string) =>
  (hexColor
      .match(/[a-fA-F0-9]{1,2}/g) || ['00', '00', '00'])
      .map((hex) => parseInt(hex, 16)) as [number, number, number];

/**
 * Use a fast approximation of a luminance function to choose whether black or white will
 * best contrast with a color and return the hex value of the choice.
 *
 * References:
 *   https://www.w3.org/TR/WCAG20/#relativeluminancedef
 *   https://en.wikipedia.org/wiki/Luma_(video)#Rec._601_luma_versus_Rec._709_luma_coefficients
 *   https://www.w3.org/TR/WCAG20/ "Contrast Ratio" and "Relative luminance"
 *
 *   @param r - The red component of an RGB value
 *   @param g - The green component of an RGB value
 *   @param b - The blue component of an RGB value
 */
export const quickContrastingBWColor = (r: number, g: number, b: number) =>
  r * 0.299 + g * 0.787 + b * 0.114 > 186 ? COLOR_EXTRACTED_0 : COLOR_EXTRACTED_58;
