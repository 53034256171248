import {PropsWithChildren} from 'react';
import {Link} from 'react-router-dom';
import {FaExternalLinkAlt} from 'react-icons/fa';

import {
  EXTERNAL_DOCUMENTATION_LINK,
  SEARCH_PATH,
  EXTERNAL_DOCUMENTATION_JAVA_AGENT_INSTALLATION_LINK,
  GETTING_STARTED_PATH,
  SCAN_SPACES_PATH,
  STORE_PATH,
} from 'src/routes';

import './styles.css';

const EXTERNAL_LINK_FRAGMENT = (
  <sup>
    <FaExternalLinkAlt className="GettingStartedGuide__step-external-link-icon"/>
  </sup>
);
export const GettingStartedGuide = () => {
  return (
    <div className='GettingStartedGuide'>
      <div className='GettingStartedGuide__container'>
        <div className="GettingStartedGuide__section">
          <h2>Welcome to CodeLogic!</h2>
          <p>
            CodeLogic analyzes your applications, databases, and shared libraries, providing powerful insights into your dependencies.
          </p>
          <p>
            To return to this page, click
            the <Link to={GETTING_STARTED_PATH}>Getting Started</Link> link in the sidebar.
          </p>
        </div>
        <div className="GettingStartedGuide__section">
          <ol className="GettingStartedGuide__list">
            <Step stepTitle="Create Scan Space" stepNumber={1}>
              <p>
                Scan Spaces are logical partitions in which you can collect scans.
              </p>

              <ul>
                <li>
                Navigate to <Link to={SCAN_SPACES_PATH}>Admin &gt; Scan Spaces</Link> (under &quot;Admin&quot; in the top nav).
                </li>
                <li>
                Add a scan space called &quot;Sandbox&quot; or whatever name suits you.
                </li>
              </ul>
            </Step>
            <Step stepTitle="Create Workspace" stepNumber={2}>
              <p>
                Workspace are used to select and show scan results from Scan Spaces.
              </p>
              <ul>
                <li>
                Navigate to <Link to={SEARCH_PATH}>Search</Link> in the sidebar.
                </li>
                <li>
                Click the current Workspace, shown in the top nav (probably called &quot;Default&quot;).
                </li>
                <li>
                Click &quot;Create Workspace&quot;.
                </li>
                <li>
                Name your Workspace and select the &quot;Sandbox&quot; Scan Space (or whatever name you chose).
                </li>
                <li>
                Check &quot;Include all Scan Space content.&quot; Click &quot;Save&quot; to create the Workspace.
                </li>
              </ul>
            </Step>
            <Step stepTitle="Scan Application" stepNumber={3}>
              <p>
                Next, we&apos;ll scan an application or database.
              </p>
              <ul>
                <li>
                  Navigate to the <Link to={STORE_PATH}>Store</Link> page (link in the sidebar).
                </li>
                <li>
                  In the &quot;Docker&quot; section, click the appropriate &quot;Generate&quot; link for the type of artifact you want to scan.
                </li>
                <li>
                  Follow the instructions to generate a Docker command to run the agent.
                </li>
              </ul>
              <br />
              <p>
                If you can&apos;t run Docker containers with access to your artifact, you can <a
                  target="_blank"
                  href={`${EXTERNAL_DOCUMENTATION_JAVA_AGENT_INSTALLATION_LINK}`} rel="noreferrer">
                    install local agents {EXTERNAL_LINK_FRAGMENT}
                </a>.
              </p>
              <ol>
              </ol>
            </Step>
          </ol>
          <p>
            To dive further into any topic, check out our <a href={EXTERNAL_DOCUMENTATION_LINK}>documentation {EXTERNAL_LINK_FRAGMENT}</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

const Step = ({stepTitle, stepNumber, children}: PropsWithChildren<{stepTitle: string, stepNumber: number}>) => (
  <li className="GettingStartedGuide__step">
    <div className="GettingStartedGuide__step-metadata">
      <span className="GettingStartedGuide__step-number">{stepNumber}</span>
      <span className="GettingStartedGuide__step-number">{stepTitle}</span>
    </div>
    <div className="GettingStartedGuide__step-content">
      <span>
        {children}
      </span>
    </div>
  </li>
);
