import {useCallback, useMemo, useState} from 'react';
import identity from 'lodash/identity';
import {ColumnDef} from '@tanstack/react-table';
import {FaEllipsisV} from 'react-icons/fa';
import styled from 'styled-components';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  TabPane,
  UncontrolledDropdown,
} from 'reactstrap';
import moment from 'moment/moment';
import {IoWarningOutline} from 'react-icons/io5';
import withReactContent from 'sweetalert2-react-content';
import SwalBase from 'sweetalert2';

import {deleteAgent, getAgents, getProperties} from 'src/api/agents';
import {AgentRequest, PageDataType} from 'src/types';
import {ListingPage} from 'src/components';
import {DATETIME_FORMAT} from 'src/constants';

import CreateAgentForm from './CreateAgentForm';
import NewApproveAgentForm from './NewApproveAgentForm';

const Container = styled.div`
  max-width: 300px;
`;
const Swal = withReactContent(SwalBase);

export function AgentRequests({setLoading}: {setLoading: (_: boolean) => void}) {
  const [data, setData] = useState<AgentRequest[]>([]);
  const [pageData, setPageData] = useState<PageDataType>();
  const [agentData, setAgentData] = useState({});
  const [editData, setEditData] = useState(false);
  const [filter, setFilter] = useState<string | undefined>();

  const [isReloadRequired, setIsReloadRequired] = useState(true);
  const [isApprovalFormOpen, setIsApprovalFormOpen] = useState(false);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);

  function SelectStatusFilter() {
    return (
      <Container>
        <Label for="filterValue">Request Status</Label>
        <Input
          type="select"
          name="filterValue"
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {['OPEN', 'APPROVED', 'DENIED', 'REGISTERED'].map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </Input>
      </Container>
    );
  }

  function CreateManualAgent() {
    return (<Button name="createAgent" alt="Create Agent" color="primary" style={{float: 'right'}} onClick={() => openCreateForm()}>Create Agent</Button>);
  }

  const openCreateForm = () => {
    setIsCreateFormOpen(true);
  };

  const openApproveForm = async ({agentId, dataSourceId, dataSourceDisplayName, label, description, requestStatus}) => {
    const {data} = await getProperties({id: agentId});
    setAgentData({
      agentId: agentId,
      agentType: dataSourceId,
      agentTypeName: dataSourceDisplayName,
      label: label,
      description: description,
      requestStatus: requestStatus,
      properties: JSON.stringify(data ? data : {}, null, '\t'),
    });

    setEditData(requestStatus !== 'OPEN');
    setIsApprovalFormOpen(true);
  };

  const handleDelete = async (agent: AgentRequest) => {
    const response = await Swal.fire({
      title: `Are you sure you want to delete agent ${agent.agentId}?`,
      text: 'This agent will no longer be available for use.',
      confirmButtonText: 'Delete',
      showCancelButton: true,
      customClass: 'WorkspaceAlert',
      iconHtml: <IoWarningOutline></IoWarningOutline>,
    });
    if (response.isConfirmed) {
      let reload = false;
      let messageTitle; let messageText; let icon;
      const result = await deleteAgent(agent.agentId);
      if (result.status === 200) {
        messageTitle = 'Success';
        messageText = 'Agent Registration successfully deleted';
        icon = 'success';
        reload = true;
      } else {
        messageTitle = 'Error';
        messageText = `Unable to delete the agent status = '${result.status}', text = '${result.statusText}'.`;
        icon = 'error';
      }
      Swal.fire({title: messageTitle, text: messageText, icon: icon, timer: 1200, showConfirmButton: false});
      if (reload) {
        setIsReloadRequired(!isReloadRequired);
      }
    }
  };

  const columns = useMemo<ColumnDef<AgentRequest>[]>(
      () => [
        {
          header: 'Agent Id',
          accessorKey: 'agentId',
          enableSorting: false,
        },
        {
          header: 'Agent Name',
          id: 'label',
          accessorFn: (row) => {
            return (!row.label) ? row.dataSourceId + ' on ' + row.ipAddress : row.label;
          },
        },
        {
          header: 'Type',
          accessorKey: 'dataSourceDisplayName',
          enableSorting: false,
        },
        {
          header: 'Data Source Id',
          accessorKey: 'dataSourceId',
          enableSorting: false,
        },
        {
          header: 'IP Address',
          accessorKey: 'ipAddress',
          enableSorting: false,
        },
        {
          header: 'Request Status',
          accessorKey: 'requestStatus',
        },
        {
          header: 'Last Updated',
          accessorKey: 'lastUpdated',
          enableSorting: false,
          accessorFn: (ar) => {
            if (ar.lastUpdated) {
              return moment(ar.lastUpdated)
                  .local()
                  .format(DATETIME_FORMAT);
            } else {
              return 'unknown';
            }
          },
        },
        {
          header: 'Actions',
          accessorFn: identity,
          enableSorting: false,
          cell: ({getValue}: {getValue: () => AgentRequest}) => (
            <div className="row-actions">
              <UncontrolledDropdown>
                <DropdownToggle color="link">
                  <FaEllipsisV />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => openApproveForm(getValue())}>
                    {getValue().requestStatus === 'OPEN' ?
                        'Approve/Reject' :
                        'View/Edit'}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleDelete(getValue())}>
                    {'Delete'}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ),
        },
      ],
      [],
  );

  const fetchAgents = useCallback(
      async (page, pageSize, sort = 'label,asc') => {
        setLoading(true);
        const result = await getAgents({
          page,
          pageSize,
          sortBy: sort,
          filter,
        });
        setLoading(false);
        if (result && result.data) {
          setData(result.data);
          setPageData(result.metaData?.pageData);
        } else {
          setData([]);
          setPageData(undefined);
        }
      },
      [isReloadRequired, filter],
  );

  return (
    <TabPane>
      <CreateManualAgent />
      <SelectStatusFilter />
      <ListingPage
        columns={columns}
        data={data}
        pageData={pageData}
        fetchData={fetchAgents}
      />
      <NewApproveAgentForm
        isOpen={isApprovalFormOpen}
        onClose={(reload: boolean) => {
          setIsApprovalFormOpen(false);
          if (reload) {
            setIsReloadRequired(!isReloadRequired);
          }
        }}
        agentData={agentData}
        editData={editData}
      />
      <CreateAgentForm
        isOpen={isCreateFormOpen}
        onClose={(reload: boolean) => {
          setIsCreateFormOpen(false);
          if (reload) {
            setIsReloadRequired(!isReloadRequired);
          }
        }}
      />
    </TabPane>
  );
}
