import {useState} from 'react';

import {ConfirmCancelInput} from 'src/components';
import {ChangeRequestSourceTypes, ChangeRequestSecret} from 'src/types';

export const UpdateSecretButton = ({
  sourceType,
  onUpdateSecret,
}: {
  sourceType: ChangeRequestSourceTypes,
  onUpdateSecret: (_: ChangeRequestSecret) => void,
}) => {
  const [secret, setSecret] = useState<string | undefined>(undefined);

  return (
    <ConfirmCancelInput
      overlay={true}
      position="after"
      promptText='Change secret'
      placeholder="secret..."
      validationMessage="Error: secret may not be empty"
      value={secret}
      onChange={(value) => setSecret(value)}
      onCancel={() => {
        setSecret(undefined);
        return true;
      }}
      onConfirm={() => {
        if (secret === undefined || secret.length === 0) {
          return false;
        }
        onUpdateSecret({
          sourceType,
          secret,
        });

        return true;
      }}
    />
  );
};
