import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const PanelHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const PanelContent = styled.div<{contentHeight?: string}>`
  display: grid;
  height: ${({contentHeight}) => contentHeight || '100%'}
`;

export const Button = styled.button`
  border: 1px solid transparent;
  background-color: transparent;
  padding: 0;

/* &:focus {
    border: 1px solid gray;
  } */
`;

export const ButtonContent = styled.div<{active: boolean}>`
  font-size: 1.2rem;
  padding: 0.0125em 0.25em;
  border-bottom: 2px solid ${({active}) => active ? 'var(--color-text-link)' : 'transparent' };
  color: ${({active}) => active ? 'var(--color-text-link)' : 'none' };
`;
