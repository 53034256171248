import {Panel, GraphObject} from 'gojs';

import {MakeFnInitializers} from '../../types';
import {processRest} from '../../utils';
import {BACKGROUND_COLOR} from '../styles';

const {make} = GraphObject;

export const row = (rest?: MakeFnInitializers) =>
  make(
      Panel,
      Panel.Horizontal,
      {
        background: BACKGROUND_COLOR.row,
      },
      processRest(rest),
  );

