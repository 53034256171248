import {AxiosResponse} from 'axios';
import Swal from 'sweetalert2';
import * as decoder from 'jwt-decode';

import {cookies} from 'src/api/cookies';
import {AuthResponseData, UserRole, ApiErrorResponse} from 'src/types';
import {AUTHENTICATED_COOKIE_NAME, AUTHENTICATED_COOKIE_PATH, ROLES_COOKIE_NAME} from 'src/api/auth';

import {
  CONTACT_ADMIN,
  INVALID_CREDENTIALS_ENTERED,
} from './constants';

export const isSuccess = (response: AxiosResponse<unknown> | AxiosResponse<AuthResponseData>): response is AxiosResponse<AuthResponseData> => {
  return 'status' in response && response.status === 200;
};

export const isError = (response: AxiosResponse<unknown>): response is AxiosResponse<ApiErrorResponse> =>
  typeof response.data === 'object' &&
    response.data !== null &&
    typeof response.data['status'] === 'string' &&
    response.data['status'] === 'error';


export const convertTokenToRoles = (token: string) => {
  const decoded: { roles: Array<{ authority: string }> } = decoder.default(token);
  if (decoded.roles && decoded.roles.length > 0) {
    return decoded.roles.map((e) => {
      if (e.authority === 'ROLE_CodeLogic - Admin') {
        return UserRole.ADMIN;
      } else if (e.authority === 'ROLE_CodeLogic - User') {
        return UserRole.USER;
      } else {
        return UserRole.NONE;
      }
    });
  } else {
    return [];
  }
};

export const convertRolesStringsToUserRoles = (authResponse: AuthResponseData) => {
  const token = authResponse.access_token;
  return convertTokenToRoles(token);
};

export const processAuthResponse = <T>(
  response: AxiosResponse<T> | AxiosResponse<ApiErrorResponse>,
  onLoggedIn: (response: AuthResponseData, roles: Array<UserRole>) => void,
) => {
  if (response && isSuccess(response)) {
    cookies.set(AUTHENTICATED_COOKIE_NAME, 'true', {
      path: AUTHENTICATED_COOKIE_PATH,
    });
    const roles = convertRolesStringsToUserRoles(response.data);
    cookies.set(ROLES_COOKIE_NAME, roles, {
      path: '/',
    });
    onLoggedIn(response.data, roles);
  } else if (isError(response)) {
    if (response.status === 403) {
      Swal.fire('Error', `${response.data.error.message} ${CONTACT_ADMIN}`, 'error');
    } else if (response.status === 401) {
      if ( response.data.error.code === 'REQ_BAD_CREDENTIALS') {
        Swal.fire('Error', INVALID_CREDENTIALS_ENTERED, 'error');
      } else {
        Swal.fire('Error', `${response.data.error.message} ${CONTACT_ADMIN}`, 'error');
      }
    }
  }
};
