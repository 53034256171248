import {useState} from 'react';

export {useLabelColors} from './colors';
export {useTextFilteredList, useTermFilteredList} from './array';
export {useTextFilter, useTermFilter} from './text';
export {useStoredValue, useFeatureFlag, useLocalSetting, cleanUpFeatureFlags} from './storage';
export {useNode, useWorkspaces, useScanSpaceApplicationNames, useResource, useRepositoryUrls} from './api';
export {usePortal} from './portal';
export {useEntitlement} from './entitlements';
export {usePageData} from './metadata';
export {useDiagram} from './gojs';
export {useContextMenuPosition} from './position';
export {useWorkspace, useMaterializedView, useMaterializedViewDefinition} from './resources';

type OrderDirection = 'asc' | 'desc'
export const useSortDirection = (defaultDirection: OrderDirection = 'asc') =>
  useState<'asc' | 'desc'>(defaultDirection);
