import {useEffect, useState} from 'react';

import {DirectRelationshipData} from 'src/types';
import {getDirectRelationships} from 'src/api/dependency';

export const useDirectRelationships = (nodeId?: string) => {
  const [loading, setLoading] = useState(false);
  const [directRelationships, setDirectRelationships] = useState<Array<DirectRelationshipData> | undefined>();

  const fetchDirectRelationshipsByNodeId = async (nodeId: string) => {
    setDirectRelationships(undefined);
    setLoading(true);
    const response = await getDirectRelationships(nodeId);
    if (response?.data?.status === 'success' && response?.data?.data) {
      setDirectRelationships(response.data.data);
    } else {
      setDirectRelationships([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (nodeId) {
      fetchDirectRelationshipsByNodeId(nodeId);
    }
  }, [nodeId]);

  return {loading, directRelationships, fetchDirectRelationshipsByNodeId};
};
