import {TypedApiResponse, ApiErrorResponse, WhereUsedData} from 'src/types';
import {getWhereUsed} from 'src/api/reports';

export const makeTableDataFetcher =
  (updater: (_: {data: Array<WhereUsedData>, metaData?: any}) => void) =>
    async ({
      nodeId,
      nodeTypes,
      relationshipTypes,
      page = 0,
      pageSize = 10000,
    }: {
      nodeId: string,
      nodeTypes?: Array<string>,
      relationshipTypes?: Array<string>
      page?: number,
      pageSize?: number,
    }) => {
      if (!nodeId) {
        return;
      }

      const result = await getWhereUsed(nodeId, {nodeTypes, relationshipTypes, page, pageSize} );

      if (result?.status === 'success' && typeof result.data !== 'undefined') {
        updater({
          data: result.data,
          metaData: result.metaData,
        });
      } else {
        updater({data: []});
      }
    };


export const makeOptionLoader = (
    apiCall: (nodeId: string) => Promise<TypedApiResponse<Array<string>> | ApiErrorResponse>,
    nodeId?: string,
) => async (input: string) => {
  if (!nodeId) {
    return [];
  }

  try {
    const result = await apiCall(nodeId);
    if (result.status === 'success') {
      return (result.data || []).map((s) => ({label: s, value: s}));
    }
    return [];
  } catch (e) {
    return [];
  }
};
