import {ReactNode} from 'react';
import get from 'lodash/get';

import {LicenseType, CustomizableTextKey} from './types';
import {LICENSE_TEXT_MAP} from './constants';

export const makeLicenseTextGetter =
  (licenseType: LicenseType) =>
    (key: CustomizableTextKey, defaultContent?: ReactNode) =>
      get(LICENSE_TEXT_MAP, [licenseType, key], defaultContent || '');
