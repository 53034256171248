import {AxiosResponse} from 'axios';

import {CODELOGIC_API_BASE_URL} from 'src/config';
import {
  GraphData,
  TypedApiSuccessResponse,
  TypedApiErrorResponse,
} from 'src/types';

import {axiosInstance} from './baseApi';
import {isAxiosError} from './utils';

export async function getTopLevelNodeLabels() {
  const endpoint = `${CODELOGIC_API_BASE_URL}/dependency/top-level/labels`;
  return await axiosInstance.get<{data: Array<string>}>(endpoint, {withCredentials: true});
}


export async function getRootDecomposition({
  definitionId,
  direction,
  relationshipFilters,
  nodeFilters,
  pageable: {page = 0, pageSize = 10} = {page: 0, pageSize: 10},
}: {
  definitionId?: string;
  direction?: 'IN' | 'OUT' | 'BOTH';
  relationshipFilters?: Array<string>;
  nodeFilters?: Array<string>;
  pageable?: {
    page: number;
    pageSize: number;
  }
}) {
  const endpoint = `${CODELOGIC_API_BASE_URL}/dependency/decomposition`;
  const requestParams = {
    relFilter: relationshipFilters,
    nodeFilters: nodeFilters,
    definitionId,

    direction,
    page,
    size: pageSize,
  };

  try {
    const response = await axiosInstance.get(endpoint, {withCredentials: true, params: requestParams});
    return response.data;
  } catch (e) {
    return {
      error: e,
      data: {
        containedNodes: [],
        referencedNodes: [],
      },
    };
  }
}

export async function getDecomposition({
  id,
  definitionId,
  direction,
  relationshipFilters,
  nodeFilters,
  pageable: {page = 0, pageSize} = {},
}: {
  id: string;
  definitionId?: string;
  direction?: 'IN' | 'OUT' | 'BOTH';
  relationshipFilters?: Array<string>;
  nodeFilters?: Array<string>;
  pageable?: {
    page?: number;
    pageSize?: number;
  }
}) {
  const endpoint = `${CODELOGIC_API_BASE_URL}/dependency/decomposition/${id}`;
  const requestParams = {
    relFilter: relationshipFilters,
    nodeFilters: nodeFilters,
    definitionId,

    direction,
    page,
    size: pageSize,
  };

  try {
    const response = await axiosInstance.get(endpoint, {withCredentials: true, params: requestParams});
    return response.data;
  } catch (e) {
    return {
      error: e,
      data: {
        containedNodes: [],
        referencedNodes: [],
      },
    };
  }
}

export async function getImpact(id: string): Promise<AxiosResponse> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/dependency/impact/full/${id}/list`;

  try {
    return await axiosInstance.get(endpoint, {withCredentials: true});
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response;
      }
    }
    throw e;
  }
}

export async function getDirectRelationships(id: string): Promise<AxiosResponse> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/dependency/impact/direct/${id}/list`;

  try {
    return await axiosInstance.get(endpoint, {withCredentials: true, params: {
      size: 1000,
    }});
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response;
      }
    }
    throw e;
  }
}


export async function getMatchingNodes(searchTerm: string, definitionId?: string, pageNum = 0, pageSize = 20 ) {
  try {
    const reqString = `${CODELOGIC_API_BASE_URL}/search/global/?searchTerm=${encodeURIComponent(searchTerm)}&page=${pageNum}&size=${pageSize}`;
    const params: any = {};

    if (definitionId) {
      params.definitionId = definitionId;
    }

    return await axiosInstance.get(reqString, {params: params});
  } catch (e) {
    if (isAxiosError(e)) {
      return e.response;
    }
  }
}

export async function getMatchingNodesByType(searchTerm: string, labels: string, definitionId?: string, pageNum = 0, pageSize = 30) {
  try {
    const reqString = `${CODELOGIC_API_BASE_URL}/search/global/?searchTerm=${encodeURIComponent(searchTerm)}&labels=${encodeURIComponent(labels)}&page=${pageNum}&size=${pageSize}`;
    const params: any = {};

    if (definitionId) {
      params.definitionId = definitionId;
    }

    return await axiosInstance.get(reqString, {params: params});
  } catch (e) {
    if (isAxiosError(e)) {
      return e.response;
    }
  }
}

export async function getMatchingNodesTypes(searchTerm: string, definitionId?: string) {
  try {
    const reqString = `${CODELOGIC_API_BASE_URL}/search/global/types?searchTerm=${encodeURIComponent(searchTerm)}`;
    const params: any = {};

    if (definitionId) {
      params.definitionId = definitionId;
    }

    return await axiosInstance.get(reqString, {params: params});
  } catch (e) {
    if (isAxiosError(e)) {
      return e.response;
    }
  }
}

export async function getPrimaryNodeTypesInDefinition(definitionId?: string): Promise<any> {
  try {
    const params :any = {};

    params.size = 1000;
    params.primaryOnly = true;

    if (definitionId) {
      params.definitionId = definitionId;
    }

    const response = await axiosInstance.get(
        `${CODELOGIC_API_BASE_URL}/nodes/types`,
        {
          withCredentials: true,
          params: params,
        },
    );
    return response.data;
  } catch (e) {
    return e;
  }
}

export async function searchWithinChildren(id: string, searchTerm: string) {
  try {
    const reqString = `${CODELOGIC_API_BASE_URL}/search/global/children?searchTerm=${encodeURIComponent(searchTerm)}&node=${encodeURIComponent(id)}&page=0&size=50`;

    const response = await axiosInstance.get(reqString);
    return response;
  } catch (e) {
    if (isAxiosError(e)) {
      return e.response;
    }
  }
}


export async function getContainsPath(id: string): Promise<AxiosResponse> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/dependency/parent/${id}/list`;
  try {
    return await axiosInstance.get(endpoint, {withCredentials: true});
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response;
      }
    }
    throw e;
  }
}

export async function getContainsPathForArray(ids: Array<string>): Promise<AxiosResponse> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/dependency/parent/list`;
  try {
    return await axiosInstance.get(endpoint, {withCredentials: true, params: {
      ids,
    },
    });
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response;
      }
    }
    throw e;
  }
}

export const getWhereUsed = async (id: string) => {
  const endpoint = `${CODELOGIC_API_BASE_URL}/dependency/where-used/${id}`;
  try {
    return (await axiosInstance.get<TypedApiSuccessResponse<GraphData>>(endpoint, {withCredentials: true})).data;
  } catch (e) {
    if (isAxiosError<TypedApiErrorResponse<unknown>>(e)) {
      if (e.response) {
        return e.response.data;
      }
      console.error(e);
    }
    throw e;
  }
};

export async function getImpactAnalysisCsv(
    id: string,
): Promise<any> {
  try {
    const response = await axiosInstance.get(
        `${CODELOGIC_API_BASE_URL}/dependency/impact/full/${id}/list/csv`,
        {
          withCredentials: true,
        },
    );
    return response.data;
  } catch (e) {
    return null;
  }
}

export async function getRelationships(fromId: string, type: string, toId:string): Promise<AxiosResponse> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/dependency/relationships`;
  try {
    return await axiosInstance.get(endpoint,
        {
          withCredentials: true,
          params: {
            fromId,
            type,
            toId,
            page: 0,
            size: 1,
          },
        });
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response;
      }
    }
    throw e;
  }
}

