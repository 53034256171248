import styled from 'styled-components';

export const NodeTreeFilter = styled.div`
  background-color: var(--color-background--standard);
  border-right: 1px solid var(--color-border--standard);
  flex: 1;
  min-width: 15rem;
  overflow-y: scroll;
`;

