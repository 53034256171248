import classnames from 'classnames';
import {InputHTMLAttributes, useEffect, useState} from 'react';
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from 'react-icons/ti';

import {SortOrder} from './types';

export function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value?: string,
  onChange: (value?: string) => void,
  debounce?: number,
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (value !== undefined) {
      const timeout = setTimeout(() => {
        onChange(value || undefined);
      }, debounce);

      return () => clearTimeout(timeout);
    }
  }, [value]);

  return (
    <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
  );
}

export const SortControl = ({
  columnId,
  sortOrder,
  onToggle,
} : {
  columnId: string,
  onToggle: (columnId: string, newOrder: SortOrder | undefined) => void,
    sortOrder?: SortOrder
}) => (
  <button onClick={() => {
    if (sortOrder === 'asc') {
      onToggle(columnId, 'desc');
    } else if (sortOrder === 'desc') {
      onToggle(columnId, undefined);
    } else {
      onToggle(columnId, 'asc');
    }
  }} className={classnames({
    'SortControl': true,
    'btn': true,
    'btn-link': true,
    'SortControl--unsorted': sortOrder === undefined,
  })}>
    {sortOrder === 'asc' ?
      <TiArrowSortedUp fontSize="1.5rem" /> :
        sortOrder === 'desc' ?
          <TiArrowSortedDown fontSize="1.5rem" /> :
          <TiArrowUnsorted fontSize="1.5rem" />
    }
  </button>
);

export const Filter = ({value, onChange}: { value?: string, onChange: (newValue?: string) => void }) => (
  <div>
    <DebouncedInput
      type="text"
      value={value}
      onChange={onChange}
    />
  </div>
);
