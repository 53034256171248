import {useContext, useState} from 'react';
import {Route, Routes, useLocation, useNavigate, Navigate} from 'react-router-dom';
import {Nav as RSNav, NavItem as RSNavItem, NavLink, NavProps} from 'reactstrap';

import * as featureFlags from 'src/feature-flags';
import {makeRelativePathFn} from 'src/util';
import {useFeatureFlag} from 'src/hooks';
import {Layout, NamespacesPage} from 'src/components';
import {AppRoutes, UserRole} from 'src/types';
import {
  ADMINISTRATION_PATH,
  AUTH_PATH,
  AWS_PATH,
  CHANGE_REQUEST_CONFIG_PATH,
  FEATURE_FLAGS_PATH,
  LICENSES_PATH,
  NAMESPACES_ADMIN_PATH,
  SCAN_SPACES_PATH,
  SERVICE_NOW_PATH,
  NOTIFICATIONS_CONFIG_PATH,
  USERS_PATH,
} from 'src/routes';
import {AuthContext} from 'src/context';

import ServiceNowConfig from './ServiceNowConfig';
import {NotificationConfigManagement} from './NotificationManagement';
import AwsConfig from './AwsConfig';
import {UserManagement} from './UserManagement';
import {LicenseTab} from './LicenseTab';
import ScanSpacesTab from './ScanSpacesTab';
import {AuthTab} from './Auth';
import {ChangeRequestTab} from './ChangeRequestTab';
import {FeatureFlagsTab} from './FeatureFlagsTab';

import './administration.css';

const userFacingFeatureFlagCount = Object.values(featureFlags).filter(({devOnly, defaultValue}) => !devOnly && !defaultValue).length;

export const AdministrationRoutes = () => {
  const navigate = useNavigate();
  const [scanSpacesDisplayEnabled] = useFeatureFlag('FEATURE_FLAG_SCAN_SPACE_UI');
  const [licensingEnabled] = useFeatureFlag('FEATURE_FLAG_LICENSING');
  const [isLoading, setIsLoading] = useState(false);
  const {hasRole} = useContext(AuthContext);

  const makeLinkFn = (path: string) => () => navigate(path);

  const NavItem = ({label, path}:{label: string, path: AppRoutes}) => {
    const {pathname} = useLocation();
    return (
      <RSNavItem>
        <NavLink active={pathname.includes(path)} onClick={makeLinkFn(path)}>
          {label}
        </NavLink>
      </RSNavItem>
    );
  };

  const Nav = (props: NavProps) => (
    <RSNav tabs {...props} >
      {scanSpacesDisplayEnabled && (
        <NavItem label="Scan Spaces" path={SCAN_SPACES_PATH}/>
      )}
      <NavItem label="ServiceNow" path={SERVICE_NOW_PATH} />
      <NavItem label="AWS" path={AWS_PATH} />
      <NavItem label="Notifications" path={NOTIFICATIONS_CONFIG_PATH} />
      {hasRole(UserRole.ADMIN) && <NavItem label="Users" path={USERS_PATH} />}
      {hasRole(UserRole.ADMIN) && <NavItem label="Auth" path={AUTH_PATH}></NavItem>}
      <NavItem label="Change Requests" path={CHANGE_REQUEST_CONFIG_PATH}/>
      {licensingEnabled && hasRole(UserRole.ADMIN) && <NavItem label="License" path={LICENSES_PATH} />}
      {userFacingFeatureFlagCount > 0 && (<NavItem label="Feature Flags" path={FEATURE_FLAGS_PATH}/>)}
      <NavItem label="Namespaces" path={NAMESPACES_ADMIN_PATH}/>
    </RSNav>
  );


  const relativePath = makeRelativePathFn(ADMINISTRATION_PATH);

  return (
    <Layout loading={isLoading}>
      <Nav className="AdminTabs" />
      <div className="admin-tab-content">
        <Routes>
          <Route path={relativePath(SERVICE_NOW_PATH)} element={<ServiceNowConfig setLoading={setIsLoading}/>} />
          <Route path={relativePath(AWS_PATH)} element={<AwsConfig setLoading={setIsLoading}/>} />
          <Route path={relativePath(NOTIFICATIONS_CONFIG_PATH)} element={<NotificationConfigManagement />} />
          {hasRole(UserRole.ADMIN) && <Route path={relativePath(AUTH_PATH)} element={<AuthTab />} />}
          {hasRole(UserRole.ADMIN) && <Route path={relativePath(USERS_PATH)} element={<UserManagement setLoading={setIsLoading}/>} />}
          {licensingEnabled && hasRole(UserRole.ADMIN) && <Route path={relativePath(LICENSES_PATH)} element={<LicenseTab />} />}
          {scanSpacesDisplayEnabled &&
            <Route path={`${relativePath(SCAN_SPACES_PATH)}/*`} element={<ScanSpacesTab />} />
          }
          <Route path={relativePath(CHANGE_REQUEST_CONFIG_PATH)} element={<ChangeRequestTab setLoading={setIsLoading} />} />
          <Route path={relativePath(FEATURE_FLAGS_PATH)} element={<FeatureFlagsTab />}/>
          <Route path={relativePath(NAMESPACES_ADMIN_PATH)} element={<NamespacesPage />}/>
          <Route path={relativePath(ADMINISTRATION_PATH)} element={<Navigate to={SCAN_SPACES_PATH} replace={true} />} />
        </Routes>
      </div>
    </Layout>
  );
};
