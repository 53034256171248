import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {useParams} from 'react-router-dom';

import {GraphData} from 'src/types';
import {SelectedIdsContext} from 'src/context';
import {
  LoadingPlaceholder,
  NoDataPlaceholder,
  NodeHierarchyList,
} from 'src/components';

import {NodeTreeFilter as NodeTreeFilterContainer} from './styled';
import {fetchDiagramData} from './utils';

export const NodeTreeFilter = ({id: idProp}: {id?: string}) => {
  const {selectedIdsMap, setSelectedIdsMap} = useContext(SelectedIdsContext);
  const {id: idParam} = useParams<{id: string | undefined}>() ?? {};

  const id = idProp || idParam;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GraphData | undefined>(undefined);
  const [disclosedNodes, setExpandedNodes] = useState<{[key: string]: boolean}>({});

  useEffect(() => {
    (async () => {
      if (id) {
        setLoading(true);
        const newData = await fetchDiagramData(id);
        setData(newData);
        setLoading(false);
        const allToggledOn = newData?.nodes?.reduce((accum, {id}) => ({
          ...accum,
          [id]: true,
        }), {}) ?? {};

        setSelectedIdsMap(allToggledOn);
        setExpandedNodes(allToggledOn);
      }
    })();
  }, [id]);

  return loading ? (
    <LoadingPlaceholder />
  ) : data?.nodes.length ? (
    <NodeTreeFilterContainer>
      <NodeHierarchyList
        selectedNodes={selectedIdsMap}
        disclosedNodes={disclosedNodes}
        onToggleDetail={(changedExpansions) => setExpandedNodes({
          ...disclosedNodes,
          ...changedExpansions,
        })}
        onSelectItem={(changedSelections) => setSelectedIdsMap({
          ...selectedIdsMap,
          ...changedSelections,
        })}
        rootNodeId={id}
        data={data}
      />
    </NodeTreeFilterContainer>
  ) : (
    <NoDataPlaceholder>
      {data && data.nodes.length === 0 ? 'No usage data found' : 'Failed to load data'}
    </NoDataPlaceholder>
  );
};

