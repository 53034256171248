export const CLIPBOARD_AVAILABLE = navigator.clipboard && window.isSecureContext;

export const APPLICATION_GRAPH_POSITION_STORAGE_KEY = 'application-graph-positions';
export const GRAPH_STATE_STORAGE_KEY_PREFIX = 'graph-state';
export const ITEM_REPORT_STATE_STORAGE_KEY_PREFIX = 'item-report';
export const RELATIONSHIP_REPORT_STATE_STORAGE_KEY_PREFIX = 'relationship-report';
export const LAST_WORKSPACE_ID = 'last-workspace-id';
export const LAST_MATERIALIZED_VIEW_DEFINITION_ID = 'last-materialized-view-definition-id';
export const LAST_MATERIALIZED_VIEW_ID = 'last-materialized-view-id';
export const LAST_WORKSPACE_IDS = 'last-workspace-ids';
export const LAST_MATERIALIZED_VIEW_DEFINITION_IDS = 'last-materialized-view-definition-ids';
export const LAST_MATERIALIZED_VIEW_IDS = 'last-materialized-view-ids';
export const CURRENT_QUERY = 'current-advanced-report-query';

export const SIDEBAR_EXPANDED = 'sidebar-expanded';

export const LOCAL_SETTING_KEY_WELCOME_GUIDE = 'welcome-guide-visible';
export const LOCAL_SETTING_KEY_APP_THEME = 'app-theme';
export const LOCAL_SETTING_LEGEND_EXPANDED = 'legend-expanded';
export const LOCAL_SETTING_SIDEBAR_AUTO_TOGGLE = 'sidebar-auto-toggle';
export const WORKSPACE_MENU_RECEIVER_ID = 'WorkspaceMenuReceiver';

export const THEMES = [
  'theme-minimal',
  'theme-minimal-inverse',
  'theme-light',
  'theme-dark',
] as const;

export const ROLES = [{
  label: 'User',
  id: 'USER',
}, {
  label: 'Admin',
  id: 'ADMIN',
}, {
  label: 'Agent',
  id: 'AGENT',
}];

const USER_MANGEMENT_ROLE_IDS = ['USER', 'ADMIN'];

export const USER_MANGEMENT_ROLES = ROLES.filter(({id}) => USER_MANGEMENT_ROLE_IDS.includes(id));

export const SEARCH_NODE = 'UnresolvedReference';

export const STORAGE_HELP_TEXT_SHOWN_KEY = 'release-assistant-show-help-text';
export const STORAGE_NODE_TREE_FILTER_SHOWN_KEY = 'node-tree-filter-show';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATETIME_FORMAT = 'YYYY-MM-DD hh:mm Z';

let calculatedDefaultTheme: typeof THEMES[number] = 'theme-dark';
const chosenTheme = localStorage.getItem(LOCAL_SETTING_KEY_APP_THEME);

if (chosenTheme) {
  calculatedDefaultTheme = chosenTheme as typeof THEMES[number];
} else {
  if (window.matchMedia) {
    const query = window.matchMedia('prefers-color-scheme: dark');
    if (query.matches) {
      calculatedDefaultTheme = 'theme-dark';
    } else {
      calculatedDefaultTheme = 'theme-light';
    }
  } else {
    calculatedDefaultTheme = 'theme-dark';
  }
}

export const DEFAULT_THEME = calculatedDefaultTheme;

export const CHANGE_REQUEST_SECRET_TYPES = [
  {
    label: 'Azure DevOps',
    value: 'AZURE_DEVOPS',
    description: 'The secret value that will need to be set as the X-Azure-Token header in your Azure Devops configuration.',
  },
  {
    label: 'GitHub',
    value: 'GITHUB',
    description: 'The secret that will be used with HMAC to validate messages received from GitHub.',
  },
  {
    label: 'GitLab',
    value: 'GITLAB',
    description: 'The secret that will be compared against the value sent from GitLab.',
  },

] as const;
