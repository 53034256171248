import {useCallback, useEffect} from 'react';

import {Section, Table, Property} from 'src/components';
import {CHANGE_REQUEST_SECRET_TYPES} from 'src/constants';
import {ChangeRequestSecret} from 'src/types';
import {useResource} from 'src/hooks';
import {addSecrets, getConfiguredSecretTypes} from 'src/api/secrets';

import {UpdateSecretButton} from './components';

type ChangeRequestLabel = typeof CHANGE_REQUEST_SECRET_TYPES[number]['label'];
type ChangeRequestValue = typeof CHANGE_REQUEST_SECRET_TYPES[number]['value'];

type ChangeRequestSecretWithId = ChangeRequestSecret & {id: string};

const LABEL_MAP = CHANGE_REQUEST_SECRET_TYPES.reduce((acc, {label, value}) => {
  acc[value] = label;
  return acc;
}, ({} as Record<ChangeRequestValue, ChangeRequestLabel>));

export const SecretsConfigSection = () => {
  const {
    entity: configuredTypes,
    loadEntity: loadConfiguredTypes,
  } = useResource(getConfiguredSecretTypes);

  const onSubmit = useCallback(async (secret: ChangeRequestSecret) => {
    await addSecrets([secret]);
    loadConfiguredTypes();
  }, []);

  useEffect(() => {
    loadConfiguredTypes();
  }, []);


  return (
    <Section
      title="Secrets"
    >
      <Table<ChangeRequestSecretWithId>
        columns={[{
          header: () => 'Related Service',
          id: 'sourceType',
          cell: ({sourceType}) => LABEL_MAP[sourceType],
        }, {
          header: () => 'Secret',
          id: 'secret',
          cell: ({secret}) => (
            <div style={{minWidth: '22em'}}>
              <Property concealed={true} hideLabel={true} label="secret" value={secret}/>
            </div>
          ),
        }, {
          header: () => '',
          id: 'actions',
          cell: ({sourceType}) => (
            <div style={{display: 'flex', minWidth: '12em', justifyContent: 'flex-end'}}>
              <UpdateSecretButton onUpdateSecret={onSubmit} sourceType={sourceType} />
            </div>
          ),

        }]}
        rows={configuredTypes?.map((t) => ({
          ...t,
          id: t.sourceType,
        }))}
      />
    </Section>
  );
};
