import {ChangeRequestSecret} from 'src/types';
import {makeResourceFetch, makeResourceListFetch} from 'src/api';

export const getConfiguredSecretTypes = makeResourceListFetch<Array<ChangeRequestSecret>>({
  path: 'settings/change-request-secrets',
});

export const addSecrets = makeResourceFetch<Array<ChangeRequestSecret>, Array<ChangeRequestSecret>>({
  method: 'PUT',
  path: 'settings/change-request-secrets',
});
