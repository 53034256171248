import {ReactNode, PropsWithChildren} from 'react';
import classnames from 'classnames';

import './styles.css';

export const ValidationMessage = ({
  message,
  children,
  isValid,
  visible,
}: PropsWithChildren<{
  message?: ReactNode,
  isValid?: boolean,
  visible?: boolean,
}>) => (
  <div className={classnames({
    'ValidationMessage': true,
    'ValidationMessage--hidden': !visible,
    'ValidationMessage--valid': isValid === true,
    'ValidationMessage--invalid': isValid === false,
  })}>
    {children || '\u00A0'}
  </div>
);
