import {CODELOGIC_API_BASE_URL} from 'src/config';
import {ServicenowData, ServicenowResponse} from 'src/types';

import {axiosInstance} from './baseApi';
import {isAxiosError} from './utils';

import {makeResourceListFetch} from './index';


const API_ENDPOINT = `${CODELOGIC_API_BASE_URL}/servicenow/instance-configuration`;

export const getConfigurations = makeResourceListFetch<Array<ServicenowData>>({method: 'GET', path: 'servicenow/instance-configuration'});

export async function postConfiguration(
    data: ServicenowData,
) {
  try {
    const reqBody = {...data, scanAfterSave: true};

    const response = await axiosInstance.post<ServicenowResponse>(API_ENDPOINT, reqBody, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
}

export async function validateConfiguration(
    data: ServicenowData,
) {
  try {
    const response = await axiosInstance.post<ServicenowResponse>(`${API_ENDPOINT}/validate`, data, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response;
      }
    }

    throw e;
  }
}

export async function deleteConfiguration(
    url: string,
) {
  try {
    const reqBody = {
      url,
    };
    const response = await axiosInstance.request<ServicenowResponse>({
      method: 'delete',
      url: API_ENDPOINT,
      data: reqBody,
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    if (isAxiosError<ServicenowResponse>(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
}
