import {CSSProperties, useEffect, useRef, useState} from 'react';
import classnames from 'classnames';

import {ConfirmCancelButton, ValidationMessage} from 'src/components';

import './styles.css';

type Props = {
  placeholder?: string,
  className?: string,
  style?: CSSProperties,
  overlay?: boolean,
  onChange: (newValue: string | undefined) => void,
  value?: string,
  validationMessage?: string,
  position?: Position,
}

type ButtonProps = Parameters<typeof ConfirmCancelButton>[0]
type Position = 'before' | 'after';

export const ConfirmCancelInput = ({
  overlay = false,
  position = 'before',
  onOpen = () => true,
  onConfirm = () => true,
  onCancel = () => true,
  onChange = () => {},
  className,
  style,
  value,
  placeholder,
  validationMessage,
  ...rest
}: ButtonProps & Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasFailedConfirm, setHasFailedConfirm] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const {current} = inputRef;
    if (current && isOpen) {
      current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!overlay) {
      return;
    }
    const {current} = ref;
    const {current: content} = contentRef;
    if (current && content && isOpen) {
      requestAnimationFrame(() => {
        const {width} = current.getBoundingClientRect();
        content.style.transform = `translateX(-${width + 5}px)`;
      });
    }
  }, [overlay, isOpen, ref.current, contentRef.current]);

  const content = (
    <div
      ref={contentRef}
      className={classnames({
        'ConfirmCancelInput__content': true,
        'ConfirmCancelInput__content--overlay': overlay,
        'ConfirmCancelInput__content--open': isOpen,
      })}
    >
      <input
        ref={inputRef}
        className="ConfirmCancelInput__input"
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          const {target: {value}} = e;
          onChange(value);
        }}
        type="text"
      />
      <div className="ConfirmCancelInput__validation-message-wrapper">
        <ValidationMessage visible={hasFailedConfirm} isValid={false}>
          {validationMessage}
        </ValidationMessage>
      </div>
    </div>
  );

  return (
    <div
      ref={ref}
      style={style}
      className={classnames({
        ConfirmCancelInput: true,
        [className || '']: !!className,
      })}>
      {position === 'before' ? content : null}
      <div className={classnames({
        'ConfirmCancelInput__controls': true,
        'ConfirmCancelInput__controls--after': position === 'after',
        'ConfirmCancelInput__controls--overlay': overlay,
      })}>
        <ConfirmCancelButton
          {...rest}
          onConfirm={async () => {
            const shouldContinue = await onConfirm();
            if (shouldContinue) {
              setIsOpen(false);
              setHasFailedConfirm(false);
              return true;
            } else {
              setHasFailedConfirm(true);
              return false;
            }
          }}
          onCancel={async () => {
            const shouldContinue = await onCancel();
            if (shouldContinue) {
              setIsOpen(false);
              setHasFailedConfirm(false);
              return true;
            }
            return false;
          }}
          onOpen={async () => {
            const shouldContinue = await onOpen();
            if (shouldContinue) {
              setIsOpen(true);
              return true;
            }
            return false;
          }}
        />
      </div>
      {position === 'after' ? content : null}
    </div>
  );
};

