import {
  ApiErrorResponse,
  GroupNode,
  GroupNodeConnection,
  TypedApiSuccessResponse,
  TypedApiResponse,
} from 'src/types';
import {CODELOGIC_API_BASE_URL} from 'src/config';

import {axiosInstance} from './baseApi';
import {isAxiosError} from './utils';

interface PageableOptions {
  page: number;
  pageSize: number;
}


export async function getGroupingNodes(id: string | null, definitionId: string | null, params?: PageableOptions) {
  let endpoint = `${CODELOGIC_API_BASE_URL}/grouping/nodes/`;
  const requestParams : any = {};

  if (!!id) {
    endpoint = endpoint.concat(id);
  }

  if (params) {
    requestParams.page = params.page;
    requestParams.size = params.pageSize;
  } else {
    requestParams.size = 1000;
  }


  if (definitionId) {
    requestParams.definitionId = definitionId;
  }

  try {
    const response = await axiosInstance.get<TypedApiSuccessResponse<Array<GroupNode>>>(endpoint, {withCredentials: true, params: requestParams});
    return response.data;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse>(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
}

export async function getGroupingNodeReferences(id: string, params?: PageableOptions) {
  let endpoint = `${CODELOGIC_API_BASE_URL}/grouping/nodes/${id}/references`;

  if (params) {
    endpoint = endpoint.concat(
        `?page=${params.page}&size=${params.pageSize}`,
    );
  }

  const response = await axiosInstance.get<TypedApiResponse<Array<GroupNodeConnection>>>(endpoint, {withCredentials: true});
  return response.data.data;
}

export async function getNodesInGroup(id: string, params?: PageableOptions) {
  let endpoint = `${CODELOGIC_API_BASE_URL}/grouping/nodes/`;

  endpoint = endpoint.concat(id);
  endpoint = endpoint.concat('/nodes');

  if (params) {
    endpoint = endpoint.concat(
        `?page=${params.page}&size=${params.pageSize}`,
    );
  }

  try {
    const response = await axiosInstance.get(endpoint, {withCredentials: true});
    return response.data;
  } catch (e) {
    return {
      error: e,
      data: [],
    };
  }
}
