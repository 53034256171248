import {useState} from 'react';
import {Formik, Form, Field} from 'formik';
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader} from 'reactstrap';
import SwalBase from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import {PropertyList} from 'src/components';
import {createAgent} from 'src/api/agents';

const Swal = withReactContent(SwalBase);

interface Errors {
    label?: string;
}

type AgentFormValue = {
  label: string,
  description: string
}


const CreateAgentForm = ({isOpen, onClose}: {isOpen: boolean, onClose: (_: boolean) => void}) => {
  const [agentNameError, setAgentNameError] = useState('');

  const toggle = () => {
    onClose(false);
  };

  const validateForm = (values: AgentFormValue) => {
    const errors: Errors = {};
    if (typeof values.label !== 'string' || values.label.length === 0) {
      errors.label = '* required';
      setAgentNameError(errors.label);
    } else {
      setAgentNameError('');
    }
    return errors;
  };

  const handleSubmit = async (values: AgentFormValue) => {
    try {
      let reload = false;
      const result = await createAgent({
        label: values.label,
        description: values.description},
      );
      if (result.status === 'success') {
        reload = true;
        const agentId = result.data.agentId;
        const password = result.data.password;

        Swal.fire({
          title: 'Success!',
          html: <ConfirmationPopup agentId={agentId} password={password} />,
          width: '45em',
          icon: 'success',
          showConfirmButton: true,
        });
      }
      onClose(reload);
    } catch ({errors}) {
      if (errors && Array.isArray(errors)) {
        errors.forEach((error) => {
          const errorMessage =
                    error.errors === undefined && Array.isArray(error) ?
                        error.map((err) => err.message).join('. ') :
                        error.errors[Object.keys(error.errors)[0]];
          console.error(errorMessage);
        });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} fade={false} modalClassName="height-auto">
      <ModalHeader toggle={toggle}>Create Agent</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            label: '',
            description: '',
          }}
          validate={validateForm}
          onSubmit={handleSubmit}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({isSubmitting}) => (
            <Form>
              <FormGroup>
                <Label for="label">Agent Name</Label>{' '}
                {agentNameError && (<span style={{color: 'red'}}>{agentNameError}</span>)}
                <Input
                  tag={Field}
                  name="label"
                  type="text"
                  component="input"
                />
                <Label for="description">Agent Description</Label>
                <Input
                  tag={Field}
                  name="description"
                  type="textarea"
                  component="textarea"
                />
              </FormGroup>
              <hr/>
              <Button type="submit" color="primary" disabled={isSubmitting}>Save</Button>
              <Button color="secondary" style={{float: 'right'}} disabled={isSubmitting} onClick={toggle}>Cancel</Button>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

const ConfirmationPopup = ({agentId, password}: {agentId: string, password: string}) => (
  <div style={{textAlign: 'left'}}>
    <p style={{marginBottom: '0'}}>
      Please copy the agentId and password to a safe
      location.
    </p>
    <p style={{color: 'orange'}}>
      There is no way to retrieve the password once this dialog box closes.
    </p>
    <div style={{textAlign: 'start'}}>
      <PropertyList properties={{
        'Agent ID': agentId,
        'Password': password,
      }} />
    </div>
  </div>
);

export default CreateAgentForm;
