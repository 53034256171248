export const CATEGORY_ORDER = ['plugins', 'docker', 'windows', 'miscellaneous'] as const;

type CategoryOrderEntry = typeof CATEGORY_ORDER[number]
type CategoryLabelMap = Record<CategoryOrderEntry, { sectionLabel: string, actionLabel: string, }>

export const CATEGORY_METADATA_MAP: CategoryLabelMap = {
  plugins: {
    sectionLabel: 'Plugins | Extensions',
    actionLabel: 'Download',
  },
  docker: {
    sectionLabel: 'Docker',
    actionLabel: 'Generate',
  },
  windows: {
    sectionLabel: 'Windows Installers',
    actionLabel: 'Download',
  },
  miscellaneous: {
    sectionLabel: 'Miscellaneous',
    actionLabel: 'Download',
  },
};


