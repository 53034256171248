import {PropsWithChildren, createContext} from 'react';

import {
  THEMES,
  DEFAULT_THEME,
  LOCAL_SETTING_KEY_APP_THEME,
} from 'src/constants';
import {useLocalSetting} from 'src/hooks';

export type ThemeName = typeof THEMES[number];

export const ThemeContext = createContext<{
  theme: ThemeName,
  setTheme:(_: ThemeName) => void,
      }>({
        theme: DEFAULT_THEME,
        setTheme: () => {},
      });

export const ThemeContextProvider = ({children}: PropsWithChildren) => {
  const [theme, setTheme] = useLocalSetting<ThemeName>(LOCAL_SETTING_KEY_APP_THEME, DEFAULT_THEME);
  return (
    <ThemeContext.Provider value={{
      theme,
      setTheme,
    }}>
      {children}
    </ThemeContext.Provider>
  );
};
