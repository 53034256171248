import './styles.css';
import {ReactNode} from 'react';
import moment from 'moment';

import {Property} from 'src/components/Property';
import {DATETIME_FORMAT, DATE_FORMAT} from 'src/constants';

import {Props} from './types';

const formatValue = (value: ReactNode) => {
  if (typeof value === 'string') {
    const str = value;
    if (str.length <= 30) {
      const date = moment(str);
      if (date.isValid()) {
        if (date.hours() > 0) {
          return date.format(DATETIME_FORMAT);
        } else {
          return date.format(DATE_FORMAT);
        }
      } else {
        return value;
      }
    } else {
      return value;
    }
  } else {
    return value;
  }
};

export const PropertyList = ({properties, displayMissingProperties = true, disableCopy = false}: Props) => (
  <div className="PropertiesList">
    {Object.entries(properties).map(([label, value]) => value !== undefined ? (
      <Property key={label} label={label} value={formatValue(value)} disableCopy={disableCopy}/>
    ) : null,
    )}
  </div>
);
