import {axiosInstance} from 'src/api/baseApi';
import {CODELOGIC_API_BASE_URL} from 'src/config';
import {isAxiosError} from 'src/api/utils';
import {ApiErrorResponse, DiffReportType} from 'src/types';

export async function getDiffJobResultCsv(
    id: string,
    type: DiffReportType,
) {
  try {
    const response = await axiosInstance.get<string>(
        `${CODELOGIC_API_BASE_URL}/diff-view-reporting/${id}/${type}/csv`,
        {withCredentials: true},
    );
    return response;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse>(e)) {
      if (e.response) {
        return e.response;
      }
    }
    throw e;
  }
}
