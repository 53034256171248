import {Modal, ModalBody, ModalHeader} from 'reactstrap';

export const RecordDetailsModal = ({
  isOpen,
  onClose,
  record,
}: {
  isOpen: boolean,
  onClose: (arg0: boolean) => void,
  record: object & {id: string} | undefined,
}) => {
  const toggle = () => {
    onClose(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} fade={false} size="xl" modalClassName="height-auto">
      <ModalHeader toggle={toggle}>Scan Session: {record?.id}</ModalHeader>
      <ModalBody style={{whiteSpace: 'pre-wrap'}}>
        {JSON.stringify(record, null, 2)}
      </ModalBody>
    </Modal>
  );
};

