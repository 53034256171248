import type {Location} from 'react-router-dom';

export const makeRelativePathFn = (parentPath: string) => (path: string) => path.slice(parentPath.length);

export const getTypedFieldFromLocationState = <T, >(location: Location, fieldKey: string, expectedType: 'string' | 'number' | 'boolean') => {
  const {state} = location;
  if (!!state && typeof state === 'object') {
    const fieldValue = state[fieldKey] as unknown;
    if (typeof fieldValue === expectedType) {
      return fieldValue as T;
    } else {
      return undefined;
    }
  }
  return undefined;
};

