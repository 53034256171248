import {UncontrolledTooltip, UncontrolledTooltipProps} from 'reactstrap';
import {useContext} from 'react';

import {AuthContext} from 'src/context';
import {UserRole} from 'src/types';

export const AdminPrivilegesTooltip = (props: UncontrolledTooltipProps) => {
  const {hasRole} = useContext(AuthContext);
  const hasPrivlege = hasRole(UserRole.ADMIN);

  return !hasPrivlege ? (
    <UncontrolledTooltip {...props}>
      Requires `ADMIN` privileges
    </UncontrolledTooltip>
  ) : null;
};

