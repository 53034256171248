import {AxiosResponse} from 'axios';

import {CODELOGIC_API_BASE_URL} from '../config';

import {axiosInstance} from './baseApi';
import {isAxiosError} from './utils';

export async function getFuzzy(id: string): Promise<AxiosResponse> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/fuzzy-match/graph`;

  try {
    return await axiosInstance.get(endpoint, {withCredentials: true,
      params: {
        id,
      },
    });
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response;
      }
    }
    throw e;
  }
}
