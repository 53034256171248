import {FocusEvent, useState, useEffect} from 'react';
import cronstrue from 'cronstrue';
import cron from 'cron-validate';
import Form from 'react-bootstrap/Form';

function CronWidget(props) {
  const [explanation, setExplanation] = useState(() => {
    try {
      return cronstrue.toString(props.value);
    } catch (e) {
      return '';
    }
  });
  if (!props.id) {
    console.error('No id for', props);
    throw new Error(`no id for props ${JSON.stringify(props)}`);
  }
  const {
    id,
    placeholder,
    required,
    value,
    label,
    readonly,
    disabled,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    schema,
    rawErrors = [],
    uiSchema,
  } = props;

  const toExplanationString = (str: string): string => {
    const parts = str.split(' ').filter((_) => _);
    if (parts.length !== 6) {
      return 'Expression Invalid: Should contain 6 groups';
    } else {
      const cronResult = cron(value.replace(/\s+/g, ' ').trim(), {
        override: {
          useSeconds: true,
          useBlankDay: true,
          useAliases: true,
        },
      });
      if (cronResult.isValid()) {
        try {
          return cronstrue.toString(value, {verbose: true});
        } catch (e) {
          return 'Expression Invalid: ';
        }
      } else {
        const errors = cronResult.getError();
        return 'Expression Invalid: ' + errors[0];
      }
    }
  };

  const _onBlur = ({target: {value}}: FocusEvent<HTMLInputElement>) =>
    onBlur(id, value);
  const _onFocus = ({
    target: {value},
  }: FocusEvent<HTMLInputElement>) => onFocus(id, value);

  const inputType = 'text';

  const _onChange = ({target: {value}}) => {
    const explanation = toExplanationString(value);
    setExplanation(explanation);
    onChange(value);
  };

  useEffect(() => {
    if (props.value) {
      const explanation = toExplanationString(props.value);
      setExplanation(explanation);
    }
  }, [props.value]);

  return (
    <Form.Group className="mb-0">
      <Form.Label className={rawErrors.length > 0 ? 'text-danger' : ''}>
        {uiSchema['ui:title'] || schema.title || label}
        {(label || uiSchema['ui:title'] || schema.title) && required ? '*' : null}
      </Form.Label>
      {' -> '}
      <Form.Label className={rawErrors.length > 0 ? 'text-danger' : ''}>
        {explanation}
      </Form.Label>
      <Form.Control
        id={id}
        placeholder={placeholder}
        autoFocus={autofocus}
        required={required}
        disabled={disabled}
        readOnly={readonly}
        className={rawErrors.length > 0 ? 'is-invalid' : ''}
        type={inputType}
        value={value || value === 0 ? value : ''}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}

      />
    </Form.Group>
  );
}

export default CronWidget;
