import styled from 'styled-components';


/* CARD_WIDTH * 2 + ARROW_WIDTH  must equal 100% */
const CARD_WIDTH = '35%';
const ARROW_WIDTH = '30%';

const ARROW_BODY_HEIGHT = 0.25;
const ARROW_HEAD_HEIGHT = 1.325;
const ARROW_LABEL_HEIGHT = 1.5;

const TEXT_OVERFLOW_ELLIPSIS = `
  overflow: hidden;
  text-overflow: ellipsis;
`;


/* NodeRelationshipNodeRow */
export const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: transparent;
`;

/* Card */

const HIGHLIGHT_BORDER_COLOR = 'var(--color-background-attention)';
const BORDER_COLOR = 'var(--color-border--standard)';

export const Container = styled.div<{highlighted: boolean}>`
  display: flex;
  flex-direction: row;
  margin: 0.25rem 0;
  padding: 0.25rem;
  width: ${CARD_WIDTH};
  border: 1px solid var(--color-border--standard);
  border-left: 1px solid ${({highlighted}) => highlighted ? HIGHLIGHT_BORDER_COLOR : BORDER_COLOR};
  background-color: var(--color-background--standard);
`;

export const DataContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
`;

export const TitleRow = styled.div`
  display: flex;
`;

export const Title = styled.span`
  font-size: 1rem;
  ${TEXT_OVERFLOW_ELLIPSIS}
`;

export const MetaDataRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 0.25em;
`;

export const MetaDataRowSection = styled.div`
  display: inline-block;
  font-size: 0.6875rem;
  margin-right: 0.625rem;
  margin-top: 0.125rem;
  &:first-child {
  margin-top: 0rem;
  }

  &:last-child {
    margin-right: 0;
  }
`;

/* ArrowDivider */
export const ArrowDividerContainer = styled.div`
  --arrow-color: var(--color-background--inverse);
  --text-color: var(--color-text--inverse);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
  width: ${ARROW_WIDTH};
`;

export const ArrowCorridor = styled.div`
  position: relative;
  width: 100%;
  height: 1.5rem;
`;

const HALF_ARROW_LABEL_HEIGHT = ARROW_LABEL_HEIGHT / 2;
const HALF_ARROW_BODY_HEIGHT = ARROW_BODY_HEIGHT / 2;
const ONE_THIRD_ARROW_HEAD_HEIGHT = ARROW_HEAD_HEIGHT / 3;
const TWO_THIRDS_ARROW_HEAD_HEIGHT = ARROW_HEAD_HEIGHT / 1.5;

export const ArrowBody = styled.div`
  position: absolute;
  top: calc(50% - ${HALF_ARROW_BODY_HEIGHT}rem);
  bottom: calc(50% - ${HALF_ARROW_BODY_HEIGHT}rem);
  width: calc(100% - ${TWO_THIRDS_ARROW_HEAD_HEIGHT}rem);
  height: ${ARROW_BODY_HEIGHT}rem;
  background-color: var(--arrow-color);
`;

export const ArrowHead = styled.div`
  position: absolute;
  top: calc(50% - ${ONE_THIRD_ARROW_HEAD_HEIGHT}rem);
  bottom: calc(50% - ${ONE_THIRD_ARROW_HEAD_HEIGHT}rem);
  right: 0;
  width: 0;
  height: 0;
  border-top: ${ONE_THIRD_ARROW_HEAD_HEIGHT}rem solid transparent;
  border-bottom: ${ONE_THIRD_ARROW_HEAD_HEIGHT}rem solid transparent;
  border-left: ${TWO_THIRDS_ARROW_HEAD_HEIGHT}rem solid var(--arrow-color);
`;


export const ArrowLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: calc(50%-${HALF_ARROW_LABEL_HEIGHT}rem);
  bottom: calc(50%-${HALF_ARROW_LABEL_HEIGHT}rem);
  left: 10%;
  color: var(--text-color);
  background-color: var(--arrow-color);
  margin: auto;
  padding: 0 0.5rem;
  height: ${ARROW_LABEL_HEIGHT}rem;
  max-width: calc(80% - ${ARROW_HEAD_HEIGHT}rem);

  ${TEXT_OVERFLOW_ELLIPSIS}
`;

export const ArrowLabelText = styled.span`
  vertical-align: middle;
`;
