import {ReactEventHandler} from 'react';

import './styles.css';

export const ModalCloseButton = ({toggle}: {toggle?: ReactEventHandler}) => (
  <button
    onClick={toggle}
    type="button"
    className="link-button close CloseButton"
    aria-label="Close">
    <span aria-hidden="true">×</span>
  </button>
);
