import {useEffect, useRef} from 'react';

import {ToggledSetting} from 'src/components';

import {Props, Option as OptionType, OptionList} from './types';
import {setSelection} from './utils';

import './styles.css';

export const MultiSelectList = <T, >({
  renderOption,
  disabled = false,
  readOnly = false,
  options,
  selectAllLabel = 'Select All',
  onSelectionChanged = () => {},
  maxColumnCount = 3,
  preserveSpacingWhenReadOnly = false,
}: Props<T>) => {
  const ref = useRef<HTMLInputElement>(null);

  const updateOptionsState = (newOpts: OptionList<T>) => {
    onSelectionChanged(newOpts);
  };

  const allSelected = options.every(({selected})=> selected);
  const noneSelected = options.every(({selected})=> !selected);
  const someSelected = !allSelected && !noneSelected;

  useEffect(() => {
    if (ref?.current) {
      if (someSelected) {
        ref.current.indeterminate = true;
      } else {
        ref.current.indeterminate = false;
      }
    }
  }, [someSelected]);

  const selectAll = () => {
    updateOptionsState(setSelection(options, true));
  };
  const unSelectAll = () => {
    updateOptionsState(setSelection(options, false));
  };

  const selectAllAction = allSelected ? unSelectAll : noneSelected ? selectAll : unSelectAll;

  const makeOptionUpdater = (item: OptionType<T>) =>
    () => {
      const updatedIndex = options.indexOf(item);
      const updatedOption = options[updatedIndex];

      updateOptionsState([
        ...options.slice(0, updatedIndex),
        {
          ...updatedOption,
          selected: !updatedOption.selected,
        },
        ...options.slice(updatedIndex + 1),
      ]);
    };

  const optionsCount = options.length;

  const columnLength = Math.ceil(optionsCount / maxColumnCount);
  const columns: Array<Array<OptionType<T>>> = [];
  for (let i = 0; i < maxColumnCount; i++) {
    columns.push(options.slice(i * columnLength, Math.min((i + 1) * columnLength, optionsCount)));
  }

  return (
    <div className="MultiSelectList">
      <div style={{
        display: (readOnly && !preserveSpacingWhenReadOnly) ? 'none' : 'unset',
        visibility: (readOnly && preserveSpacingWhenReadOnly) ? 'hidden' : 'visible',
      }} className="MultiSelectList__header">
        <label className="MultiSelectList__select-all">
          <input ref={ref} disabled={disabled} type="checkbox" checked={allSelected} onChange={selectAllAction} />
          {' '}<span className="MultiSelectList__select-all-help-text">{selectAllLabel}</span>
        </label>
      </div>
      <div className="MultiSelectList__options">
        {columns.map((column, i) => (
          <div key={i} className="MultiSelectList__options__column">
            {column.map((option) => {
              const {id, selected} = option;
              return (
                <ToggledSetting
                  key={id}
                  id={id}
                  disabled={disabled}
                  readOnly={readOnly}
                  preserveSpacingWhenReadOnly={preserveSpacingWhenReadOnly}
                  selected={selected}
                  onSelect={makeOptionUpdater(option)}
                >
                  {renderOption(option)}
                </ToggledSetting>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};
