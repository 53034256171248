import {GraphObject, Iterator} from 'gojs';

type FnParameters = Parameters<typeof goJsMake>


// These types are taken from gojs code. https://github.com/CodeLogicIncEngineering/capeui/blob/integration/client/node_modules/gojs/release/go.d.ts#L16435
// The attempt here is to cover non-standard constructors that GoJS has mapped to strings, but to still attempt to prevent ourselves from using invalid strings.
type StringConstructor =
  | 'ToolTip'
  | 'ContextMenu'
  | 'Button'
  | 'TreeExpanderButton'
  | 'SubGraphExpanderButton'
  | 'ContextMenuButton'
  | 'PanelExpanderButton'
  | 'CheckBoxButton'
  | 'CheckBox';

export type LinkClickedEvent = {
  fromId: string,
  toId: string,
}

type MakeFnParameters = [FnParameters[0] | StringConstructor, ...Omit<FnParameters, '0'>]

const {make: goJsMake} = GraphObject;

export {isNode, isSearchNode, isGroupNode, isLink} from './utils';
export {transaction} from './transaction';

export const make = (...[first, ...args]: MakeFnParameters) => goJsMake(first as any, ...((args || []).filter(Boolean)));

export const toArray = <T>(iterator: Iterator<T>) => {
  const list:Array<T> = [];
  while (iterator.next()) {
    list.push(iterator.value);
  }
  return list;
};

