import styled from 'styled-components';
export const Container = styled.div``;

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  margin-bottom: 1rem;
`;

export const FiltersContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export const ListContainer = styled.div`
  height: 100%;
  width:100%;
  display: flex;
  flex-direction: column;
`;

export const FilterContainer = styled.div`
  flex: 1;
  max-width: 30rem;
  padding-left: 1rem;

  &:first-child {
    padding-left: 0;
  }
`;

export const PagerContainer = styled.div`
  margin-top: 1rem;
`;
