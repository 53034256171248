import {useContext} from 'react';

import {NODE_PATH} from 'src/routes';
import {CLIPBOARD_AVAILABLE} from 'src/constants';
import {
  OverflowMenu,
  Tag,
} from 'src/components';
import {ColorLegendContext} from 'src/context';

import './styles.css';

type Props = {
  name?: string,
  id?: string,
  type?: string,
  showCopy?: boolean,
  identity: string,
}

// only prepend protocol and host if they aren't part of the base url
const NODE_BASE_PATH = NODE_PATH.startsWith('http') ? NODE_PATH : `${window.location.protocol}//${window.location.host}${('/' + NODE_PATH).replace('//', '/')}`;

export const Header = ({name, id, identity, type, showCopy}: Props) => {
  const {
    getLegendEntry,
  } = useContext(ColorLegendContext);

  const {color, fgColor} = type ? getLegendEntry(type) : {fgColor: undefined, color: undefined};

  return (
    <div className="NodeDetailHeader__container">
      <div className="NodeDetailHeader__primary">
        <h1 className="NodeDetailHeader__title" title={name}>{name}</h1>
      </div>
      <div className="NodeDetailHeader__secondary">
        {type && (
          <div className="NodeDetailHeader__secondary__item">
            <Tag
              fontSize="1rem"
              label={type}
              backgroundColor={color}
              color={fgColor}
            />
          </div>
        )}
        {(CLIPBOARD_AVAILABLE && showCopy) && <div className="NodeDetailHeader__secondary__item">
          <OverflowMenu label="Copy" options={[{
            content: 'ID',
            onClick: () => navigator.clipboard.writeText('' + id),
          }, {
            content: 'Identity',
            onClick: () => navigator.clipboard.writeText('' + identity),
          }, {
            content: 'Direct Link',
            onClick: () => navigator.clipboard.writeText(
                `${NODE_BASE_PATH}/${id}`,
            ),
          }]} />
        </div>}
      </div>
    </div>
  );
};

