import {useContext} from 'react';
import SwalBase from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Container,
} from 'reactstrap';
import {IoTrashOutline, IoWarningOutline} from 'react-icons/io5';
import {useNavigate} from 'react-router-dom';

import {Layout} from 'src/components';
import {WorkspaceContext} from 'src/context';
import {WORKSPACE_CREATE_PATH} from 'src/routes';
import {ApiErrorResponse, TypedApiResponse, Workspace} from 'src/types';
import {deleteWorkspace, setDefault} from 'src/api/workspace';
import {useWorkspaces} from 'src/hooks';

import {WorkspaceMenu} from './components';

const MAX_DESCRIPTION_LENGTH_CHARS = 300;
const UNICODE_ELLIPSIS = '…';

const isError = (response: TypedApiResponse<unknown> | ApiErrorResponse | Error): response is ApiErrorResponse => {
  return 'status' in response && response.status === 'error';
};

import './styles.css';

const Swal = withReactContent(SwalBase);

const truncateText = (text: string, maxLen: number) =>
    maxLen < (text.length ?? 0) ?
      text.slice(0, maxLen) + UNICODE_ELLIPSIS :
      text;

export const WorkspacesPage = () => {
  const {loading, workspaces, loadWorkspaces} = useWorkspaces({autoInitialize: true});
  const navigate = useNavigate();
  const {currentWorkspace, setCurrentWorkspace, initializeWorkspace} = useContext(WorkspaceContext);

  const deleteWorkspaceOnClick = async (workspace: Workspace) => {
    const response = await Swal.fire({
      title: `Are you sure you want to delete ${workspace.displayName}?`,
      text: 'Deleting this workspace will remove all of its data.',
      confirmButtonText: 'Delete',
      showCancelButton: true,
      customClass: 'WorkspaceAlert',
      iconHtml: <IoWarningOutline />,
    });

    if (response.isConfirmed) {
      const result = await deleteWorkspace(workspace.id);
      if (result.status === 'error' && isError(result)) {
        if (result.error.message) {
          await Swal.fire({
            title: 'Error!',
            text: `${result.error.message}`,
            icon: 'error',
            timer: 3600,
          });
        } else {
          await Swal.fire({
            title: 'Error!',
            text: `Unable to delete ${workspace.displayName}`,
            icon: 'error',
            timer: 3600,
          });
        }
      } else {
        await initializeWorkspace();
        await loadWorkspaces();
      }
    }
  };

  const setDefaultWorkspaceOnClick = async (workspace: Workspace) => {
    const response = await Swal.fire({
      title: `Are you sure you want to make ${workspace.displayName} the default?`,
      text: 'This workspace will then display as Default.',
      confirmButtonText: 'Default',
      showCancelButton: true,
      customClass: 'WorkspaceAlert',
      iconHtml: <IoWarningOutline />,
    });

    if (response.isConfirmed) {
      const result = await setDefault(workspace.id);
      if (!result || isError(result)) {
        Swal.fire(`Unable to set ${workspace.displayName} as primary`);
      } else {
        await loadWorkspaces();
      }
    }
  };

  const workspaceCols = workspaces?.map((workspace) => {
    const descriptionSummary = truncateText(workspace?.description ?? '', MAX_DESCRIPTION_LENGTH_CHARS);
    const canEdit = !workspace.primary;
    const canDelete = !workspace.primary;
    return (
      <div key={workspace.id} className='WorkspaceCont'>
        <Card key={workspace.id} outline className={workspace.id === currentWorkspace?.id ? 'selected' : ''} onClick={() => {
          setCurrentWorkspace(workspace);
        }}>
          <CardBody>
            <CardTitle tag='div'>
              <h2 className='TitleText'>
                {workspace.displayName}
              </h2>
              <WorkspaceMenu id={workspace.id} onMarkDefault={() => {
                setDefaultWorkspaceOnClick(workspace);
              }} showDefault={canEdit}/>
            </CardTitle>
            <CardText>
              {descriptionSummary}
            </CardText>
            {canDelete && (
              <a onClick={() => deleteWorkspaceOnClick(workspace)} >
                <IoTrashOutline className='TrashIcon' onClick={(e) => {
                  e.stopPropagation();
                  deleteWorkspaceOnClick(workspace);
                }} />
              </a>
            )}
          </CardBody>
        </Card>
      </div>
    );
  });

  return (
    <Layout title="Workspace" loading={loading} options={
      <Button
        onClick={ () => navigate(`${WORKSPACE_CREATE_PATH}`) }
      >Create Workspace</Button>
    }>
      <Container style={{
        display: 'flex',
        flexFlow: 'row wrap',
      }}>
        {workspaceCols}
      </Container>
    </Layout>
  );
};
