import {useEffect, useState, useReducer, useCallback, useContext} from 'react';
import uniq from 'lodash/uniq';
import Swal from 'sweetalert2';
import {
  Button,
  ButtonDropdown,
  InputGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  UncontrolledTooltipProps,
} from 'reactstrap';
import {IoMdClose} from 'react-icons/io';
import {VscDebugRestart} from 'react-icons/vsc';
import {useLocation, useNavigate} from 'react-router-dom';

import {getRootDecomposition, getContainsPathForArray, getPrimaryNodeTypesInDefinition} from 'src/api/dependency';
import {getGroupingNodes} from 'src/api/groups';
import {THEMES} from 'src/styles/colors';
import {
  PageDataType,
  DiagramNode,
  DiagramLink,
} from 'src/types';
import {
  TypeLegend,
  GraphView,
  GridColumnsLayout,
  TagSelect,
} from 'src/components';
import {
  GRAPH_STATE_STORAGE_KEY_PREFIX,
  LOCAL_SETTING_LEGEND_EXPANDED,
} from 'src/constants';
import {SearchPanelResults} from 'src/components/SearchPanelResults';
import {
  colorTag,
  mapContainedNodesToNodes,
  mapGroupData,
} from 'src/state/dependency2';
import {NodeResultObj} from 'src/components/SearchPanelResults';
import {SelectedIdsContext, ThemeContext} from 'src/context';
import {TextSearch} from 'src/components/TextSearch';
import {useQuery} from 'src/hooks/router';
import {useStoredValue} from 'src/hooks';

import {fetchNodeAndPath} from './GraphView/utils';
import {fetchData} from './utils';

import './dependency.css';

export type GraphPageState = {
  'query': string | undefined,
  'labels': Array<string>,
  'activeLabels': Array<string>,
  'pagingSize': number,
  'loadedNodes': Array<NodeResultObj>,
  'pageData': PageDataType,
}

const tooltipOptions: UncontrolledTooltipProps = {
  placement: 'top',
  autohide: false,
  target: '',
  delay: {show: 0, hide: 750},
};

type GraphState = {
  nodes: Array<DiagramNode>,
  edges: Array<DiagramLink>
};

export const DependencyGraph = ({
  currentWorkspaceId,
  currentMaterializedViewDefinitionId,
  currentMaterializedViewId,
}: {
  currentWorkspaceId: string,
  currentMaterializedViewDefinitionId: string,
  currentMaterializedViewId: string,
}) => {
  const {selectedIds, setSelectedIds} = useContext(SelectedIdsContext);
  const {theme: themeName} = useContext(ThemeContext);
  const theme = THEMES[themeName];

  const key = `${GRAPH_STATE_STORAGE_KEY_PREFIX}--${currentMaterializedViewId}`;
  const [{nodes, edges}, setData] = useStoredValue<GraphState>(key, {
    nodes: [],
    edges: [],
  });

  const [showLegend, setShowLegend] = useStoredValue<boolean>(LOCAL_SETTING_LEGEND_EXPANDED, true);

  const selectedNodeIds = nodes.filter(({id}) => selectedIds.includes(id)).map(({id}) => id);
  const selectedEdgeIds = edges.filter(({id}) => selectedIds.includes(id)).map(({id}) => id);

  const selectedNodes = nodes.filter(({id}) => selectedNodeIds.includes(id));

  const focusedNode = selectedNodes.length ? selectedNodes[0] : undefined;

  const [dropdownOpen, setOpen] = useState(false);
  const [searchScope, setSearchScope] = useState('all');
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const {get} = useQuery();
  const entityId = get('entityId');
  const {pathname} = useLocation();
  const navigate = useNavigate();

  type GraphPageAction =
    | {
      type: 'fetchPage',
      payload:number

      }
    | {
      type: 'addNodes',
      payload: {
        nodes: Array<NodeResultObj>,
        pageData: PageDataType,
        labels: Array<string>,
      }
    }
    | {
      type: 'replaceNodes',
      payload: {
        nodes: Array<NodeResultObj>,
        pageData: PageDataType,
        labels: Array<string>,
      }
    }
    | {
      type: 'updateActiveLabels',
      payload: Array<string>
    }
    | {
      type: 'updateLabels',
      payload: Array<string>
    }
    | {
      type: 'query',
      payload: {
        term: string
      }
    }
    | { type: 'reset' }

  const [pageState, dispatch] = useReducer((state: GraphPageState, action: GraphPageAction) => {
    switch (action.type) {
      case 'fetchPage':
        return {
          ...state,
          pageData: {
            ...state.pageData,
            currentPage: action.payload,
          },
        };
      case 'addNodes':
        return {
          ...state,
          loadedNodes: [...state.loadedNodes, ...action.payload.nodes],
          pageData: action.payload.pageData,
          labels: [...action.payload.labels],
        };
      case 'replaceNodes':
        return {
          ...state,
          loadedNodes: [...action.payload.nodes],
          pageData: action.payload.pageData,
          labels: [...action.payload.labels],
        };
      case 'updateActiveLabels':
        return {
          ...state,
          activeLabels: action.payload,
          loadedNodes: [],
          pageData: {
            ...state.pageData,
            currentPage: 0,
          },
        };
      case 'updateLabels':
        return {
          ...state,
          labels: action.payload,
        };
      case 'query':
        return {
          ...state,
          query: action.payload.term,
        };
      case 'reset':
      default:
        return {
          query: undefined,
          labels: [],
          activeLabels: [],
          pageNumber: 0,
          pagingSize: 20,
          loadedNodes: [],
          pageData: {
            currentPage: 0,
            resultsOnPage: 0,
            totalResults: 0,
          } as PageDataType,
        };
    }
  }, {
    query: undefined,
    labels: [],
    activeLabels: [],
    pageNumber: 0,
    pagingSize: 20,
    loadedNodes: [],
    pageData: {
      currentPage: 0,
      resultsOnPage: 0,
      totalResults: 0,
    } as PageDataType,
  });
  const {pageData} = pageState;

  const handleTextSearch = (term: string) => {
    dispatch({type: 'query', payload: {term}});
  };

  const handleSelectFilter = (terms: Array<string>) => {
    dispatch({type: 'updateActiveLabels', payload: terms});
  };

  useEffect(() => {
    (async () => {
      try {
        if (currentMaterializedViewDefinitionId) {
          const result = await getPrimaryNodeTypesInDefinition(currentMaterializedViewDefinitionId);
          dispatch({type: 'updateLabels', payload: result.data});
        }
      } catch (e) {
        dispatch({type: 'updateLabels', payload: []});
        console.error(e);
      }
    })();
  }, [currentMaterializedViewDefinitionId]);

  useEffect(() => {
    closeSearchPanel();
  }, [currentWorkspaceId]);


  useEffect(() => {
    (async () => {
      const {
        query,
        activeLabels,
        pageData: {
          currentPage,
        },
      } = pageState;

      if (!query || !currentMaterializedViewDefinitionId) {
        return;
      }

      const {
        nodes,
        pageData,
        labels,
      } = await fetchData({
        definitionId: currentMaterializedViewDefinitionId,
        query,
        labels: activeLabels,
        pageSize: 20,
        pageNumber: currentPage,
      });

      dispatch({
        type: currentPage === 0 ? 'replaceNodes' : 'addNodes',
        payload: {
          nodes: [...nodes],
          labels,
          pageData,
        },
      });
      openSearchPanel();
    })();
  }, [pageState.activeLabels, pageState.query, pageState.pageData.currentPage]);

  const closeSearchPanel = () => {
    if (!isPanelOpen) return;

    setIsPanelOpen(false);
    dispatch({type: 'reset'});
  };

  const submitNodeSearch = async (selectedNodes: Array<NodeResultObj>) => {
    setData({
      nodes: [],
      edges: [],
    });

    if (selectedNodes && selectedNodes.length > 0) {
      const nodeResults = await getContainsPathForArray(selectedNodes.map((it) => it.id));
      let results : any;
      if (nodeResults.data.status === 'success') {
        results = nodeResults.data.data;
      } else {
        if (nodeResults.status === 404) {
          Swal.fire({
            title: 'No dependency node found',
            text: 'This may occur when no incoming relationships exist to the item.',
          });
        } else {
          const message = nodeResults.data.error ?
            nodeResults.data.error.message :
            'unknown server error';

          Swal.fire({
            title: 'An error occurred during search.',
            text: message,
          });
        }

        results = {
          id: selectedNodes[0].id,
          name: selectedNodes[0].name,
          type: selectedNodes[0].type,
          containedNodes: [],
          referencedNodes: [],
          nodes: [],
          relationships: [],
        };
      }

      const nodes = results.nodes.map((n) => ({
        ...n,
        metadata: {
          type: n.primaryLabel,
          identity: n.identity,
        },
        text: n.name,
        isHighlighted: selectedNodes.some((n2) => n.id === n2.id),
        category: results.relationships.some((r) => r.startId === n.id) || n.relationshipCount > 0 ? 'parent' : '',
      }));

      const relationships = results.relationships.map((r) => ({
        ...r,
        id: r.id ? r.id : `${r.startId}--${r.endId}`,
        from: r.startId,
        to: r.endId,
      }));

      setData({
        nodes,
        edges: relationships,
      });
    }
  };

  const resetGraph = async () => {
    setIsPanelOpen(false);
    setData({
      nodes: [],
      edges: [],
    });

    const data = await getRootDecomposition({
      definitionId: currentMaterializedViewDefinitionId || undefined,
    });
    const groupData = await getGroupingNodes(null, currentMaterializedViewDefinitionId || null, {page: 0, pageSize: 1000});

    if (groupData.status !== 'success') {
      return;
    }

    if (!groupData.data) {
      // what now?
      return;
    }

    const groupNodes = mapGroupData(groupData.data);
    const containedNodes = mapContainedNodesToNodes(data.data.containedNodes);
    const nodes = [...groupNodes, ...containedNodes];
    // This sadly doesnt come from the results
    nodes?.filter((n) => !n.isGroupNode).forEach((n) => n.isScanRoot = true);
    const edges: Array<any> = [];

    if (entityId) {
      const {nodes: ns, edges: es} = await fetchNodeAndPath(entityId);
      nodes.push(...ns.filter((n) => !nodes.find((it) => it.id === n.id)));
      edges.push(...es);

      navigate(pathname, {replace: true});
    }

    setData({
      nodes,
      edges,
    });

    dispatch({type: 'reset'});
  };

  useEffect(() => {
    // We use resetGraph here to initialize a graph, in
    // the case where the user has no stored graph and the storage
    // target is not 'generic'
    if (window.localStorage.getItem(key) === null) {
      resetGraph();
    }
  }, [resetGraph, key]);

  const convertTypeToFilter = useCallback((type: string) => {
    const {color, fgColor} = colorTag(type, {
      type: 'node',
      transparency: false,
      themeName,
      theme,
    });
    return {
      backgroundColor: color,
      color: fgColor,
      label: type,
      value: type,
    };
  }, [colorTag, theme]);


  const toggleDropDown = () => setOpen(!dropdownOpen);

  function openSearchPanel() {
    setIsPanelOpen(true);
    setData({
      nodes: [],
      edges: [],
    });
  }

  const searchHeader = (
    <div className="page-header-left">
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', maxWidth: '40rem', minWidth: '10rem'}}>
          <h1 className="title">Search</h1>
          <InputGroup>
            <ButtonDropdown
              addonType='prepend'
              isOpen={dropdownOpen}
              toggle={toggleDropDown}
              className='search-dropdown'
            >
              <DropdownToggle caret variant='link'>
                {searchScope === 'all' ?
                      'All' :
                      'In item'}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => setSearchScope('all')}
                >
                      All
                </DropdownItem>
                <DropdownItem
                  onClick={() => setSearchScope('node')}
                  disabled={!focusedNode}
                >
                      Within item
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
            <TextSearch
              onSearch={handleTextSearch}
            />
            <Button
              id='resetMap'
              color="secondary"
              className="search-btn-drop"
              onClick={resetGraph}
            >
              <VscDebugRestart />
            </Button>
            <UncontrolledTooltip {...tooltipOptions} target='resetMap'>
              <span>Reset Map</span>
            </UncontrolledTooltip>
          </InputGroup>
        </div>
        {showLegend ? (
          nodes && nodes.length > 0 && (
            <div style={{
              zIndex: 3,
              position: 'relative',
              // corresponds to probable width of TypeLegend
              minWidth: '12.5rem',
            }}>
              <TypeLegend
                onHide={setShowLegend.bind(null, false)}
                nodeTypes={uniq(nodes.map(({metadata: {type}}) => type).filter((type) => !!type) as Array<string>)}
                linkTypes={uniq(edges.map(({type}) => type).filter((type) => !!type ))}
              />
            </div>
          )
        ) : (
          <Button
            color="link"
            className="search-btn-drop"
            onClick={setShowLegend.bind(null, !showLegend)}
          >
            Show Legend
          </Button>
        )}
      </div>
    </div>
  );

  const panel = (
    <div className="search-results-container">
      {isPanelOpen && (
        <div style={{
          display: 'flex',
          flexFlow: 'row',
          position: 'sticky',
          zIndex: 1,
          top: 0,
          marginRight: '0.25em',
        }}>
          <InputGroup className="tag-select-container">
            <TagSelect
              placeholder="filter results by type"
              onChange={(l) => handleSelectFilter([...l.map((v) => v.value)])}
              value={pageState.activeLabels.map(convertTypeToFilter)}
              options={pageState.labels.map(convertTypeToFilter)}
            />
          </InputGroup>
          <button className="btn btn-outline"
            style={{
              marginLeft: '0.5rem',
              alignSelf: 'flex-start',
            }}
            onClick={closeSearchPanel}>
            <IoMdClose />
          </button>
        </div>
      )}
      <SearchPanelResults
        searchTerm={pageState.query}
        nodeResults={pageState.loadedNodes}
        submitNodeSearch={submitNodeSearch}
      />
      {pageData && !!pageData.totalPages && typeof pageData.startingResult !== undefined && pageData.totalPages > 1 && (
        <div className="search-pager">
          {pageState.loadedNodes.length < pageData.totalResults && (
            <Button color="link" onClick={()=>{
              dispatch({type: 'fetchPage', payload: pageData.currentPage + 1});
            }}>
              Load More
            </Button>
          )}
          <p className="pager-results" >Showing:  1 - {pageState.loadedNodes.length} of {pageData.totalResults}</p>
        </div>
      )}
    </div>
  );

  return (
    <>
      {searchHeader}
      <div style={{position: 'relative', flex: 1}}>
        <GridColumnsLayout columnRatios={isPanelOpen ? [2, 5] : [1]}>
          {isPanelOpen && panel}
          <div style={{position: 'relative'}}>
            <GraphView
              setData={setData}
              selectedElements={{
                selectedNodeIds,
                selectedEdgeIds,
              }}
              onUpdateElementSelections={({selectedEdgeIds, selectedNodeIds}) => setSelectedIds([...selectedNodeIds, ...selectedEdgeIds])}
              data={{
                nodes,
                edges,
              }}
            />
          </div>
        </GridColumnsLayout>
      </div>
    </>
  );
};
