import {AxiosResponse} from 'axios';
import {Diagram as GOJSDiagram, GraphLinksModel} from 'gojs';

import {CHANGE_REQUEST_SECRET_TYPES, THEMES} from 'src/constants';
import * as appRoutes from 'src/routes';

import {HttpMethod} from './primitive';

export type AppRouteKeys = keyof typeof appRoutes
export type AppRoutes = typeof appRoutes[AppRouteKeys];

// types for servicenow config

export interface ServicenowData {
  url: string;
  username: string;
  password: string;
}

export interface ServicenowResponse {
  status: 'success';
  data: Array<ServicenowData>;
}

export interface ApiResponse {
  status: string;
}

export interface ApiSuccessResponse {
  status: 'success',
  data: any,
  metaData: any,
}


export interface ApiErrorResponse<T = undefined> {
  status: 'error',
  error: T extends undefined ? ApiError : ApiErrorWithDetails<T>
}


export type ValidationError = {field: string, message: string}

export interface ApiError {
  code: string,
  message: string,
}

export interface ApiErrorWithDetails<T = unknown> {
  code: string,
  message: string,
  details?: Array<T>
}


type StatusName = 'success' | 'error'

export interface TypedApiResponse<T, U = StatusName> {
  status: U;
  data?: T;
  metaData?: MetaDataType;
}

export type TypedApiErrorResponse<T> = TypedApiResponse<T, 'error'>
export type TypedApiSuccessResponse<T> = TypedApiResponse<T, 'success'>

export type ApiResponseWithId = TypedApiSuccessResponse<{id: string}>


export type WhereUsedData = {
    fromItem: {
      id: string,
      name: string
      shortName?: string
      type: string,
      scanRootId: string,
      scanRootName: string,
      scanRootIdentity: string
    },
    toItem: {
      id: string,
      name: string,
      shortName?: string
      type: string,
      scanRootId: string,
      scanRootName: string,
      scanRootIdentity: string
    },
    relationshipType: string
}

export type WhereUsedResponse = TypedApiSuccessResponse<Array<WhereUsedData>>

export interface MaterializedView {
  id: string;
  buildStarted: string;
  buildEnded: string;
  buildFailed: string;
}

export type MaterializedViewStatusMessage = {
  workspaceId: string,
  materializedView: MaterializedView,
}

export type NamespacesMessage = {
  filename: string,
  namespaces: Array<string>,
  agentMetaData: AgentMetaData,
}

export type AgentMetaData = {
  dataSourceId: string,
}

export interface MaterializedViewDefinition {
  id: string;
  primary: boolean;
  frozen?: boolean;
  type: 'WORKSPACE' | 'RELEASE_ASSISTANT';
  displayName?: string;
  headSha?: string;
  excludedScanContexts?: Array<string>;
  includedScanContexts?: Array<string>;
  scanSpaceApplications?: IdToStringList;
}

export interface IdToStringList {
  [id: string]: Array<string> | null
}

export type CreateReleaseAssistantJobPayload = {
  leftMaterializedViewDefinitionId: string,
  rightMaterializedViewDefinitionId: string,
  type:
    | 'RELEASE_ASSISTANT'
    | 'UPGRADE_ASSISTANT'
    | 'CHANGE_REQUEST'
};

export type ComparisonJob = {
  id: string,
  workspaceId: string,
  displayName: string,
  pullRequestTitle: string | undefined,
  type: string,
  createdBy: string
  createdOn: string,
  status: string,
  leftComparisonMaterializedViewDefinition: MaterializedViewDefinition,
  rightComparisonMaterializedViewDefinition: MaterializedViewDefinition,
  diffMaterializedViewDefinition: MaterializedViewDefinition,
  currentStep: number,
  totalSteps: number,
}

export type ComparisonJobById = Omit<ComparisonJob,
  'leftComparisonMaterializedViewDefinition' |
  'rightComparisonMaterializedViewDefinition' |
  'diffMaterializedViewDefinition'> & {
  displayName: string,
  pullRequestTitle: string | undefined,
  leftComparisonMaterializedViewDefinitionId: string,
  rightComparisonMaterializedViewDefinitionId: string,
  diffMaterializedViewDefinitionId: string,
}


export type ReleaseAssistantJobResult = unknown

export interface ImpactScore {
  id: string;
  name: string;
  identity: string;
  type: string;
  inboundRelationshipCount: number;
  properties: Map<string, any>;
}

export interface EntityStat {
  id: string;
  entityId: string;
  value: string;
  name: string;
  timestamp: string;
}

export interface GroupNode {
  id: string;
  name: string;
  identity: string;
  type: string;
  groupedNodeCount: number;
  childGroupingCount: number;
  properties: Map<string, any>;
}

export type GroupNodeSearchResult = {
  id: string;
  name: string;
  identity: string;
  primaryLabel: string;
}


export interface GroupNodeConnection {
  fromGroupId: string;
  toGroupId: string;
  direction: 'IN' | 'OUT';
  count: number;
}

export interface AgentScanInfo {
  lastScan: number,
  name: string,
}

export interface ApplicationStats {
  nodeTypeCounts: any;
  relationshipTypeCounts: any;
  agentTypeDates: Record<string, AgentScanInfo>;
  lastAgentDate: number;
}

export interface ScanSpace {
  id: string,
  displayName: string,
  description: string,
  primary: boolean,
}

export interface ScanMetadata {
  id: string,
  scanSpaceId: string,
  scanContextId: string,
  agentId: string,
  dataSourceId: string,
  scanStartedOn: string
}

export type ScanRoot = {
  scanContextId: string,
  name: string,
  applicationNames: Array<string>,
  gitHeadSHA: string,
  gitBranch: string,

  // TODO: convert this to our date type?,
  finishedOn: string
}

export interface IncludedScanRoot {
  name: string,
  sha1: string
}

export interface IncludedScan {
  nestedScan: boolean,
  scanContextId: string,
  scanRoot: IncludedScanRoot
  scanStartedOn: string,
  stats?: {
    [key: string]: string
  }
}

export interface ScanSessionAgent {
  id: string,
  name: string
}

export interface ScanSession {
  id: string,
  scanSpaceId: string,
  agent: ScanSessionAgent,
  dataSourceId: string,
  startedOn: string,
  finishedOn: string,
  fingerprint: string,
  expectedScanCount: number,
  relatedHashes: Array<string>,
  applicationNames: Array<string>,
  includedScans: Array<IncludedScan>
}

export interface Workspace {
  id: string,
  displayName: string,
  description: string,
  primary: boolean,
  comparison?: boolean,
  visible: boolean
  version: number,
  materializedViewDefinition: MaterializedViewDefinition,
  materializedViewDefinitions: Array<MaterializedViewDefinition>,
}

export type UpdateMaterializedViewDefinitionPayload = Omit<MaterializedViewDefinition, 'primary' | 'frozen'>
export type CreateMaterializedViewDefinitionPayload = Omit<UpdateMaterializedViewDefinitionPayload, 'id' >

export type UpdateWorkspacePayload = Omit<Workspace, 'materializedViewDefinitions' | 'materializedViewDefinition' | 'primary' | 'frozen' | 'visible' | 'version'>;
export type UpdateWorkspaceWithMaterializedViewDefinitionPayload = UpdateWorkspacePayload & {
  materializedViewDefinition: UpdateMaterializedViewDefinitionPayload
};

export type CreateWorkspaceWithMaterializedViewDefinitionPayload = Omit<UpdateWorkspacePayload, 'id'> & {
  materializedViewDefinition: CreateMaterializedViewDefinitionPayload
}

// types for aws config

export interface AwsData {
  accountId: string;
  accessKey: string;
  secretKey: string;
}

export interface AwsResponse {
  status: string;
  data: any;
}

// types for auth

export interface AuthData {
  email?: string;
  password?: string;
}

export interface AuthResponse extends AxiosResponse {
  status: number;
  data: AuthResponseData;
}

export interface AuthResponseData {
  // eslint-disable-next-line camelcase
  access_token: string;
  // eslint-disable-next-line camelcase
  expires_in: number;
  // eslint-disable-next-line camelcase
  token_type: string;
  userId: string;
}

// types for node detail

export type NodeDetailParams = {
  nodeId: string;
}

// types for search

export interface NodeDetails {
  dataType: string;
  extendedPropertiesKey: string;
  id: string;
  identity: string;
  name: string;
  direction: string;
}

export type NodeDetailsWithProperties = NodeDetails & { properties: Record<string, any>}

export interface ContainedNode {
  containedNodes: NodeDetails[];
  containedType: string;
  id: string;
}

export interface ReferencedNode {
  referencedNodes: NodeDetails[];
  referencedType: string;
}

export interface NodeData {
  containedNodes: ContainedNode[];
  referencedNodes: ReferencedNode[];
  id: string;
  identity: string;
  name: string;
  type: string;
  properties: Record<string, any>;
}

export interface RelationshipData {
  type: string;
  properties: Record<string, any>;
}

export type GraphData = {
  nodes: ListNodeData[];
  relationships: ListRelationshipData[];
}

export type ListNodeData = {
  id: string;
  key?: string;
  identity: string;
  name: string;
  shortName?: string;
  primaryLabel: string;
  properties: Record<string, any>;
  [key: string]: unknown;
}

export type DirectRelationshipData = {
  node: ListNodeData,
  parentNode?: ListNodeData,
  direction: 'IN' | 'OUT',
}

export type ListRelationshipData = {
  id: string;
  identity: string;
  startId: string;
  endId: string;
  type: string;
  group: boolean;
  contains: boolean;
  [key: string]: unknown;
}

export type DiagramNode = {
  isGroupNode?: boolean,
  metadata: {
    type?: string,
    identity: string,
  },
  text: string
  name?: string,
  id: string,
  category?: string,
  [key: string]: unknown,
}

export type DiagramLink = {
  from: string,
  to: string,
  id: string,
  key: string,
  type: string,
  [key: string]: unknown,
}
export type Diagram = GOJSDiagram & {model: GraphLinksModel}

export type ListEdgeData = ListRelationshipData;

// Types for Auth context

export interface AuthContextType {
  isLoggedIn: boolean;
  setIsLoggedIn: (arg0: boolean) => void;
  roles: Array<UserRole>;
  setRoles: (userRoles: Array<UserRole>) => void;
  hasRole: (userRole: UserRole) => boolean;
}

// Types for Dependency context

export type NodeLabelColor = { type: string, color: string, fgColor: string }

export type ColorLegend = Record<string, NodeLabelColor>

type ThemeKeys = typeof THEMES[number];
export type ThemeColorLegendMap = {[P in ThemeKeys]: ColorLegend}

export interface ColorLegendContext {
  colorLegend: ThemeColorLegendMap;
  getLegendEntry: (tag: string, type?: 'node' | 'edge') => NodeLabelColor;
}

// Types for API meta data

export interface PageDataType {
  currentPage: number;
  groupedBy?: string;
  groupsOnPage?: number;
  pageSize?: number;
  resultsOnPage: number;
  startingResult?: number;
  totalPages?: number;
  totalResults: number;
}

export interface MetaDataType {
  pageData: PageDataType;
}

export enum RequestStatus {
  // eslint-disable-next-line no-unused-vars
  OPEN = 'OPEN',
  // eslint-disable-next-line no-unused-vars
  APPROVED = 'APPROVED',
  // eslint-disable-next-line no-unused-vars
  DENIED = 'DENIED',
  // eslint-disable-next-line no-unused-vars
  REGISTERED = 'REGISTERED',
}

export enum UserRole {
  // eslint-disable-next-line no-unused-vars
  ADMIN='ADMIN',
  // eslint-disable-next-line no-unused-vars
  USER='USER',
  // eslint-disable-next-line no-unused-vars
  NONE='NONE',
}

export interface AgentRequest {
  agentId: string;
  dataSourceId: string;
  label: string;
  description: string;
  dataSourceDisplayName: string;
  ipAddress: string;
  requestStatus: RequestStatus;
  openedOn: string;
  closedOn?: string;
  lastUpdated?: string;
}

export interface CreateAgentRequest {
  label: string;
  description: string;
}

export interface AgentUpdatePropertiesRequest {
  id?: string;
  dataSourceId: string;
  properties: string;
}

// Types for Listing Page

export type QueryFilterType<T = unknown> = {
  page?: number;
  pageSize?: number;
  sortBy?: string;
  filter?: string;
  username?: string;
} & {[Properties in keyof T]?: T[Properties]}

export interface ResourceListFetchBuilderType<T> {
  method?: HttpMethod;
  path: string;
  filterName?: string;
  defaultSortBy?: string;
  constantParams?: T;
}

export interface ResourceFetchBuilderType<T> {
  method?: HttpMethod;
  path: string;
  constantParams?: T;
}

export interface ResourceFetchByIdBuilderType {
  method?: HttpMethod;
  path: string;
  after?: string;
}

export interface ListingPageProps {
  pageIndex?: number;
  columns: any;
  data: any;
  pageData: any;
  sortBy?: any;
  filter?: any;
  fetchData: any;
}

export interface KeyValuePair {
  key: string;
  value: any;
}

export type User = {
    id: string
    username: string
    roles: Array<string>
    firstName: string;
    lastName: string;
}

export type ChangeRequest = {
  id: string;
  title: string;
  userLogin: string;
  created: string;
  closed: string;
  state: string;
  headRef: string;
  baseRepositoryHtmlUrl: string;
  changeRequestConfigs?: Array<ChangeRequestConfig>;
  comparisonJobs?: Array<ComparisonJob>;
  htmlUrl: string;
}

export type ChangeRequestConfig = {
  id: string;
  repositoryUrl: string;
  branchPattern: string;
  workspaceId: string;
  workspaceName: string;
  retention?: string;
}

export type CreateChangeRequestConfigPayload = Omit<ChangeRequestConfig, 'id' | 'workspaceName'>
export type UpdateChangeRequestConfigPayload = Omit<ChangeRequestConfig, 'workspaceName'>
export type ChangeRequestConfigDefault = Omit<CreateChangeRequestConfigPayload, 'workspaceId' | 'workspaceName'>

export type ChangeRequestResponse = TypedApiSuccessResponse<Array<ChangeRequest>>


type ContainsPassword = {
  password: string
}

export type CreateUserPayload = Omit<User, 'id'> & ContainsPassword
export type UpdateUserPayload = User & Partial<ContainsPassword>

// We don't use them yet, but T could be an ISODateString if we run our api response through isIsoDateString
type LicenseEntitlements<T = string> = {
    'edit-metrics-settings-enabled': boolean,
    'ldap-enabled': boolean,
    'max-agents': number,
    'max-users': number,
    'multi-server-enabled': boolean,
    'runtime-scans-enabled': boolean,
    'scan-stored-procedures-enabled': boolean,
    'sso-enabled': boolean,
    'usage-metrics-enabled': boolean,
    'validity-period-end': T,
    'validity-period-start': T
}

// We don't use them yet, but T could be an ISODateString if we run our api response through isIsoDateString
export type License<T = string> = {
    'currentDate': T,
    'entitlements': LicenseEntitlements<T>,
    'expirationDate': T,
    'licenseType': T,
    'active': true
}

export type AwsConfig = {
  'accountId'?: string,
  'accessKey'?: string,
}

export type AuthorizationConfig = {
  redirectUrl?: string;
  authorizationType: AuthorizationType,
  tenantId: string,
  clientId: string,
  clientSecret?: string,
  externalGroupToInternalRoleMap: {[key: string]: string}
};

export type BasicAuthorizationConfig = {
  authorizationType: AuthorizationType,
  externalGroupToInternalRoleMap: {[key: string]: string},
};

export enum AuthorizationType {
  // eslint-disable-next-line no-unused-vars
  BASIC='BASIC',
  // eslint-disable-next-line no-unused-vars
  AZURE_AD='AZURE_AD',
}

export type OverviewApplication = {
  id: string,
  name: string,
}

export type OverviewRelationship = {
  fromId: string,
  fromName: string,

  relationshipType: string,

  toId: string,
  toName: string,

  fromApplication: OverviewApplication,
  toApplication: OverviewApplication,
}

export type DiagramChangeLens = {
  value: 'all' | 'added' | 'removed' | 'impacted' | 'impacted-only'
  label: string,
}

export type DiffReportType = 'all' | 'added' | 'removed' | 'impacted'

export type AgentSettings = {
  expungeScanSessions: boolean,
  recursiveScan: boolean,
  rescanArchive: boolean
}

export type Namespace = {
  namespace: string,
  dataSourceId: string,
}

export type RepositoryIgnore = {
  id: string,
  expression: string,
}

export type ChangeRequestSourceTypes = typeof CHANGE_REQUEST_SECRET_TYPES[number]['value']

export type ChangeRequestSecret = {
  secret: string,
  sourceType: ChangeRequestSourceTypes
}

export type AnalyzeDBRequest = {
  application: string,
  username: string,
  password: string,
  jdbcUrl: string,
}

type FuzzyQueryMatchType =
    | 'EXACT'
    | 'ENDING_WITH'
    | 'CONTAINS'
    | 'EQUALS'
    | 'GREATER'
    | 'LESS'
    | 'GREATER_OR_EQUAL'
    | 'LESS_OR_EQUAL';

type FuzzyQueryType =
  | 'NODE'
  | 'RELATIONSHIP';

type FuzzyQueryDirection =
  | 'OUT'
  | 'IN'
  | 'ANY';

export type FuzzyQueryPart = {
  applications?: Array<string>;
  matchType?: FuzzyQueryMatchType;
  caseSensitive?: boolean;
  queryType: FuzzyQueryType;
  directionType?: FuzzyQueryDirection;
  deepRelationship?: boolean;
  labels?: string[];
  fuzzyLabels?: string[][];
  properties?: {[key: string]: unknown};

}

/**
 * NB: 'returns' keys must correspond to query parts, e.g. `n_0`, `r_1`, `r_3`.
 * You must know and account for implicit query parts, so if there are
 * two fuzzy nodes, the first one is `n_0` and the second one is `n_2`,
 * because there is an implicit `r_1` between them.
 */
export type AdvancedQuery = {
  queryParts: Array<FuzzyQueryPart>,
  returns: {[key: string]: Array<string>}
};

export type AdvancedReport = AdvancedQuery & {
  id: string;
  name: string,
  description: string,
  createdBy: string,
  createdOn: string,
}

export type CreateAdvancedReport = Omit<AdvancedReport, 'createdBy' | 'createdOn' | 'id'>

export type SlackConfig = {
  'id': string,
  'accessToken': string,
  'title': string
}
