import {useContext} from 'react';
import {IoMdHelpCircle} from 'react-icons/io';
import {IoSettingsSharp} from 'react-icons/io5';
import {IoPersonSharp} from 'react-icons/io5';
import {NavLink as Link, useLocation} from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';

import {ReactComponent as Logo} from 'src/svgs/codelogic-header-logo.svg';
import {AuthContext} from 'src/context';
import {cookies} from 'src/api/cookies';
import {WorkspaceSelector} from 'src/components/Workspaces/Selector';
import {AUTHENTICATED_COOKIE_NAME, AUTHENTICATED_COOKIE_PATH, logout, ROLES_COOKIE_NAME} from 'src/api/auth';
import {
  ADMINISTRATION_PATH,
  LOCAL_SETTINGS_PATH,
  SEARCH_PATH,
  EXTERNAL_DOCUMENTATION_LINK,
  EXTERNAL_SWAGGER_DOCUMENTATION_LINK,
} from 'src/routes';

import './styles.css';

export const Header = () => {
  const {pathname} = useLocation();
  return (
    <>
      <Navbar
        className="NavBar"
        light
        expand="xs"
      >
        <NavbarBrand tag={Link} to={SEARCH_PATH}>
          <Logo style={{width: '135px', color: 'var(--color-sidenav-link-1)'}}/>
        </NavbarBrand>
        {pathname.startsWith(SEARCH_PATH) && <WorkspaceSelector />}
        <Nav navbar>
          <NavItem>
            <NavLink tag={Link} to={ADMINISTRATION_PATH}>
              <IoSettingsSharp className="NavBar__icon" fontSize="1em" /> <span>Admin</span>
            </NavLink>
          </NavItem>
          <HelpMenu />
          <ProfileMenu />
        </Nav>
      </Navbar>
    </>
  );
};

const HelpMenu = () => (
  <UncontrolledDropdown nav>
    <DropdownToggle nav>
      <IoMdHelpCircle className="NavBar__icon" fontSize="1em" /> <span>Support</span>
    </DropdownToggle>
    <DropdownMenu end>
      <DropdownItem
        tag="a"
        href={EXTERNAL_DOCUMENTATION_LINK}
        target='_blank'
        rel='noopener noreferrer'
      >
        Documentation
      </DropdownItem>
      <DropdownItem
        tag={Link}
        to={EXTERNAL_SWAGGER_DOCUMENTATION_LINK}
        target='_blank'
        rel='noopener noreferrer'
      >
        Swagger API
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const ProfileMenu = () => {
  const {setIsLoggedIn} = useContext(AuthContext);

  const requestLogout = () => {
    logout().then((_res) => {
      cookies.remove(AUTHENTICATED_COOKIE_NAME, {
        path: AUTHENTICATED_COOKIE_PATH,
      });
      cookies.remove(ROLES_COOKIE_NAME, {
        path: '/',
      });
      setIsLoggedIn(false);
    });
  };

  return (
    <UncontrolledDropdown nav>
      <DropdownToggle nav>
        <IoPersonSharp className="NavBar__icon" fontSize="1em" /> <span>Profile</span>
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem
          tag={Link}
          to={LOCAL_SETTINGS_PATH}
        >
          <span>Local settings</span>
        </DropdownItem>
        <DropdownItem
          className="link-button"
          onClick={requestLogout}
        >
        Logout
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
