import {useCallback, useEffect, useMemo, useState} from 'react';
import identity from 'lodash/identity';
import {ColumnDef} from '@tanstack/react-table';
import SwalBase from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {IoWarningOutline} from 'react-icons/io5';
import {Button, TabPane} from 'reactstrap';
import styled from 'styled-components';

import {getUsers} from 'src/api/users';
import {User, PageDataType} from 'src/types';
import {ListingPage} from 'src/components';

import {UserForm} from './Form';
import {removeUser} from './utils';
import {ActionMenu} from './components';

const Swal = withReactContent(SwalBase);

const Container = styled.div`
  width: 100%;
  display: flex;
  flexDirection: row;
`;

export const UserManagement = ({setLoading}: {setLoading: (_: boolean) => void}) => {
  const [data, setData] = useState<User[]>([]);

  // TODO: move this into data
  const [pageData, setPageData] = useState<PageDataType>();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formSubject, setFormSubject] = useState<User>();
  const [dependsOnReload, setIsReloadRequired] = useState(true);
  const reload = () => setIsReloadRequired(!dependsOnReload);

  const close = ({shouldUpdate}: {shouldUpdate: boolean}) => {
    setIsFormOpen(false);
    setFormSubject(undefined);
    if (shouldUpdate) {
      reload();
    }
  };

  const handleKeyDown = ({key}: KeyboardEvent) => {
    if (key === 'Escape') {
      setIsFormOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  });

  const userDeleteConfirmation = async (user: User) => {
    const response = await Swal.fire({
      title: `Are you sure you want to delete user ${user.username}?`,
      text: 'This user will no longer be able to login.',
      confirmButtonText: 'Delete',
      showCancelButton: true,
      customClass: 'WorkspaceAlert',
      iconHtml: <IoWarningOutline></IoWarningOutline>,
    });

    if (response.isConfirmed) {
      await removeUser(user);
      await fetchUsers(pageData?.currentPage, pageData?.pageSize);
    }
  };

  const columns = useMemo<ColumnDef<User>[]>(
      () => [
        {
          header: 'First',
          accessorKey: 'firstName',
        },
        {
          header: 'Last',
          accessorKey: 'lastName',
        },
        {
          header: 'Roles',
          accessorFn: ({roles}) => [...roles].sort().join(', '),
          enableSorting: false,
        },
        {
          header: 'Username (Email)',
          accessorKey: 'username',
        },
        {
          header: 'Actions',
          accessorFn: identity,
          enableSorting: false,
          cell: ({getValue}) => (
            <ActionMenu menuItems={[{
              label: 'Edit',
              action: () => {
                setFormSubject(getValue());
                setIsFormOpen(true);
              },
            }, {
              label: 'Delete',
              action: () => userDeleteConfirmation(getValue()),
            }]} />
          ),
        },
      ],
      [],
  );

  const fetchUsers = useCallback(async (page?: number, pageSize?: number, sortBy?: string) => {
    setLoading(true);
    const result = await getUsers({
      page,
      pageSize,
      sortBy,
    });
    setLoading(false);
    if (result && result.data) {
      setData(result.data);
      setPageData(result.metaData?.pageData);
    } else {
      setData([]);
      setPageData(undefined);
    }
  }, [dependsOnReload]);

  return (
    <TabPane>
      <Container>
        <Button alt="Create User" color="primary" onClick={() => setIsFormOpen(true)} style={{margin: '0 0 0 auto'}}>Create User</Button>
      </Container>
      <ListingPage
        columns={columns}
        data={data}
        pageData={pageData}
        fetchData={fetchUsers}
      />
      {isFormOpen && <UserForm user={formSubject} onClose={close} />}
    </TabPane>
  );
};

