import {Route, Routes, Navigate} from 'react-router-dom';

import {Layout} from 'src/components';
import {makeRelativePathFn} from 'src/util';
import {
  WELCOME_WIZARD_PATH,
  WELCOME_WIZARD_INTRO_PATH,
  WELCOME_WIZARD_NAMESPACE_PATH,
  WELCOME_WIZARD_COMPLETE_PATH,
  WELCOME_WIZARD_AGENT_SETTINGS_PATH,
} from 'src/routes';

import {
  Intro,
  NamespacesStep,
  AgentSettingsStep,
  WizardComplete,
} from './components';

import './styles.css';

export const WelcomeWizard = () => {
  const relativePath = makeRelativePathFn(WELCOME_WIZARD_PATH);

  return (
    <Layout loading={false}>
      <Routes>
        <Route path={relativePath(WELCOME_WIZARD_INTRO_PATH)} element={<Intro />} />
        <Route path={relativePath(WELCOME_WIZARD_NAMESPACE_PATH)} element={<NamespacesStep />} />
        <Route path={relativePath(WELCOME_WIZARD_COMPLETE_PATH)} element={<WizardComplete />} />
        <Route path={relativePath(WELCOME_WIZARD_AGENT_SETTINGS_PATH)} element={<AgentSettingsStep />} />
        <Route path={relativePath(WELCOME_WIZARD_PATH)} element={<Navigate to={WELCOME_WIZARD_INTRO_PATH} replace={true} />} />
      </Routes>
    </Layout>
  );
};
