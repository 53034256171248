import {useEffect, useState} from 'react';
import {IoClose} from 'react-icons/io5';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import {useNavigate} from 'react-router-dom';
import {FormGroup, Input, Label} from 'reactstrap';

import {RELEASE_ASSISTANT_PATH} from 'src/routes';
import {createMVComparisonDefinition} from 'src/api/materializedViewDefinitions';
import {
  ConfirmCancelInput,
  Page,
  Table,
  Section,
} from 'src/components';
import {ScanRoot} from 'src/types';
import {useResource} from 'src/hooks';
import {getScanSpaces} from 'src/api/scanSpace';

import {useScanRoots} from './hooks';

import './styles.css';

type IdToScanRootMap = {[key: string]: ScanRoot}

export const Create = () => {
  const [selectedScanRoots, setSelectedScanRoots] = useState<IdToScanRootMap>({});
  const [mvdName, setMVDName] = useState<string | undefined>();
  const [filters, setFilters] = useState<{[key: string]: string | undefined}>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const {loading, scanRoots, metadata, fetchScans} = useScanRoots();
  const [scanSpaceId, setScanSpaceId] = useState<string>();
  const navigate = useNavigate();
  const {entity: scanSpaces, loading: loadingScanSpaces, loadEntity: loadScanSpaces} = useResource(getScanSpaces);

  useEffect(() => {
    loadScanSpaces({page: 0, pageSize: 1000});
  }, []);

  useEffect(() => {
    if (!scanSpaceId && scanSpaces) {
      setScanSpaceId(scanSpaces.find((s) => s.primary)?.id);
    }
  }, [scanSpaces]);

  useEffect(() => {
    fetchScans({
      scanSpaceId: scanSpaceId,
      page: currentPage,
      pageSize,
      filters: {
        nameFilter: filters.name,
        gitBranchFilter: filters['branch-name'],
        applicationFilter: filters.applications,
        gitHeadSHAFilter: filters.sha,
      },
    });
  }, [pageSize, currentPage, filters, scanSpaceId]);

  return (
    <Page
      className="ReleaseAssistantPage"
      title="Release Assistant"
      controls={
        <ConfirmCancelInput
          promptText="Create"
          placeholder="Name the new release"
          value={mvdName}
          validationMessage="Error: release name required"
          onChange={(value) => {
            setMVDName(value);
          }}
          onCancel={() => {
            setMVDName(undefined);
            return true;
          }}
          onConfirm={async () => {
            if (!mvdName) {
              return false;
            } else {
              const scanRootIds = Object.entries(selectedScanRoots).filter(([, value]) => !!value).map(([key]) => key);
              await createMVComparisonDefinition({
                primary: false,
                type: 'RELEASE_ASSISTANT',
                displayName: mvdName,
                includedScanContexts: scanRootIds,
              });
              navigate(RELEASE_ASSISTANT_PATH);
              return true;
            }
          }}
        />
      }>
      <Section title="Selected Scans">
        <Table
          noRecordsMessage="No scans selected"
          rows={Object.values(selectedScanRoots).map((s) => ({
            ...s,
            id: s.scanContextId,
          }))}
          columns={[{
            id: 'remove',
            header: () => null,
            cell: ({id}) => (
              <button
                className="btn btn-link"
                style={{padding: '0rem 0.5rem', fontSize: '1.25rem'}}
                onClick={() => setSelectedScanRoots(omitBy({
                  ...selectedScanRoots,
                  [id]: undefined,
                }, isUndefined) as IdToScanRootMap)}>
                <IoClose />
              </button>
            ),
          }, {
            id: 'name',
            header: () => 'Name',
            cell: ({name}) => name,
          }, {
            id: 'applications',
            header: () => 'Application(s)',
            cell: ({applicationNames}) => applicationNames.join(', '),
          }, {
            id: 'sha',
            header: () => 'Git Hash',
            cell: ({gitHeadSHA}) => gitHeadSHA,
          }, {
            id: 'branch-name',
            header: () => 'Branch Name',
            cell: ({gitBranch}) => gitBranch,
          }]}
        />
      </Section>
      <Section title="Source Scans">
        {!loadingScanSpaces &&
          <Section>
            <FormGroup>
              <Label className="CreateForm__field-label">Scan Space</Label>
            </FormGroup>
            <Input type='select' onChange={(e) => setScanSpaceId(e.target.value)} value={scanSpaceId}>
              {scanSpaces && scanSpaces.map((s) => <option key={s.id} value={s.id}>{s.displayName}</option>)}
            </Input>
          </Section>
        }
        <Table
          loading={loading}
          onPageChange={setCurrentPage}
          onFilterChange={setFilters}
          onSelectPageSize={setPageSize}
          metadata={metadata}
          rows={(scanRoots || []).map((s) => ({
            ...s,
            id: s.scanContextId,
            selected: !!selectedScanRoots[s.scanContextId],
          }))}
          columns={[{
            id: 'selector',
            header: ({rows}) => {
              const allSelected = rows.length > 0 && rows.every(({selected}) => !!selected);

              return (
                <div style={{
                  alignItems: 'center',
                  display: 'flex',
                }}>
                  <input onChange={() => {
                    let updatedSelections = {};
                    if (allSelected) {
                      updatedSelections = rows.reduce<{[key: string]: undefined}>((obj, {id}) => ({
                        ...obj,
                        [id]: undefined,
                      }), {});
                    } else {
                      updatedSelections = rows.reduce<IdToScanRootMap>((obj, {selected, id, ...scanRoot}) => ({
                        ...obj,
                        [id]: scanRoot,
                      }), {});
                    }

                    setSelectedScanRoots(omitBy({
                      ...selectedScanRoots,
                      ...updatedSelections,
                    }, isUndefined) as IdToScanRootMap);
                  }}
                  checked={allSelected}
                  type="checkbox" />
                </div>
              );
            },
            cell: ({selected, id, ...scanRoot}) => <input onChange={() => {
              setSelectedScanRoots(omitBy({
                ...selectedScanRoots,
                [id]: !selected ? scanRoot : undefined,
              }, isUndefined) as IdToScanRootMap);
            }} checked={selected} type="checkbox" />,
          }, {
            id: 'name',
            canFilter: true,
            header: () => 'Name',
            cell: ({name}) => name,
          }, {
            id: 'applications',
            header: () => 'Application(s)',
            canFilter: true,
            cell: ({applicationNames}) => applicationNames.join(', '),
          }, {
            id: 'sha',
            header: () => 'Git Hash',
            canFilter: true,
            cell: ({gitHeadSHA}) => gitHeadSHA,
          }, {
            id: 'branch-name',
            header: () => 'Branch Name',
            canFilter: true,
            cell: ({gitBranch}) => gitBranch,
          }]}
        />
      </Section>
    </Page>
  );
};
