import {Formik, Form, Field, FieldArray, ErrorMessage} from 'formik';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import SwalBase from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import {createUser, getUser, getUsers, updateUser} from 'src/api/users';

import {ConfirmationPopup} from './components';
import {FormFields, Props} from './types';
import {makeInitialEditState, makeFormValidator} from './utils';
import {OPTIONS} from './constants';
import './styles-form.css';

const Swal = withReactContent(SwalBase);

export const UserForm = ({onClose, user}: Props) => {
  const closeAndUpdate = () => onClose({shouldUpdate: true});
  const closeWithoutUpdate = () => onClose({shouldUpdate: false});

  const handleSubmit = async ({confirmPassword, password, username, ...values}: FormFields) => {
    if (!user) {
      const result = await getUsers({page: 0, pageSize: 1, filter: username});
      if (result.status === 'success' && result.data && result.data.length > 0) {
        Swal.fire({
          title: 'Error!',
          text: `Username ${username} is already in use!`,
          width: '45em',
          icon: 'error',
        });
        return;
      }
    }

    const result = user ? await updateUser({
      ...values,
      username,
      password: password.length > 0 ? password : undefined,
      id: user.id,
    }) : await createUser({
      ...values,
      username,
      password,
    });

    if (result.status === 'success' && result.data) {
      const newUserResponse = await getUser({id: result.data.id});
      if (newUserResponse.status === 'success') {
        Swal.fire({
          title: 'Success!',
          html: newUserResponse.data && <ConfirmationPopup {...newUserResponse.data} />,
          width: '45em',
          icon: 'success',
          showConfirmButton: true,
        });

        closeAndUpdate();
      } else {
        // if we succeeded in creating the thing but couldn't fetch
        // it for some reason, we still indicate the succes of the call.
        Swal.fire({
          title: 'Success!',
          icon: 'success',
          showConfirmButton: true,
        });
      }
    } else if (result.status === 'error') {
      const errorMessages = (
        <>
          {result.error.details?.map(({field, message}) => (
            <p key={`${field}__${message}`}><span>{field}</span>: <span>{message}</span></p>
          ))}
        </>
      );

      Swal.fire({
        title: 'Error',
        html: errorMessages,
        width: '45em',
        icon: 'error',
        showConfirmButton: true,
      });

      closeWithoutUpdate();
    }
    closeWithoutUpdate();
  };

  return (
    <Modal isOpen={true} fade={false} modalClassName="height-auto">
      <ModalHeader>{user ? 'Edit' : 'Create'} User</ModalHeader>
      <ModalBody>
        <Formik<FormFields>
          initialValues={user ? makeInitialEditState(user) : {
            firstName: '',
            lastName: '',
            username: '',
            password: '',
            confirmPassword: '',
            roles: [],
          }}
          validate={makeFormValidator(user)}
          onSubmit={handleSubmit}
          validateOnBlur={true}
        >
          {({isSubmitting}) => (
            <Form autoComplete="off">
              <FormGroup>
                <div className="Form__field">
                  <Label for="username">Username</Label>{' '}
                  <span className='Form__error'><ErrorMessage name="username" /></span>
                  <Input
                    tag={Field}
                    name="username"
                    type="text"
                    component="input"
                  />
                </div>
                <div className="Form__field">
                  <Label for="firstName">First Name</Label>{' '}
                  <span className='Form__error'><ErrorMessage name="firstName" /></span>
                  <Input
                    tag={Field}
                    name="firstName"
                    type="text"
                    component="input"
                  />
                </div>
                <div className="Form__field">
                  <Label for="lastName">Last Name</Label>{' '}
                  <span className='Form__error'><ErrorMessage name="lastName" /></span>
                  <Input
                    tag={Field}
                    name="lastName"
                    type="text"
                    component="input"
                  />
                </div>
                <div className="Form__field">
                  <Label for="roles">Role</Label>{' '}
                  <span className='Form__error'><ErrorMessage name="roles" /></span>
                  <FieldArray name="roles">
                    {() => (
                      <Input
                        tag={Field}
                        name='roles.0'
                        as="select"
                      >
                        {OPTIONS.map(({label, id, disabled}, index) => (
                          <option key={id} selected={index === 0} disabled={disabled} value={id}>{label}</option>
                        ))}
                      </Input>
                    )}
                  </FieldArray>
                </div>
                <div className="Form__field">
                  <Label for="password">Password</Label>{' '}
                  <span className='Form__error'><ErrorMessage name="password" /></span>
                  <Input
                    autoComplete="new-password"
                    tag={Field}
                    name="password"
                    type="password"
                    component="input"
                  />
                </div>
                <div className="Form__field">
                  <Label for="confirmPassword">Confirm Password</Label>{' '}
                  <span className='Form__error'><ErrorMessage name="confirmPassword" /></span>
                  <Input
                    tag={Field}
                    autoComplete="new-password"
                    name="confirmPassword"
                    type="password"
                    component="input"
                  />
                </div>
              </FormGroup>
              <Button type="submit" color="primary" disabled={isSubmitting}>Save</Button>
              <Button color="secondary" style={{float: 'right'}} disabled={isSubmitting} onClick={() => onClose({shouldUpdate: false})}>Cancel</Button>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};
