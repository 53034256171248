import qs from 'qs';
import Axios from 'axios';

import {responseErrorInterceptor, responseSuccessInterceptor, requestInterceptor} from './interceptors';

export const axiosInstance = Axios.create({
  paramsSerializer: (params) =>
    qs.stringify(params, {arrayFormat: 'repeat'}),
});

axiosInstance.defaults.params = {};
axiosInstance.interceptors.request.use(requestInterceptor);
axiosInstance.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);

// type GetParams = Parameters<typeof _axiosInstance.get>
// type PutParams = Parameters<typeof _axiosInstance.put>
// type DeleteParams = Parameters<typeof _axiosInstance.delete>
// type PostParams = Parameters<typeof _axiosInstance.post>
// type RequestParams = Parameters<typeof _axiosInstance.request>

// export const axiosInstance = {
//   request: (...args: RequestParams) => {
//     _axiosInstance.request(...args);
//   },
//   get: (...args: GetParams) => {
//     _axiosInstance.get(...args);
//   },
//   post: (...args: PostParams) => {
//     _axiosInstance.post(...args);
//   },
//   put: (...args:PutParams) => {
//     _axiosInstance.put(...args);
//   },
//   patch: (...args:PutParams) => {
//     _axiosInstance.put(...args);
//   },
//   head: (...args:PutParams) => {
//     _axiosInstance.put(...args);
//   },
//   options: (...args:PutParams) => {
//     _axiosInstance.put(...args);
//   },
//   delete: (...args: DeleteParams) => {
//     _axiosInstance.delete(...args);
//   },
// };
