import {MaterializedViewDefinition, TypedApiResponse} from 'src/types';

import {CODELOGIC_API_BASE_URL} from '../config';

import {axiosInstance} from './baseApi';


export async function createMVComparisonDefinition(definition: Omit<MaterializedViewDefinition, 'id'>): Promise<TypedApiResponse<string | null>> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/materialized-view-definition/comparison/`;

  try {
    const response = await axiosInstance.post(endpoint, definition, {
      withCredentials: true,
    });

    return response.data;
  } catch (e) {
    return {
      status: 'error',
    };
  }
}

export async function getMaterializedViewDefinition(definitionId: string): Promise<TypedApiResponse<MaterializedViewDefinition>> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/materialized-view-definition/${definitionId}`;

  try {
    const response = await axiosInstance.get(endpoint, {withCredentials: true});
    return response.data;
  } catch (e) {
    return {
      status: 'error',
    };
  }
}

export async function listMaterializedViewDefinitions({
  type,
  pageNumber = 0,
  pageSize = 10,
}: {
  type?: string,
  pageNumber?: number,
  pageSize?: number
} = {}): Promise<TypedApiResponse<Array<MaterializedViewDefinition>>> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/materialized-view-definition/`;

  const params = {
    type,
  };
  params['page'] = pageNumber;
  if (pageSize) {
    params['size'] = pageSize;
  }
  try {
    const response = await axiosInstance.get(endpoint, {
      withCredentials: true,
      params,
    });
    return response.data;
  } catch (e) {
    return {
      status: 'error',
    };
  }
}

export async function deleteMaterializedViewDefinition(definitionId: string): Promise<TypedApiResponse<boolean>> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/materialized-view-definition/${definitionId}`;
  try {
    const response = await axiosInstance.delete(endpoint, {withCredentials: true});
    return response.data;
  } catch (e) {
    return {
      status: 'error',
    } as const;
  }
}
