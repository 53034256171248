import {useEffect, useState} from 'react';
import Swal from 'sweetalert2';

import {
  DiagramNode,
  DiagramLink,
} from 'src/types';
import {getFuzzy} from 'src/api/fuzzyMatch';
import {GraphView} from 'src/components/Dependency';

import './styles.css';

export const SearchDetailTab = ({nodeId}: {nodeId: string}) => {
  const [{nodes, edges}, setData] = useState<{
    nodes: Array<DiagramNode>,
    edges: Array<DiagramLink>
  }>({
    nodes: [],
    edges: [],
  });

  useEffect(() => {
    if (nodeId) {
      analyzeFuzzy(nodeId);
    }
  }, [nodeId]);

  const analyzeFuzzy = async (nodeId) => {
    const response = await getFuzzy(nodeId);
    let results : any = {};
    if (response.data.status === 'success') {
      results = response.data.data;
    } else {
      if (response.status === 404) {
        Swal.fire({
          title: 'No dependency impact found',
          text: 'This may occur when no incoming relationships exist to the item.',
        });
      } else {
        const message = response.data.error ?
          response.data.error.message :
          'unknown server error';

        Swal.fire({
          title: 'An error occurred calculating impact.',
          text: message,
        });
      }

      results = {
        containedNodes: [],
        referencedNodes: [],
        nodes: [],
        relationships: [],
      };
    }
    results.nodes.map((n) => {
      n.metadata = {};
      n.metadata.type = n.primaryLabel;
      n.metadata.identity = n.identity;
      n.text = n.name;
      n.id = n.id;
    });

    results.relationships.map((r) => {
      r.from = r.startId;
      r.to = r.endId;
      r.id = r.id;
    });
  };
  const height = '100%';
  return nodes.length > 0 ? (
    <div style={{height: height || undefined}} className="SearchDetailTab">
      {(
        <GraphView
          data={{
            nodes,
            edges,
          }}
          setData={setData}
        />
      )}
    </div>
  ) : <NoData />;
};

const NoData = () => <span>No fuzzy analysis data</span>;
