import {CODELOGIC_API_BASE_URL} from 'src/config';
import {
  ApiErrorResponse,
  ApiResponseWithId,
  ApiSuccessResponse,
  ChangeRequestConfig,
  CreateChangeRequestConfigPayload,
  UpdateChangeRequestConfigPayload,
  TypedApiResponse,
  ValidationError,
} from 'src/types';

import {axiosInstance} from './baseApi';
import {isAxiosError} from './utils';

import {makeResourceListFetch} from './index';


export const getChangeRequestConfigs = makeResourceListFetch<Array<ChangeRequestConfig>>({method: 'GET', path: '/change-request-config'});


export const updateConfig = async (changeRequestConfig: UpdateChangeRequestConfigPayload): Promise<ApiSuccessResponse | ApiErrorResponse<ValidationError>> => {
  try {
    const response = await axiosInstance.put(`${CODELOGIC_API_BASE_URL}/change-request-config/${changeRequestConfig.id}`, changeRequestConfig, {withCredentials: true});
    return response?.data;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse<ValidationError>>(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
};

export const createConfig = async (createConfigPayload: CreateChangeRequestConfigPayload): Promise<ApiResponseWithId | ApiErrorResponse<ValidationError>> => {
  try {
    const response = await axiosInstance.post(`${CODELOGIC_API_BASE_URL}/change-request-config`, createConfigPayload, {withCredentials: true});
    return response?.data;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse<ValidationError>>(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
};

export const deleteConfig = async (id: string): Promise<TypedApiResponse<any> | ApiErrorResponse | Error> => {
  try {
    const {data} = await axiosInstance.delete<TypedApiResponse<any>>(`${CODELOGIC_API_BASE_URL}/change-request-config/${encodeURIComponent(id)}`, {withCredentials: true});
    return data;
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
};
