import {PropsWithChildren, useEffect, useRef, useState} from 'react';
import classnames from 'classnames';

import './styles.css';

export const ContextMenu = ({
  x,
  y,
  visible: initialVisibleState = false,
  manageEvents = true,
  positionFixed = false,
  children,
}: PropsWithChildren<{
  x: number,
  y: number,
  visible: boolean,
  manageEvents?: boolean,
  positionFixed?: boolean,
}>) => {
  const ref = useRef<HTMLDivElement>(null);

  const [visible, setVisible] = useState(initialVisibleState);

  useEffect(() => {
    const {current} = ref;
    if (current) {
      const cxElement = current;
      cxElement.style.left = x + 'px';
      const {height} = cxElement.getBoundingClientRect();

      const windowBottom = window.innerHeight + window.scrollY;

      if ((y + height) >= windowBottom) {
        cxElement.style.top = `${y - height}px`;
      } else {
        cxElement.style.top = `${y}px`;
      }

      const preventDefault = (e: Event) => {
        setVisible(false);
        return false;
      };

      if (manageEvents) {
        document.addEventListener('contextmenu', preventDefault);
      }
      document.addEventListener('click', preventDefault);

      setVisible(initialVisibleState);

      return () => {
        document.removeEventListener('contextmenu', preventDefault);
        document.removeEventListener('click', preventDefault);
      };
    }
  }, [x, y, initialVisibleState]);

  return (
    <div ref={ref} className='menu ContextMenu' style={{
      display: visible ? 'block' : 'none',
      position: positionFixed ? 'fixed' : 'absolute',
    }}>
      <ul className="ContextMenu__list">
        {children}
      </ul>
    </div>
  );
};
export const MenuItem = <T, >({
  onClick,
  context,
  children,
  disabled,
  isSection,
}: PropsWithChildren<{
  onClick: (_:T | undefined) => void,
  context?: T
  disabled?: boolean,
  isSection?: boolean
}>) => (
    <li className={classnames({
      'ContextMenu__menu-item': true,
      'ContextMenu__section-start': isSection,
    })}>
      <button className="ContextMenu__link link-button" disabled={disabled} onClick={() => onClick(context)}>
        {children}
      </button>
    </li>
  );
