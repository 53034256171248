import {makeResourceListFetch, makeFileUploader} from 'src/api';
import {ApiErrorResponse, License, TypedApiResponse} from 'src/types';

import {CODELOGIC_API_BASE_URL} from '../config';

import {axiosInstance} from './baseApi';
import {isAxiosError} from './utils';

export const getLicense = makeResourceListFetch<License>({method: 'GET', path: 'license'});

export const uploadLicense = makeFileUploader('PUT', 'license');

export async function getLicenseEntitlement(name: string) {
  const endpoint = `${CODELOGIC_API_BASE_URL}/license/entitlements/${encodeURIComponent(name)}`;

  try {
    const response = await axiosInstance.get<TypedApiResponse<boolean | null>>(endpoint, {
      withCredentials: true,
    });

    return response?.data;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse>(e)) {
      return {
        status: 'error',
        data: false,
      } as const;
    }
    throw e;
  }
}
