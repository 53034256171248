import {
  Binding,
  GraphObject,
  Link,
  Point,
  Shape,
  TextBlock,
} from 'gojs';


import {SEARCH_NODE} from 'src/constants';
import {col, container, row} from 'src/util/gojs/components';
import {getColor, getTextColor} from 'src/graph';
import {margin} from 'src/util/gojs/utils';
import {promptFont} from 'src/util/gojs/styles';
import {ClickHandler} from 'src/util/gojs/types';

const {make} = GraphObject;

export const makeLinkTemplate = ({
  handleContextClick = () => {},
}: {
  handleContextClick?: ClickHandler,
}) => make(
    Link, // the whole link panel
    {
      layerName: 'Background',
      adjusting: Link.Stretch,
      reshapable: true,
    },
    make(
        Shape, // the link shape
        {
          strokeWidth: 1.5,
        },
        new Binding('stroke', '', getColor).ofObject(),
        new Binding('strokeDashArray', '', (n) => n.destType === SEARCH_NODE ? [10, 5] : null),
    ),

    make(
        Shape, // the arrowhead
        {
          toArrow: 'standard',
          stroke: null,
        },

        new Binding('fill', '', getColor).ofObject(),
    ),
    container([
      {

        segmentOffset: new Point(0, 0),
        segmentOrientation: Link.OrientUpright,
      },
      new Binding('background', '', getColor).ofObject(),
      new Binding('visible', '', (node) => {
        if (node.diffValue && node.diffValue === 'added' || node.diffValue === 'removed') {
          return true;
        } else {
          return false;
        }
      }),
      row([
        col([
          {
            margin: margin(2, 5),
          },
          make(
              TextBlock,
              'Unknown',
              {font: promptFont(1)},
              new Binding('text', '', (node) => {
                let text = '';
                if (node.diffValue === 'added') {
                  text += '+ ';
                } else if (node.diffValue === 'removed') {
                  text += '- ';
                }
                text += node.type;
                return text;
              }),
              new Binding('stroke', '', getTextColor).ofObject(),
          ),
        ]),
      ]),
    ]),
    {
      contextClick: handleContextClick,
    },
);
