import {useRef, useState, KeyboardEvent} from 'react';
import {Button, Input} from 'reactstrap';
import {FaSearch, FaTimes} from 'react-icons/fa';

import './textsearch.css';

type TextSearchProps = {
  onSearch: (value: string) => void;
  onChange?: (value: string) => void,
}
export const TextSearch = ({onSearch, onChange}: TextSearchProps) => {
  const [searchValue, setSearchValue] = useState('');
  const innerSearchBoxRef = useRef<HTMLInputElement>(null);

  const onSearchValueChange = (value: string) => {
    onChange && onChange(value);
    setSearchValue(value);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch(searchValue);
    }
  };

  return (
    <>
      <Input
        type='text'
        name='search'
        placeholder='Enter text to search'
        aria-label='Enter text to search'
        value={searchValue}
        onChange={(e) => onSearchValueChange(e.target.value)}
        onKeyUp={(e) => handleKeyPress(e)}
        innerRef={innerSearchBoxRef}
      />
      <Button cssModule={{'btn-secondary': 'TextSearch__clear-btn'}} aria-label='reset click' onClick={() => onSearchValueChange('')}>
        <FaTimes />
      </Button>
      <Button aria-label='Search click'
        onClick={()=> onSearch(searchValue)}>
        <FaSearch />
      </Button>
    </>
  );
};
