import {CSSObjectWithLabel} from 'react-select';
export const TAG_SELECT_STYLE_OVERRIDES = {
  container: (provided: CSSObjectWithLabel, {hasValue, isFocused}: any) => ({
    ...provided,
    display: 'inline-block',
    flex: hasValue ? '1' : undefined,
    width: isFocused ? '100%' : 'auto',
  }),
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: 'none',
    backgroundColor: 'var(--color-background--standard)',
  }),
  placeholder: (provided: CSSObjectWithLabel, {isFocused}: { isFocused: boolean }) => ({
    ...provided,
    color: isFocused ? 'var(--color-text-link)' : 'var(--color-text-link--hover)',
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
  }),
  input: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: 'auto',
    minWidth: '3.5em',
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    color: 'var(--color-text-link)',
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: '1px solid var(--color-border--standard)',
    margin: '0',
    backgroundColor: 'var(--color-background--standard)',
    zIndex: 3,
    minWidth: '100%',
    width: 'auto',
  }),
} as const;
