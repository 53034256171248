import {useLocation} from 'react-router-dom';
import {useMemo} from 'react';

export const useQuery = () => {
  const {search} = useLocation();
  const searchParams = new URLSearchParams(search);
  return useMemo(() => ({
    get: searchParams.get.bind(searchParams),
    getAll: searchParams.getAll.bind(searchParams),
  }), [search]);
};
