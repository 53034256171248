import {Button} from 'reactstrap';
import {useNavigate} from 'react-router-dom';

import {useFeatureFlag} from 'src/hooks';
import {
  STORE_PATH,
  SCAN_ARTIFACTS_PATH,
  WELCOME_WIZARD_NAMESPACE_PATH,
  WELCOME_WIZARD_AGENT_SETTINGS_PATH,
  WELCOME_WIZARD_COMPLETE_PATH,

} from 'src/routes';
import {Namespaces} from 'src/components/Namespaces/Namespaces';
import {AgentSettings} from 'src/components/AgentSettings';

import './styles.css';

export const Intro = () => {
  const navigate = useNavigate();
  return (
    <div className='WelcomeWizard'>
      <div className='WelcomeWizard__container'>
        <h2>Welcome to CodeLogic!</h2>
        <p>
          CodeLogic analyzes your applications, databases, and shared libraries to provide powerful insights into your
          dependencies.
        </p>
        <p>
          To get started, the installer will walk you through the following steps:
          <ul>
            <li>Define Namespaces for Java & .Net</li>
            <li>Configure default agent settings</li>
          </ul>
        </p>
        <span className='WelcomeWizard__continuation-prompt'>
          Ready? Let&apos;s get started!
        </span>
        <Button className='WelcomeWizard__continue-button' color='primary' onClick={() => navigate(WELCOME_WIZARD_NAMESPACE_PATH)}>Next</Button>
      </div>
    </div>
  );
};

export const NamespacesStep = () => {
  const navigate = useNavigate();
  return (
    <div className="WelcomeWizard">
      <div className='WelcomeWizard__container'>
        <h2>Define Namespaces</h2>
        <p>
          In this step we&apos;ll define your namespaces.
          CodeLogic uses namespaces to better understand your applications and
          its interactions.
        </p>
        <Namespaces />
        <span className='WelcomeWizard__continuation-prompt'>
          Next, we&apos;ll configure default agent settings.
        </span>
        <Button className='WelcomeWizard__continue-button' color='primary' onClick={() => navigate(WELCOME_WIZARD_AGENT_SETTINGS_PATH)}>Next</Button>
      </div>
    </div>
  );
};


export const AgentSettingsStep = () => {
  const navigate = useNavigate();
  return (
    <div className="WelcomeWizard">
      <div className='WelcomeWizard__container'>
        <h2>Configure Agents</h2>
        <p>Great, now that we&apos;ve defined your namespaces, let&apos;s configure your default agent settings.</p>
        <AgentSettings />
        <Button className='WelcomeWizard__continue-button' color='primary' onClick={() => navigate(WELCOME_WIZARD_COMPLETE_PATH)}>Next</Button>
      </div>
    </div>
  );
};


export const WizardComplete = () => {
  const navigate = useNavigate();
  const [serverSideScanningEnabled] = useFeatureFlag('FEATURE_FLAG_SERVER_SIDE_SCANNING');


  const content = serverSideScanningEnabled ? (
    <>
      <p>
        You can now scan artifacts, databases and generate Docker commands to scan items on remote systems.
      </p>
      <p>
        To close the wizard and start scanning, click Finish.
      </p>
    </>
  ) : (
    <>
      <p>
        You can now scan artifacts and databases using containerized Agents or by installation.
      </p>
      <p>
        To close the wizard and get started with Agents, click Finish.
      </p>
    </>
  );

  return (
    <div className='WelcomeWizard'>
      <div className='WelcomeWizard__container'>
        <h2>CodeLogic is Ready!</h2>
        <p>
          You have successfully configured CodeLogic.
        </p>
        {content}
        <Button className='WelcomeWizard__continue-button' color='primary' onClick={() => navigate(serverSideScanningEnabled ? SCAN_ARTIFACTS_PATH : STORE_PATH)}>Finish</Button>
      </div>
    </div>
  );
};
