import {useState} from 'react';

export const useContextMenuPosition = () => {
  const [contextMenuState, setContextMenuState] = useState({
    x: 0,
    y: 0,
    visible: false,
  });

  const showContextMenu = (x: number, y: number) => {
    setContextMenuState({
      x,
      y,
      visible: true,
    });
  };
  const hideContextMenu = () => {
    setContextMenuState({
      x: 0,
      y: 0,
      visible: false,
    });
  };

  return {
    contextMenuState,
    showContextMenu,
    hideContextMenu,
  } as const;
};
