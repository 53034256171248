import {Page, SettingHolder} from 'src/components';
import * as flags from 'src/feature-flags';

import {FeatureDisplay} from './FeatureDisplay';

export const FeatureFlagsTab = () => {
  const features = flags ?
    Object.values(flags).filter((f) => !f.devOnly && !f.defaultValue) :
    null;
  return (
    <Page title='Feature Flags'>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        {features && features.map((feature) => {
          return (
            <SettingHolder key={feature.key} selector={
              <FeatureDisplay feature={feature} />
            } name={feature.name} description={feature.description} />
          );
        })}
        {(!features || features.length) <= 0 && <h4 style={{marginLeft: '1.5rem'}}>No Feature Flags to configure at this time.</h4>}
      </div>
    </Page>
  );
};
