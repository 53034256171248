import {MouseEvent, useState} from 'react';
import {Button} from 'reactstrap';
import classnames from 'classnames';
import {BiCopy} from 'react-icons/bi';


import {CLIPBOARD_AVAILABLE} from 'src/constants';

import {Props} from './types';

import './styles.css';

const COPIED_CONFIRMATION_DISPLAY_DURATION = 2000;


// Without a clipboard, defined this component to return nothing
export const CopyLink = CLIPBOARD_AVAILABLE ? ({value}: Props) => {
  if (!CLIPBOARD_AVAILABLE) {
    return <></>;
  }

  const [recentlyCopied, setIsRecentlyCopied] = useState(false);
  const copy = async (e: MouseEvent) => {
    e.preventDefault();
    navigator.clipboard.writeText('' + value);
    setIsRecentlyCopied(true);
    setTimeout(() => setIsRecentlyCopied(false), COPIED_CONFIRMATION_DISPLAY_DURATION);
  };

  return (
    <span className={classnames({
      'CopyLink': true,
      'CopyLink__color-transition': recentlyCopied,
    })}
    title="Copy to clipboard"
    >
      <Button color="link" style={{color: 'inherit'}} onClick={copy} className="CopyLink-button">
        <BiCopy />
      </Button>
      <span
        className={classnames({
          'CopyLink-confirmation': true,
          'CopyLink--copied__fade-out': recentlyCopied,
        })}>{recentlyCopied ? 'Copied' : ''}</span>
    </span>
  );
} : () => null;
