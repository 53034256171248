import {useContext} from 'react';
import classnames from 'classnames';
import {Modal as RSModal, ModalBody} from 'reactstrap';

import {ThemeContext} from 'src/context';
import {ModalCloseButton} from 'src/components';

import {Props} from './types';
import './styles.css';

export const Modal = ({placement = 'center', borderColor, toggle, ...props}: Props) => {
  const {theme} = useContext(ThemeContext);
  return (
    <RSModal
      fade={false}
      toggle={toggle}
      wrapClassName={classnames({
        CLModal: true,
        [(theme || '')]: true,
        [`CLModal--placement-${placement}`]: true,
      })}
      {...props}>
      <ModalBody
        className="CLModal__body"
        style={{
          borderColor: borderColor || 'transparent',
        }}
      >
        {props.children}
        <ModalCloseButton toggle={toggle} />
      </ModalBody>
    </RSModal>
  );
};

