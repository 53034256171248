import {TextBlock, GraphObject} from 'gojs';

import {promptFont} from '../../styles/fonts';
import {MakeFnInitializers} from '../../types';
import {processRest} from '../../utils';

const {make} = GraphObject;

// TODO: Extract builder functions to a more general utility
export const text = (rest?: MakeFnInitializers) =>
  make(
      TextBlock,
      {
        font: promptFont(1),
        editable: false,
        stroke: 'black',
      },
      processRest(rest),
  );
