import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - var(--top-nav-height));
`;

export const TitleContainer = styled.div``;

export const Main = styled.div`
  overflow: hidden;
`;
