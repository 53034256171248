import {useEffect, useRef} from 'react';
import keyBy from 'lodash/keyBy';
import isEqual from 'lodash/isEqual';
import {
  Node as GOJSNode,
  Link,
  Diagram as GOJSDiagram,
  DiagramEvent,
  Set as GoSet,
  Key,
  ObjectData,
  IncrementalData,
  Part,
} from 'gojs';
import {ReactDiagram} from 'gojs-react';

import {
  DiagramLink,
  DiagramNode,
  ListNodeData,
} from 'src/types';
import {
  isDefined,
} from 'src/types/util';
import {LoadingPlaceholder} from 'src/components';
import {useDiagram} from 'src/hooks';


type DiagramData = {
  nodes: Array<DiagramNode>,
  edges: Array<DiagramLink>
}

type SelectedIds = Array<Key>;

export const Diagram = ({
  initDiagram,
  loading = false,
  setData = () => {},
  onUpdateElementSelections = () => {},
  data: {
    nodes,
    edges,
  } = {
    nodes: [],
    edges: [],
  },
  selectedIds = [],
}: {
  loading: boolean,
  setData: (_: DiagramData) => void,
  onUpdateElementSelections?: (_: {
    selectedNodeIds: Array<string>,
    selectedEdgeIds: Array<string>,
  }) => void,
  initDiagram: () => GOJSDiagram,
  data?: DiagramData
  selectedIds?: SelectedIds
}) => {
  const ref = useRef<ReactDiagram>(null);
  const diagram = useDiagram(ref);

  useEffect(() => {
    if (diagram) {
      const onSelectionChanged = (event: DiagramEvent) => {
        const selection = event.subject as GoSet<Part>;
        const selectionIds = selection.filter((it) => it instanceof GOJSNode)
            .map(({data}: {data: ListNodeData}) => data.id)
            .toArray();
        const selectionKeys = selection.filter((it) => it instanceof Link)
            .map(({data}: {data: ListNodeData}) => data)
            .filter((data) => data.id !== undefined)
            .map((data: ListNodeData) => data.id || '')
            .toArray();

        onUpdateElementSelections({
          selectedNodeIds: selectionIds,
          selectedEdgeIds: selectionKeys || [],
        });
      };

      selectedIds && diagram.selectCollection(selectedIds.map((k) => diagram.findPartForKey(k) || undefined).filter(isDefined));

      diagram.addDiagramListener('ChangedSelection', onSelectionChanged);
      return () => {
        diagram.removeDiagramListener('ChangedSelection', onSelectionChanged);
      };
    }
  }, [diagram, onUpdateElementSelections]);

  const modelChangeHandler = ({
    insertedNodeKeys = [],
    insertedLinkKeys = [],
    modifiedNodeData = [],
    modifiedLinkData = [],
    removedNodeKeys = [],
    removedLinkKeys = [],
  }: {
    insertedNodeKeys?: Array<string>,
    modifiedNodeData?: Array<DiagramNode>,
    removedNodeKeys?: Array<string>,
    insertedLinkKeys?: Array<string>,
    modifiedLinkData?: Array<DiagramLink>,
    removedLinkKeys?: Array<string>,
    modelData?: ObjectData,
  }) => {
    const idToNodeMap = keyBy(nodes, (n) => n.id);
    const idToLinkMap = keyBy(edges, (n) => n.id);

    modifiedNodeData.forEach((m) => {
      idToNodeMap[m.id] = m;
    });

    modifiedLinkData.forEach((m) => {
      idToLinkMap[m.id] = m;
    });

    removedNodeKeys.forEach((k) => {
      delete idToNodeMap[k];
    });

    removedLinkKeys.forEach((k) => {
      delete idToLinkMap[k];
    });

    // console.log({
    //   edges: edges.map(x => x.id),
    //   nodes: nodes.map(x => x.id),
    //   insertedNodeKeys,
    //   insertedLinkKeys,
    //   modifiedNodeData: modifiedNodeData.map(x => x.id),
    //   modifiedLinkData: modifiedLinkData.map(x => x.id),
    //   removedNodeKeys,
    //   removedLinkKeys,
    //   idToNodeMap,
    //   idToLinkMap,
    // });

    const noModNodeDataOrIsEqual = (!modifiedNodeData.length || modifiedNodeData.every((md) => {
      const match = nodes.find((d) => d.id === md.id);
      if (!match || isEqual(md, match)) {
        return true;
      } else {
        // console.log(md, match)
        return false;
      }
    }));

    const noModLinkDataOrIsEqual = (!modifiedLinkData.length || modifiedLinkData.every((md) => {
      const match = edges.find((d) => d.id === md.id);
      if (!match || isEqual(md, match)) {
        return true;
      } else {
        // console.log(md, match)
        return false;
      }
    }));

    const noInsertedLinksOrAlreadyHas = (!insertedLinkKeys.length || edges.every(({id}) => insertedLinkKeys.includes(id)));
    const noInsertedNodesOrAlreadyHas = (!insertedNodeKeys.length || nodes.every(({id}) => insertedNodeKeys.includes(id)));
    const noRemovedNodesOrAlreadyRemoved = (!removedNodeKeys.length || !nodes.some(({id}) => removedNodeKeys.includes(id)));
    const noRemovedLinksOrAlreadyRemoved = (!removedLinkKeys.length || !edges.some(({id}) => removedLinkKeys.includes(id)));
    // console.log({
    //   noModNodeDataOrIsEqual,
    //   noModLinkDataOrIsEqual,
    //   noInsertedLinksOrAlreadyHas,
    //   noInsertedNodesOrAlreadyHas,
    //   noRemovedNodesOrAlreadyRemoved,
    //   noRemovedLinksOrAlreadyRemoved,
    // });

    if (
      noModNodeDataOrIsEqual &&
        noModLinkDataOrIsEqual &&
        noInsertedLinksOrAlreadyHas &&
        noInsertedNodesOrAlreadyHas &&
        noRemovedNodesOrAlreadyRemoved &&
        noRemovedLinksOrAlreadyRemoved
    ) {
      return;
    }

    setData({
      edges: Object.values(idToLinkMap),
      nodes: Object.values(idToNodeMap),
    });
  };

  return (
    loading ? <LoadingPlaceholder position="absolute" scale={1.2} /> : (
          <ReactDiagram
            ref={ref}
            divClassName="JobDiagram"
            initDiagram={initDiagram}
            onModelChange={modelChangeHandler as (e: IncrementalData) => void}
            nodeDataArray={nodes}
            linkDataArray={edges}
            skipsDiagramUpdate={false}
          />
    )
  );
};
