import {FunctionComponent, useState} from 'react';
import groupBy from 'lodash/groupBy';
import classnames from 'classnames';
import {
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from 'reactstrap';

import {
  TabProps,
  TabListProps,
} from './types';

import './styles.css';

const DEFAULT_GROUP = 'default';

export const TabList = ({onTabChange, tabs}: TabListProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const updateActiveTab = (tabNumber: number) => {
    onTabChange && onTabChange({
      prev: tabs[activeTab],
      next: tabs[tabNumber],
    });
    setActiveTab(tabNumber);
  };

  const Tab: FunctionComponent<TabProps> = ({tabNumber, children}) => (
    <NavItem className="NavItem">
      <NavLink
        className={classnames({
          NavLink: true,
          active: tabNumber === activeTab,
        })}
        onClick={() => updateActiveTab(tabNumber) }
      >
        {children}
      </NavLink>
    </NavItem>
  );
  const tab = tabs[activeTab];

  let index = 0;
  return (
    <>
      <div className="TabList">
        {Object.values(groupBy(tabs, ({group}) => group || DEFAULT_GROUP)).reverse().map((tabs, i) => {
          return (
            <Nav key={i} className="TabList__tab-group" tabs>
              {tabs.map(({tab}, j) =>
                <Tab key={j} tabNumber={index++}>{tab}</Tab>,
              )}
            </Nav>
          );
        })}
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane className="TabList--pane">
          {tab.pane}
        </TabPane>
      </TabContent>

    </>
  );
};
// Previous implementation which we may need to return to.
// <TabContent activeTab={activeTab}>
//   {tabs.map(({pane}, i) =>
//     <TabPane className="TabList--pane" key={i} tabId={i}>
//       {pane}
//     </TabPane>,
//   )}
// </TabContent>
