import get from 'lodash/get';

import {fetchDataForNode} from 'src/graph';
import {isNode, isLink, transaction, toArray} from 'src/util/gojs';
import {isDefined} from 'src/types/util';
import {
  Diagram,
  Map as GoJSMap,
  InputEvent,
  GraphObject,
  Node,
  Part,
} from 'src/util/gojs/go';
import {
  makeNodeTemplate,
  makeLinkTemplate,
  makeDiagram,
  commitNodesToDiagram,
  removeElementsFromDiagram,
} from 'src/graph';
import type {Theme} from 'src/styles/colors';
import {THEMES} from 'src/styles/colors';
import {DEFAULT_THEME} from 'src/constants';
import {getContainsPath} from 'src/api/dependency';

export const unselect = (p: Part) => p.isSelected = false;
export const select = (p: Part | Node) => p.isSelected = true;

export const allChildrenRemoved = (node: Node) => node.findNodesOutOf().count === 0;

export const deselectAllNodes = (node: Node) => {
  const diagram = node.diagram;

  if (diagram) {
    node.diagram.selection.each(unselect);

    // reconcile selection with event handler
    const nodes = diagram.nodes;
    if (nodes) {
      const selectedNodes = toArray(nodes.filter(({isSelected}) => isSelected) || []).filter(isDefined);
      node.diagram?.selectCollection(selectedNodes);
    }
  }
};

export const fetchNodeAndPath = async (id: string) => {
  const response = await getContainsPath(id);

  const nodes = response.data.data.nodes;
  const edges = response.data.data.relationships;
  return {
    nodes: nodes.map((n) => (
      {
        metadata: {
          type: n.primaryLabel,
          identity: n.identity,
        },
        text: n.name,
        isHighlighted: false,
        id: n.id,
        relationshipCount: n.relationshipCount,
        category: n.relationshipCount && n.relationshipCount > 0 ? 'parent' : 'leaf',
        isTreeExpanded: n.id === id ? false : true,
        properties: {
          diffValue: n.properties.diffValue,
          diffImpacted: n.properties.diffImpacted,
        },
      }
    )),
    edges: edges.map((e) =>({
      ...e,
      from: e.startId,
      to: e.endId,
    })),
  };
};

export const replaceSelection = (node: Node) => {
  deselectAllNodes(node);
  node.isSelected = true;

  // reconcile selection with event handler
  const nodes = node.diagram?.nodes;
  if (nodes) {
    const selectedNodes = toArray(nodes.filter(({isSelected}) => isSelected) || []).filter(isDefined);
    node.diagram?.selectCollection(selectedNodes);
  }
};

const recursiveOperation = (operation: (node: Node) => void) => (node: Node, predicateFn: (n: Node) => boolean = () => true) => {
  function op(node: Node) {
    if (!node) {
      return;
    }
    const iterator = node.findLinksOutOf().filter((link) => {
      return link.data.contains || link.data.groups;
    }).map((x) => x.toNode);

    if (iterator.count === 0) {
      return;
    }


    iterator.each((n) => {
      if (!n) {
        return;
      }
      op(n);

      if (predicateFn && !predicateFn(n)) {
        return;
      }
      operation(n);
    });
  }

  op(node);

  if (!predicateFn(node)) {
    return;
  }

  operation(node);
};

export const hideChildren = recursiveOperation((node) => {
  const parentNode = node.findTreeParentNode();
  const diagram = node.diagram;

  if (!diagram) {
    return;
  }

  diagram.removeParts(node.findLinksInto());

  if (parentNode) {
    if (allChildrenRemoved(parentNode)) {
      parentNode.collapseTree();
      parentNode.data.wasLoaded = false;
    }
  }

  node.findNodesOutOf().each((n) => {
    if (n && n.findLinksInto().filter((l) => l.fromNode?.key !== node.key).count === 0) {
      // guessing there maybe a gotcha here too
      diagram.removeParts(n.findTreeChildrenNodes());
      diagram.remove(n);
    }
  });

  diagram.remove(node);
});

export const selectNodeAndChildren = recursiveOperation(select);

export const makeNodeMetaDataGetter = <T>(key: string, defaultValue?: T) => (node: Node) => get(node, ['data', 'metadata', key], defaultValue);

export const getNodeIdentity = makeNodeMetaDataGetter('identity');
export const getNodeType = makeNodeMetaDataGetter('type');

const toggleNodeExpansionHandler = async (_e: InputEvent, obj: GraphObject) => {
  const node = obj.part;

  if (!isNode(node)) {
    return;
  }

  await toggleNodeExpansion(node);
};

export async function toggleNodeExpansion(
    node: Node,
) {
  const diagram = node.diagram;
  if (!diagram) {
    return;
  }

  const {category} = node;
  if (category === 'parent') {
    const children = node.findLinksOutOf();

    if (children.count === 0) {
      const {nodes, edges} = await fetchDataForNode(node);
      transaction('add children', diagram, () => {
        commitNodesToDiagram(diagram, {
          nodes,
          edges,
        });
      });
    } else {
      transaction('remove children', diagram, () => {
        const keys = node.findTreeParts().map((n) => n.key?.toString()).toArray().filter(isDefined).filter((k) => k !== node.key);

        // We just attempt to remove both types here with any available key.
        removeElementsFromDiagram(diagram, {
          nodes: keys,
          edges: [...keys],
        });
      });
    }
  }
}

// const clickToggleNodeSelection = (_e: InputEvent, obj: GraphObject) => {
//   const node = obj.part;
//
//   if (!isNode(node)) {
//     return;
//   }
//
//   selectNodeAndChildren(node);
//
//   // reconcile selection with event handler
//   const nodes = node.diagram?.nodes;
//   if (nodes) {
//     const selectedNodes = toArray(nodes.filter(({isSelected}) => isSelected) || []).filter(isDefined);
//     node.diagram?.selectCollection(selectedNodes);
//   }
// };


export const makeDiagramInitializer = ({
  showContextMenu,
  onInitialized = () => {},
  theme = THEMES[DEFAULT_THEME],
  themeName = DEFAULT_THEME,
}: {
  showContextMenu: (x: number, y:number) => void,
  onInitialized?: (_:Diagram) => void,
  theme?: Theme,
  themeName: keyof typeof THEMES,
}) => () => {
  async function prepareAndShowContextMenu(e: InputEvent, obj: GraphObject) {
    if (!isNode(obj)) {
      return;
    }

    const viewPoint = e.viewPoint;
    showContextMenu(viewPoint.x, viewPoint.y);
  }

  async function prepareAndShowLinkContextMenu(e: InputEvent, obj: GraphObject) {
    if (!isLink(obj)) {
      return;
    }

    const viewPoint = e.viewPoint;
    showContextMenu(viewPoint.x, viewPoint.y);
  }

  const diagram = makeDiagram({
    theme,
    themeName,
    handleContextClick: (e) => {
      const viewPoint = e.viewPoint;
      showContextMenu(viewPoint.x, viewPoint.y);
    },
  });

  diagram.nodeTemplateMap = new GoJSMap<string, Node>()
      .add('', makeNodeTemplate({
        expandable: true,
        handleClickExpander: toggleNodeExpansionHandler,
        // handleDoubleClick: clickToggleNodeSelection,
        handleContextClick: prepareAndShowContextMenu,
      }));
  diagram.linkTemplate = makeLinkTemplate({
    handleContextClick: prepareAndShowLinkContextMenu,
  });

  diagram.addDiagramListener('LayoutCompleted', (_e) => {
    const selected = diagram.selection.first();
    if (selected) {
      diagram.scrollToRect(selected.actualBounds);
    }
  });

  onInitialized(diagram);
  return diagram;
};
