import {KeyboardEvent as ReactKeyboardEvent, useEffect, useState} from 'react';
import classnames from 'classnames';

import * as featureFlags from 'src/feature-flags';
import {useFeatureFlag, useTextFilter} from 'src/hooks';

type FlagArg = Parameters<typeof useFeatureFlag>[0]

import './styles.css';

const ANIMATION_DURATION = 450;
const DOWN = true;
const UP = false;

let timer: NodeJS.Timeout;
const keysDown:{[key: string]: boolean} = {};

const setKeyState = (key: string, isKeyDown: boolean) => {
  if (key === 'Meta' || key === 'OS') {
    keysDown['OS'] = isKeyDown;
  } else {
    keysDown[key] = isKeyDown;
  }
};

let keyChain = '';

let keyChainTimer: NodeJS.Timeout;

export const Console = () => {
  const [terms, updateInput] = useTextFilter();

  const [consoleState, setConsoleState] = useState<'visible' | 'showing' | 'hidden' | 'hiding'>('hidden');

  useEffect(() => {
    const showConsole = () => {
      clearTimeout(timer);
      setConsoleState('visible');
      // timer = setTimeout(() => setConsoleState('visible'), ANIMATION_DURATION);
    };

    const hideConsole = () => {
      setConsoleState('hiding');
      clearTimeout(timer);
      timer = setTimeout(() => setConsoleState('hidden'), ANIMATION_DURATION);
    };

    const toggleConsole = () => {
      consoleState === 'hidden' ? showConsole() : hideConsole();
    };

    const addKey = (key: string) => {
      if (keyChainTimer) {
        clearTimeout(keyChainTimer);
      }
      keyChain = keyChain + key;

      keyChainTimer = setTimeout(() => {
        keyChain = '';
      }, 1000);
    };

    const trackKey = ({key}: KeyboardEvent) => {
      if (!key) {
        return;
      }

      // skip mod keys like 'control' 'alt', except 'escape'
      if (key.length === 1 || key === 'Escape') {
        addKey(key);
      }

      setKeyState(key, DOWN);
    };

    const handleKey = (e: KeyboardEvent) => {
      const {key} = e;

      if (!key) {
        return;
      }

      if (key === 'Escape' && consoleState === 'visible') {
        hideConsole();
      }

      if (keysDown['Control'] && keysDown['Alt'] && keysDown['Shift'] && keysDown['K']) {
        toggleConsole();
      }

      setKeyState(key, UP);
    };

    window.addEventListener('keyup', handleKey);
    window.addEventListener('keydown', trackKey);
    return () => {
      window.removeEventListener('keyup', handleKey);
      window.removeEventListener('keydown', trackKey);
    };
  }, [consoleState]);

  const result = classnames({
    'Console': true,
    'Console--effect-SlideDown': consoleState !== 'hidden',
    'Console--effect-SlideUp': consoleState === 'hiding',
  });

  return consoleState !== 'hidden' ? (
    <div className={result}>
      <div className="Console__title-container">
        <h1 className="Console__title">Feature Flags</h1>
        <input autoFocus onKeyUp={(e: ReactKeyboardEvent<HTMLInputElement>) => updateInput(e.currentTarget.value)} placeholder='Search...' className="Console__text-field" />
      </div>
      <div className="Console__content">
        {Object.entries(featureFlags).filter(([k]) => terms.length ? terms.every((t) => k.toLowerCase().includes(t.toLowerCase())) : true).map(([name]) => (
          <FlagToggle key={name} flag={name as FlagArg} />
        ))}
      </div>
    </div>
  ) : null;
};

const FlagToggle = ({flag}: {flag: FlagArg}) => {
  const [flagValue, setFlagValue] = useFeatureFlag(flag);
  const description = featureFlags[flag].description;
  const isBoolean = typeof flagValue === 'boolean';
  return (
    <p title={description}>
      <label>
        {isBoolean ? (
          <BooleanFlag flag={flag.replace(/feature.flag./i, '')} value={flagValue} setValue={setFlagValue} />
        ) : (
          <GenericFlag flag={flag.replace(/feature.flag./i, '')} value={flagValue} />
        )}
      </label>
    </p>
  );
};

const GenericFlag = ({flag, value}: {flag: string, value: unknown}) => (
  <p>
    <span>{flag}:</span>
    {JSON.stringify(value)}
  </p>
);

const BooleanFlag = ({flag, value, setValue}: {flag: string, value: boolean, setValue: (value: boolean) => void}) => (
  <div className="BooleanFlag">
    <input type="checkbox" style={{position: 'absolute', left: '-10000px'}} onChange={() => setValue(!value)} checked={!!value}/>
    <span className="Console__checkbox">
      {value ? '☒' : '☐'}
    </span>
    &nbsp;
    <span>{flag.replace(/feature.flag./i, '')}</span>
  </div>
);
