import React, {useEffect, useState} from 'react';
import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik';
import {Button, FormGroup, Input, Label} from 'reactstrap';
import withReactContent from 'sweetalert2-react-content';
import SwalBase from 'sweetalert2';

import {Page} from 'src/components/Page';
import {AnalyzeDBRequest} from 'src/types';
import {isError, isSuccess} from 'src/api';
import {postAnalyzeDB} from 'src/api/serverSideScanning';

import {FormFields, Errors} from './types';


const DATABASE_TYPES = [{
  label: 'MySQL',
  id: 'mysql',
}, {
  label: 'Postgresql',
  id: 'postgres',
}, {
  label: 'SQL Server',
  id: 'sqlserver',
}, {
  label: 'Oracle',
  id: 'oracle',
}];
const OPTIONS = [{id: '', label: '', disabled: true}, ...DATABASE_TYPES.map((o) => ({...o, disabled: false}))];
const Swal = withReactContent(SwalBase);

export const ScanDatabasePage = () => {
  const [type, setType] = useState<string>('');
  const [hostname, setHostname] = useState<string | undefined>();
  const [database, setDatabase] = useState<string | undefined>();
  const [url, setUrl] = useState<string | undefined>();
  const TYPE_FORMATTERS = {
    'mysql': `jdbc:mysql://${hostname}/${database}`,
    'postgres': `jdbc:postgresql://${hostname}/${database}`,
    'sqlserver': `jdbc:sqlserver://${hostname};database=${database};`,
    'oracle': `jdbc:oracle:thin:@//${hostname}/${database}`,
  };

  useEffect(() => {
    if (database && hostname && type) {
      // setUrl(`${type} ${hostname}, ${database}`);
      setUrl(TYPE_FORMATTERS[type]);
    }
  }, [database, type, hostname]);

  return (
    <Page title='Scan Database'>
      <div style={{margin: '1rem', width: '50%'}}>
        <div style={{marginBottom: '1rem'}}>Here you can scan your databases.</div>
        <Formik<FormFields>
          initialValues={{
            application: '',
            username: '',
            password: '',
            jdbcUrl: '',
          }}
          validate={(values) => {
            const errors: Errors = {};

            if (!values.application) {
              errors.application = '* required';
            }

            if (!values.username) {
              errors.username = '* required';
            }

            if (!values.password) {
              errors.password = '* required';
            }

            if (!url) {
              errors.jdbcUrl = '* required';
            }

            return errors;
          }}
          onSubmit={async (values) => {
            const request: AnalyzeDBRequest = {
              application: values.application,
              username: values.username,
              password: values.password,
              jdbcUrl: url || '',
            };
            const response = await postAnalyzeDB(request);
            if (isSuccess(response)) {
              await Swal.fire({
                title: 'Success!',
                text: `Successfully submitted ${values.jdbcUrl} for scanning!`,
                icon: 'success',
                timer: 1200,
                showConfirmButton: false,
              });
              return response.data;
            } else if (isError(response)) {
              await Swal.fire({
                title: 'Error!',
                text: `Error Scanning ${values.jdbcUrl}!`,
                icon: 'error',
                timer: 1200,
              });
            }
          }}
        >
          {({isSubmitting, setFieldValue, values}) => (
            <Form>
              <FormGroup>
                <Label for='application'>Application</Label>
                <ErrorMessage
                  className='form-error'
                  name='application'
                  component='div'
                />
                <Input name='application' type='text' tag={Field} />
                <Label for='username'>Username</Label>
                <ErrorMessage
                  className='form-error'
                  name='username'
                  component='div'
                />
                <Input name='username' type='text' tag={Field} />
                <Label for='password'>Password</Label>
                <ErrorMessage
                  className='form-error'
                  name='password'
                  component='div'
                />
                <Input name='password' type='password' tag={Field} />
                <div style={{borderTop: 'solid', margin: '1rem 0 1rem 0'}} />
                <Label for='database'>Database</Label>
                <ErrorMessage
                  className='form-error'
                  name='database'
                  component='div'
                />
                <Input name='database' type='text' tag={Field} value={database} onChange={(e) => setDatabase(e.target.value)} />
                <Label for='hostname'>Hostname or IP Address</Label>
                <ErrorMessage
                  className='form-error'
                  name='hostname'
                  component='div'
                />
                <Input name='hostname' type='text' tag={Field} value={hostname} onChange={(e) => setHostname(e.target.value)} />
                <Label for="types">Database Types</Label>{' '}
                <ErrorMessage
                  className='form-error'
                  name='types'
                  component='div'
                />
                <FieldArray name="types">
                  {() => (
                    <Input
                      tag={Field}
                      className='form-select'
                      name='type'
                      as="select"
                      value={type}
                      onChange={(e) => {
                        setType(e.target.value);
                      }
                      }
                    >
                      {OPTIONS.map(({label, id, disabled}, index) => (
                        <option key={id} disabled={disabled} value={id}>{label}</option>
                      ))}
                    </Input>
                  )}
                </FieldArray>

                <div style={{
                  textAlign: 'center',
                  margin: '1rem 0 1rem 0',
                }}>
                  <p>
                    --- OR ---
                  </p>
                </div>
                <Label for='jdbcUrl'>JDBC Connection String</Label>
                <ErrorMessage
                  className='form-error'
                  name='jdbcUrl'
                  component='div'
                />
                <Input
                  name='jdbcUrl'
                  type='text'
                  tag={Field}
                  value={url}
                  onChange={(e) => {
                    setUrl(e.target.value);
                    setDatabase('');
                    setHostname('');
                    setType('');
                  }
                  }
                />
              </FormGroup>
              <Button type="submit" color="primary" disabled={isSubmitting}>Scan</Button>
            </Form>
          )}
        </Formik>
      </div>
    </Page>
  );
};
