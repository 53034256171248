import {useEffect, useState} from 'react';

import './styles.css';

const ERROR_PLACEHOLDER_DELAY_MS = 500;

type Position = 'initial' | 'inherit' | 'absolute' | 'relative';

export const ErrorPlaceholder = ({
  position = 'initial',
  scale = 1,
  noDelay = false,
  plaintext = false,
  message,

}: {
  position?: Position,
  scale?: number,
  noDelay?: boolean,
  plaintext?: boolean,
  message?: string,
}) => {
  const [displayed, setDisplayed] = useState(false);

  useEffect(() => {
    if (noDelay) {
      setDisplayed(true);
    } else {
      const timer = setTimeout(() => {
        setDisplayed(true);
      }, ERROR_PLACEHOLDER_DELAY_MS);

      return () => clearTimeout(timer);
    }
  }, []);

  if (!displayed) {
    return null;
  }

  return (
      plaintext ? (
        <div><p>{`error: ${message}`}</p></div>
      ) : (
        <div
          className="ErrorPlaceholder"
          style={{
            transform: `scale(${scale})`,
            position,
          }}
        >
        </div>
      )
  );
};
