import Swal from 'sweetalert2';

import {
  ApiError,
  ApiErrorResponse,
  AuthorizationConfig,
  BasicAuthorizationConfig,
  TypedApiResponse,
} from 'src/types';
import {updateConfig} from 'src/api/authConfig';

export const updateAuthConfig = async (config: AuthorizationConfig | BasicAuthorizationConfig) : Promise<AuthorizationConfig | BasicAuthorizationConfig | ApiError | undefined> => {
  const response = await updateConfig(config);
  if (isSuccess(response)) {
    Swal.fire({
      title: 'Success!',
      text: 'Authorization Config Updated Successfully',
      icon: 'success',
      timer: 1200,
      showConfirmButton: false,
    });
    return response.data;
  } else if (isError(response)) {
    Swal.fire({
      title: 'Error!',
      text: 'Error Updating Authorization Config!',
      icon: 'error',
      html: response.error,
      timer: 1200,
    });
    return response.error;
  }
};

const isError = (response: TypedApiResponse<unknown> | ApiErrorResponse | Error): response is ApiErrorResponse => {
  return 'status' in response && response.status === 'error';
};

const isSuccess = (response: TypedApiResponse<AuthorizationConfig> | TypedApiResponse<BasicAuthorizationConfig> | ApiErrorResponse | Error)
  : response is TypedApiResponse<AuthorizationConfig | BasicAuthorizationConfig> => {
  return 'status' in response && response.status === 'success';
};
