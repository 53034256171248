import {useContext, useEffect, useState} from 'react';
import {Button} from 'reactstrap';
import {useSubscription} from 'react-stomp-hooks';

import {getDefaultMaterializedViewId, getLatestDefaultMaterializedView} from 'src/api/materializedViews';
import {Banner} from 'src/components';
import {WorkspaceContext} from 'src/context';
import {MaterializedViewStatusMessage} from 'src/types';

type Props = {
  dataAvailable: () => void,
}

export const MaterializedViewAlert = ({dataAvailable}: Props) => {
  const [showAlert, setShowAlert] = useState(false);
  const [waitingForData, setWaitingForData] = useState(false);
  const [showFirstScan, setFirstScan] = useState(false);
  const [showNewScan, setNewScan] = useState(false);
  const [showMatStarted, setMatStarted] = useState(false);
  const {currentWorkspace} = useContext(WorkspaceContext);


  useSubscription('/topic/materializedViews', (message) => {
    const response: MaterializedViewStatusMessage = JSON.parse(message.body);
    if (response.workspaceId && response.workspaceId === currentWorkspace?.id) {
      const materializedView = response.materializedView;

      // Only auto load if it is the primary workspace
      if (currentWorkspace.primary && waitingForData) {
        if (materializedView?.buildEnded) {
          setShowAlert(false);
          dataAvailable();
        } else if (!materializedView?.buildEnded && !materializedView?.buildFailed) {
          setShowAlert(true);
          setFirstScan(true);
        }
      } else if (materializedView.buildEnded) {
        setShowAlert(true);
        setNewScan(true);
        setFirstScan(false);
        setMatStarted(false);
      } else if (materializedView.buildStarted) {
        setShowAlert(true);
        setNewScan(false);
        setFirstScan(false);
        setMatStarted(true);
      }
    }
  });

  const loadAlertData = async () => {
    const viewIdResponse = await getDefaultMaterializedViewId();
    const materializedViewResponse = await getLatestDefaultMaterializedView();
    if (viewIdResponse.status === 'success' && materializedViewResponse.status === 'success') {
      const viewId = viewIdResponse.data;
      if (!waitingForData && !viewId) {
        setWaitingForData(true);
      }
    }
  };

  useEffect(() => {
    loadAlertData();
  }, []);

  const displayBanner = currentWorkspace && showAlert && (showFirstScan || showNewScan || showMatStarted);

  const handleLinkClick = () => {
    dataAvailable();
    setShowAlert(false);
  };

  return (
    displayBanner ?
        (
          <Banner onClose={() => setShowAlert(false)}>
            {showFirstScan && 'Scan Complete. Hang tight as we analyze the results.'}
            {
              showNewScan &&
                <>New scan data available <Button color='link' onClick={handleLinkClick}>Load Now</Button></>
            }
            {showMatStarted && 'Scan Started on new data!'}
          </Banner>
        ) : null
  );
};
