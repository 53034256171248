import {useState} from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';
import Swal from 'sweetalert2';

import {postConfiguration} from 'src/api/awsConfig';

import './administration.css';

interface Errors {
  accountId?: string;
  accessKey?: string;
  secretKey?: string;
}

const NewAwsConfig = ({isOpen, onClose, editData}) => {
  const [validationError, setValidationError] = useState('');

  const toggle = () => {
    onClose();
  };

  async function handleSubmit(values) {
    try {
      setValidationError('');
      // const validate = await validateConfiguration(values);

      // if (validate.status !== 'success') {
      //   return setValidationError('Configuration validation failed');
      // }

      const res = await postConfiguration(values);

      onClose();
      if (res.data.status === 'success') {
        Swal.fire({
          title: 'Success!',
          text: 'Configuration added successfully',
          icon: 'success',
          timer: 1200,
          showConfirmButton: false,
        });
      }
    } catch ({errors}) {
      if (errors && Array.isArray(errors)) {
        errors.forEach((error) => {
          const errorMessage =
          error.errors === undefined ?
            error.map((err) => err.message).join('. ') :
            error.errors[Object.keys(error.errors)[0]];
          console.error(errorMessage);
        });
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fade={false}
      modalClassName="height-auto"
    >
      <ModalHeader toggle={toggle}>
        {editData ? 'Edit' : 'New'} Configuration
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            accountId: (editData && editData.accountId) || '',
            accessKey: '',
            secretKey: '',
          }}
          validate={(values) => {
            const errors: Errors = {};
            if (!values.accountId) {
              errors.accountId = 'Account ID is required';
            }
            if (!values.accessKey) {
              errors.accessKey = 'Access Key is required';
            }
            if (!values.secretKey) {
              errors.secretKey = 'Secret Key is required';
            }
            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {({isSubmitting}) => (
            <Form>
              <FormGroup>
                <Label for="accountId">Account ID</Label>
                <Input
                  tag={Field}
                  name="accountId"
                  type="text"
                  component="input"
                  disabled={editData ? true : false}
                />
                <ErrorMessage
                  className="form-error"
                  name="accountId"
                  component="div"
                />
              </FormGroup>
              <FormGroup>
                <Label for="accessKey">Access Key</Label>
                <Input
                  tag={Field}
                  name="accessKey"
                  type="text"
                  component="input"
                />
                <ErrorMessage
                  className="form-error"
                  name="accessKey"
                  component="div"
                />
              </FormGroup>
              <FormGroup>
                <Label for="secretKey">Secret Key</Label>
                <Input
                  tag={Field}
                  name="secretKey"
                  type="password"
                  component="input"
                />
                <ErrorMessage
                  className="form-error"
                  name="secretKey"
                  component="div"
                />
              </FormGroup>
              {validationError && (
                <span style={{color: 'red'}}>{validationError}</span>
              )}
              <hr />
              <Button type="submit" disabled={isSubmitting} color="primary">
                Save
              </Button>{' '}
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default NewAwsConfig;
