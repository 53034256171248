import {CreateButton} from './components';
export const MAIN_HELP_TEXT = `
Release Assistant is a "What If" simulator. Here you can choose between different software components,
including both external libraries as well as those which you've developed internally. Release Assistant
will generate a simulated release, stitching together your selected components. Each release can then
be viewed independently, or compared to answer common "What If" scenarios, such as:
`;

export const MAIN_HELP_CONTENT = (
  <>
    <p>{MAIN_HELP_TEXT}</p>
    <ul>
      <li>What is the blast radius for the changes in a Release Candidate vs the Current Release?</li>
      <li>What would happen if I upgraded to a newer version of Apache Commons?</li>
    </ul>
  </>
);

export const RELEASE_ANALYSIS_RESULTS_TITLE_TEXT = 'Release Analysis Results';
export const RELEASE_ANALYSIS_RESULTS_HELP_TEXT = 'Choose two releases and select run to begin analysis. Blast radius results will appear in this list.';

export const RELEASES_TITLE_TEXT = 'Releases';

export const RELEASES_HELP_TEXT = <>Create a Release by selecting the <CreateButton /> button. Release simulation progress and results will appears in this list.</>;
