import {AxiosResponse} from 'axios';

import {CODELOGIC_API_BASE_URL} from 'src/config';
import {
  ApiErrorResponse,
  ApiResponse,
  ApiSuccessResponse,
  ScanMetadata,
  ScanRoot,
  ScanSession,
  ScanSpace,
  TypedApiResponse,
} from 'src/types';

import {axiosInstance} from './baseApi';
import {isAxiosError} from './utils';

import {makeResourceListFetch} from './index';

export async function getScans(scanSpaceId: string, page: number, size: number, sort: string) {
  const endpoint = `${CODELOGIC_API_BASE_URL}/scanspace/${scanSpaceId}/scans`;

  try {
    const response = await axiosInstance.get<TypedApiResponse<Array<ScanMetadata>>>(endpoint, {
      withCredentials: true,
      params: {page, size, sort},
    });
    return response.data;
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
}

export async function getDefaultScanSpaceScanRoots({
  scanSpaceId,
  page,
  size,
  filters,
}: {
  scanSpaceId?: string,
  page?: number,
  size?: number,
  filters?: {
    nameFilter?: string,
    applicationFilter?: string,
    gitBranchFilter?: string,
    gitHeadSHAFilter?: string,
  }
}) {
  const endpoint = `${CODELOGIC_API_BASE_URL}/scanspace/scanRoots`;
  try {
    const response = await axiosInstance.get<TypedApiResponse<Array<ScanRoot>>>(endpoint, {
      withCredentials: true,
      params: {
        page,
        size,
        scanSpaceId,
        ...filters,
      },
    });
    return response.data;
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
}

export async function getScanSessions(scanSpaceId: string, page: number, size: number, sort: string): Promise<TypedApiResponse<Array<ScanSession>>> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/scanspace/${scanSpaceId}/scan-sessions`;

  try {
    const response = await axiosInstance.get<TypedApiResponse<Array<ScanSession>>>(endpoint, {
      withCredentials: true,
      params: {page, size, sort},
    });
    return response.data;
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
}

export async function deleteScanSession(scanSessionId: string) {
  try {
    const response = await axiosInstance.delete<ApiResponse>(`${CODELOGIC_API_BASE_URL}/scan-session/${scanSessionId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    if (isAxiosError<ApiResponse>(e)) {
      if (e.response) {
        return e.response;
      }
    }
    throw e;
  }
}

export async function deleteScanMetadata(scanSpaceId: string, scanContextId: string) {
  try {
    const response = await axiosInstance.delete<ApiResponse>(`${CODELOGIC_API_BASE_URL}/scanspace/${scanSpaceId}/scan/${scanContextId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    if (isAxiosError<ApiResponse>(e)) {
      if (e.response) {
        return e.response;
      }
    }
    throw e;
  }
}

export const getScanSpaces = makeResourceListFetch<Array<ScanSpace>>({method: 'GET', path: 'scanspace', defaultSortBy: 'displayName,asc,ignoreCase'});

export async function getOrCreateScanSpaceByName(name: string): Promise<TypedApiResponse<ScanSpace>> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/scanspace/by-name?name=${name}`;

  try {
    const response = await axiosInstance.put(endpoint, {withCredentials: true});

    return response.data;
  } catch (e) {
    return {
      status: 'error',
    };
  }
}

export async function getDefaultScanSpaceApplications(): Promise<TypedApiResponse<Array<string>>> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/scanspace/applications/`;

  try {
    const response = await axiosInstance.get(endpoint, {
      withCredentials: true,
      params: {sort: 'name,asc,ignoreCase'},
    },
    );

    return response.data;
  } catch (e) {
    return {
      status: 'error',
    };
  }
}

export async function getScanSpaceApplications(id: string): Promise<TypedApiResponse<Array<string>>> {
  const endpoint = `${CODELOGIC_API_BASE_URL}/scanspace/${id}/applications/`;

  try {
    const response = await axiosInstance.get(endpoint, {
      withCredentials: true,
      params: {sort: 'name,asc,ignoreCase'},
    });

    return response.data;
  } catch (e) {
    return {
      status: 'error',
    };
  }
}


export async function deleteScanSpace(id: string) {
  try {
    const response = await axiosInstance.delete<ApiResponse>(`${CODELOGIC_API_BASE_URL}/scanspace/${id}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    if (isAxiosError<ApiResponse>(e)) {
      if (e.response) {
        return e.response;
      }
    }

    throw e;
  }
}

function successHandler(value: AxiosResponse) {
  return value.data as ApiSuccessResponse;
}

export async function postScanSpace(data: ScanSpace): Promise<ApiSuccessResponse | ApiErrorResponse | Error> {
  return await axiosInstance.post(`${CODELOGIC_API_BASE_URL}/scanspace`, data, {withCredentials: true})
      .then(successHandler);
}

export async function updateScanSpace(data: ScanSpace): Promise<ApiSuccessResponse | ApiErrorResponse | Error> {
  return axiosInstance.put(`${CODELOGIC_API_BASE_URL}/scanspace/${data.id}`, data, {withCredentials: true})
      .then(successHandler);
}
