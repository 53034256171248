import classnames from 'classnames';

import {Props} from './types';

import './styles.css';

export const Page = ({
  title,
  controls = null,
  className,
  children,
  variant = 'default',
}: Props) => (
  <div className={classnames({
    'Page': true,
    'Page--fullscreen': variant === 'fullscreen',
    [className || '']: !!className,
  })}>
    <div className={classnames({
      'Page__header': true,
      'Page__header--fullscreen': variant === 'fullscreen',
    })}>
      {title && (
        <div className="Page__title-container">
          {typeof title === 'string' ? (<h1 className="Page__title">{title}</h1>) : title}
        </div>
      )}
      <div className="Page__controls">{controls}</div>
    </div>
    <div className={classnames({
      'Page__content': true,
      'Page__content--fullscreen': variant === 'fullscreen',
    })}>{children}</div>
  </div>
);

