import {CSSProperties, PropsWithChildren} from 'react';

import './styles.css';

export const GridColumnsLayout = ({
  children,
  columnRatios,
  styles,
}: PropsWithChildren<{
  styles?: CSSProperties,
  columnRatios: Array<number | string>,
}>) => (
  <div
    style={{
      ...styles,
      gridTemplateColumns: columnRatios.map((r) => `${typeof r === 'number' ? r + 'fr' : r}`).join(' '),
    }}
    className="GridColumnsLayout">
    {children}
  </div>
);
