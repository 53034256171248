import {useState} from 'react';
import {Form as FormikForm, Formik} from 'formik';
import {Button} from 'reactstrap';

import {DEFAULT_SERVER_ADDRESS} from './constants';
import {FormData, Props} from './types';
import {Step1, Step2} from './steps';
import {
  isValidServerAddress,
  isValidScanPath,
  makeCommandGenerator,
} from './utils';

import './styles.css';


const STEPS = [Step1, Step2] as const;

const LAST_STEP_INDEX = STEPS.length - 1;

export const MultiStepForm = ({
  agentType,
  onCancel,
  onSubmit,
  agentProperties,
  agentLimitReached,
}: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const generateCommand = makeCommandGenerator(agentType);

  const isLastStep = currentStep === LAST_STEP_INDEX;
  const isFirstStep = currentStep === 0;

  const next = () => {
    isLastStep ? onSubmit() : setCurrentStep(currentStep + 1);
  };

  const back = () => {
    isFirstStep ?
      onCancel() : setCurrentStep(currentStep - 1);
  };

  const CurrentStepComponent = STEPS[currentStep];

  return (
    <Formik<FormData>
      initialValues={{
        scanPath: '',
        serverAddress: DEFAULT_SERVER_ADDRESS,
        command: '',
        agentType,
        application: '',
        agentUUID: agentProperties?.agentUUID,
        agentPassword: agentProperties?.agentPassword,
        username: '',
        password: '',
        jdbcUrl: '',
        database: '',
      }}
      validate={(values) => {
        const errors: {[K in keyof typeof values]?: string} = {};
        if (!isValidServerAddress(values.serverAddress)) {
          errors.serverAddress = 'Server address must be a valid hostname or ip address.';
        }

        if (agentType !== 'sql' && !isValidScanPath(values.scanPath)) {
          errors.scanPath = 'required';
        }

        if (!values.application) {
          errors.application = 'required';
        }

        if (agentType === 'sql') {
          if (!values.username) {
            errors.username = 'required';
          }
          if (!values.password) {
            errors.password = 'required';
          }
          if (!values.jdbcUrl) {
            errors.jdbcUrl = 'required';
          }
        }

        values.command = generateCommand(values);

        return errors;
      }}
      onSubmit={next}
    >
      {({isSubmitting, isValid, values}) => (
        <FormikForm className='MultiStepForm' autoComplete='off'>
          <div className = "MultiStepForm__section" >
            <CurrentStepComponent agentLimitReached={agentLimitReached} {...values} />
          </div>
          <div className="MultiStepForm__controls">
            <Button
              type="submit"
              color="primary"
            >
              {currentStep === LAST_STEP_INDEX ? 'Close' : 'Next'}
            </Button>
            <Button type="button" color="secondary" onClick={back}>
              {isFirstStep ? 'Cancel' : 'Back'}
            </Button>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};
