import {FormGroup, Input, Label} from 'reactstrap';

import './styles.css';

type Props = {
 name: string;
 onChange: (value: boolean) => void;
 isChecked: boolean;
}

export const ToggleSwitch = ({name, onChange, isChecked}: Props) => {
  const handleInputChange = () => {
    onChange(!isChecked);
  };

  return (
    <FormGroup check style={{display: 'flex'}}>
      <Label className="ToggleSwitch__switch" check>
        <Input
          type="checkbox"
          name={name}
          checked={isChecked}
          onChange={handleInputChange}
          className="custom-control-input"
        />
        <span className="ToggleSwitch__slider round">
        </span>
      </Label>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '1rem'}}>
        {isChecked ? 'On' : 'Off'}
      </div>
    </FormGroup>
  );
};
