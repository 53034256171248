import type {ThemeName} from 'src/context';

const colorPrimaryRedValues = [0, 73, 120];
const colorPrimaryGreenValues = [199, 219, 76];

const colorDarkGray1 = '#495057';
const colorLight1 = '#ffffff';
const colorDark1 = '#000000';

export const colorPrimaryRed = `rgb(${colorPrimaryRedValues.join(',')})`;
export const colorPrimaryGreen = `rgb(${colorPrimaryGreenValues.join(',')})`;

export const COLOR_EXTRACTED_0 = 'rgb(0, 0, 0)'; // '#000';
export const COLOR_EXTRACTED_7 = 'rgb(108, 117, 125)'; // '#6c757d';
export const COLOR_EXTRACTED_58 = 'rgb(255, 255, 255)'; // '#fff';
export const COLOR_EXTRACTED_59 = 'rgb(26, 111, 173)'; // '#1a6fad';
export const COLOR_EXTRACTED_83 = 'rgb(89, 90, 90)'; // '#595a5a';
export const COLOR_EXTRACTED_85 = 'rgb(34, 119, 255)'; // '#2277ff';

type ThemeFields = 'color-text' | 'color-background--standard';
export type Theme = Record<ThemeFields, string>;

// DEFAULT_THEME's colors are intentionally entirely outside of our color-scheme as a last-ditch fallback.
export const DEFAULT_THEME: Theme = {
  'color-text': 'black',
  'color-background--standard': 'white',
};

export const THEMES: {[K in ThemeName]: Theme} = {
  'theme-light': {
    'color-text': colorDarkGray1,
    'color-background--standard': colorLight1,
  },
  'theme-dark': {
    // https://github.com/CodeLogicIncEngineering/capeui/blob/integration/client/src/styles/colors.css#L210
    'color-text': 'hsl(206deg, 89%, 66%)',

    // https://github.com/CodeLogicIncEngineering/capeui/blob/integration/client/src/styles/colors.css#L209
    'color-background--standard': 'hsl(206deg, 0%, 11%)',
  },
  'theme-minimal': {
    'color-text': colorDark1,
    'color-background--standard': colorLight1,
  },
  'theme-minimal-inverse': {
    // inverse theme is the same as minimal theme, because the colors are inverted in css.
    'color-text': colorLight1,
    'color-background--standard': colorDark1,
  },
} as const;
