import {ReactNode} from 'react';
import classnames from 'classnames';
import {v4 as uuid} from 'uuid';
import {BsQuestionCircle} from 'react-icons/bs';
import {UncontrolledTooltip} from 'reactstrap';

import {HelpIconContainer} from './styled';

/**
 * Render a help icon with accompanying tooltip
 *
 * `tooltipId` should not be provided unless re-use of the same tooltip content is required for multiple icons
 */
export const HelpIcon = ({
  tooltipClassName,
  content,
  tooltipId = `id-${uuid()}`,
}: {
  tooltipClassName?: string,
  content: ReactNode,
  tooltipId?: string,
}) => (
  <HelpIconContainer>
    <UncontrolledTooltip
      className={classnames({
        'HelpIcon__tooltip': true,
        [tooltipClassName || '']: !!tooltipClassName,
      })}
      target={`#${tooltipId}`}
    >
      {content}
    </UncontrolledTooltip>
    <BsQuestionCircle id={tooltipId} />
  </HelpIconContainer>
);

