import {Route, Routes} from 'react-router-dom';
import {TabPane} from 'reactstrap';

import {
  ScansList,
  ScanSessionsList,
  ScanSpacesList,
} from 'src/components';

const ScanSpacesTab = () => {
  return (
    <TabPane>
      <Routes>
        <Route path='/' element={
          <ScanSpacesList />
        }/>
        <Route path={'/:entityId/contexts'} element={
          <ScansList />
        } />
        <Route path={'/:entityId/sessions'} element={
          <ScanSessionsList />
        } />
      </Routes>
    </TabPane>
  );
};


export default ScanSpacesTab;
