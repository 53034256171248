import {useEffect, useState} from 'react';

import * as featureFlags from 'src/feature-flags';
import {StorableValue} from 'src/types/primitive';
import {getStorage} from 'src/util';

export const useStoredValue = <T extends StorableValue>(
  key: string,
  initialValue: T,
) => {
  const [getItem, setItem] = getStorage<T>(key, initialValue);
  const [value, setValue] = useState<T>(initialValue);

  useEffect(() => {
    const storedValue = getItem();
    if (storedValue === initialValue) {
      setValue(initialValue);
    } else {
      setValue(getItem());
    }
  }, [key]);

  const setStoredValue = (value: T) => {
    setValue(setItem(value));
  };

  return [value, setStoredValue] as const;
};

type KeyOfFeatureFlags = keyof typeof featureFlags;
type ValueOfFeatureFlags = typeof featureFlags.FEATURE_FLAG_SCAN_SPACE_UI;

export const useLocalSetting = <T extends StorableValue>(settingName: string, initialValue: T) => {
  return useStoredValue<T>(settingName, initialValue);
};

export const useFeatureFlag = (flagName: KeyOfFeatureFlags) => {
  const {defaultValue, key} = featureFlags[flagName];
  return useStoredValue(key, defaultValue);
};

export const useFeatureFlagByValue = (flag: ValueOfFeatureFlags) => {
  return useStoredValue<boolean>(flag.key, flag.defaultValue);
};

export const cleanUpFeatureFlags = async () => {
  const flags = Object.values(featureFlags);
  const knownFlags: Array<string> = flags.map(({key}) => key);
  for (const key in localStorage) {
    if (key.startsWith('feature-flag-') && !knownFlags.includes(key)) {
      window.localStorage.removeItem(key);
    }
  }
};

