import {useContext, useState} from 'react';
import classnames from 'classnames';

import {
  AsyncSelect,
  HelpIcon,
  Section,
  Page,
  LabeledControl,
  ValidationMessage,
} from 'src/components';
import {createReleaseJob} from 'src/api/comparison-job';
import {WorkspaceContext} from 'src/context';
import {STORAGE_HELP_TEXT_SHOWN_KEY} from 'src/constants';
import {useStoredValue} from 'src/hooks';
import {MaterializedViewDefinition} from 'src/types';

import {
  MAIN_HELP_CONTENT,
  RELEASE_ANALYSIS_RESULTS_TITLE_TEXT,
  RELEASE_ANALYSIS_RESULTS_HELP_TEXT,
  RELEASES_TITLE_TEXT,
  RELEASES_HELP_TEXT,
} from './messages';
import {
  CreateButton,
  ReleaseAssistantJobTable,
  MaterializedViewDefinitionTable,
} from './components';
import {loadOptions} from './utils';

export const List = () => {
  const [fromMVD, setFromMVD] = useState<MaterializedViewDefinition | undefined>();
  const [toMVD, setToMVD] = useState<MaterializedViewDefinition | undefined>();
  const [hasAttemptedRun, setHasAttemptedRun] = useState(false);
  const [refetchJobsTrigger, setrefetchJobsTrigger] = useState(0);
  const refetchJobs = () => setrefetchJobsTrigger(refetchJobsTrigger + 1);

  const [showHelpText, setShowHelpText] = useStoredValue<boolean>(STORAGE_HELP_TEXT_SHOWN_KEY, true);
  const [serverMessage, setServerMessage] = useState<{message: string, valid: boolean} | undefined>(undefined);
  const {currentWorkspace} = useContext(WorkspaceContext);

  const workspaceId = currentWorkspace?.id;

  return (
    <Page title="Release Assistant" controls={!showHelpText && (
      <button
        onClick={() => setShowHelpText(!showHelpText)}
        className={classnames({
          'btn btn-link': true,
          'ReleaseAssistantPage__help--shown': showHelpText,
          'ReleaseAssistantPage__help--hidden': !showHelpText,
        })}>Show additional help</button>
    )
    }>
      {showHelpText && (
        <Section type="help">
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <button onClick={() => setShowHelpText(!showHelpText)} className={classnames({
              'btn btn-link': true,
            })}>hide</button>
          </div>
          {showHelpText && MAIN_HELP_CONTENT}
        </Section>
      )}
      <Section>
        <div className="ReleaseAssistantPage__controls">
          <div className="ReleaseAssistantPage__job-controls">
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <LabeledControl label="Current Release">
                <AsyncSelect<MaterializedViewDefinition>
                  placeholder='Choose current Release'
                  onChange={(v) => setFromMVD(v || undefined)}
                  getOptionLabel={({displayName}) => displayName || 'unnamed'}
                  getOptionValue={({id}) => id}
                  loadOptions={loadOptions}
                />
                <ValidationMessage visible={hasAttemptedRun && fromMVD === undefined} isValid={false}>Must select a &apos;current&apos; release</ValidationMessage>
              </LabeledControl>
            </div>

            <div style={{display: 'flex', flexDirection: 'row'}}>
              <LabeledControl label="Target Release">
                <AsyncSelect<MaterializedViewDefinition>
                  placeholder='Choose "what if" Release'
                  onChange={(v) => setToMVD(v || undefined)}
                  getOptionLabel={({displayName}) => displayName || 'unnamed'}
                  getOptionValue={({id}) => id}
                  loadOptions={loadOptions}
                />
                <ValidationMessage visible={hasAttemptedRun && toMVD === undefined} isValid={false}>Must select a &apos;target&apos; release</ValidationMessage>
              </LabeledControl>
              <LabeledControl>
                <button
                  style={{marginLeft: '1rem'}}
                  onClick={async () => {
                    if (fromMVD && toMVD && workspaceId) {
                      try {
                        const response = await createReleaseJob({
                          leftMaterializedViewDefinitionId: fromMVD.id,
                          rightMaterializedViewDefinitionId: toMVD.id,
                          type: 'RELEASE_ASSISTANT',
                        });
                        if (response.status === 'success') {
                          setServerMessage({
                            message: 'Successfully created comparison',
                            valid: true,
                          });
                          refetchJobs();
                          return response.data;
                        } else {
                          setServerMessage({
                            message: 'Error creating Release Comparsion',
                            valid: false,
                          });
                        }
                      } catch (e) {
                        setServerMessage({
                          message: 'Error creating Release Comparsion',
                          valid: false,
                        });
                      }
                    } else {
                      setHasAttemptedRun(true);
                    }
                  }}
                  className='btn btn-primary'
                >
              Run
                </button>
                <div style={{paddingLeft: '1rem'}}>
                  <ValidationMessage visible={!!serverMessage} isValid={serverMessage?.valid}>{serverMessage?.message}</ValidationMessage>
                </div>
              </LabeledControl>
            </div>
          </div>
          <div className="ReleaseAssistantPage__create-controls">
            <LabeledControl>
              <CreateButton />
            </LabeledControl>
          </div>
        </div>
      </Section>
      <Section title={
        <span>Results <HelpIcon content={
          <div>
            <p style={{fontWeight: 'bold'}}>{RELEASE_ANALYSIS_RESULTS_TITLE_TEXT}</p>
            <p>{RELEASE_ANALYSIS_RESULTS_HELP_TEXT}</p>
          </div>
        } /></span>
      }>
        {workspaceId && (
          <ReleaseAssistantJobTable
            triggerRefetch={refetchJobsTrigger}
          />
        )}
      </Section>
      <Section
        title={
          <span>Releases <HelpIcon content={
            <div>
              <p style={{fontWeight: 'bold'}}>{RELEASES_TITLE_TEXT}</p>
              <p>{RELEASES_HELP_TEXT}</p>
            </div>

          } /></span>
        }
      >
        <MaterializedViewDefinitionTable />
      </Section>
    </Page>
  );
};
