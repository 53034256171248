import {Diagram, Model} from 'gojs';

// type DiagramTransaction = (arg0: Diagram) => void
// type ModelTransaction = (arg0: Model) => void

export const transaction = async (
    transactionName: string,
    transactionContext: Diagram | Model,
    fn: () => void | Promise<void>,
) => {
  transactionContext.startTransaction(transactionName);
  await fn();
  transactionContext.commitTransaction(transactionName);
};
