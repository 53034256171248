import {CODELOGIC_UI_PATH, CODELOGIC_API_BASE_URL} from 'src/config';

export const APP_ROOT = CODELOGIC_UI_PATH;

export const NAMESPACES_PATH = `${CODELOGIC_UI_PATH}/namespaces` as const;
export const SCAN_ARTIFACTS_PATH = `${CODELOGIC_UI_PATH}/scan-artifacts` as const;
export const SCAN_DATABASE_PATH = `${CODELOGIC_UI_PATH}/scan-database` as const;
export const SEARCH_PATH = `${CODELOGIC_UI_PATH}/search` as const;
export const CHANGE_REQUEST_PATH = `${CODELOGIC_UI_PATH}/change-request` as const;
export const INVESTIGATE_USAGES_PATH = `${CODELOGIC_UI_PATH}/search/:id/usages` as const;
export const REPORTS_RELATIONSHIPS_PATH = `${CODELOGIC_UI_PATH}/reports/relationship` as const;
export const REPORTS_ITEM_PATH = `${CODELOGIC_UI_PATH}/reports/item` as const;

export const AGENTS_PATH = `${CODELOGIC_UI_PATH}/agents` as const;
export const AGENTS_MANAGEMENT_PATH = `${AGENTS_PATH}/manage` as const;
export const AGENT_SETTINGS_PATH = `${AGENTS_PATH}/global-settings` as const;

export const STORE_PATH = `${CODELOGIC_UI_PATH}/store` as const;

export const ADMINISTRATION_PATH = `${CODELOGIC_UI_PATH}/administration` as const;
export const SERVICE_NOW_PATH = `${ADMINISTRATION_PATH}/service-now` as const;
export const AWS_PATH = `${ADMINISTRATION_PATH}/aws` as const;
export const NOTIFICATIONS_CONFIG_PATH = `${ADMINISTRATION_PATH}/notifications` as const;

export const AUTH_PATH = `${ADMINISTRATION_PATH}/auth` as const;
export const USERS_PATH = `${ADMINISTRATION_PATH}/users` as const;
export const LICENSES_PATH = `${ADMINISTRATION_PATH}/license` as const;
export const FEATURE_FLAGS_PATH = `${ADMINISTRATION_PATH}/feature-flags` as const;
export const NAMESPACES_ADMIN_PATH = `${ADMINISTRATION_PATH}/namespaces` as const;
export const CHANGE_REQUEST_CONFIG_PATH = `${ADMINISTRATION_PATH}/change-request-config` as const;
export const SCAN_SPACES_PATH = `${ADMINISTRATION_PATH}/scan-spaces` as const;
export const SCAN_SPACE_CONTEXTS_PATH = `${ADMINISTRATION_PATH}/scan-spaces/:entityId/contexts` as const;
export const SCAN_SPACE_SESSIONS_PATH = `${CODELOGIC_UI_PATH}/scan-spaces/:entityId/sessions`;

export const LOCAL_SETTINGS_PATH = `${CODELOGIC_UI_PATH}/local-settings` as const;

export const NODE_ID_PATH = `${CODELOGIC_UI_PATH}/node/:nodeId` as const;
export const NODE_PATH = `${CODELOGIC_UI_PATH}/node` as const;
export const AUTH_CALLBACK_PATH = `${CODELOGIC_UI_PATH}/callback` as const;
export const AZURE_AD_AUTH_CALLBACK_PATH = `${CODELOGIC_UI_PATH}/azure/callback` as const;

export const WORKSPACES_PATH = `${CODELOGIC_UI_PATH}/workspaces` as const;
export const WORKSPACE_CREATE_PATH = `${WORKSPACES_PATH}/create` as const;
export const WORKSPACE_VIEW_PATH = `${WORKSPACES_PATH}/:entityId` as const;
export const WORKSPACE_EDIT_PATH = `${WORKSPACES_PATH}/:entityId/edit` as const;
export const WORKSPACE_COPY_PATH = `${WORKSPACES_PATH}/:entityId/copy` as const;

export const RELEASE_ASSISTANT_PATH = `${CODELOGIC_UI_PATH}/release-assistant` as const;
export const RELEASE_ASSISTANT_CREATE_PATH = `${RELEASE_ASSISTANT_PATH}/create` as const;

export const EXTERNAL_DOCUMENTATION_LINK = 'https://docs.codelogic.com' as const;
export const EXTERNAL_DOCUMENTATION_JAVA_AGENT_INSTALLATION_LINK = 'https://docs.codelogic.com/Running_Java_Agent_CLI/' as const;
export const EXTERNAL_SWAGGER_DOCUMENTATION_LINK = `${CODELOGIC_API_BASE_URL}/swagger-ui/index.html#/` as const;

export const EXTERNAL_JAVASCRIPT_DOCKER_AGENT_SUPPORT_LINK = 'https://docs.codelogic.com/Agent_Install_Docker_JavaScript/';
export const EXTERNAL_DOTNET_DOCKER_AGENT_SUPPORT_LINK = 'https://docs.codelogic.com/Agent_Install_Docker_NET/';
export const EXTERNAL_JAVA_DOCKER_AGENT_SUPPORT_LINK = 'https://docs.codelogic.com/Agent_Install_Docker_Java/';
export const EXTERNAL_SQL_DOCKER_AGENT_SUPPORT_LINK = 'https://docs.codelogic.com/Agent_Install_Docker_SQL/';

export const RELATIVE_COMPARISON_VIEW_PATH = 'comparison/:entityId' as const;
export const RELATIVE_CHANGE_REQUEST_COMPARISON_VIEW_PATH = 'change-request/comparison/:entityId' as const;

export const WELCOME_WIZARD_PATH = `${CODELOGIC_UI_PATH}/welcome` as const;
export const WELCOME_WIZARD_INTRO_PATH = `${WELCOME_WIZARD_PATH}/intro` as const;
export const WELCOME_WIZARD_NAMESPACE_PATH = `${WELCOME_WIZARD_PATH}/namespaces` as const;
export const WELCOME_WIZARD_AGENT_SETTINGS_PATH = `${WELCOME_WIZARD_PATH}/agent-settings` as const;
export const WELCOME_WIZARD_COMPLETE_PATH = `${WELCOME_WIZARD_PATH}/complete` as const;
export const GETTING_STARTED_PATH = `${CODELOGIC_UI_PATH}/guide` as const;
