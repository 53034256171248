import {KeyboardEvent} from 'react';

import './styles.css';

export const SearchInput = ({onKeyUp}: {onKeyUp: (e: KeyboardEvent<HTMLInputElement>) => void}) => (
  <input
    className="SearchInput"
    placeholder="Search"
    onKeyUp={onKeyUp}
  />
);
