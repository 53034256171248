import {RefObject, useCallback, useEffect, useState} from 'react';

type Props = {
  divRef: RefObject<HTMLDivElement>;
  onDrop: (files: File[]) => void;
}
export default function useDragging({onDrop, divRef}: Props): boolean {
  const [dragActive, setDragActive] = useState(false);

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onDrop(Array.from(e.dataTransfer.files));
    }
  }, [onDrop]);

  useEffect(() => {
    const divEl = divRef.current;
    divEl?.addEventListener('dragenter', handleDragEnter);
    divEl?.addEventListener('dragleave', handleDragLeave);
    divEl?.addEventListener('dragover', handleDragOver);
    divEl?.addEventListener('drop', handleDrop);
    return () => {
      divEl?.removeEventListener('dragenter', handleDragEnter);
      divEl?.removeEventListener('dragleave', handleDragLeave);
      divEl?.removeEventListener('dragover', handleDragOver);
      divEl?.removeEventListener('drop', handleDrop);
    };
  }, [
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    divRef,
  ]);

  return dragActive;
}
