import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {useState} from 'react';

import {Layout} from 'src/components/Layout';
import {Namespace, NamespacesMessage} from 'src/types';

type Props = {
  msg: NamespacesMessage;
  onAdd: (namespaces: Array<Namespace>) => void;
};
export const ScanModal = ({msg, onAdd}: Props) => {
  const [isOpen, setOpen] = useState(true);
  const [selectedNamespaces, setSelectedNamespaces] = useState<Array<Namespace>>([]);

  const toggleSelection = (namespace: string) => {
    const index = selectedNamespaces.findIndex((n) => n.namespace === namespace);
    if (index > -1) {
      const temp = [...selectedNamespaces];
      temp.splice(index, 1);
      setSelectedNamespaces(temp);
    } else {
      setSelectedNamespaces([...selectedNamespaces, {namespace, dataSourceId: msg.agentMetaData.dataSourceId}]);
    }
  };

  const handleAdd = () => {
    onAdd(selectedNamespaces);
    setOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      fade={false}
      fullscreen="xl"
      size="xl"
    >
      <ModalHeader toggle={() => {
        setOpen(false);
      }}>Scan Results {msg.filename ? `from ${msg.filename}` : ''}</ModalHeader>
      <ModalBody>
        <Layout>
          <div style={{overflowX: 'scroll'}}>
            <table className='table' cellSpacing={0} cellPadding={0}>
              <thead>
                <tr>
                  <th>
                  Found Namespaces
                  </th>
                  <th>
                  </th>
                </tr>
              </thead>
              <tbody>
                {msg &&
                msg.namespaces &&
                msg.namespaces.map((n) => (
                  <tr key={n}>
                    <td><input type='checkbox' onClick={() => toggleSelection(n)}/></td>
                    <td key={n} style={{width: '90%'}}>{n}</td>
                  </tr>
                ))
                }
              </tbody>
            </table>
            <Button color='primary' onClick={handleAdd}>Add</Button>
          </div>
        </Layout>
      </ModalBody>
    </Modal>
  );
};
