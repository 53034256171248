import Swal from 'sweetalert2';
import download from 'downloadjs';

import {isError} from 'src/api';
import {listMaterializedViewDefinitions} from 'src/api/materializedViewDefinitions';
import {getJobResultCsv} from 'src/api/comparison-job';
import {DiffReportType} from 'src/types';
import {getDiffJobResultCsv} from 'src/api/diff-view-reporting';

export enum DecompositionDataLoadedState {
  NONE = 0, // 0b0000 No data loaded
  INCOMING_ADDED = 1, // 0b0001 Incoming added data loaded
  INCOMING_REMOVED = 2, // 0b0010 Incoming removed data loaded
  INCOMING_BOTH = 3, // 0b0011 Incoming both data loaded
  OUTGOING_ADDED = 4, // 0b0100 Outgoing added data loaded
  OUTGOING_REMOVED = 8, // 0b1000 Outgoing removed data loaded
  OUTGOING_BOTH = 12, // 0b1100 Outgoing both data loaded
  ALL = 15, // 0b1111 All data loaded
}


export const loadOptions = async () => {
  const result = await listMaterializedViewDefinitions({type: 'RELEASE_ASSISTANT'});

  if (result.status === 'success') {
    return result.data || [];
  } else {
    return [];
  }
};
export const downloadCSV = async ({jobId, fileName, type}: {jobId: string, fileName: string, type?: DiffReportType}) => {
  const result = !type ?
    await getJobResultCsv(jobId) :
    await getDiffJobResultCsv(jobId, type);
  if (result.status === 200 && typeof result.data === 'string') {
    const downloadSucceeded = download(
        result.data,
        fileName,
        'text/csv;charset=UTF-8',
    );
    if (downloadSucceeded) {
      await Swal.fire({title: 'Successfully downloaded and saved CSV',
        html: `File saved as: <code>${fileName}</code>`,
        icon: 'success',
        width: '80%',
      });
    } else {
      await Swal.fire('Error saving downloaded CSV', 'error');
    }
  } else if (result.status !== 502 && typeof result.data !== 'string' && isError(result.data)) {
    await Swal.fire('Unable to download CSV', result.data.error.message, 'error');
  } else {
    await Swal.fire('Unable to download CSV', 'Error retrieving CSV from the backend.  Please try again in 30 seconds.', 'error');
  }
};
