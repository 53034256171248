import {useState} from 'react';
import {Route, Routes, useLocation, useNavigate, Navigate} from 'react-router-dom';
import {Nav as RSNav, NavItem as RSNavItem, NavLink, NavProps} from 'reactstrap';

import {makeRelativePathFn} from 'src/util';
import {Layout} from 'src/components';
import {AppRoutes} from 'src/types';
import {
  AGENTS_PATH,
  AGENTS_MANAGEMENT_PATH,
  AGENT_SETTINGS_PATH,
} from 'src/routes';

import {AgentSettingsPage} from '../AgentSettings';

import {AgentRequests} from './AgentRequests';

import './styles.css';

export const AgentsRoutes = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const makeLinkFn = (path: string) => () => navigate(path);

  const NavItem = ({label, path}:{label: string, path: AppRoutes}) => {
    const {pathname} = useLocation();
    return (
      <RSNavItem>
        <NavLink active={pathname.includes(path)} onClick={makeLinkFn(path)}>
          {label}
        </NavLink>
      </RSNavItem>
    );
  };

  const Nav = (props: NavProps) => (
    <RSNav tabs {...props} >
      <NavItem label="Agents" path={AGENTS_MANAGEMENT_PATH} />
      <NavItem label="Agent Settings" path={AGENT_SETTINGS_PATH}/>
    </RSNav>
  );

  const relativePath = makeRelativePathFn(AGENTS_PATH);

  return (
    <Layout loading={isLoading}>
      <Nav className="AgentTabs" />
      <div className="agent-tab-content">
        <Routes>
          <Route path={relativePath(AGENTS_MANAGEMENT_PATH)} element={<AgentRequests setLoading={setIsLoading}/>} />
          <Route path={relativePath(AGENT_SETTINGS_PATH)} element={<AgentSettingsPage />}/>
          <Route path={relativePath(AGENTS_PATH)} element={<Navigate to={AGENTS_MANAGEMENT_PATH} replace={true} />} />
        </Routes>
      </div>
    </Layout>
  );
};

