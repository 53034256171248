import classnames from 'classnames';
import {Button} from 'reactstrap';

import {GridColumnsLayout, NodeTreeFilter, UsagesDiagram} from 'src/components';
import {SelectedIdsContextProvider} from 'src/context';
import {STORAGE_NODE_TREE_FILTER_SHOWN_KEY} from 'src/constants';
import {useStoredValue} from 'src/hooks';

import './styles.css';

export const UsagesTab = ({
  id,
}: {
  id: string
}) => {
  const [show, setShow] = useStoredValue<boolean>(STORAGE_NODE_TREE_FILTER_SHOWN_KEY, true);
  const toggleFilter = () => setShow(!show);

  return (
    <div className="UsagesTab">
      <SelectedIdsContextProvider>
        <GridColumnsLayout columnRatios={show ? [1, 4] : [1]}>
          <div className={classnames({
            'UsagesTab__sidebar--hidden': !show,
          })}>
            <div className="UsagesTab__controls">
              <Button color="link" onClick={toggleFilter}>{show ? 'hide filter' : 'show filter'}</Button>
            </div>
            {show && <NodeTreeFilter id={id} />}
          </div>

          <div style={{position: 'relative'}}>
            <UsagesDiagram enableFiltering={true} nodeId={id} />
          </div>
        </GridColumnsLayout>
      </SelectedIdsContextProvider>
    </div>
  );
};
