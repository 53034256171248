import {useEffect, useState} from 'react';

import {ApiErrorResponse, TypedApiResponse} from 'src/types';
import {getAuthorizationUrl} from 'src/api/azure';

const isSuccess = (response: TypedApiResponse<string | null> | ApiErrorResponse)
  : response is TypedApiResponse<string> => {
  return 'status' in response && response.status === 'success';
};

export const useAuthUrl = () => {
  const [url, setUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const loadAuthorizationUrl = async () => {
    const result = await getAuthorizationUrl();
    if (isSuccess(result)) {
      setUrl(result.data || null);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadAuthorizationUrl();
  }, []);

  return {loading, url, loadAuthorizationUrl} as const;
};
