import {CODELOGIC_API_BASE_URL} from 'src/config';
import {
  ApiSuccessResponse,
  License,
  ResourceFetchBuilderType,
  ResourceListFetchBuilderType,
  ResourceFetchByIdBuilderType,
  ValidationError,
} from 'src/types';
import {HttpMethod} from 'src/types/primitive';
import {
  ApiErrorResponse,
  TypedApiSuccessResponse,
  QueryFilterType,
  TypedApiResponse,
} from 'src/types';

import {isAxiosError} from './utils';
import {axiosInstance} from './baseApi';

export const makeResourceListFetch = <
  T,
  U extends Record<string, unknown> = Record<string, unknown>,
  V extends Record<string, unknown> = Record<string, unknown>,
>({
    method,
    path,
    filterName = 'filter',
    defaultSortBy,
    constantParams,
  }: ResourceListFetchBuilderType<V>,
  ) => async ({
    page,
    pageSize,
    sortBy,
    filter,
    ...restParams
  }: QueryFilterType<U> = {}): Promise<TypedApiResponse<T>> => {
    try {
      const params = {
        ...constantParams,
        ...(method === 'GET' ? restParams : {}),
        page,
        size: pageSize,
        sort: sortBy || defaultSortBy,
        [filterName]: filter,
      };

      const data = method === 'GET' ? {} : restParams;

      const response = await axiosInstance.request({
        baseURL: CODELOGIC_API_BASE_URL,
        url: path,
        method,
        withCredentials: true,
        params,
        data,
      });

      return response.data;
    } catch (e) {
      if (isAxiosError<ApiErrorResponse<ValidationError>>(e)) {
        if (e.response) {
          return e.response.data;
        }
      }
      throw e;
    }
  };

export const makeResourceFetch = <
  T,
  U extends Record<string, unknown> | Array<unknown> = Record<string, unknown>,
  V extends Record<string, unknown> = Record<string, unknown>
>({
    method,
    path,
    constantParams,
  }: ResourceFetchBuilderType<V>,
  ) => async (paramArgs: U): Promise<TypedApiResponse<T>> => {
    try {
      const params = {
        ...constantParams,
        ...(method === 'GET' ? paramArgs : {}),
      };

      const data = method === 'GET' ? {} : paramArgs;

      const response = await axiosInstance.request({
        baseURL: CODELOGIC_API_BASE_URL,
        url: path,
        method,
        withCredentials: true,
        params,
        data,
      });

      return response.data;
    } catch (e) {
      if (isAxiosError<ApiErrorResponse<ValidationError>>(e)) {
        if (e.response) {
          return e.response.data;
        }
      }
      throw e;
    }
  };

export const makeResourceFetchById = <T>(
  {
    method,
    path,
    after,
  } :ResourceFetchByIdBuilderType,
) => async ({id}: {id: string}): Promise<TypedApiResponse<T>> => {
    try {
      const response = await axiosInstance.request({
        baseURL: CODELOGIC_API_BASE_URL,
        url: `${path}/${encodeURIComponent(id)}${after ? '/' + after : ''}`,
        method,
        withCredentials: true,
      });
      return response.data;
    } catch (e) {
      if (isAxiosError<ApiErrorResponse<ValidationError>>(e)) {
        if (e.response) {
          return e.response.data;
        }
      }
      throw e;
    }
  };

export const makeFileUploader = (
    method: HttpMethod,
    path: string,
) => async (data: File) => {
  try {
    const {data: responseData} = await axiosInstance.request<TypedApiSuccessResponse<License>>({
      baseURL: CODELOGIC_API_BASE_URL,
      url: path,
      data,
      method,
      withCredentials: true,
    });
    return responseData;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse<unknown>>(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
};

export const isError = <T>(response: TypedApiResponse<unknown> | ApiSuccessResponse | ApiErrorResponse<T> | Error): response is ApiErrorResponse<T> =>
  'status' in response && response.status === 'error';

export const isSuccess = <T, U = undefined>(response: TypedApiResponse<T> | ApiSuccessResponse | ApiErrorResponse<U> | Error): response is TypedApiResponse<T> =>
  'status' in response && response.status === 'success';

