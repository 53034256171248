import {ReactNode} from 'react';

import './styles.css';

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/td#attr-colspan
const MAX_COLSPAN = 1000;

const DEFAULT_MESSAGE = <span>no records</span>;

export const NoRecords = ({message = DEFAULT_MESSAGE}: {message?: ReactNode}) => {
  const content = typeof message === 'string' || typeof message === 'number' ? <span>{message}</span> : message;

  return (
    <tr className="NoRecordsRow">
      <td colSpan={MAX_COLSPAN}>
        <div className="NoRecords">
          {content}
        </div>
      </td>
    </tr>
  );
};
