import classnames from 'classnames';

import {Props} from './types';

import './styles.css';

export const Tag = ({
  backgroundColor = 'var(--color-background--standard)',
  borderColor = 'var(--color-border--standard)',
  color,
  fontSize = '1em',
  label,
  onClick,
  onClickSecondary,
  round = true,
}: Props<HTMLDivElement>) => (
  <div className={classnames({
    'Tag': true,
    'Tag--square': !round,
  })}>
    <div
      className={classnames({
        'Tag__content': true,
        'Tag__content--with-primary': !!onClick,
        'Tag__content--with-secondary': !!onClickSecondary,
      })}
      onClick={onClick}
      style={{
        border: `1px solid ${borderColor}`,
        backgroundColor,
        color,
        fontSize,
      }}
    >
      <span>{label}</span>
    </div>
    {onClickSecondary && (
      <div
        style={{
          border: `1px solid ${borderColor}`,
          borderLeft: 0,
          backgroundColor,
          color,
          fontSize,
        }}
        className="Tag__secondary"
        onClick={onClickSecondary}>
        <span>&#x2716;</span>
      </div>
    )}
  </div>
);
