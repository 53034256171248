import {PropsWithChildren} from 'react';
import classnames from 'classnames';

import {OptionProps} from './types';

import './styles.css';

export const ToggledSetting = <T, >({
  children,
  disabled,
  readOnly,
  preserveSpacingWhenReadOnly,
  padCheckbox = true,
  selected,
  onSelect = () => {},
}: PropsWithChildren<OptionProps<T>>) => (
    <label className="ToggledSetting">
      {(readOnly && !preserveSpacingWhenReadOnly) || (
        <div className={classnames({
          'ToggledSetting__checkbox-container': true,
          'ToggledSetting__checkbox-container--padded': padCheckbox,
        })}>
          <input
            type="checkbox"
            disabled={disabled}
            checked={selected}
            onChange={onSelect}
            style={{
              visibility: (readOnly && !!preserveSpacingWhenReadOnly) ? 'hidden' : 'visible',
            }}
          />
        </div>
      )}
      <div className="ToggledSetting__content">
        {children}
      </div>
    </label>
  );

