import {AxiosResponse} from 'axios';

import {
  searchWithinChildren,
  getMatchingNodes,
  getMatchingNodesTypes,
  getMatchingNodesByType,
} from 'src/api/dependency';
import {
  mapContainedNodesToSearchNodes,
} from 'src/state/dependency2';

export const fetchData = async ({
  query,
  definitionId,
  scopeToNode,
  labels,
  pageNumber,
  pageSize,
}: {
  query: string,
  definitionId: string,
  scopeToNode?: string | {id: string},
  labels: Array<string>,
  pageNumber: number,
  pageSize: number,
}) => {
  let nodeResultsPromise: Promise<AxiosResponse | undefined> = Promise.resolve(undefined);

  if (scopeToNode) {
    const id = typeof scopeToNode === 'string' ? scopeToNode : scopeToNode.id;
    nodeResultsPromise = searchWithinChildren(id, query);
  } else {
    nodeResultsPromise = labels ?
      getMatchingNodesByType(query, labels.map((value) => value).join(','), definitionId, pageNumber, pageSize) :
        getMatchingNodes(query, definitionId, pageNumber, pageSize);
  }

  const nodeTypesResults = await getMatchingNodesTypes(query, definitionId);
  const updatedLabels: Array<string> = nodeTypesResults?.data?.data;

  const nodeResults: any = await nodeResultsPromise;
  const nodes = mapContainedNodesToSearchNodes(nodeResults?.data.data);
  const pageData = nodeResults?.data.metaData.pageData;

  return {
    nodes,
    pageData,
    labels: updatedLabels,
  };
};
