import {
  useContext,
  useEffect,
  useState,
} from 'react';
import {useParams} from 'react-router-dom';
import {ReactDiagram} from 'gojs-react';

import {GraphData} from 'src/types';
import {SelectedIdsContext, ThemeContext} from 'src/context';
import {
  LoadingPlaceholder,
  NoDataPlaceholder,
} from 'src/components';
import {THEMES} from 'src/styles/colors';

import {makeInitDiagram, fetchDiagramData} from './utils';

import './styles.css';

export const UsagesDiagram = ({nodeId: nodeIdProp, enableFiltering = false}: {nodeId?: string, enableFiltering?: boolean}) => {
  const {theme: themeName} = useContext(ThemeContext);
  const {id: nodeIdParam} = useParams<{id: string | undefined}>() ?? {};
  const {selectedIdsMap} = useContext(SelectedIdsContext);
  const [{data, loading}, setData] = useState<{
    data?: GraphData,
    loading: boolean,
  }>({
    loading: false,
    data: undefined,
  });

  const setLoading = (loading: boolean) => setData({
    loading,
    data,
  });


  const nodeId = nodeIdProp || nodeIdParam;

  useEffect(() => {
    (async () => {
      if (nodeId) {
        setLoading(true);
        const {nodes, relationships} = await fetchDiagramData(nodeId) || {};
        setLoading(false);
        setData({
          data: {
            relationships: enableFiltering ? relationships?.filter(({endId, startId}) => selectedIdsMap[endId] && selectedIdsMap[startId]) || [] : relationships || [],
            nodes: enableFiltering ? nodes?.filter(({id}) => selectedIdsMap[id]) ?? [] : nodes || [],
          },
          loading: false,
        });
      }
    })();
  }, [nodeId, selectedIdsMap]);

  return loading ? (
        <LoadingPlaceholder />
      ) : !loading && data?.nodes.length ? (
        <ReactDiagram
          initDiagram={makeInitDiagram({
            themeName,
            theme: THEMES[themeName],
            initialFocusNodeId: nodeId,
          })}
          divClassName="UsagesDiagram__diagram"
          nodeDataArray={data.nodes.map(({
            id,
            primaryLabel,
            identity,
            name,
            properties,
          }) => ({
            metadata: {
              type: primaryLabel,
              identity,
            },
            isHighlighted: id === nodeId,
            text: properties['shortName'] || name || identity,
            name,
            id,
            category: '', // Default category
          }))}
          linkDataArray={data.relationships.map(({
            startId,
            endId,
            id,
            type,
          }) => ({
            from: startId,
            to: endId,
            isDeEmphasized: type.startsWith('CONTAINS_') || type === 'GROUPS',
            type,
            // groups: true,
          })) }
          skipsDiagramUpdate={false}
        />
      ) : (
        <NoDataPlaceholder>
          {data && data.nodes.length === 0 ? 'No usage data found' : 'Failed to load data'}
        </NoDataPlaceholder>
      );
};
