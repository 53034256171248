import {GraphObject, Link, Margin, Node, Part} from 'gojs';

import {SEARCH_NODE} from 'src/constants';
import {MakeFnInitializers} from 'src/util/gojs/types';


/**
 * build margins similar to CSS
 *
 * @param arg0 - all sides of the margin box if it's the only arg.  Top and Bottom if arg1 provided.  Top if arg2 is provided.
 * @param arg1 - left and right margin unless arg3 is provided
 * @param arg2 - bottom margin
 * @param arg3 - left margin
 */
export const margin = (arg0: number, arg1?: number, arg2?: number, arg3?: number) => {
  if (typeof arg1 !== 'number') {
    return new Margin(arg0, arg0, arg0, arg0);
  }

  if (typeof arg2 !== 'number') {
    return new Margin(arg0, arg1, arg0, arg1);
  }

  if (typeof arg3 !== 'number') {
    return new Margin(arg0, arg1, arg2, arg1);
  }

  return new Margin(arg0, arg1, arg2, arg3);
};

export const processRest = (rest?: MakeFnInitializers) => [...(rest || [])].filter(Boolean);

export const isSearchNode = (n: {metadata: {type?: string}}) =>
  n.metadata.type === SEARCH_NODE;
export const isGroupNode = (n: {isGroupNode?: boolean}) =>
  n.isGroupNode;

export const isNode = (obj?: GraphObject | Part | null): obj is Node => obj instanceof Node;
export const isLink = (obj?: GraphObject | Part | null): obj is Link => obj instanceof Link;
