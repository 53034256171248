import {useCallback, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import identity from 'lodash/identity';
import {Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import {FaEllipsisV} from 'react-icons/fa';
import SwalBase from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import {
  Layout,
  ListingPage,
} from 'src/components';
import {
  PageDataType,
  QueryFilterType,
  ScanSpace,
} from 'src/types';
import {
  deleteScanSpace,
  getScanSpaces,
  postScanSpace,
  updateScanSpace,
} from 'src/api/scanSpace';

import {NewScanSpaceModal} from './components';

const Swal = withReactContent(SwalBase);

export const ScanSpacesList = () => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const makeLinkFn = (path: string) => () => navigate(path);
  const [scanSpaces, setScanSpaces] = useState<Array<ScanSpace>>([]);
  const [pageData, setPageData] = useState<PageDataType>();
  const [editData, setEditData] = useState<ScanSpace>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const deleteConfig = useCallback((id: string) => {
    Swal.fire({
      title: 'Do you want to delete this scan space?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Delete`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await deleteScanSpace(id);
        if (res.status === 'success') {
          Swal.fire({
            title: 'Scan Space deleted',
            text: 'Scan Space deleted successfully',
            icon: 'success',
            timer: 1200,
            showConfirmButton: false,
          });
          const queryFilters: QueryFilterType = {};
          if (pageData) {
            queryFilters.page = pageData.currentPage;
            queryFilters.pageSize = pageData.pageSize;
          }
          await fetchScanSpaces(queryFilters.page, queryFilters.pageSize);
        }
      }
    });
  }, [pageData]);

  const columns = useMemo(
      () => [
        {
          Header: 'Id',
          id: 'Id',
          accessorFn: identity,
          enableSorting: false,
          cell: ({getValue}) => {
            const {id} = getValue();
            return (
              <Button onClick={makeLinkFn(`${id}/contexts`)}>{id}</Button>
            );
          },
        },
        {
          Header: 'Name',
          accessorKey: 'displayName',
          enableSorting: false,
        },
        {
          Header: 'Description',
          accessorKey: 'description',
          enableSorting: false,
        },
        {
          Header: 'Actions',
          id: 'actions',
          accessorFn: identity,
          enableSorting: false,
          cell: ({getValue}) => {
            const value = getValue();
            const {id} = value;
            return (
              <div className="row-actions">
                <UncontrolledDropdown>
                  <DropdownToggle color="link">
                    <FaEllipsisV/>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => {
                      toggleModal(true, value);
                    }}>Edit</DropdownItem>
                    <DropdownItem onClick={() => deleteConfig(id)}>Delete</DropdownItem>
                    <DropdownItem onClick={makeLinkFn(`${id}/sessions`)}>
                      View Scan Sessions
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            );
          },
        },
      ],
      [pageData],
  );

  const fetchScanSpaces = useCallback(async (page = 0, pageSize = 10) => {
    setLoading(true);
    const response = await getScanSpaces({page, pageSize});
    if (response.status === 'success') {
      setScanSpaces(response.data || []);
      setPageData(response.metaData?.pageData);
    }
    setLoading(false);
  }, []);

  const toggleModal = (show: boolean, data: ScanSpace | null = null) => {
    setEditData((show && data) ? data : undefined);
    setIsModalOpen(show);
  };

  const createScanSpace = async (values) => {
    return postScanSpace(values).then(
        (value) => {
          Swal.fire({
            title: 'Scan Space added',
            icon: 'success',
            timer: 1200,
            showConfirmButton: false,
          });
          const queryFilters: QueryFilterType = {};
          if (pageData) {
            queryFilters.page = pageData.currentPage;
            queryFilters.pageSize = pageData.pageSize;
          }
          fetchScanSpaces(queryFilters?.page, queryFilters?.pageSize);
          toggleModal(false);
          return value;
        },
    );
  };

  const editScanSpace = async (values) => {
    return updateScanSpace(values).then(
        (value) => {
          Swal.fire({
            title: 'Scan Space updated',
            icon: 'success',
            timer: 1200,
            showConfirmButton: false,
          });
          const queryFilters: QueryFilterType = {};
          if (pageData) {
            queryFilters.page = pageData.currentPage;
            queryFilters.pageSize = pageData.pageSize;
          }
          fetchScanSpaces(queryFilters?.page, queryFilters?.pageSize);
          toggleModal(false);
          return value;
        },
    );
  };

  return (
    <Layout title={'Scan Spaces'} loading={isLoading}>
      <div className="admin-page-options">
        <div className='admin-page-description'>
          <span>Scan Spaces provide logical groupings and security for individual Scans.</span>
        </div>
        <div className="admin-page-actions">
          <Button color="primary" onClick={() => toggleModal(true)}>
              Add Scan Space
          </Button>
        </div>
      </div>
      <ListingPage
        columns={columns}
        data={scanSpaces}
        pageData={pageData}
        fetchData={fetchScanSpaces}
      />
      {isModalOpen && (
        <NewScanSpaceModal
          isOpen={isModalOpen}
          onClose={() => toggleModal(false)}
          editData={editData}
          onCreate={createScanSpace}
          onEdit={editScanSpace}
        />
      )}
    </Layout>
  );
};
