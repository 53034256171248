import {FaEllipsisV} from 'react-icons/fa';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import {User} from 'src/types';
import {PropertyList} from 'src/components';

import {MenuItem} from './types';

export const ConfirmationPopup = ({username, firstName, lastName, roles}: User) => {
  return (
    <PropertyList properties={{
      'Username': username,
      'First name': firstName,
      'Last name': lastName,
      'Roles': roles.join(', '),
    }} />
  );
};


export const DeleteConfirmationPopup = ({username}: Omit<User, 'id' | 'roles' | 'firstName' | 'lastName'>) => (
  <PropertyList properties={{
    'Username': username,
  }} />
);

export const ActionMenu = ({menuItems}: {menuItems: Array<MenuItem>}) => (
  <div className="row-actions">
    <UncontrolledDropdown>
      <DropdownToggle color="link">
        <FaEllipsisV />
      </DropdownToggle>
      <DropdownMenu>
        {menuItems.map(({label, action}) => (
          <DropdownItem key={label} onClick={action}>
            {label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  </div>
);
