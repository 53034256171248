import {CSSProperties, PropsWithChildren} from 'react';
import {TabPane} from 'reactstrap';

export const Container = ({children, style}: PropsWithChildren<{style?: CSSProperties}> = {style: {}}) => (
  <TabPane style={{...style, display: 'flex', height: '100%', width: '100%'}}>
    {children}
  </TabPane>
);

export const Section = ({children}: PropsWithChildren) => (
  <div className="LicenseTab__section">
    {children}
  </div>
);

