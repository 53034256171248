import {MouseEvent, useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {ErrorMessage, Form, Field as FormikField, Formik} from 'formik';
import {Button, FormGroup, Input} from 'reactstrap';

import {getScanSpaceApplications, getScanSpaces} from 'src/api/scanSpace';
import {AsyncSelect, FormSection, LoadingPlaceholder, MultiSelectList} from 'src/components';
import {createWorkspace, updateWorkspace, getWorkspace} from 'src/api/workspace';
import {generateMaterializedViewForDefinition} from 'src/api/materializedViews';
import {ApiErrorResponse, IdToStringList, TypedApiResponse, Workspace} from 'src/types';
import {WORKSPACES_PATH, APP_ROOT} from 'src/routes';
import {useResource, useScanSpaceApplicationNames} from 'src/hooks';
import {WorkspaceContext} from 'src/context/WorkspaceContext';

import {Field, ApplicationOptionContent} from './components';
import {Errors, CreateValue} from './types';

import './styles.css';

// Placeholder for future permission
const USER_CAN_EDIT = true;

export const DEFAULT_FORM_VALUES = {
  displayName: '',
  description: '',
  applications: {},
};

const isError = (response: TypedApiResponse<unknown> | ApiErrorResponse | Error): response is ApiErrorResponse => {
  return 'status' in response && response.status === 'error';
};

const isSuccess = (response: TypedApiResponse<Workspace> | ApiErrorResponse | Error): response is TypedApiResponse<Workspace> => {
  return 'status' in response && response.status === 'success';
};

export const WorkspacePage = () => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const isCopy = pathname.endsWith('/copy');
  const isEdit = pathname.endsWith('/edit');
  const isCreate = pathname.endsWith('/create');
  const {entityId} = useParams<{entityId: string | undefined}>() ?? {};
  const [apiError, setApiError] = useState('');

  const {currentWorkspace, setCurrentWorkspace} = useContext(WorkspaceContext);
  const [scanSpaceApplications, setScanSpaceApplications] = useState<IdToStringList | undefined>();


  const {entity, loading, loadEntity} = useResource(getWorkspace);
  const {entity: scanSpaces, loading: loadingScanSpaces, loadEntity: loadScanSpaces} = useResource(getScanSpaces);
  const [selectedScanSpace, setSelectedScanSpace] = useState<Exclude<typeof scanSpaces, undefined>[number]>();

  const {loading: loadingApplications, applications, load: loadApplications} = useScanSpaceApplicationNames(selectedScanSpace?.id || '');

  useEffect(() => {
    if (entityId) {
      loadEntity({id: entityId});
    }
    loadScanSpaces({page: 0, pageSize: 1000});
  }, [entityId]);

  useEffect(() => {
    if (isCreate && !selectedScanSpace?.id && !!scanSpaces) {
      setSelectedScanSpace(scanSpaces.find((s) => s.primary));
    }
  }, [scanSpaces]);

  if (entity && entity.id !== currentWorkspace?.id) {
    setCurrentWorkspace(entity);
  }
  if (entity && entity.materializedViewDefinition.scanSpaceApplications && scanSpaces && !selectedScanSpace) {
    let id;
    let scanSpace;
    if (Object.keys(entity.materializedViewDefinition.scanSpaceApplications).length > 0) {
      setScanSpaceApplications(entity.materializedViewDefinition.scanSpaceApplications);
      id = Object.keys(entity.materializedViewDefinition.scanSpaceApplications)[0];
      scanSpace = scanSpaces?.find((s) => s.id === id);
    }
    if (scanSpace) {
      setSelectedScanSpace(scanSpace);
    } else {
      scanSpace = scanSpaces?.find((s) => s.primary);
      id = scanSpace.id;
      setSelectedScanSpace(scanSpace);
    }
    loadApplications(id);
  }

  const readOnly = (!!entityId && !isCopy && !isEdit);

  const applicationNames =
    selectedScanSpace?.id &&
    entity?.materializedViewDefinition.scanSpaceApplications &&
    entity?.materializedViewDefinition?.scanSpaceApplications[selectedScanSpace.id] || [];

  const allApplicationNames = applications || [];
  const applicationsList = allApplicationNames.map((name) => ({
    id: name,
    label: name,
    selected: (!isCreate && applicationNames.includes(name)),
  }));

  // If there is only one application to choose, and we aren't
  // displaying an existing Materialized View Definition, default to selected
  if (applicationsList.length === 1 && !entity?.materializedViewDefinition) {
    applicationsList[0].selected = true;
  }

  const {displayName, description} = (entity && !isCreate) ? entity : DEFAULT_FORM_VALUES;

  const redirectToWorkspaces = () => navigate(WORKSPACES_PATH);
  const redirectToLandingPage = () => navigate(APP_ROOT);
  const redirectToEdit = () => navigate(`${WORKSPACES_PATH}/${entityId}/edit`);

  const backEvent = entityId && !readOnly ? (e: MouseEvent) => {
    redirectToWorkspaces();
    e.preventDefault();
  } : (e: MouseEvent) => {
    redirectToWorkspaces();
    e.preventDefault();
  };

  const handleSubmit = async ({applications, ...values}: CreateValue) => {
    const postData = {
      ...values,
    };

    if (entity && !isCopy && !isCreate && selectedScanSpace) {
      const response = await updateWorkspace({
        ...postData,
        id: entity.id,
        materializedViewDefinition: {
          ...entity.materializedViewDefinition,
          scanSpaceApplications,
          displayName: postData.displayName,
          type: 'WORKSPACE',
        },
      });

      if (isError(response)) {
        const code = response.error.code;
        switch (code) {
          default:
            setApiError(response.error.code + ': ' + response.error.message);
        }
      } else {
        if (isSuccess(response) && response.data && entity.id === currentWorkspace?.id) {
          if (response.data) {
            loadEntity({id: response.data.id});
          }
        }
        redirectToLandingPage();
      }
    } else if (selectedScanSpace) {
      const response = await createWorkspace({
        ...postData,
        materializedViewDefinition: {
          scanSpaceApplications,
          displayName: postData.displayName,
          type: 'WORKSPACE',
        },
      });
      if (isError(response)) {
        const code = response.error.code;
        switch (code) {
          default:
            setApiError(response.error.code + ': ' + response.error.message);
        }
      } else if (isSuccess(response)) {
        if (response.data) {
          await loadEntity({id: response.data.id});
          setCurrentWorkspace(response.data);
        }
        const mvdId = response.data?.materializedViewDefinition.id;
        if (mvdId) {
          generateMaterializedViewForDefinition({definitionId: mvdId});
        }
        redirectToLandingPage();
      }
    }
  };

  return loading || loadingScanSpaces ? (
    <LoadingPlaceholder />
  ) : (
    <Formik<CreateValue>
      enableReinitialize
      initialValues={{
        displayName: displayName + (isCopy ? ' (Copy)' : ''),
        description,
        applications: applicationsList,
      }}
      validate={(values) => {
        const errors: Errors = {};
        if (values?.displayName?.length === 0) {
          errors.displayName = 'Display Name is required';
        }

        if (scanSpaceApplications && Object.entries(scanSpaceApplications).length <= 0) {
          errors.applications = 'At least one scan space must be configured';
        }

        return errors;
      }}
      onSubmit={readOnly ? () => {} : handleSubmit}
    >
      {({isSubmitting, resetForm, setFieldValue, values, setValues}) => (
        <Form className='CreateForm' autoComplete='off'>
          <div className = "CreateForm__section" >
            {apiError && (
              <FormGroup>
                <div>{apiError}</div>
              </FormGroup>
            )}
            <Field label="Workspace Name" name="displayName" readOnly={readOnly}>
              <Input
                disabled={readOnly}
                tag={FormikField}
                name="displayName"
                autoFocus
              />
            </Field>
            <Field label="Description" name="description" readOnly={readOnly}>
              <Input
                disabled={readOnly}
                tag={FormikField}
                name="description"
              />
            </Field>
            {!readOnly &&
              <>
                <Field label="Scan Space"
                  name="scanSpace"
                  readOnly={readOnly}
                  tooltip='A Scan Space is a named container for scans'
                >
                  <AsyncSelect<Exclude<typeof scanSpaces, undefined>[number]>
                    options={scanSpaces}
                    value={selectedScanSpace}
                    getOptionLabel={({displayName}) => displayName}
                    getOptionValue={({id}) => id}
                    onChange={async (scanSpace) => {
                      if (scanSpace) {
                        setSelectedScanSpace(scanSpace);
                        const appResponse = await getScanSpaceApplications(scanSpace.id);
                        if (appResponse.status === 'success') {
                          setValues({...values, applications: appResponse.data?.map((name) => ({
                            id: name,
                            label: name,
                            selected: scanSpaceApplications && scanSpaceApplications[scanSpace.id] && scanSpaceApplications[scanSpace.id]?.includes(name) || false,
                          })) || []});
                        }
                      }
                    }}
                  />
                </Field>
                <Field label="Include all Scan Space content."
                  name="scanSpaceContent"
                  readOnly={readOnly}
                  tooltip='Include all existing applications and any new applications scanned into this Scan Space.'
                >
                  <input type='checkbox' onChange={(e) => {
                    if (selectedScanSpace) {
                      if (e.target.checked) {
                        setScanSpaceApplications({...scanSpaceApplications, [selectedScanSpace.id]: []} );
                      } else {
                        const temp = {...scanSpaceApplications};
                        delete temp[selectedScanSpace.id];
                        setScanSpaceApplications(temp);
                      }
                      setValues({...values, applications: values.applications.map((a) => ({...a, selected: false}))});
                    }
                  }} checked={selectedScanSpace?.id &&
                    scanSpaceApplications &&
                    scanSpaceApplications[selectedScanSpace.id] &&
                    scanSpaceApplications[selectedScanSpace.id]?.length === 0 &&
                    true ||
                    false}></input>
                </Field>
                <FormGroup>
                  {loadingApplications ? <LoadingPlaceholder /> : (
                  <FormSection title={'Choose Applications to Include in Your Workspace'}>
                    <MultiSelectList
                      readOnly={readOnly}
                      selectAllLabel="Select All"
                      options={ values.applications }
                      renderOption={ApplicationOptionContent}
                      onSelectionChanged={(options) => {
                        setFieldValue(
                            'applications',
                            options,
                        );
                        if (selectedScanSpace) {
                          if (options && options.filter((v) => v.selected).length > 0) {
                            setScanSpaceApplications({...scanSpaceApplications, [selectedScanSpace.id]: options ? options.filter((v) => v.selected).map((a) => a.label) : []} );
                          } else {
                            const temp = {...scanSpaceApplications};
                            delete temp[selectedScanSpace.id];
                            setScanSpaceApplications(temp);
                          }
                        }
                      }}
                    />
                  </FormSection>
                )}
                  <div className="CreateForm__error-message-container">
                    <ErrorMessage
                      name="applications"
                      component="span"
                    />
                  </div>
                </FormGroup>
              </>
            }
            {scanSpaceApplications && scanSpaces && <ul>
              {Object.entries(scanSpaceApplications).map(([k, v]) => (
                <li key={k}>
                  {'Scan Space: ' + scanSpaces.find((s) => s.id === k)?.displayName || 'empty'}
                  {v && <ul>{v.length > 0 && v.map((v) => <li key={v}>{v}</li>)}{v.length === 0 && 'All'}</ul>}
                </li>
              ))}
            </ul>}
          </div>
          <div className="CreateForm__controls">
            {
            !readOnly && USER_CAN_EDIT ? (
              <Button type="submit" disabled={isSubmitting} color="primary">Save</Button>
            ) : USER_CAN_EDIT ? (
              <Button type="button" onClick={(e) => {
                redirectToEdit();
                e.preventDefault();
              }} color="primary">Edit</Button>
            ) : (
            null
            )
            }
            {readOnly ? (
              <Button type="button" color="secondary" onClick={(e) => {
                resetForm(); backEvent(e);
              }}>Back</Button>
            ) : (
              <Button type="button" color="secondary" onClick={(e) => {
                resetForm(); backEvent(e);
              }}>Cancel</Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
