import {PropsWithChildren} from 'react';
import classnames from 'classnames';

import './styles.css';

export const NoDataPlaceholder = ({className = '', children}: PropsWithChildren<{className?: string}>) => (
  <div
    className={classnames({
      'NoDataPlaceholder': true,
      ...className.split(' ').reduce((accum, name) => ({
        ...accum,
        [name]: true,

      }), {}),
    })}>
    {children}
  </div>
);
