import {
  GraphObject,
  Panel,
  Spot,
  TextBlock,
} from 'gojs';

const {make} = GraphObject;

export const makeCustomToolTip = (tooltipText: string) =>
  make('ToolTip',
      make(Panel, 'Vertical',
          make(Panel, 'Horizontal', {alignment: Spot.Left},
              make(TextBlock, {margin: 4, text: tooltipText}),
          ),
      ),
  );

