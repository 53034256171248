import {CODELOGIC_API_BASE_URL} from 'src/config';
import {
  ApiErrorResponse,
  ApiResponseWithId,
  CreateUserPayload,
  TypedApiResponse,
  UpdateUserPayload,
  User,
  UserRole,
} from 'src/types';

import {isAxiosError} from './utils';
import {axiosInstance} from './baseApi';

import {makeResourceListFetch, makeResourceFetchById} from './index';

export const getUser = makeResourceFetchById<User>({method: 'GET', path: 'user'});

type ValidationError = {field: string, message: string}

export const updateUser = async (updateUserRequest: UpdateUserPayload): Promise<ApiResponseWithId | ApiErrorResponse<ValidationError>> => {
  try {
    const response = await axiosInstance.put(`${CODELOGIC_API_BASE_URL}/user/${updateUserRequest.id}`, updateUserRequest, {withCredentials: true});
    return response?.data;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse<ValidationError>>(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
};

export const createUser = async (createUserRequest: CreateUserPayload): Promise<ApiResponseWithId | ApiErrorResponse<ValidationError>> => {
  try {
    const response = await axiosInstance.post(`${CODELOGIC_API_BASE_URL}/user`, createUserRequest, {withCredentials: true});
    return response?.data;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse<ValidationError>>(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
};

export type UserRoleFilter = {
  roles: Array<UserRole>
};

export const getUsers = makeResourceListFetch<Array<User>, UserRoleFilter>({method: 'GET',
  path: 'user', filterName: 'username', constantParams: {roles: [UserRole.USER, UserRole.ADMIN]}});

export const deleteUser = async (id: string): Promise<TypedApiResponse<any> | ApiErrorResponse | Error> => {
  try {
    const {data} = await axiosInstance.delete<TypedApiResponse<any>>(`${CODELOGIC_API_BASE_URL}/user/${encodeURIComponent(id)}`, {withCredentials: true});
    return data;
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
};
