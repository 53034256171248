import classnames from 'classnames';
import {CSSProperties, ReactNode, MouseEvent, useEffect, useRef, useState} from 'react';

import './styles.css';

export const ConfirmCancelButton = ({
  promptText,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onConfirm = () => true,
  onCancel = () => true,
  onOpen = () => true,
  className,
  style,
  additionalContent,
}: {
  promptText: ReactNode,
  confirmText?: ReactNode,
  cancelText?: ReactNode,
  className?: string,
  style?: CSSProperties,
  onConfirm?: () => Promise<boolean> | boolean | void,
  onCancel?: () => Promise<boolean> | boolean | void,
  onOpen?: () => Promise<boolean> | boolean | void,
  additionalContent?: ReactNode,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const open = makePredicatedToggle(onOpen, () => setIsOpen(true));
  const cancel = makePredicatedToggle(onCancel, () => setIsOpen(false));
  const confirm = makePredicatedToggle(onConfirm, () => setIsOpen(false));

  const overlay = false;

  useEffect(() => {
    if (!overlay) {
      return;
    }
    const {current} = ref;
    const {current: content} = contentRef;
    if (current && content && isOpen) {
      requestAnimationFrame(() => {
        const {width} = current.getBoundingClientRect();
        content.style.transform = `translateX(-${width + 5}px)`;
      });
    }
  }, [overlay, isOpen, ref.current, contentRef.current]);

  return (
    <div
      style={style}
      ref={ref}
      className={classnames({
        ConfirmCancelButton: true,
        [className || '']: !!className,
      })}
    >
      {!isOpen && (
        <button
          onClick={open}
          className="ConfirmCancelButton__button btn btn-primary">
          {promptText}
        </button>
      )}
      {isOpen && (
        <div
          ref={contentRef}
          className={classnames({
            'ConfirmCancelButton__content--overlay': overlay,
          })}
        >
          {additionalContent && (
            <div>
              {additionalContent}
            </div>
          )}
          <div>
            <button
              className="ConfirmCancelButton__button btn btn-primary"
              onClick={confirm}
            >
              {confirmText}
            </button>
            <button
              className="ConfirmCancelButton__button btn btn-secondary"
              onClick={cancel}
            >
              {cancelText}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const makePredicatedToggle = (
    predicateFn: () => Promise<boolean> | boolean | void,
    fn: () => void,
) => async (e: MouseEvent) => {
  e.stopPropagation();
  const result = await predicateFn();
  if (result) {
    fn();
  }
};


