import throttle from 'lodash/throttle';
import {forwardRef, ReactNode, useEffect, useRef, useState} from 'react';

import {
  Button,
  ButtonContent,
  Container,
  PanelHeaderContainer,
  PanelContent,
} from './styled';

type PanelOption = {
  key: string,
  panel: ReactNode,
  toggle: ReactNode
}

type PanelSwitcherProps<T extends Array<PanelOption> = Array<PanelOption>> = {initialPanelSelection: T[number]['key'], panelOptions: T}

export const PanelSwitcher = ({initialPanelSelection, panelOptions}: PanelSwitcherProps) => {
  type OptionKeys = typeof panelOptions[number]['key']

  const [contentHeight, setContentHeight] = useState<string | undefined>(undefined);
  const [selectedKey, setSelectedKey] = useState<OptionKeys>(initialPanelSelection);
  const ref = useRef<HTMLDivElement>(null);


  const setHeight = throttle(() => {
    const {current} = ref;
    if (current) {
      const headerHeight = current.getBoundingClientRect().height;
      setContentHeight(`calc(100% - ${headerHeight}px)`);
    }
  }, 200);

  useEffect(() => {
    document.addEventListener('resize', setHeight);
    setHeight();
    return () => document.removeEventListener('resize', setHeight);
  }, []);

  return (
    <Container>
      <PanelHeader
        ref={ref}
        onClickToggle={setSelectedKey}
        selectedKey={selectedKey}
        options={panelOptions.map(({key, toggle}) => ({key, toggle}))}
      />
      <PanelContent contentHeight={contentHeight}>
        {(panelOptions.find(({key}) => key === selectedKey) || {}).panel}
      </PanelContent>
    </Container>
  );
};


type PanelHeaderProps<T extends Array<Omit<PanelOption, 'panel'>> = Array<Omit<PanelOption, 'panel'>>> = {
  onClickToggle: (k: T[number]['key']) => void
  selectedKey: T[number]['key'],
  options: T
}

const makeClickHandler = (value: string, fn: (_: string) => void) => () => fn(value);

// eslint-disable-next-line react/display-name
export const PanelHeader = forwardRef<HTMLDivElement, PanelHeaderProps>(({onClickToggle, selectedKey, options}: PanelHeaderProps, ref) => (
  <PanelHeaderContainer ref={ref}>
    {options.map(({toggle, key}, i) => (
      <Button onClick={makeClickHandler(key, onClickToggle)} key={i}>
        <ButtonContent active={key === selectedKey}>
          {toggle}
        </ButtonContent>
      </Button>
    ))}
  </PanelHeaderContainer>
));
