import {useContext, useMemo, useState} from 'react';

import {Diagram as GOJSDiagram, GraphLinksModel} from 'src/util/gojs/go';
import {makeDiagramExporter} from 'src/graph/behaviors';
import {
  useContextMenuPosition,
} from 'src/hooks';
import {
  ContextMenu,
  MenuItem, Diagram,
} from 'src/components';
import {THEMES} from 'src/styles/colors';
import {ThemeContext} from 'src/context';
import {
  Diagram as DiagramType,
  DiagramLink,
  DiagramNode,
} from 'src/types';

import {makeDiagramInitializer} from './utils';

import '../dependency.css';

export function GraphView({
  data: {nodes, edges} = {nodes: [], edges: []},
  selectedElements: {selectedNodeIds, selectedEdgeIds} = {selectedNodeIds: [], selectedEdgeIds: []},
  onUpdateElementSelections = () => {},
  setData,
}: {
  setData: (_: {nodes: Array<DiagramNode>, edges: Array<DiagramLink>}) => void,
  data?: {nodes: Array<DiagramNode>, edges: Array<DiagramLink>},
  selectedElements?: {
    selectedNodeIds: Array<string>,
    selectedEdgeIds: Array<string>
  },
  onUpdateElementSelections?: (_: {
      selectedNodeIds: Array<string>,
      selectedEdgeIds: Array<string>
    }) => void
}) {
  const {theme} = useContext(ThemeContext);
  const {
    contextMenuState,
    showContextMenu,
    hideContextMenu,
  } = useContextMenuPosition();

  const [diagram, setDiagram] = useState<GOJSDiagram & {model: GraphLinksModel}| undefined>();

  const exportDiagramAsSVG = useMemo(() => {
    if (diagram) {
      hideContextMenu();
      return makeDiagramExporter(diagram);
    } else {
      return () => console.warn('Attempted to export svg, but no diagram found');
    }
  }, [diagram]);


  return (
    <>
      <Diagram
        loading={false /* TODO: wire this up to fetching */}
        data={{
          nodes,
          edges,
        }}
        selectedIds={[...selectedNodeIds, ...selectedEdgeIds]}
        setData={setData}
        onUpdateElementSelections={onUpdateElementSelections}
        initDiagram={
          makeDiagramInitializer({
            onInitialized: (d) => setDiagram(d as DiagramType),
            theme: THEMES[theme],
            themeName: theme,
            showContextMenu,
          })
        }
      />
      <ContextMenu {...contextMenuState}>
        <MenuItem onClick={exportDiagramAsSVG}>Export SVG</MenuItem>
      </ContextMenu>
    </>
  );
}
