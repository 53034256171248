import styled from 'styled-components';

export const TitleContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 1.25rem;
`;

export const Title = styled.h1`
  font-size: 1.5rem;
`;

export const Sidebar = styled.div`
  background-color: var(--color-background--standard);
  border-right: 1px solid var(--color-border--standard);
  flex: 1 1 20%;
  min-width: 15rem;
  max-width: 25rem;
  overflow-y: scroll;
`;

