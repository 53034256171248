import {useContext} from 'react';
import {Modal, ModalBody} from 'reactstrap';

import {colorTag} from 'src/state/dependency2';
import {THEMES} from 'src/styles/colors';
import {ModalCloseButton, LoadingPlaceholder, NoDataPlaceholder} from 'src/components';
import {ThemeContext} from 'src/context';
import {useNode} from 'src/hooks';

import {NodeDetail} from '../NodeDetail';

import {Props} from './types';

import './styles.css';


export const NodeDetailModal = ({isOpen, toggle, nodeId, goToNode}: Props) => {
  const {theme: themeName} = useContext(ThemeContext);
  const {loading, node} = useNode(nodeId);

  const {color: borderColor} = node?.type ? colorTag(node.type, {themeName, theme: THEMES[themeName], transparency: false, type: 'node'}) : {color: undefined};

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fade={false}
      modalClassName="NodeDetailModal"
      fullscreen
    >
      <ModalBody className='NodeDetailModalBody' style={{borderColor}}>

        {loading ? (
          <LoadingPlaceholder />
        ) : (
          node ? (
            <NodeDetail goToNode={goToNode} node={node} />
          ) : (
            <NoDataPlaceholder>
              Node not found
            </NoDataPlaceholder>
          )
        )}
      </ModalBody>
      <ModalCloseButton toggle={toggle} />
    </Modal>
  );
};
