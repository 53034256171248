import {useContext} from 'react';

import {ColorLegendContext} from 'src/context';
import {RelationshipList} from 'src/components';
import {DirectRelationshipData} from 'src/types';

import {Props} from './types';

import './styles.css';


export const NodeRelationships = ({
  list,
  nodeId,
  goToNode,
}: Props) => {
  const {
    getLegendEntry,
  } = useContext(ColorLegendContext);


  const convertToListData = ({node: {id, name, properties}, parentNode: parent}: DirectRelationshipData) => ({
    id,
    name,
    color: getLegendEntry(properties?.primaryLabel),
    relation: properties?.primaryLabel,
    parent: parent && parent.properties && parent.properties.shortName || parent?.properties.name || '',
  });

  const outboundData = list.filter((it) => it.direction === 'OUT').map(convertToListData);
  const inboundData = list.filter((it) => it.direction === 'IN').map(convertToListData) || [];

  return (
    <div className="NodeRelationships">
      <div className="NodeRelationships-container">
        {
          <RelationshipList
            readOnly={true}
            goToNode={goToNode}
            title="Inbound"
            list={inboundData}
          />
        }
      </div>
      <div className="NodeRelationships-container">
        {
          <RelationshipList
            readOnly={true}
            goToNode={goToNode}
            title="Outbound"
            list={outboundData} />
        }
      </div>
    </div>
  );
};

