import {hexToRGBComponents} from 'src/util/colors';

export const highlight = ({isHighlighted, color}: {isHighlighted: boolean, color: string}) => isHighlighted ? color : null;

export const semiTransparentHighlight = ({isHighlighted, color}) => {
  if (isHighlighted) {
    const highlightColor = hexToRGBComponents(color);
    return `rgba(${highlightColor}, 0.3)`;
  }
  return 'transparent';
};
