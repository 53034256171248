import uniqBy from 'lodash/uniqBy';

import {getWhereUsed as getDiagramWhereUsed, getContainsPath} from 'src/api/dependency';
import {
  isDeEmphasized,
} from 'src/graph';

export const fetchDiagramData = async (nodeId: string) => {
  if (!nodeId) {
    return;
  }

  const [result, {data: containsPathData}] = await Promise.all([
    getDiagramWhereUsed(nodeId),
    getContainsPath(nodeId),

  ]);


  if (result.status === 'success' && result.data && result.data !== undefined) {
    const data = {
      nodes: result.data.nodes,
      relationships: result.data.relationships,
    };

    if (containsPathData.status === 'success' && containsPathData.data && containsPathData.data !== undefined) {
      data.nodes = uniqBy(data.nodes.concat(containsPathData.data.nodes), ({id}) => id);
      data.relationships = uniqBy(data.relationships.concat(containsPathData.data.relationships), ({startId, endId}) => `${startId},${endId}`);
    }
    return {
      nodes: data.nodes.map((n) => ({
        ...n,
        emphasized: !isDeEmphasized(n.primaryLabel),
      })),
      relationships: data.relationships.map((r) => ({...r, emphasized: !isDeEmphasized(r.type)})),
    };
  } else {
    return {
      nodes: [],
      relationships: [],
    };
  }
};
