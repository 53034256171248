import {
  Binding,
  Link,
  Point,
  TextBlock,
  Shape,
} from 'gojs';

import {SEARCH_NODE} from 'src/constants';
import {getColor, getTextColor} from 'src/graph';
import {container, row, col} from 'src/util/gojs/components';
import {margin} from 'src/util/gojs/utils';
import {make} from 'src/util/gojs';
import {promptFont} from 'src/util/gojs/styles';
import {ClickHandler} from 'src/util/gojs/types';

export const makeLinkTemplate = ({
  labeled,
  handleContextClick = () => {},
}: {
  labeled?: boolean,
  handleContextClick?: ClickHandler,
} = {}) => make(
    Link, // the whole link panel
    {
      layerName: 'Background',
      reshapable: false,
    },
    make(
        Shape, // the link shape
        {strokeWidth: 1.5},
        new Binding('stroke', '', getColor).ofObject(),
        new Binding('strokeDashArray', '', (n) => n.destType === SEARCH_NODE ? [10, 5] : null),
    ),
    make(
        Shape, // the arrowhead
        {
          toArrow: 'standard',
          stroke: null,
        },
        new Binding('fill', '', getColor).ofObject(),
    ),
    labeled && container([
      {
        segmentOffset: new Point(0, 0),
        segmentOrientation: Link.OrientUpright,
      },
      new Binding('background', '', getColor).ofObject(),
      row([
        col([
          {
            margin: margin(2, 5),
          },
          make(
              TextBlock,
              'Unknown',
              {font: promptFont(1)},
              new Binding('stroke', '', getTextColor).ofObject(),
              new Binding('text', '', ({type}) => type.toUpperCase()),
          ),
        ]),
      ]),
    ]),
    !labeled && container([
      {
        segmentOffset: new Point(0, 0),
        segmentOrientation: Link.OrientUpright,
      },
      new Binding('background', '', getColor).ofObject(),
      new Binding('visible', '', (model) => {
        if (model.neighborSelected && model.type) {
          return true;
        } else {
          return false;
        }
      }).ofModel(),
      row([
        col([
          {
            margin: margin(2, 5),
          },
          make(
              TextBlock,
              'Unknown',
              {font: promptFont(1)},
              new Binding('stroke', '', getTextColor).ofObject(),
              new Binding('text', '', ({type}) => type.toUpperCase()),
          ),
        ]),
      ]),
    ]),
    {
      contextClick: handleContextClick,
    },
);
