import {ReactNode, useCallback, useEffect, useRef} from 'react';
import {IoArrowUpCircle} from 'react-icons/io5';
import {Button} from 'reactstrap';

import './styles.css';
import useDragging from './useDragging';

type Props = {
  caption: ReactNode;
  onUpload: (files: File[]) => void;
}

export const DragDropFile = ({caption, onUpload}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const uploadRef = useRef<HTMLButtonElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const dragActive = useDragging({
    divRef,
    onDrop: onUpload,
  });
  const handleChange = useCallback((e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      onUpload(Array.from(e.target.files));
    }
  }, [inputRef]);

  const onButtonClick = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  }, [uploadRef]);

  useEffect(() => {
    const inputEl = inputRef.current;
    inputEl?.addEventListener('change', handleChange);
    const divEl = divRef.current;
    divEl?.addEventListener('click', onButtonClick);
    return () => {
      inputEl?.removeEventListener('change', handleChange);
      divEl?.removeEventListener('click', onButtonClick);
    };
  }, [
    handleChange,
    onButtonClick,
  ]);

  return (
    <div className='DragDropFile' ref={divRef}>
      <input
        ref={inputRef}
        accept='.jar,.dll'
        type='file'
        id='input-file-upload'
        className='DragDropFile__input-file-upload'
        multiple={false} />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={'DragDropFile__label-file-upload' + (dragActive ? ' drag-active' : '')}>
        <div>
          <IoArrowUpCircle fontSize='5rem' />
          <h6 style={{fontWeight: 'bold'}}>Drag & Drop a JAR or DLL Here</h6>
          <p>{caption}</p>
          <Button className='DragDropFile__upload-button' color='primary' innerRef={uploadRef}>Upload</Button>
        </div>
      </label>
    </div>
  );
};
