import {Button} from 'reactstrap';
import React, {useEffect, useState} from 'react';

import {RepositoryIgnore} from 'src/types';
import {Table, ConfirmCancelInput, Section} from 'src/components';
import {addRepositoryIgnore, getRepositoryIgnores, removeRepositoryIgnores} from 'src/api/repositoryIgnore';

export const IgnoreListConfigSection = () => {
  const [ignoreList, setIgnoreList] = React.useState<Array<RepositoryIgnore> | undefined>();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [expression, setExpression] = useState<string | undefined>();
  const getData = async () => {
    setLoading(true);
    const response = await getRepositoryIgnores();
    if (response.status === 'success' && response.data) {
      setIgnoreList(response.data);
    }
    setLoading(false);
  };

  const removeRepositoryIgnoresCall = async (ignore: RepositoryIgnore) => {
    setSubmitting(true);
    const response = await removeRepositoryIgnores([ignore]);
    setSubmitting(false);
    if (response.status === 'success') {
      setExpression(undefined);
      await getData();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Section
      style={{minWidth: '30em'}}
      title="Ignore Expressions"
      controls={
        <ConfirmCancelInput
          overlay={true}
          position="before"
          promptText='Add an Ignore Expression'
          placeholder="expression..."
          validationMessage="Error: Expression cannot be empty"
          value={expression}
          onChange={(value) => setExpression(value)}
          onCancel={() => {
            setExpression(undefined);
            return true;
          }}
          onConfirm={async () => {
            if (expression === undefined || expression.length === 0) {
              return false;
            }

            setSubmitting(true);
            await addRepositoryIgnore([{
              expression,
            }]);
            setSubmitting(false);
            setExpression(undefined);
            getData();

            return true;
          }}
        />
      }
    >

      <Table<RepositoryIgnore>
        loading={loading}
        noRecordsMessage="No ignore expressions found"
        columns={[
          {
            header: () => 'Expressions',
            id: 'expression',
            cell: ({expression}) => expression,
          },
          {
            header: () => '',
            id: 'actions',
            cell: (ignore) => (
              <Button
                className="btn btn-link"
                style={{padding: '0rem 0.5rem', fontSize: '1.25rem'}}
                disabled={submitting}
                onClick={() => removeRepositoryIgnoresCall(ignore)}
              >
             Delete
              </Button>
            ),
          },
        ]}
        rows={ignoreList}
      />
    </Section>
  );
};
