import {MD5 as md5} from 'crypto-js';
import {grayscale, lighten, darken, getLuminance, meetsContrastGuidelines} from 'polished';

import {ThemeColorLegendMap, DiagramNode, NodeLabelColor} from 'src/types';
import {
  Theme,
  DEFAULT_THEME,
} from 'src/styles/colors';
import {
  SEARCH_NODE,
  THEMES as THEME_NAMES,
  DEFAULT_THEME as DEFAULT_THEME_NAME,
} from 'src/constants';
import {hexToRGBComponents, quickContrastingBWColor} from 'src/util/colors';

export const colorLegend: ThemeColorLegendMap = THEME_NAMES.reduce((acc, name) => {
  acc[name] = {};
  return acc;
}, {} as ThemeColorLegendMap);


const LUMINANCE_STEP = 0.01;

/**
 * Build a color lookup function, bound to a callback function
 */
export const makeColorGetter =
  (themeName: typeof THEME_NAMES[number], theme: Theme, callbackFn: (key: string, colorObject: NodeLabelColor) => void) =>
    (tag: string, type: 'node' | 'edge' = 'node') => {
      if (tag && colorLegend[themeName] && colorLegend[themeName][tag]) {
        return colorLegend[themeName][tag];
      }

      const colorObject = colorTag(tag, {
        type,
        transparency: false,
        theme,
        themeName,
      });

      if (tag) {
        callbackFn(tag, colorObject);
      }
      return colorObject;
    };

const generateColorForTag = (tag: string, transparency = false) => {
  const md5hash = md5(tag);
  let color = '#' + md5hash.toString().substring(0, 6).toUpperCase();
  const [r, g, b] = hexToRGBComponents(color);
  const fgColor = quickContrastingBWColor(r, g, b);

  if (transparency) {
    color = color + '55';
  }

  return {color, fgColor};
};

export function colorTag(
    tag: string,
    {
      type = 'node',
      transparency = false,
      theme = DEFAULT_THEME,
      themeName = DEFAULT_THEME_NAME,
    }: {
    type: 'node' | 'edge',
    transparency: boolean,
    theme: Theme,
    themeName: string
  },
) {
  if (colorLegend[themeName][tag]) {
    return colorLegend[themeName][tag];
  }

  const colorObject = {
    ...generateColorForTag(tag, transparency),
    type,
  };

  const bgLuminance = getLuminance(theme['color-background--standard']);
  let {AA} = meetsContrastGuidelines(theme['color-background--standard'], colorObject.color);

  const GRAYSCALE = false;
  if (GRAYSCALE) {
    colorObject.color = grayscale(colorObject.color);
  }

  // we can make at most 1 / LUMINANCE_STEP iterations to go from 0 to 1
  // This ITERATION LIMIT is a safety net to prevent infinite loops if bugs are intruduced
  const ITERATION_LIMIT = 1 / LUMINANCE_STEP;

  const changeFn = bgLuminance < 0.5 ? lighten : darken;
  let i = 0;

  while (i < ITERATION_LIMIT && !AA) {
    colorObject.color = changeFn(LUMINANCE_STEP, colorObject.color);
    const guidelines = meetsContrastGuidelines(theme['color-background--standard'], colorObject.color);
    AA = guidelines.AA;
    i++;
  }
  return colorLegend[themeName][tag] = colorObject;
}

export type NodeKeyMap = {[k: string]: boolean}

export const isSearchNode = (type: string | undefined) => {
  return type === SEARCH_NODE;
};

/**
 * @deprecated
 * Interim local type for NodeData, may not be 100% accurate since it relies on assumptions
 * based on how the un-typed code is used here.
 */
type NodeData = {
  id: string,
  type?: string,
  identity: string,
  name: string,
  relationshipCount?: number,
  properties?: {
    shortName?: string
    primaryLabel?: string
  }
}

export const mapContainedNode = (
    {
      id,
      identity,
      name,
      properties,
      relationshipCount,
    }: NodeData,
    type?: string,
) => ({
  metadata: {
    type,
    identity,
  },
  text: name || identity,
  properties,
  relationshipCount,
  id,
});

/**
 * @deprecated
 * Type signature generated based on the assumptions the code
 * in this method is making.  It may be inaccurate and should be
 * replaced with correct types
 */
type GroupData = NodeData & {groupedNodeCount: number, childGroupingCount: number}

export const mapGroupData = (
    groups: Array<GroupData>,
) => {
  if (groups) {
    return groups.map((n) => {
      const result: DiagramNode = mapContainedNode(n, n.type);
      result.isGroupNode = true;
      if (n.groupedNodeCount > 0 || n.childGroupingCount > 0) {
        result.category = 'parent';
      }
      return result;
    });
  } else {
    return [];
  }
};

export const mapContainedNodesToNodes = (
    containedNodes: Array<any>,
) => {
  const nodes: Array<any> = [];
  containedNodes.forEach((cn) => {
    cn.containedNodes.forEach((n) => {
      const result: any = mapContainedNode(n, cn.containedType);
      result.isGroupNode = false;
      if (n.relationshipCount > 0) {
        result.category = 'parent';
      }
      nodes.push(result);
    });
  });
  return nodes;
};

export const mapContainedNodesToSearchNodes = (containedNodes: Array<any>) => {
  const nodes: Array<any> = [];
  containedNodes.forEach((cn) => {
    cn.containedNodes.forEach((n) => {
      const result: any = {...n, containedType: cn.containedType};
      result.isGroupNode = false;
      if (n.relationshipCount > 0) {
        result.category = 'parent';
      }
      nodes.push(result);
    });
  });
  return nodes;
};

export const mapReferencedNodesToNodes = (
    referencedNodes: Array<any>,
) => {
  const nodes: Array<any> = [];
  referencedNodes.forEach((cn) => {
    cn.referencedNodes.forEach((n) => {
      const result: any = {};
      result.metadata = {};
      result.metadata.type = cn.referencedType;
      result.metadata.identity = n.identity;
      result.text = n.name || n.identity;
      result.id = n.id;
      result.isGroupNode = false;
      result.relationshipCount = n.relationshipCount;
      result.properties = n.properties;
      if (n.relationshipCount > 0) {
        result.category = 'parent';
      }
      nodes.push(result);
    });
  });
  return nodes;
};

export const mapContainedNodesToEdges = (
    containedNodes: Array<any>,
    baseNodeId: string,
    baseNodeType: string,
) => {
  const edges: Array<any> = [];

  containedNodes.forEach((cn) => {
    cn.containedNodes.forEach((n) => {
      const result: any = {};

      if (n.direction === 'IN') {
        result.from = n.id;
        result.to = baseNodeId;
        result.id = `${cn.containedType}.${n.id}.${baseNodeId}`;
        result.destType = baseNodeType;
      } else {
        result.from = baseNodeId;
        result.to = n.id;
        result.id = `${cn.containedType}.${baseNodeId}.${n.id}`;
        result.destType = cn.containedType;
      }


      result.contains = true;
      result.diffValue = n.relationshipDiffValue;
      result.type = n.relationshipLabels[0];
      edges.push(result);
    });
  });
  return edges;
};

export const mapReferencedNodesToEdges = (
    referencedNodes: Array<any>,
    baseNodeId: string,
    baseNodeType: string,
) => {
  const edges: Array<any> = [];

  referencedNodes.forEach((cn) => {
    cn.referencedNodes.forEach((n) => {
      const result: any = {};

      if (n.direction === 'IN') {
        result.from = n.id;
        result.to = baseNodeId;
        result.id = `${cn.containedType}.${n.id}.${baseNodeId}`;
        result.destType = baseNodeType;
        result.expanded = true;
      } else {
        result.from = baseNodeId;
        result.to = n.id;

        result.id = `${cn.containedType}.${baseNodeId}.${n.id}`;

        result.destType = cn.referencedType;
        result.expanded = false;
      }

      result.contains = false;
      result.diffValue = n.relationshipDiffValue;
      result.type = n.relationshipLabels[0];
      edges.push(result);
    });
  });
  return edges;
};
