import {ArrowDivider, Card} from './components';
import {ListItem} from './styled';
import {NodeRelationshipNodeRowProps} from './types';

export const NodeToNodeRow = ({
  fromItem,
  toItem,
  relationshipType,
  fromItemActions,
  toItemActions,
}: NodeRelationshipNodeRowProps) => (
  <ListItem>
    <Card name={fromItem.shortName || fromItem.name} scanRootName={fromItem.scanRootName} type={fromItem.type} actions={fromItemActions}/>
    <ArrowDivider label={relationshipType}/>
    <Card name={toItem.shortName || toItem.name} scanRootName={toItem.scanRootName} type={toItem.type} actions={toItemActions}/>
  </ListItem>
);
