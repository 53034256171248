import {ReactNode} from 'react';

import {NodeData} from 'src/types';
import {LoadingPlaceholder, NoDataPlaceholder, PropertyList, TabList} from 'src/components';
import {NodeDetailHeader} from 'src/components';

import {NodeRelationships} from '../NodeRelationships';
import {UsagesTab} from '../UsagesTab';
import {isSearchNode} from '../../../state/dependency2';
import {SearchDetailTab} from '../SearchDetail';
import {useDirectRelationships} from '../NodeRelationships/hooks';

const isArray = Array.isArray.bind(Array);

import './styles.css';

export const NodeDetail = ({
  node,
  goToNode,
}: {
  node: NodeData,
  goToNode: (id: string, name: string) => void,
}) => {
  const {loading, directRelationships} = useDirectRelationships(node.id);

  const tabs: Array<{tab: ReactNode, pane: ReactNode}> = [];
  tabs.push({
    tab: 'Relationships',
    pane: (
      <div className="NodeDetail__content">
        {loading ? (
          <LoadingPlaceholder />
        ) : isArray(directRelationships) && node ? (
          <NodeRelationships
            goToNode={goToNode}
            nodeId={node.id}
            list={directRelationships}
          />
        ) : (
          <NoDataPlaceholder>
            Unable to fetch relationships
          </NoDataPlaceholder>
        )}
      </div>
    ),
  });

  tabs.push({
    tab: 'Impact Analysis',
    pane: (
      <UsagesTab id={node.id} />
    ),
  });

  if (node && node.properties) {
    tabs.push({
      tab: 'Details',
      pane: (
        <div className="NodeDetail__content">
          {node && node.properties && <PropertyList properties={{
            id: node.id,
            identifier: node.identity,
          }}/>}
          <PropertyList properties={node.properties}/>
        </div>
      ),
    });
  }

  if (isSearchNode(node.type) && node.properties.fuzzy) {
    tabs.push({
      tab: 'Search Details(beta)',
      pane: (
        <div className="NodeDetail__content">
          <SearchDetailTab nodeId={node.id}></SearchDetailTab>
        </div>
      ),
    });
  }

  /* TODO: https://crosscode.atlassian.net/browse/CAPE-1244*/
  return (
    <>
      <NodeDetailHeader
        id={node.id}
        identity={node.identity}
        name={node.properties?.shortName || node.name}
        type={node.type}
        showCopy={true}
      />
      <TabList tabs={tabs} />
    </>
  );
};
