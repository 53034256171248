import {CODELOGIC_API_BASE_URL} from 'src/config';
import {
  AnalyzeDBRequest,
  ApiErrorResponse,
  TypedApiSuccessResponse,
  ApiSuccessResponse,
  ValidationError,
} from 'src/types';

import {axiosInstance} from './baseApi';
import {isAxiosError} from './utils';

function getOnUploadProgress(onProgress: ((percentage: number) => void) | undefined) {
  return (progressEvent) => {
    if (onProgress) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      onProgress(percentCompleted);
    }
  };
}

export const postNamespaceScan = async (file: File, onProgress?: (percentage: number) => void) : Promise<TypedApiSuccessResponse<unknown> | ApiErrorResponse> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axiosInstance.post(`${CODELOGIC_API_BASE_URL}/server-side-scan/namespace-scan`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
      onUploadProgress: getOnUploadProgress(onProgress),
    });
    return response.data;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse<ValidationError>>(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
};

export const postAnalyze = async (
    file: File,
    application: string,
    database?: string,
    onProgress?: (percentage: number) => void,
) : Promise<ApiSuccessResponse | ApiErrorResponse> => {
  try {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('application', application);
    if (database) {
      formData.append('database', database);
    }
    const response = await axiosInstance.post(`${CODELOGIC_API_BASE_URL}/server-side-scan/analyze`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
      onUploadProgress: getOnUploadProgress(onProgress),
    });
    return response.data;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse<ValidationError>>(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
};

export const postAnalyzeDB = async (request: AnalyzeDBRequest): Promise<TypedApiSuccessResponse<unknown> | ApiErrorResponse> => {
  try {
    const response = await axiosInstance.post(`${CODELOGIC_API_BASE_URL}/server-side-scan/analyze-sql`, null, {
      withCredentials: true,
      params: request,
    });
    return response?.data;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse<ValidationError>>(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
};
