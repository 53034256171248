import {rgba, ellipsis} from 'polished';
import styled from 'styled-components';

export const ItemButton = styled.button`
  /* Adapt the colors based on color prop */
  background-color: var(--color-background--standard);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  color: var(--color-text);
  margin: 0.25em;
  padding: 0.25em 0.5rem;
  border-width: 2px;
  border-color: transparent;

  &:hover {
    background-color: ${(props) => rgba(props.color ? props.color : 'var(--color-background--standard)', 0.1)};
  }

  &.active {
    background-color: ${(props) => rgba(props.color ? props.color : 'var(--color-background--standard)', 0.3)};
    border-color: ${(props) => props.color || 'gray'};
    border-width: 2px;
    border-style: solid;
    border-radius: 3px;
  }
`;

export const Truncate = styled.div`
  ${ellipsis('100%', 2)};
  width: 100%;
  line-height: 1rem;
  max-height: 2rem;
  word-wrap: break-word;
  overflow: hidden;
  text-align: left;
  margin-bottom: 0.25rem;
  `;
