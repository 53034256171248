import {MouseEvent, useContext} from 'react';
import classnames from 'classnames';
import sortBy from 'lodash/sortBy';

import {colorTag} from 'src/state/dependency2';
import {DetailToggle, Tag} from 'src/components';
import {ThemeContext} from 'src/context';
import {THEMES} from 'src/styles/colors';

import {recursivelySelectChildren} from './utils';
import {RowProps} from './types';

export const makeRowComponentFn = (nodeOfInterest?: string) =>
  function Row({
    node,
    depth,
    selectedNodes = {},
    disclosedNodes = {},
    onToggleDetail = () => {},
    onSelect = () => {},
  }: RowProps) {
    const {theme: themeName} = useContext(ThemeContext);
    const theme = THEMES[themeName];
    const isNodeOfInterest = nodeOfInterest === node.id;
    const {color, fgColor} = (isNodeOfInterest) ? {fgColor: 'black', color: 'white'} : colorTag(node.primaryLabel, {type: 'node', transparency: false, theme, themeName});

    const isDisclosed = disclosedNodes[node.id];

    const childCount = node.children?.length ? String(node.children.length) : '';
    const isSelected = !!selectedNodes[node.id];

    return (
      <>
        <li
          style={{paddingLeft: `${depth}rem`}}
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            onSelect(recursivelySelectChildren([node], !isSelected));
          }}

          className={classnames({
            'NodeHierarchyList__Row': true,
            'NodeHierarchyList__Row--node-of-interest': isNodeOfInterest,
            'NodeHierarchyList__Row--selected': isSelected,
          })} key={node.id}>
          <div className="NodeHierarchyList__Row__content">
            <div className="NodeHierarchyList__Row__toggle-container">
              {(node.children || []).length > 0 && (
                <DetailToggle
                  className="NodeHierarchyList__disclosure-toggle"
                  onToggle={(disclosed) => {
                    onToggleDetail({[node.id]: disclosed});
                  }}
                  toggled={isDisclosed}
                />
              )}
            </div>
            <div className="NodeHierarchyList__Row__details">
              <div className="NodeHierarchyList__Row__title-row">
                <span className="NodeHierarchyList__Row__title" title={node.properties.shortName || node.name}>
                  {node.properties.shortName || node.name}
                </span>

                <div className="NodeHierarchyList__Row__metadata">
                  {isNodeOfInterest && (
                    <span className="NodeHierarchyList__Row__metadata__nodeOfInterest-text">
                    Usages
                    </span>
                  )}
                  <span className="NodeHierarchyList__Row__metadata__child-count">
                    {childCount}
                  </span>
                </div>
              </div>
              <div className="NodeHierarchyList__Row__data">
                <Tag
                  fontSize="0.6875em"
                  backgroundColor={color}
                  borderColor='transparent'
                  color={fgColor}
                  label={node.primaryLabel}
                />
              </div>
            </div>
          </div>
        </li>
        {isDisclosed && node.children && sortBy(node.children, ['primaryLabel', 'name']).map((n) => (
          <Row
            key={n.id}
            onSelect={onSelect}
            onToggleDetail={onToggleDetail}
            selectedNodes={selectedNodes}
            disclosedNodes={disclosedNodes}
            depth={depth + 1}
            node={n}
          />
        ))}
      </>
    );
  };
