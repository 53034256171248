import {ReactNode, useEffect, useState} from 'react';
import classnames from 'classnames';

import {PageDataType} from 'src/types';
import {MetaDataPager, NoRecords, LoadingRecords} from 'src/components';

import {SortControl, Filter} from './components';
import {SortOrder} from './types';

type MetaDataPagerParams = Parameters<typeof MetaDataPager>[0];

type OnPageChange = MetaDataPagerParams['onPageChange']
type OnSelectPageSize = MetaDataPagerParams['onSelectPageSize']

import './styles.css';

let defaultIndex = 0;

export type ColumnDefinition<T> = {
  id: string,
  canSort?: boolean,
  canFilter?: boolean,
  initialSortOrder?: SortOrder,
  header?: ({rows}: {rows: Array<T>}) => ReactNode,
  footer?: ({rows}: {rows: Array<T>}) => ReactNode,
  cell: ((data: T) => ReactNode),
  width?: number,
}

export type ColumnsState<T> = ColumnDefinition<T> & {
  filter?: string,
}

export const Table = <T extends {id: string}>({
  metadata,
  loading,
  noRecordsMessage,
  onFilterChange,
  columns,
  rows = [],
  className,
  onSelectPageSize = () =>{},
  onPageChange = () => {},
}: {
  metadata?: {pageData: PageDataType},
  columns: Array<ColumnsState<T>>,
  rows?: Array<T>,
  loading?: boolean,
  onFilterChange?: (_: {[key: ColumnsState<T>['id']]: string | undefined}) => void,
  noRecordsMessage?: ReactNode,
  className?: string,
  onSelectPageSize?: OnSelectPageSize,
  onPageChange?: OnPageChange,
}) => {
  const hasFooter = columns.some(({footer}) => !!footer);
  const hasHeader = columns.some(({header}) => !!header);
  const hasFilter = columns.some(({canFilter}) => canFilter);


  const [
    sortOrders,
    setSortOrders,
  ] = useState<
    {[ColId in typeof columns[number]['id']]?: undefined | SortOrder}
  >({});
  const sortColumn = (id: string, sortOrder?: SortOrder) => setSortOrders({...sortOrders, [id]: sortOrder});


  const [
    columnFilters,
    setColumnFilters,
  ] = useState<
    {[ColId in typeof columns[number]['id']]?: undefined | string}
  >(columns.reduce((accum, {
    id,
    filter,
  }) => {
    if (filter) {
      accum[id] = filter;
    }
    return accum;
  }, {}));

  const makeColumnFilterFn =
    (id: string) =>
      (filterValue?: string) => {
        if (columnFilters[id] !== filterValue) {
          setColumnFilters({...columnFilters, [id]: filterValue});
        }
      };

  useEffect(() => {
    onFilterChange && onFilterChange(columnFilters);
  }, [columnFilters, onFilterChange]);


  return (
    <div className={classnames({
      Table: true,
      [className || '']: !!className,
    })}>
      <table
        role="table"
        className="table table-hover"
        cellSpacing="0"
        cellPadding="0"
      >
        {hasHeader ? (
        <thead>
          <tr role="row">
            {columns.map(({
              id,
              header,
              canSort,
              canFilter,
              filter,
            }) => (
              <th
                style={{verticalAlign: 'top'}}
                role="columnheader"
                key={id}
              >
                <div className="Table__header" >
                  {header && header({rows: rows})}{' '}
                  {canSort ? (
                      <SortControl columnId={id} onToggle={sortColumn} sortOrder={sortOrders[id]} />
                  ) : null}

                </div>
                {hasFilter ? (
                  <div>
                    {canFilter && <Filter value={filter} onChange={makeColumnFilterFn(id)}/>}
                  </div>
                ) : null}
              </th>
            ))}
          </tr>
        </thead>
      ) : null}
        <tbody role="rowgroup">
          {loading ? <LoadingRecords /> : rows.length === 0 && !loading ? <NoRecords message={noRecordsMessage} /> : rows.map((row) => (
            <tr role="row" key={row.id || defaultIndex++}>
              {columns.map(({id, cell, width}) => (
                <td role="cell" width={width} key={id}>
                  {cell(row)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        {hasFooter ? (
        <tfoot>
          <tr role="row">
            {columns.map(({id, footer}, index) => (
              <th key={index}>
                {footer && footer({rows})}
              </th>
            ))}
          </tr>
        </tfoot>
      ) : null}
      </table>
      {metadata?.pageData && (
        <div className="Table__pagination-wrapper">
          <MetaDataPager
            onSelectPageSize={onSelectPageSize}
            onPageChange={onPageChange}
            pageData={{
              ...metadata.pageData,
              currentPage: metadata.pageData.currentPage,
              pageSize: metadata.pageData.pageSize || 0,
              startingResult: metadata.pageData.startingResult || 0,
              totalPages: metadata.pageData.totalPages || 0,
            }} />
        </div>
      )}
    </div>
  );
};

