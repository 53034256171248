import {MdTableRows, MdAccountTree} from 'react-icons/md';

import {SelectedIdsContextProvider} from 'src/context';
import {HelpIcon, NodeUsageTable, NodeTreeFilter, PanelSwitcher, FixedHeightLayout, UsagesDiagram} from 'src/components';

import {HELP_IMAGE} from './help-image';
import {TitleContainer, Title} from './styled';

import './styles.css';


export const Page = () => {
  return (
    <FixedHeightLayout
      renderHeader={() => (
        <TitleContainer>
          <Title>Investigate Usages <HelpIcon tooltipClassName="InvestigateUsages__help-icon-tooltip" content={
            <div>
              {HELP_IMAGE}
            </div>
          }/></Title>
        </TitleContainer>
      )}
    >
      <div style={{display: 'flex', height: '100%'}}>
        <SelectedIdsContextProvider>
          <NodeTreeFilter />
          <div style={{width: '100%', padding: '0 2rem', height: '100%', overflowY: 'scroll'}}>
            <PanelSwitcher
              initialPanelSelection='graph'
              panelOptions={[{
                key: 'table',
                toggle: <MdTableRows />,
                panel: (
                  <NodeUsageTable />
                ),
              }, {
                key: 'graph',
                toggle: <MdAccountTree />,
                panel: (
                  <div style={{position: 'relative'}}>
                    <UsagesDiagram enableFiltering={true} />
                  </div>
                ),
              }]}
            />
          </div>
        </SelectedIdsContextProvider>
      </div>
    </FixedHeightLayout>
  );
};
