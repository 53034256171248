import {useContext} from 'react';
import {isEmpty} from 'lodash';
import {Button} from 'reactstrap';

import {THEMES} from 'src/styles/colors';
import {ThemeContext} from 'src/context';
import {colorTag} from 'src/state/dependency2';

import './styles.css';

export const TypeLegend = ({
  nodeTypes,
  linkTypes,
  onHide,
}: {
  nodeTypes: Array<string>,
  linkTypes: Array<string>,
  onHide?: (_: unknown) => void
}) => {
  const {theme: themeName} = useContext(ThemeContext);
  const theme = THEMES[themeName];
  if (isEmpty(nodeTypes) && isEmpty(linkTypes)) {
    return <div className="TypeLegend"></div>;
  }

  return (
    <div className="TypeLegend">
      <h1 style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      Legend
        {onHide && (
          <Button
            color="link"
            className="search-btn-drop"
            onClick={onHide}
          >
          Hide
          </Button>
        )}
      </h1>
      {!isEmpty(linkTypes) && (
        <>
          <h2>Relationships</h2>
          <div className="TypeLegend__items">
            {linkTypes
                .sort((a, b) => a.localeCompare(b))
                .map((type) => (
                  <p key={type}>
                    <span
                      className="edge"
                      style={{background: colorTag(type, {transparency: false, type: 'node', theme, themeName}).color}}
                    ></span>{' '}
                    {type}
                  </p>
                ))}
          </div>
        </>
      )}

      {!isEmpty(nodeTypes) && (
        <>
          <h2>Items</h2>
          <div className="TypeLegend__items">
            {nodeTypes
                .sort((a, b) => a.localeCompare(b))
                .map((type) => {
                  const color = colorTag(type, {transparency: false, type: 'node', theme, themeName}).color;
                  return (
                    <p key={type}>
                      <span style={{background: color}}></span>
                      <span style={{background: color}}></span>
                      <span style={{background: color}}></span> {type}
                    </p>
                  );
                })}
          </div>
        </>
      )}
    </div>
  );
};
