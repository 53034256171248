import {ReactNode, PropsWithChildren} from 'react';

import {Container, TitleContainer, Main} from './styled';

export const FixedHeightLayout = ({
  viewHeight = 100,
  headerHeight = '4.5rem',
  renderHeader,
  children,
}: PropsWithChildren<{
  renderHeader: ({height: string}) => ReactNode,
  headerHeight?: string
  viewHeight?: number
}>) => (
  <Container className="FixedHeightLayout" style={{height: `calc(${viewHeight}vh - var(--top-nav-height))`}}>
    <TitleContainer style={{height: headerHeight}}>
      {renderHeader({height: headerHeight})}
    </TitleContainer>
    <Main style={{height: `calc(${viewHeight}vh - ${headerHeight} - var(--top-nav-height))`}}>
      {children}
    </Main>
  </Container>

);

