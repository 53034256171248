import {useEffect, useState} from 'react';
import {
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {Table} from 'reactstrap';
import {FaSortUp, FaSortDown, FaSort} from 'react-icons/fa';

import {ListingPageProps} from 'src/types';
import {Pager} from 'src/components';

import {NoRecords} from '../NoRecords';

import './styles.css';

export const ListingPage = ({columns, data, pageData, fetchData}: ListingPageProps) => {
  const [totalItems, setTotalItems] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [{pageIndex, pageSize}, setPagination] =
    useState<PaginationState>({
      pageIndex: pageData?.currentPage ?? 0,
      pageSize: pageData?.pageSize ?? 10,
    });

  const setPageSize = (pageSize: number) => setPagination({pageSize, pageIndex});
  const setPageIndex = (pageIndex: number) => setPagination({pageSize, pageIndex});

  const {
    getHeaderGroups,
    getRowModel,
  } = useReactTable(
      {
        columns,
        data,
        state: {
          sorting,
        },
        pageCount,
        manualPagination: true,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
      },
  );

  useEffect(() => {
    setTotalItems(pageData?.totalResults ?? 0);
    setPageCount(pageData?.totalPages ?? 1);
  }, [pageData]);


  useEffect(() => {
    let sortByStr: string | undefined;

    if (sorting?.length) {
      sortByStr = `${sorting[0].id},${sorting[0].desc ? 'desc' : 'asc'}`;
    }

    // const {pageSize, pageIndex} = getState().pagination;
    fetchData(pageIndex, pageSize, sortByStr);
  }, [pageIndex, pageSize, fetchData, sorting]);

  return (
    <div className="listing-page">
      <Table hover role="table">
        <thead>
          {getHeaderGroups().map((headerGroup, index) => (
            <tr key={headerGroup.id} role="row">
              {headerGroup.headers.map((header, innerIndex) => (
                <th
                  key={`th-header-${index}-${innerIndex}`}
                  onClick={header.column.getToggleSortingHandler()}
                  role="columnheader"
                >
                  <div className="listing-page__header-cell">

                    {flexRender(header.column.columnDef.header, header.getContext())}

                    {header.column.getCanSort() && (
                      <span className="sort-indicator">
                        {
                          {
                            asc: <FaSortUp />,
                            desc: <FaSortDown />,
                          }[header.column.getIsSorted() as string] ?? <FaSort className="placeholder" />
                        }
                      </span>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody role="rowgroup">
          {getRowModel().rows.length === 0 ? <NoRecords /> : getRowModel().rows.map((row) => {
            return (
              <tr key={row.id} role="row">
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} role="cell">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Pager
        totalItems={totalItems}
        itemsOnPage={data.length}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onSelectPageSize={(size) => setPageSize(size)}
        onPageChange={(newPage) => setPageIndex(newPage)}
      />
    </div>
  );
};
