import {PropsWithChildren} from 'react';
import {
  Modal as RSModal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

import './styles-form.css';

export const Modal = ({
  title,
  children,
}: PropsWithChildren<{
  title?: string,
}>) => (
  <RSModal isOpen={true} fade={false} modalClassName="height-auto">
    <ModalHeader>{title}</ModalHeader>
    <ModalBody>
      {children}
    </ModalBody>
  </RSModal>
);
