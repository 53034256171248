import {Layout} from 'src/components';

import {Namespaces} from './Namespaces';

export const NamespacesPage = () => {
  return (
    <Layout title='Define Namespaces'>
      <div>
        <p>
        Edit namespaces
        </p>
      </div>
      <Namespaces />
    </Layout>
  );
};
