import {PropsWithChildren, ReactNode} from 'react';
import {ErrorMessage} from 'formik';
import {FormGroup, Label, UncontrolledTooltip} from 'reactstrap';
import {BsQuestionCircle} from 'react-icons/bs';

import './styles.css';

type Props = PropsWithChildren<{
  readOnly: boolean,
  label: ReactNode,
  name: string,
  tooltip?: string,
}>

export const Field = ({readOnly, label, name, tooltip, children}: Props) => (
  <FormGroup>
    <Label className="CreateForm__field-label">
      <p className="CreateForm__field-title">
        {label}
        <>
          {tooltip && <>
            <UncontrolledTooltip target={name + '-tooltip'}>
              <p>{tooltip}</p>
            </UncontrolledTooltip>
            <BsQuestionCircle className="tooltip-icon" style={{marginLeft: '0.1em'}} id={name + '-tooltip'} />
          </>
          }
        </>
      </p>
      {children}
    </Label>
    <div className="CreateForm__error-message-container">
      <ErrorMessage
        name={name}
        component="span"
      />
    </div>
  </FormGroup>

);

export const ApplicationOptionContent = ({label, description}: {label: string, description?: string}) => (
  <div className="CreateForm__option__detail">
    <div className="CreateForm__option__label">
      {label}
    </div>
    <div className="CreateForm__option__description">
      {description}
    </div>
  </div>
);
