import {MouseEvent, useContext, useEffect, useState} from 'react';
import classnames from 'classnames';
import './searchpanel.css';

import {ThemeContext} from 'src/context';
import {Tag} from 'src/components';
import {THEMES} from 'src/styles/colors';
import {colorTag} from 'src/state/dependency2';

import {SearchPanelProps} from './types';
import {ItemButton, Truncate} from './styled';
import {NodeResultObj, Meta} from './types';

export function SearchPanelResults({
  searchTerm,
  submitNodeSearch,
  nodeResults,
}: SearchPanelProps) {
  const {theme: themeName} = useContext(ThemeContext);
  const [nodes, setNodes] = useState<Array<NodeResultObj>>([]);
  const [activeNodes, setActiveNodes] = useState<Array<NodeResultObj>>([]);
  const [activeId, setActiveId] = useState<Array<string>>([]);
  const [currentNodes, setCurrentNodes] = useState<Array<NodeResultObj>>([]);

  const addToActive = (e:MouseEvent, node:NodeResultObj) => {
    if (e.ctrlKey) {
      if (!isNodeActive(node.id)) {
        const newSelectedNodes = [...activeNodes, node];
        setActiveNodes(newSelectedNodes);
        setCurrentNodes([...currentNodes, node]);
        setActiveId((activeId) => [...activeId, node.id]);
        submitNodeSearch(newSelectedNodes, 'combined');
      } else {
        const newActiveNodes = activeNodes.filter((item) => item.id !== node.id);
        setActiveNodes(newActiveNodes);
        setCurrentNodes(currentNodes.filter((cn) => cn.id !== node.id));
        setActiveId(activeId.filter((nodeId) => nodeId !== node.id));
        submitNodeSearch(newActiveNodes, 'combined');
      }
    } else if (e.shiftKey) {
      if (checkIsIncluded(activeNodes[activeNodes.length - 1].id, node.id)) {
        getAllBetween(activeId[activeId.length - 1], node.id);
      } else {
        getAllBetween(node.id, activeId[activeId.length - 1]);
      }
    } else {
      setActiveNodes([node]);
      setActiveId([node.id]);
      submitNodeSearch([node], 'fresh');
    }
  };

  const checkIsIncluded = (prevItem: string, currentItem: string) => {
    const prevItemIndex = nodes.findIndex((node) => node.id === prevItem);
    const currItemIndex = nodes.findIndex((node) => node.id === currentItem);
    return currItemIndex > prevItemIndex;
  };

  const getAllBetween = (start: string, end: string) => {
    const idArray = new Array(start);
    const startingPoint = nodes.findIndex((node) => node.id === start);
    const endingPoint = nodes.findIndex((node) => node.id === end);
    const selectedNodes: Array<NodeResultObj> = [...currentNodes];
    for (let i = startingPoint; i <= endingPoint; i++) {
      const currId = nodes[i].id;
      if (currId) {
        idArray.push(currId);
        const currNode = nodes.find((node) => node.id === currId);
        if (currNode) {
          selectedNodes.push(currNode);
        }
      } else {
        return;
      }
    }
    idArray.push(end);
    setActiveId([...activeId, ...idArray]);
    setCurrentNodes(selectedNodes);
    setActiveNodes(selectedNodes);
    submitNodeSearch(selectedNodes, 'combined');
  };

  const isNodeActive = (currId: string) => {
    return activeId.find((nodeId) => nodeId === currId);
  };

  useEffect(() => {
    if (nodeResults && nodeResults.length > 0) {
      const nodesWithMetaData = nodeResults.map((item) => {
        const colorObj = colorTag(item.primaryLabel, {
          themeName,
          theme: THEMES[themeName],
          transparency: false,
          type: 'node',
        });
        const metadata: Meta = {type: '', identity: ''};
        metadata.type = item.primaryLabel;
        metadata.identity = item.identity;
        return {...item, metadata, colorObj: colorObj};
      });
      setNodes(nodesWithMetaData);
    }
  }, [nodeResults]);

  return (
    <div className={classnames({
      'SearchPanelView__search-results': true,
      'SearchPanelView__search-results--no-results': nodes.length === 0,
    })}>
      {nodes.length > 0 ? nodes.map((node) => (
        <ItemButton
          className={isNodeActive(node.id) ? 'active' : ''}
          key={node.id} color={node.colorObj?.color}
          id={node.id}
          title={node.parentName ? 'parent:' + node.parentName : node.parentName}
          onClick={(event)=> {
            addToActive(event, node);
          }}>
          <Truncate>{node.shortName ? node.shortName : node.name}</Truncate>
          <div className='SearchPanelView__item-type'>
            <Tag
              backgroundColor={node.colorObj?.color}
              color={node.colorObj?.fgColor}
              label={node.primaryLabel}
            />
            <div className='SearchPanelView__root-name'>{node.scanRootName}</div>
          </div>
        </ItemButton>
      )) : <span>No results for search term &apos;{searchTerm}&apos;</span>}
    </div>
  );
}
