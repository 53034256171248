import {KeyboardEvent, useState} from 'react';
import orderBy from 'lodash/orderBy';

import {SearchInput, MultiSelectList, TagSelect} from 'src/components';
import {
  useTextFilteredList,
  useTermFilteredList,
  useSortDirection,
} from 'src/hooks';

import {RelationshipListItem} from './RelationshipListItem';
import {Props, RelationshipRowData} from './types';
import {buildSelectOptions} from './utils';
import {CountTag, NoRecords, SortToggle} from './components';

import './styles.css';

const noop = (id: string, name: string) => {};

type SelectedRelationshipRowData = RelationshipRowData & {disabled: boolean, selected: boolean}

export const RelationshipList = ({
  title,
  list: receivedList,
  onSelectedItemsChange = () => {},
  readOnly = false,
  goToNode = noop,
}: Props) => {
  const [list, setList] = useState(receivedList.map((s) => ({disabled: false, selected: false, ...s})));

  const updateList = (list: Array<SelectedRelationshipRowData>) => {
    setList(list);
    onSelectedItemsChange(
        list
            .map(({disabled, ...o}) => ({
              // strip disabled from 'o' to return to to a plain RelationshipRowData shape.
              ...o,
            })));
  };
  const [sortDirection, setSortDirection] = useSortDirection();

  // const [tagFilters, setTagFilters] = useState<Array<string>>([]);

  // const tagFilteredList = filterListOnRelationTerms(list, tagFilters);
  const {filteredList: tagFilteredList, terms: tagFilters, setFilterTerms} = useTermFilteredList(list, 'relation');
  const {filteredList: finalFilteredList, setFilterText} = useTextFilteredList(tagFilteredList, 'name');
  // const finalFilteredList = filterListOnNameTerms(tagFilteredList, splitSearchTerms);

  const sortedList = orderBy(finalFilteredList, [(x) => x.name.toLowerCase(), (x) => x.relation?.toLowerCase()], [sortDirection, sortDirection]);

  const handleSearchTermChanged = ({currentTarget: {value}}: KeyboardEvent<HTMLInputElement>) => {
    setFilterText(value);
  };

  const handleTagSelectionChanged = (values: Readonly<Array<{label: string}>>) => {
    setFilterTerms(values.map(({label}) => label));
  };

  const options = buildSelectOptions(list);
  const selectedOptions = options.filter(({label}) => tagFilters.includes(label));

  return (
    <div className="RelationshipList">
      <div className="RelationshipList__header">
        <div className="RelationshipList__header__row">
          <div className="RelationshipList__title">
            {title && <h2>{title}</h2>}
            <CountTag count={sortedList.length}/>
          </div>

          <div className="RelationshipList__tagFilter">
            {tagFilteredList.length > 0 && (
              <TagSelect
                value={selectedOptions}
                options={buildSelectOptions(list)}
                onChange={handleTagSelectionChanged}
              />
            )}
          </div>
        </div>
        <div className="RelationshipList__header__row">
          <SortToggle onClick={setSortDirection}/>
          <SearchInput onKeyUp={handleSearchTermChanged} />
        </div>
      </div>
      <div>
        {sortedList.length ? <MultiSelectList
          readOnly={readOnly}
          maxColumnCount={1}
          options={sortedList}
          onSelectionChanged={updateList}
          renderOption={({id, name, relation, color, parent}) => (
            <RelationshipListItem
              key={id}
              id={id}
              name={name}
              relation={relation}
              color={color}
              goToNode={goToNode}
              parentName={parent}
            />
          )}
        /> : <NoRecords />}
      </div>
    </div>
  );
};
