import {useContext, useState} from 'react';
import {
  DropdownItem,
} from 'reactstrap';
import classnames from 'classnames';
import {IoPencilSharp} from 'react-icons/io5';
import {IoIosAddCircle} from 'react-icons/io';
import {useNavigate} from 'react-router-dom';

import {WORKSPACE_CREATE_PATH, WORKSPACE_EDIT_PATH, WORKSPACES_PATH} from 'src/routes';
import {Workspace} from 'src/types';
import {WorkspaceContext} from 'src/context';

import {Props} from './types';

import './styles.css';

const canEditWorkspace = (workspace?: Workspace) =>
  workspace && !workspace.primary;

let timer: NodeJS.Timeout;
export const Panel = ({onPerformAction, loading, workspaces}: Props) => {
  const navigate = useNavigate();
  const [/* displayedWorkspace*/, setDisplayedWorkspace] = useState<Workspace | undefined>();
  const {currentWorkspace, setCurrentWorkspace} = useContext(WorkspaceContext);

  const updateWorkspaceSelection = (workspace: Workspace) => {
    if (workspace) {
      setCurrentWorkspace(workspace);
    }
  };

  return !loading ? (
    <div className='WorkspaceDisplay small-scrollbar'>
      <div className='WorkspaceMenuContainer'>
        <DropdownItem header className='WorkspaceHeader' tag='div'>
          {
            currentWorkspace && canEditWorkspace(currentWorkspace) && <a className='WorkspaceLink' onClick={() => {
              navigate(WORKSPACE_EDIT_PATH.replace(':entityId', currentWorkspace.id));
              onPerformAction();
            }}>
              <IoPencilSharp className='WorkspaceDisplay__icon'></IoPencilSharp>
              Edit Current Workspace
            </a>
          }
          <a
            onClick={() => {
              navigate(WORKSPACES_PATH);
              onPerformAction();
            }}
            className='WorkspaceLink'
          >
            View All
          </a>
        </DropdownItem>
        <div className='WorkspaceItems'>
          {workspaces?.map((workspace) => (
            <DropdownItem
              className={classnames({
                active: currentWorkspace?.id === workspace.id,
              })}
              key={workspace.id}
              onClick={() => {
                updateWorkspaceSelection(workspace);
              }}
              onMouseEnter={() => {
                clearTimeout(timer);
                setDisplayedWorkspace(workspace);
              }}
              onMouseLeave={() => {
                clearTimeout(timer);
                timer = setTimeout(() => setDisplayedWorkspace(undefined), 200);
              }}
            >
              <span>{workspace.displayName}</span>
            </DropdownItem>
          ))}
        </div>
        <div className="WorkspaceFooter">
          <a className='WorkspaceLink' onClick={() => {
            navigate(WORKSPACE_CREATE_PATH);
            onPerformAction();
          }}>
            <IoIosAddCircle className='WorkspaceDisplay__icon'></IoIosAddCircle>
              Create Workspace
          </a>
        </div>
      </div>
      {/* (displayedWorkspace || currentWorkspace) && (
        <div
          className='WorkspacePanelDetails'
          onMouseEnter={() => {
            clearTimeout(timer);
            setDisplayedWorkspace(displayedWorkspace)
          }}
          onMouseLeave={() => {
            clearTimeout(timer);
            timer = setTimeout(() => setDisplayedWorkspace(undefined), 200);
          }}
        >
          <div className='WorkspaceTitle'>
            <h2>{displayedWorkspace ? displayedWorkspace.displayName : currentWorkspace?.displayName}</h2>
            {currentWorkspace && (!displayedWorkspace || displayedWorkspace.id === currentWorkspace.id) &&
                <a className='WorkspaceLink' onClick={() => {
                  navigate(WORKSPACE_EDIT_PATH.replace(':entityId', currentWorkspace?.id));
                  onPerformAction();
                }}>
                  <IoPencilSharp className='WorkspaceDisplay__icon'></IoPencilSharp>
                  Edit
                </a>
            }
          </div>
          <div className='WorkspaceContent'>
            {displayedWorkspace ? displayedWorkspace.description : currentWorkspace?.description}
          </div>
        </div>
      )*/}
    </div>
  ) : null;
};
