import {CODELOGIC_API_BASE_URL, CODELOGIC_UI_PATH} from 'src/config';
import {AuthData, AuthResponse} from 'src/types';

import {axiosInstance} from './baseApi';
import {isAxiosError} from './utils';


export const AUTHENTICATED_COOKIE_PATH = CODELOGIC_UI_PATH;
export const AUTHENTICATED_COOKIE_NAME = 'codelogic.authenticated';
export const ROLES_COOKIE_NAME = 'codelogic.roles';

export const DEMO_AUTH_USERNAME = 'demo@demo.com';
export const DEMO_AUTH_PASSWORD = 'demodemo';

export async function authenticate(data: AuthData) {
  if (!data.email || !data.password) {
    return;
  }

  const params = new URLSearchParams();
  params.append('username', data.email);
  params.append('password', data.password);

  try {
    const response = await axiosInstance.post<AuthResponse>(
        `${CODELOGIC_API_BASE_URL}/authenticate`,
        params,
        {
          withCredentials: true,
          headers: {'content-type': 'application/x-www-form-urlencoded'},
        },
    );
    return response;
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        console.error(e.response);
        return e.response;
      }
    }

    throw e;
  }
}

export async function logout(): Promise<AuthResponse | unknown> {
  try {
    const response: {
      data: AuthResponse;
    } = await axiosInstance.get(`${CODELOGIC_API_BASE_URL}/logout`, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    if (isAxiosError(e)) {
      return e.response;
    }
    throw e;
  }
}
