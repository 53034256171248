import {CODELOGIC_API_BASE_URL} from 'src/config';
import {axiosInstance} from 'src/api/baseApi';
import {
  ApiErrorResponse,
  CreateReleaseAssistantJobPayload,
  ComparisonJob,
  ComparisonJobById,
  ReleaseAssistantJobResult,
  TypedApiResponse,
  TypedApiSuccessResponse,
} from 'src/types';
import {isAxiosError} from 'src/api/utils';

export async function getReleaseJobs({
  page,
  size,
}: {
  page?: number,
  size?: number,
}) {
  const endpoint = `${CODELOGIC_API_BASE_URL}/comparison-job/list/`;
  try {
    const response = await axiosInstance.get<TypedApiResponse<Array<ComparisonJob>>>(endpoint, {
      withCredentials: true,
      params: {
        page,
        size,
        type: 'RELEASE_ASSISTANT',
      },
    });
    return response.data;
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
}

export async function getReleaseJobResults({
  comparisonJobId,
  page,
  size,
}: {
  comparisonJobId: string,
  page?: number,
  size?: number,
}) {
  const endpoint = `${CODELOGIC_API_BASE_URL}/comparison-job/${comparisonJobId}/results`;
  try {
    const response = await axiosInstance.get<TypedApiSuccessResponse<Array<ReleaseAssistantJobResult>>>(endpoint, {
      withCredentials: true,
      params: {
        page,
        size,
        type: 'RELEASE_ASSISTANT',
      },
    });
    return response.data;
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
}

export async function createReleaseJob(releaseJob: CreateReleaseAssistantJobPayload) {
  const endpoint = `${CODELOGIC_API_BASE_URL}/comparison-job/`;
  try {
    const response = await axiosInstance.post<TypedApiResponse<ComparisonJob>>(endpoint, {}, {
      params: releaseJob,
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
}

export async function getJobResultCsv(
    id: string,
) {
  try {
    const response = await axiosInstance.get<string>(
        `${CODELOGIC_API_BASE_URL}/comparison-job/${id}/results/csv`,
        {withCredentials: true},
    );
    return response;
  } catch (e) {
    if (isAxiosError<ApiErrorResponse>(e)) {
      if (e.response) {
        return e.response;
      }
    }
    throw e;
  }
}

export async function getComparisonJob({
  comparisonJobId,
}: {
  comparisonJobId: string,
}) {
  const endpoint = `${CODELOGIC_API_BASE_URL}/comparison-job/${comparisonJobId}`;
  try {
    const response = await axiosInstance.get<TypedApiSuccessResponse<ComparisonJobById>>(endpoint, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
}

export const deleteComparisonJob = async (id: string): Promise<TypedApiResponse<any> | ApiErrorResponse | Error> => {
  try {
    const {data} =
      await axiosInstance.delete<TypedApiResponse<any>>(
          `${CODELOGIC_API_BASE_URL}/comparison-job/${encodeURIComponent(id)}`,
          {withCredentials: true});
    return data;
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response) {
        return e.response.data;
      }
    }
    throw e;
  }
};

