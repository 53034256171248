import {MouseEvent, ReactNode} from 'react';
import {IoEllipsisHorizontal} from 'react-icons/io5';

import {Menu} from 'src/components';

type Option = {
  onClick?: (e: MouseEvent) => void
  title?: string,
  content: ReactNode
  disabled?: boolean,
  hidden?: boolean,
  header?: boolean
}

type Props = {
  title?: string,
  options: Array<Option>
  hidden?: boolean,
}

export const ActionsMenu = ({title, options, hidden}: Props) => (
  <Menu
    type="link"
    title={title}
    styles={{
      toggle: {
        fontSize: '1em',
      },
    }}
    label={<IoEllipsisHorizontal />}
    options={options}
    hidden={hidden}
  />
);
