import {Diagram, Size} from 'src/util/gojs/go';
export const makeDiagramExporter = (diagram: Diagram) => () => {
  const svg = diagram?.makeSvg(
      {
        scale: 1,
        maxSize: new Size(Infinity, Infinity),
      },
  );
  const rootNode = svg?.getRootNode({});
  if (rootNode) {
    const svgstr = new XMLSerializer().serializeToString(rootNode);
    const blob = new Blob([svgstr], {type: 'image/svg+xml'});

    const url = window.URL.createObjectURL(blob);
    const filename = 'mySVGFile.svg';

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;

    document.body.appendChild(a);
    requestAnimationFrame(() => {
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  }
};
