import {Panel, GraphObject} from 'gojs';

import {MakeFnInitializers} from 'src/util/gojs/types';
import {processRest} from 'src/util/gojs/utils';

import {BACKGROUND_COLOR} from '../styles';

const {make, Fill} = GraphObject;

export const col = (rest?: MakeFnInitializers) =>
  make(
      Panel,
      Panel.Vertical,
      {
        background: BACKGROUND_COLOR.col,
        stretch: Fill,
      },
      processRest(rest),
  );

