import {useEffect, useState} from 'react';

import {SpinnerPlaceholder, TextPlaceholder} from './components';

import './styles.css';

const LOADING_PLACEHOLDER_DELAY_MS = 500;

type Position = 'initial' | 'inherit' | 'absolute' | 'relative';

export const LoadingPlaceholder = ({
  position = 'initial',
  title,
  scale = 1,
  noDelay = false,
  plaintext = false,

}: {
  title?: string,
  position?: Position,
  scale?: number,
  noDelay?: boolean
  plaintext?: boolean
}) => {
  const [displayed, setDisplayed] = useState(false);

  useEffect(() => {
    if (noDelay) {
      setDisplayed(true);
    } else {
      const timer = setTimeout(() => {
        setDisplayed(true);
      }, LOADING_PLACEHOLDER_DELAY_MS);

      return () => clearTimeout(timer);
    }
  }, []);

  if (!displayed) {
    return null;
  }

  return (
      plaintext ? (
        <TextPlaceholder title={title}/>
      ) : (
        <div
          className="LoadingPlaceholder"
          style={{
            transform: `scale(${scale})`,
            position,
          }}
        >
          <SpinnerPlaceholder title={title}/>
        </div>
      )
  );
};
