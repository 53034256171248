import {SlackConfig} from 'src/types';
import {makeResourceListFetch, makeResourceFetch} from 'src/api';

export const ROOT_PATH = 'notifications/service/config/slack';

type Entity = SlackConfig;

type CreateEntity = Omit<Entity, 'id'>;

export const create =
  makeResourceFetch<Entity, CreateEntity>({
    path: ROOT_PATH,
    method: 'POST',
  });

export const update =
  makeResourceFetch<Entity, Entity>({
    path: ROOT_PATH,
    method: 'PUT',
  });

export const list =
  makeResourceListFetch<Array<Entity>, Record<never, never>>({
    path: `${ROOT_PATH}/list/`,
  });
