import {Form, Formik} from 'formik';
import {Button} from 'reactstrap';
import React from 'react';

import {AuthorizationType, BasicAuthorizationConfig} from 'src/types';
import {updateAuthConfig} from 'src/components/Administration/Auth/utils';

import {Props} from './types';

const isSuccess = (response: any)
  : response is BasicAuthorizationConfig => {
  return 'authorizationType' in response && response.authorizationType === AuthorizationType.BASIC;
};

export const BasicForm = ({onSuccess: onSuccess} : Props) => {
  return (
    <>
      <Formik<any>
        initialValues={{
        }}
        onSubmit={async (values) => {
          const config: BasicAuthorizationConfig = {
            authorizationType: AuthorizationType.BASIC,
            externalGroupToInternalRoleMap: {},
          };
          const result = await updateAuthConfig(config);
          if (isSuccess(result)) {
            onSuccess(result);
          }
        }}
      >
        {({isSubmitting, errors}) => (
          <>
            <div style={{padding: '1rem'}}><p>Standard authentication method</p></div>
            <Form>
              <Button color="secondary" style={{marginRight: '1rem'}}>Cancel</Button>
              <Button type="submit" color="primary">Save</Button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
