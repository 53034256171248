import {PropsWithChildren, useContext, useEffect, useState} from 'react';
import {useSubscription} from 'react-stomp-hooks';
import {Button} from 'reactstrap';

import {WorkspaceContext} from 'src/context';
import {NoDataPlaceholder, LoadingPlaceholder} from 'src/components';
import {getMaterializedViewId} from 'src/api/materializedViews';
import {MaterializedView, MaterializedViewStatusMessage} from 'src/types';

import './styles.css';

export const WorkingOnIt = ({
  gotData = () => {},
  hadData = () => {},
  children,
}: PropsWithChildren<{
  gotData?: (materializedView?: MaterializedView) => void,
  hadData?: () => void}>,
) => {
  const {currentWorkspace} = useContext(WorkspaceContext);
  const [hasMatView, setHasMatView] = useState(true);

  useSubscription('/topic/materializedViews', (message) => {
    const response: MaterializedViewStatusMessage = JSON.parse(message.body);
    if (response.workspaceId && response.workspaceId === currentWorkspace?.id) {
      const materializedView = response.materializedView;
      if (materializedView.buildEnded) {
        gotData && gotData(materializedView);
        setHasMatView(true);
      }
    }
  });


  const initData = async () => {
    if (currentWorkspace?.materializedViewDefinition && !currentWorkspace.materializedViewDefinition.primary) {
      const materializedViewId = await getMaterializedViewId(currentWorkspace.materializedViewDefinition.id);
      if (materializedViewId.status === 'success' && materializedViewId.data) {
        setHasMatView(true);
        hadData();
      } else {
        setHasMatView(false);
      }
    } else if (currentWorkspace?.materializedViewDefinition && currentWorkspace.materializedViewDefinition.primary) {
      hadData();
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <>
      <div className='WorkingOnIt'>
        {currentWorkspace && !currentWorkspace.primary && !hasMatView ? (
          <h1>Building Graph<LoadingPlaceholder noDelay={true} plaintext={true} /></h1>
        ) : (
          <h1>New scan data available <Button style={{fontSize: '1em'}} color='link' onClick={() => {}}>Load Now</Button></h1>
        )}
      </div>
      {currentWorkspace && currentWorkspace.primary && !hasMatView &&
        <NoDataPlaceholder>
          {children}
        </NoDataPlaceholder>
      }
    </>);
};
