import {useContext, useState, useRef} from 'react';
import {v4 as uuid} from 'uuid';

import {AdminPrivilegesTooltip, Modal} from 'src/components';
import {AuthContext} from 'src/context';
import {createAgent} from 'src/api/agents';
import {UserRole} from 'src/types';

import {MultiStepForm} from './Form';
import {AgentProperties} from './Form/types';
import {AgentType} from './types';


import './styles.css';

export const AgentCommandGenerator = ({type}: {type: AgentType}) => {
  const {current: id} = useRef(`id-${uuid().replace(/-/g, '')}`);
  const {hasRole} = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [agentLimitReached, setAgentLimitReached] = useState(false);
  const initialState = {
    agentUUID: '<put UUID here>',
    agentPassword: '<put password here>',
  };
  const [agentProperties, setAgentProperties] = useState<AgentProperties>(initialState);

  async function retrieveCredentials() {
    const response = await createAgent({label: type + '-test', description: 'Auto generated agent value!'});
    if (response.status === 'success') {
      setAgentProperties({
        agentUUID: response.data.agentId,
        agentPassword: response.data.password,
      });
    }
    return response;
  }

  const open = async () => {
    const response = await retrieveCredentials();
    if (response.status === 'success') {
      setIsOpen(true);
    } else if (response.status === 'error' && response.error.code === 'REQ_BAD_LICENSE_LIMIT_REACHED') {
      setAgentLimitReached(true);
      setIsOpen(true);
    }
  };

  const close = () => {
    setAgentProperties(initialState);
    setIsOpen(false);
  };

  const canGenerateCommand = hasRole(UserRole.ADMIN);

  return (
    <>
      <button
        id={id}
        onClick={open}
        className="AgentCommandGenerator link-button"
        disabled={!canGenerateCommand}
      >
        Generate
      </button>
      <AdminPrivilegesTooltip placement='bottom' target={`#${id}`}/>
      <Modal isOpen={isOpen} toggle={close} fade={false} modalClassName="height-auto">
        <MultiStepForm
          agentLimitReached={agentLimitReached}
          agentType={type}
          onCancel={close}
          onSubmit={close}
          agentProperties={agentProperties}
        />
      </Modal>
    </>
  );
};
